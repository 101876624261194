import { Box, Fab, Tooltip } from '@mui/material';
import { green } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from 'src/components/mui-components';
import { AssignAndPlanIcon, AssignNowIcon } from '../AssignIcons';

interface IAssignButtons {
  bulkAssign: () => void;
  id: string;
  isLoading: boolean;
  setOpen: (o: boolean) => void;
}

export const AssignButtons = ({ bulkAssign, id, isLoading, setOpen }: IAssignButtons) => {
  const { t } = useTranslation('assignFlow');

  return (
    <>
      <Tooltip disableInteractive title={t('ListActionAssignAndPlanButton')}>
        <Fab
          aria-label={t('ListActionAssignAndPlanButton')}
          color="primary"
          data-automation-id={`AssignAndPlanButton-${id}`}
          disabled={isLoading}
          onClick={() => setOpen(true)}
          size="small"
        >
          <AssignAndPlanIcon size={24} />
        </Fab>
      </Tooltip>
      <Tooltip disableInteractive title={t('ListActionAssignNowButton')}>
        <Box sx={{ position: 'relative' }}>
          <Fab
            aria-label={t('ListActionAssignNowButton')}
            color="primary"
            data-automation-id={`AssignNowButton-${id}`}
            disabled={isLoading}
            onClick={bulkAssign}
            size="small"
          >
            <AssignNowIcon size={24} />
          </Fab>
          {isLoading ? (
            <CircularProgress
              size={48}
              sx={{
                color: green[500],
                insetBlockStart: -4,
                insetInlineStart: -4,
                position: 'absolute',
                zIndex: 1051,
              }}
            />
          ) : null}
        </Box>
      </Tooltip>
    </>
  );
};
