export const chartColors = [
  [
    '#3646B5',
    '#3A66D5',
    '#3C77E8',
    '#3E8BFC',
    '#3D9AFF',
    '#4FA9FF',
    '#6CB9FF',
    '#96CDFF',
    '#BFE0FF',
    '#E4F3FF',
  ],
  [
    '#1D565A',
    '#247984',
    '#288C9C',
    '#2DA1B5',
    '#30B1C8',
    '#3CBCD0',
    '#56C7D9',
    '#82D8E5',
    '#B2E7EF',
    '#E0F5F9',
  ],
  [
    '#4A6728',
    '#6B8838',
    '#7F9C40',
    '#93B04A',
    '#A3C052',
    '#B1C96B',
    '#BFD285',
    '#D1DFA8',
    '#E3ECC9',
    '#F4F7EA',
  ],
  [
    '#005B00',
    '#007A00',
    '#008A00',
    '#119C12',
    '#20AB1C',
    '#4CB845',
    '#6FC468',
    '#9AD494',
    '#C2E5BE',
    '#E6F5E4',
  ],
  [
    '#D56200',
    '#DA8B00',
    '#DCA200',
    '#DEB902',
    '#E0CA06',
    '#E4D13B',
    '#E8D85F',
    '#EFE38C',
    '#F6EEBA',
    '#FBF9E3',
  ],
  [
    '#BA1A1A',
    '#C92726',
    '#D62E2E',
    '#E83833',
    '#F64234',
    '#F2534F',
    '#E77372',
    '#F19A9A',
    '#FFCDD2',
    '#FFEBEE',
  ],
  [
    '#821E62',
    '#A9236E',
    '#BF2574',
    '#D6267C',
    '#E82881',
    '#E84895',
    '#EA68AA',
    '#EE93C3',
    '#F3BEDB',
    '#FAE5F0',
  ],
  [
    '#212121',
    '#424242',
    '#616161',
    '#757575',
    '#9E9E9E',
    '#BDBDBD',
    '#E0E0E0',
    '#EEEEEE',
    '#F5F5F5',
    '#FAFAFA',
  ],
];
