export const systemAdministrationRoutes = [
  '/system-administration/*',
  '/Settings/*',
  '/Employee/EmployeeType',
  '/Employee/EmployeeType/Index',
  '/Financial/ProductNumber',
  '/Financial/ProductNumber/Index',
];

export const personalSettingsRoutes = ['/Settings/UserSettings/*'];
export const designSixPageSettingsRoutes = ['/PersonalPageSettings/*'];
export const editProjectTemplateRoute = '/Settings/ProjectTemplate/EditProjectTemplate/';
