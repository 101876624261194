import { Today } from '@mui/icons-material';
import { Grid, Typography, Stack } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DatePicker,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from 'src/components/mui-components';

interface ISelectedAction {
  value: string;
  body?: { [key: string]: string | boolean };
  uri?: string;
}

interface IProjectActionDialog {
  showActionUpdate: boolean;
  setShowActionUpdate: Dispatch<SetStateAction<boolean>>;
  applyProjectsUpdate: (uri?: string, validDate?: Date) => void;
  selectedAction: ISelectedAction;
}

export const ProjectActionDialog = ({
  showActionUpdate,
  setShowActionUpdate,
  applyProjectsUpdate,
  selectedAction,
}: IProjectActionDialog) => {
  const { t } = useTranslation('searchProjects');

  const [validDate, setValidDate] = useState<Date | null>(new Date());

  const handleValidDateChange = (date: Date | null) => {
    if (date instanceof Date) {
      const offset = -date.getTimezoneOffset();
      date.setHours(Math.trunc(offset / 60), offset % 60);
    }
    setValidDate(date);
  };

  const setTodayDate = () => {
    handleValidDateChange(new Date());
  };

  const handleActionsApply = (uri?: string) => {
    applyProjectsUpdate(uri, validDate ?? undefined);
  };

  return (
    <Dialog
      open={!!showActionUpdate}
      onClose={() => setShowActionUpdate(false)}
      aria-labelledby="dialog-text"
      aria-describedby="dialog-text-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="dialog-text">
        {selectedAction?.uri === 'stage' ? t('ChangeStageToText') : t('ChangeStatusToText')}{' '}
        {selectedAction?.uri === 'stage'
          ? selectedAction.value
          : t(`Status${selectedAction.value}Text`)}{' '}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="center" sx={{ mt: '0' }}>
          <Grid item xs={2}>
            <Typography variant="h4">{t('ValidFromText')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row">
              <DatePicker
                format="dd-MM-yyyy"
                onChange={(value) => handleValidDateChange(value)}
                value={validDate || null}
                slotProps={{
                  textField: { size: 'small', id: 'valid-date-picker' },
                }}
              />
              <IconButton
                title={t('SetTodayDateTooltipText')}
                data-automation-id="SetTodayDateTooltipButton"
                onClick={() => setTodayDate()}
              >
                <Today fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setShowActionUpdate(false)}>
          {t('CancelButton')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleActionsApply(selectedAction?.uri);
            setShowActionUpdate(false);
          }}
        >
          {t('OkButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
