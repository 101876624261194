import { useActive, useCommands } from '@remirror/react';
import { useTranslation } from 'react-i18next';
import { Stack, IconButton, ToggleButtonGroup, ToggleButton } from 'src/components/mui-components';
import {
  Code,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatStrikethrough,
  FormatUnderlined,
  HorizontalRule,
  RedoOutlined,
  UndoOutlined,
} from '@mui/icons-material';
import { InsertImage, SelectHeading, UpdateLink } from './components';
import { useBuildShortcutString } from './helpers/getOSControlKey';

export const Menu = ({ className }: { className?: string }) => {
  const commands = useCommands();
  const active = useActive();
  const fontStyleFormatsArray = [
    ...(active.bold() ? ['bold'] : []),
    ...(active.italic() ? ['italic'] : []),
    ...(active.underline() ? ['underline'] : []),
    ...(active.strike() ? ['strike'] : []),
    ...(active.horizontalRule() ? ['horizontalRule'] : []),
  ];

  const layoutFormatsArray = [
    ...(active.bulletList() ? ['bulletList'] : []),
    ...(active.orderedList() ? ['orderedList'] : []),
    ...(active.codeBlock() ? ['codeBlock'] : []),
  ];

  let alignmentArray = 'left';

  if (active.heading({ nodeTextAlignment: 'center' })) {
    alignmentArray = 'center';
  } else if (active.heading({ nodeTextAlignment: 'justify' })) {
    alignmentArray = 'justify';
  } else if (active.heading({ nodeTextAlignment: 'right' })) {
    alignmentArray = 'right';
  } else if (active.heading({ nodeTextAlignment: 'left' })) {
    alignmentArray = 'left';
  } else if (active.paragraph({ nodeTextAlignment: 'center' })) {
    alignmentArray = 'center';
  } else if (active.paragraph({ nodeTextAlignment: 'justify' })) {
    alignmentArray = 'justify';
  } else if (active.paragraph({ nodeTextAlignment: 'right' })) {
    alignmentArray = 'right';
  } else if (active.paragraph({ nodeTextAlignment: 'left' })) {
    alignmentArray = 'left';
  }

  const { t } = useTranslation('richTextEditor');
  const shortcutString = useBuildShortcutString();

  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" className={className}>
      <Stack direction="row" gap={0.5}>
        <IconButton
          data-automation-id="ibtnFormatUndo"
          title={shortcutString(t('FormatUndoLabel'), 'Z')}
          disabled={!commands.undo.enabled()}
          onClick={() => {
            commands.undo();
            commands.focus();
          }}
        >
          <UndoOutlined />
        </IconButton>
        <IconButton
          data-automation-id="ibtnFormatRedo"
          title={shortcutString(t('FormatRedoLabel'), 'Y')}
          disabled={!commands.redo.enabled()}
          onClick={() => {
            commands.redo();
            commands.focus();
          }}
        >
          <RedoOutlined />
        </IconButton>
      </Stack>
      <SelectHeading />
      <ToggleButtonGroup value={fontStyleFormatsArray} size="small">
        <ToggleButton
          value="bold"
          data-automation-id="ibtnFormatBold"
          disabled={!commands.toggleBold.enabled()}
          onClick={() => {
            commands.toggleBold();
            commands.focus();
          }}
          tooltipProps={{ title: shortcutString(t('FormatBoldLabel'), 'B') }}
        >
          <FormatBold />
        </ToggleButton>
        <ToggleButton
          value="italic"
          data-automation-id="ibtnFormatItalic"
          disabled={!commands.toggleItalic.enabled()}
          onClick={() => {
            commands.toggleItalic();
            commands.focus();
          }}
          tooltipProps={{ title: shortcutString(t('FormatItalicLabel'), 'I') }}
        >
          <FormatItalic />
        </ToggleButton>
        <ToggleButton
          value="underline"
          data-automation-id="ibtnFormatUnderlined"
          disabled={!commands.toggleUnderline.enabled()}
          onClick={() => {
            commands.toggleUnderline();
            commands.focus();
          }}
          tooltipProps={{ title: shortcutString(t('FormatUnderlinedLabel'), 'U') }}
        >
          <FormatUnderlined />
        </ToggleButton>
        <ToggleButton
          value="strike"
          data-automation-id="ibtnFormatStrike"
          disabled={!commands.toggleStrike.enabled()}
          onClick={() => {
            commands.toggleStrike();
            commands.focus();
          }}
          tooltipProps={{ title: shortcutString(t('FormatStrikeThroughLabel')) }}
        >
          <FormatStrikethrough />
        </ToggleButton>
        <ToggleButton
          value="horizontalRule"
          data-automation-id="ibtnFormatHorizontalRule"
          disabled={!commands.insertHorizontalRule.enabled()}
          onClick={() => {
            commands.insertHorizontalRule();
            commands.focus();
          }}
          tooltipProps={{ title: shortcutString(t('FormatHorizontalRuleLabel')) }}
        >
          <HorizontalRule />
        </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup value={layoutFormatsArray} size="small">
        <ToggleButton
          value="bulletList"
          data-automation-id="ibtnFormatListBulleted"
          disabled={!commands.toggleBulletList.enabled()}
          onClick={() => {
            commands.toggleBulletList();
            commands.focus();
          }}
          tooltipProps={{ title: shortcutString(t('FormatListBulletedLabel')) }}
        >
          <FormatListBulleted />
        </ToggleButton>
        <ToggleButton
          value="orderedList"
          data-automation-id="ibtnFormatListNumbered"
          disabled={!commands.toggleOrderedList.enabled()}
          onClick={() => {
            commands.toggleOrderedList();
            commands.focus();
          }}
          tooltipProps={{ title: shortcutString(t('FormatListNumberedLabel')) }}
        >
          <FormatListNumbered />
        </ToggleButton>

        <ToggleButton
          value="codeBlock"
          data-automation-id="ibtnFormatCode"
          disabled={active.codeBlock() || !commands.createCodeBlock.enabled({ language: '' })}
          onClick={() => {
            commands.createCodeBlock({ language: '' });
            commands.focus();
          }}
          tooltipProps={{ title: shortcutString(t('FormatCodeLabel')) }}
        >
          <Code />
        </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup value={alignmentArray} size="small" exclusive>
        <ToggleButton
          value="left"
          data-automation-id="ibtnFormatAlignLeft"
          disabled={
            !(
              active.heading({ nodeTextAlignment: 'center' }) ||
              active.heading({ nodeTextAlignment: 'justify' }) ||
              active.heading({ nodeTextAlignment: 'right' }) ||
              active.paragraph({ nodeTextAlignment: 'center' }) ||
              active.paragraph({ nodeTextAlignment: 'justify' }) ||
              active.paragraph({ nodeTextAlignment: 'right' })
            )
          }
          onClick={() => {
            commands.leftAlign();
            commands.focus();
          }}
          tooltipProps={{ title: shortcutString(t('FormatAlignLeftLabel')) }}
        >
          <FormatAlignLeft />
        </ToggleButton>
        <ToggleButton
          value="center"
          data-automation-id="ibtnFormatAlignCenter"
          disabled={
            active.heading({ nodeTextAlignment: 'center' }) ||
            active.paragraph({ nodeTextAlignment: 'center' })
          }
          onClick={() => {
            commands.centerAlign();
            commands.focus();
          }}
          tooltipProps={{ title: shortcutString(t('FormatAlignCenterLabel')) }}
        >
          <FormatAlignCenter />
        </ToggleButton>
        <ToggleButton
          value="right"
          data-automation-id="ibtnFormatAlignRight"
          disabled={
            active.heading({ nodeTextAlignment: 'right' }) ||
            active.paragraph({ nodeTextAlignment: 'right' })
          }
          onClick={() => {
            commands.rightAlign();
            commands.focus();
          }}
          tooltipProps={{ title: shortcutString(t('FormatAlignRightLabel')) }}
        >
          <FormatAlignRight />
        </ToggleButton>
        <ToggleButton
          value="justify"
          data-automation-id="ibtnFormatAlignJustify"
          disabled={
            active.heading({ nodeTextAlignment: 'justify' }) ||
            active.paragraph({ nodeTextAlignment: 'justify' })
          }
          onClick={() => {
            commands.justifyAlign();
            commands.focus();
          }}
          tooltipProps={{ title: shortcutString(t('FormatAlignJustifyLabel')) }}
        >
          <FormatAlignJustify />
        </ToggleButton>
      </ToggleButtonGroup>
      <Stack direction="row" gap={0.5}>
        <InsertImage />
        <UpdateLink />

        <IconButton
          data-automation-id="ibtnFormatIndentDecrease"
          disabled={!commands.decreaseIndent.enabled()}
          onClick={() => {
            commands.decreaseIndent();
            commands.focus();
          }}
        >
          <FormatIndentDecrease />
        </IconButton>
        <IconButton
          data-automation-id="ibtnFormatIndentIncrease"
          disabled={!commands.increaseIndent.enabled()}
          onClick={() => {
            commands.increaseIndent();
            commands.focus();
          }}
        >
          <FormatIndentIncrease />
        </IconButton>
      </Stack>
    </Stack>
  );
};
