import { useState } from 'react';
import { ContextMenu } from 'src/components/ui-components/ContextMenu';
import { Dialog, DialogContent, DialogTitle } from 'src/components/mui-components/Dialog';
import PreviewFeatures from 'src/screens/PreviewFeatures';
import { useTranslation } from 'react-i18next';
import { FeatureControl } from 'src/components/utils/FeatureControl';
import HelpButton from 'src/components/layout/Header/components/HelpButton';
import { useGetPreviewFeaturesCount } from 'src/apis/userPreviewFeaturesAPI';
import styles from './PreviewFeaturesButton.module.scss';

const previewFeatureButtonText = (translationText = '', previewCount = '') =>
  `${translationText} ${previewCount}`?.trim();

export const previewFeatureButtonTextForText = previewFeatureButtonText;

const PreviewFeatureTriggerButton = ({ setModalOpen }: any) => {
  const { t } = useTranslation('header');
  const { previewFeaturesCount } = useGetPreviewFeaturesCount();

  return (
    <ContextMenu.Item
      data-automation-id="HeaderPreviewFeatures"
      onSelect={() => setModalOpen(true)}
    >
      {previewFeatureButtonText(t('UserMenuPreviewFeaturesLink'), previewFeaturesCount)}
    </ContextMenu.Item>
  );
};

const PreviewFeaturesButton = () => {
  const { t } = useTranslation('header');
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <FeatureControl indexControlName="UserPreviewFeatures">
      <>
        <PreviewFeatureTriggerButton setModalOpen={setModalOpen} />
        <Dialog
          data-automation-id="ModalPreviewFeatures"
          aria-labelledby="preview-feature"
          fullWidth
          maxWidth="sm"
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <DialogTitle
            id="preview-feature"
            closeText={t('UserMenuPreviewFeaturesCloseModal')}
            closeModal={() => setModalOpen(false)}
            headingComponent={
              <FeatureControl indexControlName="Users">
                <HelpButton data-automation-id="HeaderHelpSectionPreviewFeatures" />
              </FeatureControl>
            }
          >
            {t('UserMenuPreviewFeaturesLink')}
          </DialogTitle>
          <DialogContent
            sx={{
              pt: 2,
              pb: 0,
              px: 0,
            }}
          >
            <div className={styles.PreviewFeaturesWrapper}>
              <PreviewFeatures />
            </div>
          </DialogContent>
        </Dialog>
      </>
    </FeatureControl>
  );
};

export default PreviewFeaturesButton;
