export default {
  model: {
    properties: {
      count: 1,
    },
    class: 'viewselector',
    entities: [
      {
        class: 'viewselector',
        properties: {
          name: 'currency',
          options: [
            {
              value: 'currency-project',
              selected: true,
            },
            {
              value: 'currency-system',
              selected: false,
            },
            {
              value: 'currency-euro',
              selected: false,
            },
          ],
        },
      },
    ],
    links: [
      {
        href: '/viewoptions',
        rel: 'self',
      },
    ],
  },
};
