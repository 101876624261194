import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { ITimeTrackingDelay } from 'src/apis/types/timeTrackingDelayAPI';

const GET_TIME_TRACKING_DELAY_REPORTING_WIDGET_URL_PATH =
  '/api/v2/insights-service/time-tracking-delay';
const GET_TIME_TRACKING_DELAY_REPORTING_WIDGET_MSW_STATUS_KEY = 'getTimeTrackingDelayAPI';

const GET_TIME_TRACKING_DELAY_WIDGET_KEY = 'GET_TIME_TRACKING_DELAY_REPORTING_WIDGET_KEY';

const getTimeTrackingDelayReportingWidget = (): Promise<ITimeTrackingDelay> =>
  getFetch({
    path: GET_TIME_TRACKING_DELAY_REPORTING_WIDGET_URL_PATH,
    key: GET_TIME_TRACKING_DELAY_REPORTING_WIDGET_MSW_STATUS_KEY,
  });

export const useGetTimeTrackingDelayReportingWidget = () => {
  const { data, ...restProps } = useQuery([GET_TIME_TRACKING_DELAY_WIDGET_KEY], () =>
    getTimeTrackingDelayReportingWidget(),
  );

  if (data?.properties) {
    return {
      widgetName: data?.properties.type,
      widgetData: data?.properties.data,
      ...restProps,
    };
  }

  return {
    widgetName: '',
    widgetData: {
      lastRegistration: new Date(),
      currentPeriodDelayedTimeTracking: 0,
      previousPeriodDelayedTimeTracking: 0,
      currentPeriodRegistrationCount: 0,
    },
    ...restProps,
  };
};
