import { Menu as MuiMenu, MenuProps as MuiMenuProps } from '@mui/material';
import styles from './TabMenu.module.scss';

/**
 * A wrapper of the MUI Menu component with an upward arrow above the menu's Paper.
 *
 * Default values applied to the Menu component:
 * - `transformOrigin: { horizontal: 'center', vertical: 'top' }`
 * - `anchorOrigin: { horizontal: 'center', vertical: 'bottom' }`
 * - `slotProps: { paper: { className: styles.MenuPaper } }`
 */
export const TabMenu = ({ transformOrigin, anchorOrigin, slotProps, ...rest }: MuiMenuProps) => (
  <MuiMenu
    transformOrigin={{ horizontal: 'center', vertical: 'top' }}
    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    {...rest}
    slotProps={{
      ...slotProps,
      paper: {
        ...slotProps?.paper,
        className: styles.MenuPaper,
      },
    }}
  />
);
