import classNames from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from 'src/components/mui-components';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { convertNumber, formatNumber, validateNumberInput } from 'src/utils/number';
import { stringToPascal } from 'src/utils/string';
import styles from './CustomizedCell.module.scss';

type CustomizedCellProps = {
  row: number;
  column: string;
  value: string;
  editMode?: boolean;
  isImmutable: boolean;
  onCellValueChange: (value: string) => void;
};

const CustomizedCell = ({
  row,
  column,
  value,
  editMode,
  isImmutable,
  onCellValueChange,
}: CustomizedCellProps) => {
  const [editValue, setEditValue] = useState(value);
  const [error, setError] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const { t } = useTranslation('systemAdminExchangeRate');

  const siteLocale = useGetLocale();
  const actualValueInt = parseFloat(value);

  useEffect(() => {
    const fullValue = actualValueInt.toLocaleString(siteLocale, {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
    setEditValue(fullValue);
  }, [siteLocale, actualValueInt]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditValue(event.target.value);
  };

  const handleBlur = () => {
    const { status, value: outputValue } = validateNumberInput(
      editValue,
      t('errors.UseDecimalFormatValidationFeedback'),
    );

    if (status === 'error') {
      setError(outputValue);
      return;
    }
    const decimalFormat = formatNumber(outputValue, siteLocale, { min: 4, max: 4 });
    const currentValueInt = convertNumber(decimalFormat, siteLocale);

    setIsSaved(true);
    setError('');
    onCellValueChange(currentValueInt.toString());
  };

  const onFocus = () => {
    setIsSaved(false);
  };

  return (
    <div>
      {editMode && !isImmutable ? (
        <TextField
          hiddenLabel
          ariaLabel={`tableRow${row} tableColumn${stringToPascal(column)}`}
          size="small"
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={onFocus}
          value={editValue}
          inputProps={{ style: { fontSize: 14 } }}
          errorMessage={error}
          usePopOutValidation
          status="error"
          className={classNames({
            [styles.saved]: isSaved,
          })}
          data-automation-id="ExchangeRateTableCellEditInput"
        />
      ) : (
        <div>{formatNumber(value, siteLocale, { min: 4, max: 4 })}</div>
      )}
    </div>
  );
};

export default CustomizedCell;
