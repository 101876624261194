import { Dispatch, SetStateAction } from 'react';
import { IParent } from 'src/screens/ResourcePlanner/types/resourcePlanner';

export interface IAssignFlowValue {
  endsAt: Date;
  isWorkItem: boolean;
  name: string;
  resourceId: string;
  resourceSourceReferenceId: string;
  resourceType: string;
  startsAt: Date;
  workItemId: string;
  workItemSourceReferenceId: string;
  workItemType: string;
  hideTooltip?: boolean;
  parentProject?: IParent;
  parentTask?: IParent;
  setHideTooltip?: Dispatch<SetStateAction<boolean>>;
}

export const INITIAL_ASSIGN_FLOW_STORE: IAssignFlowValue = {
  endsAt: new Date(),
  isWorkItem: false,
  name: '',
  resourceId: '',
  resourceSourceReferenceId: '',
  resourceType: '',
  startsAt: new Date(),
  workItemId: '',
  workItemSourceReferenceId: '',
  workItemType: '',
};
