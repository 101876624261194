import { useSystemAdministrationMenu } from 'src/apis/menusAPI/systemAdministrationAPI';
import VerticalNavigationSearchBar from 'src/components/layout/VerticalNavigationSearchBar';
import LayoutSettings from 'src/components/layout/LayoutSettings';
import { Spinner } from 'src/components/ui-components/Spinner';
import { Icon } from 'src/components/ui-components/Icon';
import LegacyPage from 'src/components/layout/LegacyPage';
import { useTranslation } from 'react-i18next';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import {
  systemAdminDesignSixPagePath,
  systemAdminDesignSevenPagePath,
} from 'src/designSevenPagePath';
import { IMenusPropertiesWithPagePath, ISubMenusWithPagePath } from 'src/apis/types/menusAPI';
import { useVerifyDesignSevenPage } from 'src/apis/pagesAPI/designSevenAPI';
import { SystemAdminExchangeRates } from './Finance';
import { Competences } from './Employees';
import { FrontPage } from './General';
import { ResourceManagement } from './Projects';

const icons = {
  SystemAdministrationGeneralSettingRootPage: <Icon iconName="setting" />,
  SystemAdministrationRegistrationTimeRegistrationSetting: <Icon iconName="timeAndExp" />,
  SystemAdministrationApprovalSetting: <Icon iconName="like" />,
  SystemAdministrationReportActivation: <Icon iconName="report" />,
  SystemAdministrationPMProjectSetting: <Icon iconName="project" />,
  SystemAdministrationEmployeeSetting: <Icon iconName="employee" />,
  SystemAdministrationInvoiceSetting: <Icon iconName="finance" />,
  SystemAdministrationCRMSetting: <Icon iconName="customer" />,
  SystemAdministrationIntegrationSystemIntegration: <Icon iconName="integration" />,
  SystemAdministrationGeneralSettingDepartments: <Icon iconName="generalSetting" />,
  SystemAdministrationGeneralSettingLicenseSetting: <Icon iconName="profile" />,
};

export default () => {
  const { systemAdminMenu, isSystemAdministrationMenuEmpty, error, isLoading } =
    useSystemAdministrationMenu();
  const { t } = useTranslation('systemAdministration');
  const { pathname } = useLocation();
  const doesUserHaveAccessToPage = systemAdminMenu.some((obj: IMenusPropertiesWithPagePath) =>
    obj.subMenus?.some((subMenu: ISubMenusWithPagePath) => subMenu.pagePath === pathname),
  );
  const { isDesignSeven } = useVerifyDesignSevenPage('DesignSevenFrontPage');

  return !isSystemAdministrationMenuEmpty ? (
    <LayoutSettings
      navigation={
        <VerticalNavigationSearchBar
          icons={icons}
          t={t}
          menu={systemAdminMenu}
          error={error}
          isLoading={isLoading}
        />
      }
      title={t('HeaderTitle')}
    >
      <Routes>
        <Route
          path={systemAdminDesignSixPagePath.exchangeRate}
          element={
            doesUserHaveAccessToPage ? (
              <SystemAdminExchangeRates />
            ) : (
              <Navigate to="/error" state={{ from: pathname }} />
            )
          }
        />

        {isDesignSeven && (
          <>
            <Route
              path={systemAdminDesignSevenPagePath.employees.competences}
              element={
                doesUserHaveAccessToPage ? (
                  <Competences />
                ) : (
                  <Navigate to="/error" state={{ from: pathname }} />
                )
              }
            />
            <Route
              path={systemAdminDesignSevenPagePath.general.frontpage}
              element={
                doesUserHaveAccessToPage ? (
                  <FrontPage />
                ) : (
                  <Navigate to="/error" state={{ from: pathname }} />
                )
              }
            />
            <Route
              path={systemAdminDesignSevenPagePath.projects.resourceManagement}
              element={
                doesUserHaveAccessToPage ? (
                  <ResourceManagement />
                ) : (
                  <Navigate to="/error" state={{ from: pathname }} />
                )
              }
            />
          </>
        )}

        <Route path="*" element={<LegacyPage data-automation-id="LegacyPage" />} />
      </Routes>
    </LayoutSettings>
  ) : (
    <Spinner data-automation-id="spinner" />
  );
};
