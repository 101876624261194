import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFetch } from 'src/utils/fetcher';
import { GET_COMPETENCES_KEY } from 'src/apis/competencesAPI/types';
import { COMPETENCE_GROUPS_URL_PATH, GET_COMPETENCE_GROUPS_KEY } from '../types';

export const POST_COMPETENCE_GROUPS_MSW_STATUS_KEY = 'postCompetenceGroups';

export const POST_COMPETENCE_GROUPS_KEY = 'POST_COMPETENCE_GROUPS';

const postCompetenceGroups = ({ name }: { name: string }) =>
  postFetch({
    path: COMPETENCE_GROUPS_URL_PATH,
    key: POST_COMPETENCE_GROUPS_MSW_STATUS_KEY,
    body: JSON.stringify({
      Name: name,
    }),
  });

export const usePostCompetenceGroups = () => {
  const qc = useQueryClient();

  return useMutation(postCompetenceGroups, {
    onSuccess: () => {
      qc.invalidateQueries([GET_COMPETENCE_GROUPS_KEY]);
      qc.invalidateQueries([GET_COMPETENCES_KEY]);
    },
  });
};
