import { Icon, Text } from 'src/components/ui-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IValueText } from 'src/screens/Home/HomeType';
import { translationAnyText } from 'src/utils/translation';
import { getIconName } from '../../helpers';
import { ReplaceAbbrTag } from '../ReplaceAbbrTag';
import styles from './ValueText.module.scss';

export const ValueText = ({
  trending,
  value,
  translationKey,
  trendingDirection,
  abbrText,
  abbrTitle,
  ...restProps
}: IValueText) => {
  const { t } = useTranslation('frontpage');
  const translationText = translationAnyText(t, translationKey).replace('{{value}}', value);

  if (trending === 'Yes' || trending === 'No') {
    return (
      <dd
        className={classNames(styles.label, {
          [styles.trendingUp]: trending === 'Yes',
          [styles.trendingDown]: trending === 'No',
        })}
        {...restProps}
      >
        <ReplaceAbbrTag abbrText={abbrText} abbrTitle={abbrTitle}>
          {translationText}
        </ReplaceAbbrTag>
        <Icon iconName={getIconName({ trending, trendingDirection })} size="large" />
      </dd>
    );
  }
  return (
    <Text as="dd" bold size="subHeading" {...restProps}>
      <ReplaceAbbrTag abbrText={abbrText} abbrTitle={abbrTitle}>
        {translationText}
      </ReplaceAbbrTag>
    </Text>
  );
};
