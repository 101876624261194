import { forwardRef, InputHTMLAttributes } from 'react';
import logger from 'src/utils/logger';
import { TStatus } from 'src/assets/variables/status';
import { FormFieldValidation, FormField } from 'src/components/ui-components/FormField';
import { useAutoId } from 'src/hooks/useAutoId';

import styles from './RadioButton.module.scss';

export interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  /** Label */
  label: string;
  /** Element id attribute if you need control */
  id?: string;
  /** Element name attribute */
  name: string;
  /** Status (ENUM: Success, Warning, Error) */
  status?: TStatus;
  /** Validation object */
  validationMsg?: string;
}

type Ref = HTMLInputElement;

export const RadioButton = forwardRef<Ref, RadioButtonProps>(
  ({ id, label, name, status, validationMsg, ...props }, ref) => {
    const autoId = useAutoId(id);
    // We want to have a warning in the console if a label is empty string.
    if (label === '') logger.warn('`label` must never be an empty string!');
    if (name === '') logger.warn('`name` must never be an empty string!');

    return (
      <FormField>
        <div className={styles.wrapper}>
          <input
            className={styles.control}
            id={autoId}
            name={name}
            type="radio"
            aria-describedby={status ? `radioButtonDesc-${autoId}` : undefined}
            ref={ref}
            {...props}
          />
          <label htmlFor={autoId} className={styles.label}>
            <span className={styles.pseudoControl} aria-hidden="true" />
            <span className={styles.labelText}>{label}</span>
          </label>
        </div>
        {!!validationMsg && (
          <FormFieldValidation validationMsg={validationMsg} id={`radioButtonDesc-${autoId}`} />
        )}
      </FormField>
    );
  },
);

export default RadioButton;
