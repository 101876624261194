import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { getPathWithQueries } from 'src/utils/url/url';
import { THourlyRatesRes } from './getHourlyRatesAPI.types';

const GET_HOURLY_RATES_URL_PATH = '/api/v2/contract-hourly-rates/dropdown-content';
const GET_HOURLY_RATES_KEY = 'HourlyRates';

interface IGetHourlyRates {
  suggestedHourlyRateId?: string;
  taskId?: string;
}

const getHourlyRates = ({
  suggestedHourlyRateId,
  taskId,
}: IGetHourlyRates): Promise<THourlyRatesRes> =>
  getFetch({
    path: getPathWithQueries(GET_HOURLY_RATES_URL_PATH, {
      suggestedHourlyRateId,
      taskId,
    }),
    key: GET_HOURLY_RATES_KEY,
  });

export const useGetHourlyRates = (params: IGetHourlyRates, enableQuery?: boolean) => {
  const { suggestedHourlyRateId, taskId } = params;
  const { data, ...rest } = useQuery(
    [GET_HOURLY_RATES_KEY, suggestedHourlyRateId, taskId],
    () => getHourlyRates(params),
    { enabled: enableQuery && !!suggestedHourlyRateId && !!taskId },
  );

  return { data: data?.entities, ...rest };
};
