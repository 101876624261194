import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';
import { GET_FEATURES_KEY } from '../const';

const PUT_FEATURES_URL_PATH = '/api/v2/features';
export const PUT_FEATURES_MSW_STATUS_KEY = 'putFeatureAPI';
export const PUT_FEATURES_KEY = 'PUT_FEATURES';

export interface IUpdateFeature {
  featureId: number;
  featureEnable: boolean;
}

const updateFeature = (updateModel: IUpdateFeature) =>
  putFetch({
    path: `${PUT_FEATURES_URL_PATH}${concatQuerys([
      { param: 'identifier', value: updateModel.featureId.toString() },
    ])}`,
    key: PUT_FEATURES_MSW_STATUS_KEY,
    body: JSON.stringify({
      enabled: updateModel.featureEnable,
    }),
  });

export const usePutFeature = () => {
  const queryClient = useQueryClient();

  return useMutation(updateFeature, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_FEATURES_KEY]);
    },
  });
};
