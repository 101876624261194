import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { IOverdueInvoices } from 'src/apis/types/overdueInvoicesAPI';
import { concatQuerys } from 'src/utils/url';
import { IPaginationModel } from 'src/apis/types/paginationModelAPI';

const GET_OVERDUE_INVOICES_REPORTING_WIDGET_URL_PATH = '/api/v2/insights-service/overdue-invoice';
const GET_OVERDUE_INVOICES_REPORTING_WIDGET_MSW_STATUS_KEY = 'getOverdueInvoicesAPI';

const GET_OVERDUE_INVOICES_REPORTING_WIDGET_KEY = 'GET_OVERDUE_INVOICES_REPORTING_WIDGET_KEY';

const getOverdueInvoicesReportingWidget = (
  page: number = 0,
  pageSize: number = 0,
): Promise<IOverdueInvoices> =>
  getFetch({
    path: `${GET_OVERDUE_INVOICES_REPORTING_WIDGET_URL_PATH}${concatQuerys([
      { param: '$pagesize', value: pageSize.toString() },
      { param: '$page', value: page.toString() },
    ])}`,
    key: GET_OVERDUE_INVOICES_REPORTING_WIDGET_MSW_STATUS_KEY,
  });

export const useGetOverdueInvoicesReportingWidget = ({ page, pageSize }: IPaginationModel) => {
  const { data, ...restProps } = useQuery(
    [GET_OVERDUE_INVOICES_REPORTING_WIDGET_KEY, page, pageSize],
    () => getOverdueInvoicesReportingWidget(page + 1, pageSize),
    { keepPreviousData: true },
  );

  if (data?.properties) {
    return {
      widgetName: data?.properties.type,
      widgetData: data?.properties.data,
      currencyAbb: data?.properties.systemCurrencyAbb,
      paginationInfo: {
        page: data?.properties.paginationInfo.pageNumber,
        totalRecord: data?.properties.paginationInfo.totalRecord,
      },
      ...restProps,
    };
  }

  return {
    widgetName: '',
    widgetData: [],
    currencyAbb: '',
    paginationInfo: {
      page: 0,
      totalRecord: 0,
    },
    ...restProps,
  };
};
