export default {
  Entities: [
    {
      Properties: {
        DisplayText: '.NET',
        Value: '7',
      },
    },
    {
      Properties: {
        DisplayText: 'Agil / Scrum',
        Value: '3',
      },
    },
    {
      Properties: {
        DisplayText: 'Change Management Mastering',
        Value: '11',
      },
    },
    {
      Properties: {
        DisplayText: 'Dansk',
        Value: '1',
      },
    },
    {
      Properties: {
        DisplayText: 'Java',
        Value: '9',
      },
    },
    {
      Properties: {
        DisplayText: 'Lego Serious Play',
        Value: '10',
      },
    },
    {
      Properties: {
        DisplayText: 'Prince2',
        Value: '2',
      },
    },
    {
      Properties: {
        DisplayText: 'Python',
        Value: '8',
      },
    },
    {
      Properties: {
        DisplayText: 'Svensk',
        Value: '5',
      },
    },
    {
      Properties: {
        DisplayText: 'Tysk',
        Value: '6',
      },
    },
    {
      Properties: {
        DisplayText: 'Økonomisk styring',
        Value: '4',
      },
    },
  ],
  Links: [
    {
      Href: 'https://uat.timelog.com/rp-test1/api/v2/filters/competence/content?pageIdentifier=NewResourcePlanner',
      Rel: 'self',
    },
  ],
};
