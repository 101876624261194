import { RefObject, ReactNode } from 'react';
import { useSearch } from 'src/apis/searchAPI';
import { TCategory } from 'src/apis/types/searchAPI';
import { useTranslation } from 'react-i18next';
import { useCategoriesString } from 'src/stores/MainSearchStore';
import { Spinner } from 'src/components/ui-components/Spinner';
import { Icon } from 'src/components/ui-components/Icon';
import SearchItem from '../SearchItem';
import styles from './SearchResultPanel.module.scss';

const icons: Record<TCategory, ReactNode> = {
  Customers: <Icon iconName="customer" />,
  Invoices: <Icon iconName="finance" />,
  Employees: <Icon iconName="employee" />,
  Projects: <Icon iconName="project" />,
  Contacts: <Icon iconName="contact" />,
};

interface SearchResultPanelProps {
  isPauseSearch: boolean;
  searchValue: string;
  onItemSelect: () => void;
  searchContainerResults: RefObject<HTMLUListElement>;
}

export default ({
  isPauseSearch,
  searchValue,
  onItemSelect,
  searchContainerResults,
}: SearchResultPanelProps) => {
  const { t } = useTranslation('header');
  const categoryString = useCategoriesString();

  const { searchResult, isSearchResultEmpty } = useSearch(
    searchValue,
    categoryString,
    isPauseSearch,
  );

  if (!searchValue) {
    return null;
  }

  if (isPauseSearch) {
    return (
      <div className={styles.SearchResults} data-automation-id="MainSearchResultsLoading">
        <Spinner small />
      </div>
    );
  }

  if (!isSearchResultEmpty) {
    return (
      <ul
        data-automation-id="MainSearchResults"
        id="MainSearchResults"
        className={styles.SearchResults}
        ref={searchContainerResults}
      >
        {searchResult?.map(
          ({ isEmpty, category, list }) =>
            !isEmpty && (
              <li
                key={`search-category-${category}`}
                data-automation-id={`SearchCategory${category}`}
              >
                <SearchItem
                  searchValue={searchValue}
                  searchList={list}
                  icon={icons[category]}
                  category={category}
                  categoryLabel={t(`SearchCategory${category}Label`)}
                  onItemSelect={onItemSelect}
                />
              </li>
            ),
        )}
      </ul>
    );
  }

  if (isSearchResultEmpty) {
    return (
      <div className={styles.SearchResults} data-automation-id="MainSearchNoResultsFound">
        {t('SearchNoResultsFound')}
      </div>
    );
  }

  return null;
};
