import { RPRow } from '../types/resourcePlanner';

/**
 * Recursively applies `parentProject` and `parentTask` props to children.
 */
export const applyParentPropsToChildren = (
  children: RPRow[],
  parentList: Array<{
    name: string;
    projectId: string;
    workItemId: string;
  }>,
) =>
  children.map((c) => {
    const match = c.pathToParent?.split('/').filter((p) => p);
    const firstParent = match?.[0];
    const parentProject = parentList.find((p) => p.workItemId === firstParent);
    let newRow = { ...c };

    if (c.children?.length) {
      const childrenWithParentTask = c.children.map((child) => ({
        ...child,
        parentTask: {
          id: c.sourceReferenceId,
          name: c.name,
        },
      }));

      newRow = {
        ...newRow,
        children: childrenWithParentTask,
      };
    }

    if (parentProject) {
      newRow = {
        ...newRow,
        parentProject: {
          id: parentProject.projectId,
          name: parentProject.name,
        },
      };
    }

    return newRow;
  });
