import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IFeatureResult } from '../types';
import { GET_FEATURES_KEY } from '../const';

const GET_FEATURES_FRONT_PAGE_URL_PATH = '/api/v2/features/front-page';
export const GET_FEATURES_FRONT_PAGE_MSW_STATUS_KEY = 'getFrontPageFeatureAPI';

const getFrontPageFeature = (): Promise<IFeatureResult> =>
  getFetch({
    path: GET_FEATURES_FRONT_PAGE_URL_PATH,
    key: GET_FEATURES_FRONT_PAGE_MSW_STATUS_KEY,
  });

export const useGetFrontPageFeature = () => {
  const { data, ...rest } = useQuery([GET_FEATURES_KEY], getFrontPageFeature);

  const featureData = data?.properties ? data.properties?.featureList : [];

  return {
    data: featureData,
    ...rest,
  };
};
