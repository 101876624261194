import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { getPathWithQueries } from 'src/utils/url/url';
import { TEmployeeAllocationRes } from './getEmployeeAllocationAPI.types';

const GET_EMPLOYEE_ALLOCATION_URL_PATH = '/api/v2/projects/{projectId}/employee-allocation';
const GET_EMPLOYEE_ALLOCATION_KEY = 'EmployeeAllocation';

interface IGetEmployeeAllocation {
  projectId: string;
  taskId: string;
  userId: string;
}

const getEmployeeAllocation = ({
  projectId,
  taskId,
  userId,
}: IGetEmployeeAllocation): Promise<TEmployeeAllocationRes> =>
  getFetch({
    path: getPathWithQueries(GET_EMPLOYEE_ALLOCATION_URL_PATH.replace('{projectId}', projectId), {
      taskId,
      userId,
    }),
    key: GET_EMPLOYEE_ALLOCATION_KEY,
  });

export const useGetEmployeeAllocation = (params: IGetEmployeeAllocation, enableQuery?: boolean) => {
  const { projectId, taskId, userId } = params;
  const { data, ...rest } = useQuery(
    [GET_EMPLOYEE_ALLOCATION_KEY, projectId, userId, taskId],
    () => getEmployeeAllocation(params),
    { enabled: enableQuery && !!userId && !!taskId },
  );

  return { data: data?.properties, ...rest };
};
