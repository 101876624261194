export default {
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10002',
      responseType: 'GroupByWorkItem',
      viewOptions: {
        'grouped-by': 'group-by-work-item',
        'reporting-types': 'utilization',
        'period-types': 'day',
        'unit-types': 'hours',
        'period-starts-at': '2022-11-09',
        'period-ends-at': '2023-04-30',
      },
      periods: [
        {
          startsAt: '2022-11-09T00:00:00',
          endsAt: '2022-11-09T00:00:00',
          type: 'Day',
          identifier: '2022Nov09',
        },
        {
          startsAt: '2022-11-10T00:00:00',
          endsAt: '2022-11-10T00:00:00',
          type: 'Day',
          identifier: '2022Nov10',
        },
        {
          startsAt: '2022-11-11T00:00:00',
          endsAt: '2022-11-11T00:00:00',
          type: 'Day',
          identifier: '2022Nov11',
        },
        {
          startsAt: '2022-11-12T00:00:00',
          endsAt: '2022-11-12T00:00:00',
          type: 'Day',
          identifier: '2022Nov12',
        },
        {
          startsAt: '2022-11-13T00:00:00',
          endsAt: '2022-11-13T00:00:00',
          type: 'Day',
          identifier: '2022Nov13',
        },
        {
          startsAt: '2022-11-14T00:00:00',
          endsAt: '2022-11-14T00:00:00',
          type: 'Day',
          identifier: '2022Nov14',
        },
        {
          startsAt: '2022-11-15T00:00:00',
          endsAt: '2022-11-15T00:00:00',
          type: 'Day',
          identifier: '2022Nov15',
        },
        {
          startsAt: '2022-11-16T00:00:00',
          endsAt: '2022-11-16T00:00:00',
          type: 'Day',
          identifier: '2022Nov16',
        },
        {
          startsAt: '2022-11-17T00:00:00',
          endsAt: '2022-11-17T00:00:00',
          type: 'Day',
          identifier: '2022Nov17',
        },
        {
          startsAt: '2022-11-18T00:00:00',
          endsAt: '2022-11-18T00:00:00',
          type: 'Day',
          identifier: '2022Nov18',
        },
        {
          startsAt: '2022-11-19T00:00:00',
          endsAt: '2022-11-19T00:00:00',
          type: 'Day',
          identifier: '2022Nov19',
        },
        {
          startsAt: '2022-11-20T00:00:00',
          endsAt: '2022-11-20T00:00:00',
          type: 'Day',
          identifier: '2022Nov20',
        },
        {
          startsAt: '2022-11-21T00:00:00',
          endsAt: '2022-11-21T00:00:00',
          type: 'Day',
          identifier: '2022Nov21',
        },
        {
          startsAt: '2022-11-22T00:00:00',
          endsAt: '2022-11-22T00:00:00',
          type: 'Day',
          identifier: '2022Nov22',
        },
        {
          startsAt: '2022-11-23T00:00:00',
          endsAt: '2022-11-23T00:00:00',
          type: 'Day',
          identifier: '2022Nov23',
        },
        {
          startsAt: '2022-11-24T00:00:00',
          endsAt: '2022-11-24T00:00:00',
          type: 'Day',
          identifier: '2022Nov24',
        },
        {
          startsAt: '2022-11-25T00:00:00',
          endsAt: '2022-11-25T00:00:00',
          type: 'Day',
          identifier: '2022Nov25',
        },
        {
          startsAt: '2022-11-26T00:00:00',
          endsAt: '2022-11-26T00:00:00',
          type: 'Day',
          identifier: '2022Nov26',
        },
        {
          startsAt: '2022-11-27T00:00:00',
          endsAt: '2022-11-27T00:00:00',
          type: 'Day',
          identifier: '2022Nov27',
        },
        {
          startsAt: '2022-11-28T00:00:00',
          endsAt: '2022-11-28T00:00:00',
          type: 'Day',
          identifier: '2022Nov28',
        },
        {
          startsAt: '2022-11-29T00:00:00',
          endsAt: '2022-11-29T00:00:00',
          type: 'Day',
          identifier: '2022Nov29',
        },
        {
          startsAt: '2022-11-30T00:00:00',
          endsAt: '2022-11-30T00:00:00',
          type: 'Day',
          identifier: '2022Nov30',
        },
        {
          startsAt: '2022-12-01T00:00:00',
          endsAt: '2022-12-01T00:00:00',
          type: 'Day',
          identifier: '2022Dec01',
        },
        {
          startsAt: '2022-12-02T00:00:00',
          endsAt: '2022-12-02T00:00:00',
          type: 'Day',
          identifier: '2022Dec02',
        },
        {
          startsAt: '2022-12-03T00:00:00',
          endsAt: '2022-12-03T00:00:00',
          type: 'Day',
          identifier: '2022Dec03',
        },
        {
          startsAt: '2022-12-04T00:00:00',
          endsAt: '2022-12-04T00:00:00',
          type: 'Day',
          identifier: '2022Dec04',
        },
        {
          startsAt: '2022-12-05T00:00:00',
          endsAt: '2022-12-05T00:00:00',
          type: 'Day',
          identifier: '2022Dec05',
        },
        {
          startsAt: '2022-12-06T00:00:00',
          endsAt: '2022-12-06T00:00:00',
          type: 'Day',
          identifier: '2022Dec06',
        },
        {
          startsAt: '2022-12-07T00:00:00',
          endsAt: '2022-12-07T00:00:00',
          type: 'Day',
          identifier: '2022Dec07',
        },
        {
          startsAt: '2022-12-08T00:00:00',
          endsAt: '2022-12-08T00:00:00',
          type: 'Day',
          identifier: '2022Dec08',
        },
        {
          startsAt: '2022-12-09T00:00:00',
          endsAt: '2022-12-09T00:00:00',
          type: 'Day',
          identifier: '2022Dec09',
        },
        {
          startsAt: '2022-12-10T00:00:00',
          endsAt: '2022-12-10T00:00:00',
          type: 'Day',
          identifier: '2022Dec10',
        },
        {
          startsAt: '2022-12-11T00:00:00',
          endsAt: '2022-12-11T00:00:00',
          type: 'Day',
          identifier: '2022Dec11',
        },
        {
          startsAt: '2022-12-12T00:00:00',
          endsAt: '2022-12-12T00:00:00',
          type: 'Day',
          identifier: '2022Dec12',
        },
        {
          startsAt: '2022-12-13T00:00:00',
          endsAt: '2022-12-13T00:00:00',
          type: 'Day',
          identifier: '2022Dec13',
        },
        {
          startsAt: '2022-12-14T00:00:00',
          endsAt: '2022-12-14T00:00:00',
          type: 'Day',
          identifier: '2022Dec14',
        },
        {
          startsAt: '2022-12-15T00:00:00',
          endsAt: '2022-12-15T00:00:00',
          type: 'Day',
          identifier: '2022Dec15',
        },
        {
          startsAt: '2022-12-16T00:00:00',
          endsAt: '2022-12-16T00:00:00',
          type: 'Day',
          identifier: '2022Dec16',
        },
        {
          startsAt: '2022-12-17T00:00:00',
          endsAt: '2022-12-17T00:00:00',
          type: 'Day',
          identifier: '2022Dec17',
        },
        {
          startsAt: '2022-12-18T00:00:00',
          endsAt: '2022-12-18T00:00:00',
          type: 'Day',
          identifier: '2022Dec18',
        },
        {
          startsAt: '2022-12-19T00:00:00',
          endsAt: '2022-12-19T00:00:00',
          type: 'Day',
          identifier: '2022Dec19',
        },
        {
          startsAt: '2022-12-20T00:00:00',
          endsAt: '2022-12-20T00:00:00',
          type: 'Day',
          identifier: '2022Dec20',
        },
        {
          startsAt: '2022-12-21T00:00:00',
          endsAt: '2022-12-21T00:00:00',
          type: 'Day',
          identifier: '2022Dec21',
        },
        {
          startsAt: '2022-12-22T00:00:00',
          endsAt: '2022-12-22T00:00:00',
          type: 'Day',
          identifier: '2022Dec22',
        },
        {
          startsAt: '2022-12-23T00:00:00',
          endsAt: '2022-12-23T00:00:00',
          type: 'Day',
          identifier: '2022Dec23',
        },
        {
          startsAt: '2022-12-24T00:00:00',
          endsAt: '2022-12-24T00:00:00',
          type: 'Day',
          identifier: '2022Dec24',
        },
        {
          startsAt: '2022-12-25T00:00:00',
          endsAt: '2022-12-25T00:00:00',
          type: 'Day',
          identifier: '2022Dec25',
        },
        {
          startsAt: '2022-12-26T00:00:00',
          endsAt: '2022-12-26T00:00:00',
          type: 'Day',
          identifier: '2022Dec26',
        },
        {
          startsAt: '2022-12-27T00:00:00',
          endsAt: '2022-12-27T00:00:00',
          type: 'Day',
          identifier: '2022Dec27',
        },
        {
          startsAt: '2022-12-28T00:00:00',
          endsAt: '2022-12-28T00:00:00',
          type: 'Day',
          identifier: '2022Dec28',
        },
        {
          startsAt: '2022-12-29T00:00:00',
          endsAt: '2022-12-29T00:00:00',
          type: 'Day',
          identifier: '2022Dec29',
        },
        {
          startsAt: '2022-12-30T00:00:00',
          endsAt: '2022-12-30T00:00:00',
          type: 'Day',
          identifier: '2022Dec30',
        },
        {
          startsAt: '2022-12-31T00:00:00',
          endsAt: '2022-12-31T00:00:00',
          type: 'Day',
          identifier: '2022Dec31',
        },
        {
          startsAt: '2023-01-01T00:00:00',
          endsAt: '2023-01-01T00:00:00',
          type: 'Day',
          identifier: '2023Jan01',
        },
        {
          startsAt: '2023-01-02T00:00:00',
          endsAt: '2023-01-02T00:00:00',
          type: 'Day',
          identifier: '2023Jan02',
        },
        {
          startsAt: '2023-01-03T00:00:00',
          endsAt: '2023-01-03T00:00:00',
          type: 'Day',
          identifier: '2023Jan03',
        },
        {
          startsAt: '2023-01-04T00:00:00',
          endsAt: '2023-01-04T00:00:00',
          type: 'Day',
          identifier: '2023Jan04',
        },
        {
          startsAt: '2023-01-05T00:00:00',
          endsAt: '2023-01-05T00:00:00',
          type: 'Day',
          identifier: '2023Jan05',
        },
        {
          startsAt: '2023-01-06T00:00:00',
          endsAt: '2023-01-06T00:00:00',
          type: 'Day',
          identifier: '2023Jan06',
        },
        {
          startsAt: '2023-01-07T00:00:00',
          endsAt: '2023-01-07T00:00:00',
          type: 'Day',
          identifier: '2023Jan07',
        },
        {
          startsAt: '2023-01-08T00:00:00',
          endsAt: '2023-01-08T00:00:00',
          type: 'Day',
          identifier: '2023Jan08',
        },
        {
          startsAt: '2023-01-09T00:00:00',
          endsAt: '2023-01-09T00:00:00',
          type: 'Day',
          identifier: '2023Jan09',
        },
        {
          startsAt: '2023-01-10T00:00:00',
          endsAt: '2023-01-10T00:00:00',
          type: 'Day',
          identifier: '2023Jan10',
        },
        {
          startsAt: '2023-01-11T00:00:00',
          endsAt: '2023-01-11T00:00:00',
          type: 'Day',
          identifier: '2023Jan11',
        },
        {
          startsAt: '2023-01-12T00:00:00',
          endsAt: '2023-01-12T00:00:00',
          type: 'Day',
          identifier: '2023Jan12',
        },
        {
          startsAt: '2023-01-13T00:00:00',
          endsAt: '2023-01-13T00:00:00',
          type: 'Day',
          identifier: '2023Jan13',
        },
        {
          startsAt: '2023-01-14T00:00:00',
          endsAt: '2023-01-14T00:00:00',
          type: 'Day',
          identifier: '2023Jan14',
        },
        {
          startsAt: '2023-01-15T00:00:00',
          endsAt: '2023-01-15T00:00:00',
          type: 'Day',
          identifier: '2023Jan15',
        },
        {
          startsAt: '2023-01-16T00:00:00',
          endsAt: '2023-01-16T00:00:00',
          type: 'Day',
          identifier: '2023Jan16',
        },
        {
          startsAt: '2023-01-17T00:00:00',
          endsAt: '2023-01-17T00:00:00',
          type: 'Day',
          identifier: '2023Jan17',
        },
        {
          startsAt: '2023-01-18T00:00:00',
          endsAt: '2023-01-18T00:00:00',
          type: 'Day',
          identifier: '2023Jan18',
        },
        {
          startsAt: '2023-01-19T00:00:00',
          endsAt: '2023-01-19T00:00:00',
          type: 'Day',
          identifier: '2023Jan19',
        },
        {
          startsAt: '2023-01-20T00:00:00',
          endsAt: '2023-01-20T00:00:00',
          type: 'Day',
          identifier: '2023Jan20',
        },
        {
          startsAt: '2023-01-21T00:00:00',
          endsAt: '2023-01-21T00:00:00',
          type: 'Day',
          identifier: '2023Jan21',
        },
        {
          startsAt: '2023-01-22T00:00:00',
          endsAt: '2023-01-22T00:00:00',
          type: 'Day',
          identifier: '2023Jan22',
        },
        {
          startsAt: '2023-01-23T00:00:00',
          endsAt: '2023-01-23T00:00:00',
          type: 'Day',
          identifier: '2023Jan23',
        },
        {
          startsAt: '2023-01-24T00:00:00',
          endsAt: '2023-01-24T00:00:00',
          type: 'Day',
          identifier: '2023Jan24',
        },
        {
          startsAt: '2023-01-25T00:00:00',
          endsAt: '2023-01-25T00:00:00',
          type: 'Day',
          identifier: '2023Jan25',
        },
        {
          startsAt: '2023-01-26T00:00:00',
          endsAt: '2023-01-26T00:00:00',
          type: 'Day',
          identifier: '2023Jan26',
        },
        {
          startsAt: '2023-01-27T00:00:00',
          endsAt: '2023-01-27T00:00:00',
          type: 'Day',
          identifier: '2023Jan27',
        },
        {
          startsAt: '2023-01-28T00:00:00',
          endsAt: '2023-01-28T00:00:00',
          type: 'Day',
          identifier: '2023Jan28',
        },
        {
          startsAt: '2023-01-29T00:00:00',
          endsAt: '2023-01-29T00:00:00',
          type: 'Day',
          identifier: '2023Jan29',
        },
        {
          startsAt: '2023-01-30T00:00:00',
          endsAt: '2023-01-30T00:00:00',
          type: 'Day',
          identifier: '2023Jan30',
        },
        {
          startsAt: '2023-01-31T00:00:00',
          endsAt: '2023-01-31T00:00:00',
          type: 'Day',
          identifier: '2023Jan31',
        },
        {
          startsAt: '2023-02-01T00:00:00',
          endsAt: '2023-02-01T00:00:00',
          type: 'Day',
          identifier: '2023Feb01',
        },
        {
          startsAt: '2023-02-02T00:00:00',
          endsAt: '2023-02-02T00:00:00',
          type: 'Day',
          identifier: '2023Feb02',
        },
        {
          startsAt: '2023-02-03T00:00:00',
          endsAt: '2023-02-03T00:00:00',
          type: 'Day',
          identifier: '2023Feb03',
        },
        {
          startsAt: '2023-02-04T00:00:00',
          endsAt: '2023-02-04T00:00:00',
          type: 'Day',
          identifier: '2023Feb04',
        },
        {
          startsAt: '2023-02-05T00:00:00',
          endsAt: '2023-02-05T00:00:00',
          type: 'Day',
          identifier: '2023Feb05',
        },
        {
          startsAt: '2023-02-06T00:00:00',
          endsAt: '2023-02-06T00:00:00',
          type: 'Day',
          identifier: '2023Feb06',
        },
        {
          startsAt: '2023-02-07T00:00:00',
          endsAt: '2023-02-07T00:00:00',
          type: 'Day',
          identifier: '2023Feb07',
        },
        {
          startsAt: '2023-02-08T00:00:00',
          endsAt: '2023-02-08T00:00:00',
          type: 'Day',
          identifier: '2023Feb08',
        },
        {
          startsAt: '2023-02-09T00:00:00',
          endsAt: '2023-02-09T00:00:00',
          type: 'Day',
          identifier: '2023Feb09',
        },
        {
          startsAt: '2023-02-10T00:00:00',
          endsAt: '2023-02-10T00:00:00',
          type: 'Day',
          identifier: '2023Feb10',
        },
        {
          startsAt: '2023-02-11T00:00:00',
          endsAt: '2023-02-11T00:00:00',
          type: 'Day',
          identifier: '2023Feb11',
        },
        {
          startsAt: '2023-02-12T00:00:00',
          endsAt: '2023-02-12T00:00:00',
          type: 'Day',
          identifier: '2023Feb12',
        },
        {
          startsAt: '2023-02-13T00:00:00',
          endsAt: '2023-02-13T00:00:00',
          type: 'Day',
          identifier: '2023Feb13',
        },
        {
          startsAt: '2023-02-14T00:00:00',
          endsAt: '2023-02-14T00:00:00',
          type: 'Day',
          identifier: '2023Feb14',
        },
        {
          startsAt: '2023-02-15T00:00:00',
          endsAt: '2023-02-15T00:00:00',
          type: 'Day',
          identifier: '2023Feb15',
        },
        {
          startsAt: '2023-02-16T00:00:00',
          endsAt: '2023-02-16T00:00:00',
          type: 'Day',
          identifier: '2023Feb16',
        },
        {
          startsAt: '2023-02-17T00:00:00',
          endsAt: '2023-02-17T00:00:00',
          type: 'Day',
          identifier: '2023Feb17',
        },
        {
          startsAt: '2023-02-18T00:00:00',
          endsAt: '2023-02-18T00:00:00',
          type: 'Day',
          identifier: '2023Feb18',
        },
        {
          startsAt: '2023-02-19T00:00:00',
          endsAt: '2023-02-19T00:00:00',
          type: 'Day',
          identifier: '2023Feb19',
        },
        {
          startsAt: '2023-02-20T00:00:00',
          endsAt: '2023-02-20T00:00:00',
          type: 'Day',
          identifier: '2023Feb20',
        },
        {
          startsAt: '2023-02-21T00:00:00',
          endsAt: '2023-02-21T00:00:00',
          type: 'Day',
          identifier: '2023Feb21',
        },
        {
          startsAt: '2023-02-22T00:00:00',
          endsAt: '2023-02-22T00:00:00',
          type: 'Day',
          identifier: '2023Feb22',
        },
        {
          startsAt: '2023-02-23T00:00:00',
          endsAt: '2023-02-23T00:00:00',
          type: 'Day',
          identifier: '2023Feb23',
        },
        {
          startsAt: '2023-02-24T00:00:00',
          endsAt: '2023-02-24T00:00:00',
          type: 'Day',
          identifier: '2023Feb24',
        },
        {
          startsAt: '2023-02-25T00:00:00',
          endsAt: '2023-02-25T00:00:00',
          type: 'Day',
          identifier: '2023Feb25',
        },
        {
          startsAt: '2023-02-26T00:00:00',
          endsAt: '2023-02-26T00:00:00',
          type: 'Day',
          identifier: '2023Feb26',
        },
        {
          startsAt: '2023-02-27T00:00:00',
          endsAt: '2023-02-27T00:00:00',
          type: 'Day',
          identifier: '2023Feb27',
        },
        {
          startsAt: '2023-02-28T00:00:00',
          endsAt: '2023-02-28T00:00:00',
          type: 'Day',
          identifier: '2023Feb28',
        },
        {
          startsAt: '2023-03-01T00:00:00',
          endsAt: '2023-03-01T00:00:00',
          type: 'Day',
          identifier: '2023Mar01',
        },
        {
          startsAt: '2023-03-02T00:00:00',
          endsAt: '2023-03-02T00:00:00',
          type: 'Day',
          identifier: '2023Mar02',
        },
        {
          startsAt: '2023-03-03T00:00:00',
          endsAt: '2023-03-03T00:00:00',
          type: 'Day',
          identifier: '2023Mar03',
        },
        {
          startsAt: '2023-03-04T00:00:00',
          endsAt: '2023-03-04T00:00:00',
          type: 'Day',
          identifier: '2023Mar04',
        },
        {
          startsAt: '2023-03-05T00:00:00',
          endsAt: '2023-03-05T00:00:00',
          type: 'Day',
          identifier: '2023Mar05',
        },
        {
          startsAt: '2023-03-06T00:00:00',
          endsAt: '2023-03-06T00:00:00',
          type: 'Day',
          identifier: '2023Mar06',
        },
        {
          startsAt: '2023-03-07T00:00:00',
          endsAt: '2023-03-07T00:00:00',
          type: 'Day',
          identifier: '2023Mar07',
        },
        {
          startsAt: '2023-03-08T00:00:00',
          endsAt: '2023-03-08T00:00:00',
          type: 'Day',
          identifier: '2023Mar08',
        },
        {
          startsAt: '2023-03-09T00:00:00',
          endsAt: '2023-03-09T00:00:00',
          type: 'Day',
          identifier: '2023Mar09',
        },
        {
          startsAt: '2023-03-10T00:00:00',
          endsAt: '2023-03-10T00:00:00',
          type: 'Day',
          identifier: '2023Mar10',
        },
        {
          startsAt: '2023-03-11T00:00:00',
          endsAt: '2023-03-11T00:00:00',
          type: 'Day',
          identifier: '2023Mar11',
        },
        {
          startsAt: '2023-03-12T00:00:00',
          endsAt: '2023-03-12T00:00:00',
          type: 'Day',
          identifier: '2023Mar12',
        },
        {
          startsAt: '2023-03-13T00:00:00',
          endsAt: '2023-03-13T00:00:00',
          type: 'Day',
          identifier: '2023Mar13',
        },
        {
          startsAt: '2023-03-14T00:00:00',
          endsAt: '2023-03-14T00:00:00',
          type: 'Day',
          identifier: '2023Mar14',
        },
        {
          startsAt: '2023-03-15T00:00:00',
          endsAt: '2023-03-15T00:00:00',
          type: 'Day',
          identifier: '2023Mar15',
        },
        {
          startsAt: '2023-03-16T00:00:00',
          endsAt: '2023-03-16T00:00:00',
          type: 'Day',
          identifier: '2023Mar16',
        },
        {
          startsAt: '2023-03-17T00:00:00',
          endsAt: '2023-03-17T00:00:00',
          type: 'Day',
          identifier: '2023Mar17',
        },
        {
          startsAt: '2023-03-18T00:00:00',
          endsAt: '2023-03-18T00:00:00',
          type: 'Day',
          identifier: '2023Mar18',
        },
        {
          startsAt: '2023-03-19T00:00:00',
          endsAt: '2023-03-19T00:00:00',
          type: 'Day',
          identifier: '2023Mar19',
        },
        {
          startsAt: '2023-03-20T00:00:00',
          endsAt: '2023-03-20T00:00:00',
          type: 'Day',
          identifier: '2023Mar20',
        },
        {
          startsAt: '2023-03-21T00:00:00',
          endsAt: '2023-03-21T00:00:00',
          type: 'Day',
          identifier: '2023Mar21',
        },
        {
          startsAt: '2023-03-22T00:00:00',
          endsAt: '2023-03-22T00:00:00',
          type: 'Day',
          identifier: '2023Mar22',
        },
        {
          startsAt: '2023-03-23T00:00:00',
          endsAt: '2023-03-23T00:00:00',
          type: 'Day',
          identifier: '2023Mar23',
        },
        {
          startsAt: '2023-03-24T00:00:00',
          endsAt: '2023-03-24T00:00:00',
          type: 'Day',
          identifier: '2023Mar24',
        },
        {
          startsAt: '2023-03-25T00:00:00',
          endsAt: '2023-03-25T00:00:00',
          type: 'Day',
          identifier: '2023Mar25',
        },
        {
          startsAt: '2023-03-26T00:00:00',
          endsAt: '2023-03-26T00:00:00',
          type: 'Day',
          identifier: '2023Mar26',
        },
        {
          startsAt: '2023-03-27T00:00:00',
          endsAt: '2023-03-27T00:00:00',
          type: 'Day',
          identifier: '2023Mar27',
        },
        {
          startsAt: '2023-03-28T00:00:00',
          endsAt: '2023-03-28T00:00:00',
          type: 'Day',
          identifier: '2023Mar28',
        },
        {
          startsAt: '2023-03-29T00:00:00',
          endsAt: '2023-03-29T00:00:00',
          type: 'Day',
          identifier: '2023Mar29',
        },
        {
          startsAt: '2023-03-30T00:00:00',
          endsAt: '2023-03-30T00:00:00',
          type: 'Day',
          identifier: '2023Mar30',
        },
        {
          startsAt: '2023-03-31T00:00:00',
          endsAt: '2023-03-31T00:00:00',
          type: 'Day',
          identifier: '2023Mar31',
        },
        {
          startsAt: '2023-04-01T00:00:00',
          endsAt: '2023-04-01T00:00:00',
          type: 'Day',
          identifier: '2023Apr01',
        },
        {
          startsAt: '2023-04-02T00:00:00',
          endsAt: '2023-04-02T00:00:00',
          type: 'Day',
          identifier: '2023Apr02',
        },
        {
          startsAt: '2023-04-03T00:00:00',
          endsAt: '2023-04-03T00:00:00',
          type: 'Day',
          identifier: '2023Apr03',
        },
        {
          startsAt: '2023-04-04T00:00:00',
          endsAt: '2023-04-04T00:00:00',
          type: 'Day',
          identifier: '2023Apr04',
        },
        {
          startsAt: '2023-04-05T00:00:00',
          endsAt: '2023-04-05T00:00:00',
          type: 'Day',
          identifier: '2023Apr05',
        },
        {
          startsAt: '2023-04-06T00:00:00',
          endsAt: '2023-04-06T00:00:00',
          type: 'Day',
          identifier: '2023Apr06',
        },
        {
          startsAt: '2023-04-07T00:00:00',
          endsAt: '2023-04-07T00:00:00',
          type: 'Day',
          identifier: '2023Apr07',
        },
        {
          startsAt: '2023-04-08T00:00:00',
          endsAt: '2023-04-08T00:00:00',
          type: 'Day',
          identifier: '2023Apr08',
        },
        {
          startsAt: '2023-04-09T00:00:00',
          endsAt: '2023-04-09T00:00:00',
          type: 'Day',
          identifier: '2023Apr09',
        },
        {
          startsAt: '2023-04-10T00:00:00',
          endsAt: '2023-04-10T00:00:00',
          type: 'Day',
          identifier: '2023Apr10',
        },
        {
          startsAt: '2023-04-11T00:00:00',
          endsAt: '2023-04-11T00:00:00',
          type: 'Day',
          identifier: '2023Apr11',
        },
        {
          startsAt: '2023-04-12T00:00:00',
          endsAt: '2023-04-12T00:00:00',
          type: 'Day',
          identifier: '2023Apr12',
        },
        {
          startsAt: '2023-04-13T00:00:00',
          endsAt: '2023-04-13T00:00:00',
          type: 'Day',
          identifier: '2023Apr13',
        },
        {
          startsAt: '2023-04-14T00:00:00',
          endsAt: '2023-04-14T00:00:00',
          type: 'Day',
          identifier: '2023Apr14',
        },
        {
          startsAt: '2023-04-15T00:00:00',
          endsAt: '2023-04-15T00:00:00',
          type: 'Day',
          identifier: '2023Apr15',
        },
        {
          startsAt: '2023-04-16T00:00:00',
          endsAt: '2023-04-16T00:00:00',
          type: 'Day',
          identifier: '2023Apr16',
        },
        {
          startsAt: '2023-04-17T00:00:00',
          endsAt: '2023-04-17T00:00:00',
          type: 'Day',
          identifier: '2023Apr17',
        },
        {
          startsAt: '2023-04-18T00:00:00',
          endsAt: '2023-04-18T00:00:00',
          type: 'Day',
          identifier: '2023Apr18',
        },
        {
          startsAt: '2023-04-19T00:00:00',
          endsAt: '2023-04-19T00:00:00',
          type: 'Day',
          identifier: '2023Apr19',
        },
        {
          startsAt: '2023-04-20T00:00:00',
          endsAt: '2023-04-20T00:00:00',
          type: 'Day',
          identifier: '2023Apr20',
        },
        {
          startsAt: '2023-04-21T00:00:00',
          endsAt: '2023-04-21T00:00:00',
          type: 'Day',
          identifier: '2023Apr21',
        },
        {
          startsAt: '2023-04-22T00:00:00',
          endsAt: '2023-04-22T00:00:00',
          type: 'Day',
          identifier: '2023Apr22',
        },
        {
          startsAt: '2023-04-23T00:00:00',
          endsAt: '2023-04-23T00:00:00',
          type: 'Day',
          identifier: '2023Apr23',
        },
        {
          startsAt: '2023-04-24T00:00:00',
          endsAt: '2023-04-24T00:00:00',
          type: 'Day',
          identifier: '2023Apr24',
        },
        {
          startsAt: '2023-04-25T00:00:00',
          endsAt: '2023-04-25T00:00:00',
          type: 'Day',
          identifier: '2023Apr25',
        },
        {
          startsAt: '2023-04-26T00:00:00',
          endsAt: '2023-04-26T00:00:00',
          type: 'Day',
          identifier: '2023Apr26',
        },
        {
          startsAt: '2023-04-27T00:00:00',
          endsAt: '2023-04-27T00:00:00',
          type: 'Day',
          identifier: '2023Apr27',
        },
        {
          startsAt: '2023-04-28T00:00:00',
          endsAt: '2023-04-28T00:00:00',
          type: 'Day',
          identifier: '2023Apr28',
        },
        {
          startsAt: '2023-04-29T00:00:00',
          endsAt: '2023-04-29T00:00:00',
          type: 'Day',
          identifier: '2023Apr29',
        },
        {
          startsAt: '2023-04-30T00:00:00',
          endsAt: '2023-04-30T00:00:00',
          type: 'Day',
          identifier: '2023Apr30',
        },
      ],
      children: [
        {
          StartsAt: '2021-09-23T00:00:00',
          EndsAt: '2021-12-16T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2021-10-22T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437113315786752',
                  workItemSourceReferenceId: '440',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 4.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92437113315786752',
                  workItemSourceReferenceId: '440',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 4.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437113315786752',
              workItemSourceReferenceId: '440',
              workItemType: 'Task',
              id: '92437113315786752',
              name: 'Teknisk setup af web services integration',
              sourceReferenceId: '440',
              tenantId: '10002',
              parentId: '92437112669863936',
              type: 'Task',
              pathToParent: '/92437112669863936/',
              sortOrder: 1,
              level: 2,
              sortablePath: '/Business Central implementering CIS/001',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-09-23T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 9.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437113923960832',
                  workItemSourceReferenceId: '441',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 4.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463472915054592',
                  resourceSourceReferenceId: 32,
                  resourceType: 'Employee',
                  workItemId: '92437113923960832',
                  workItemSourceReferenceId: '441',
                  workItemType: 'Task',
                  id: '90463472915054592',
                  name: 'Klaus Lingskov',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 32,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 4.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437113923960832',
              workItemSourceReferenceId: '441',
              workItemType: 'Task',
              id: '92437113923960832',
              name: 'Ny BC kunde - system opsætning',
              sourceReferenceId: '441',
              tenantId: '10002',
              parentId: '92437112669863936',
              type: 'Task',
              pathToParent: '/92437112669863936/',
              sortOrder: 2,
              level: 2,
              sortablePath: '/Business Central implementering CIS/002',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-11-29T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437114523746304',
                  workItemSourceReferenceId: '442',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 24.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92437114523746304',
                  workItemSourceReferenceId: '442',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 24.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437114523746304',
              workItemSourceReferenceId: '442',
              workItemType: 'Task',
              id: '92437114523746304',
              name: 'Test- og driftkonvertering ',
              sourceReferenceId: '442',
              tenantId: '10002',
              parentId: '92437112669863936',
              type: 'Task',
              pathToParent: '/92437112669863936/',
              sortOrder: 3,
              level: 2,
              sortablePath: '/Business Central implementering CIS/003',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-10-31T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437115157086208',
                  workItemSourceReferenceId: '443',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462688282411008',
                  resourceSourceReferenceId: 15,
                  resourceType: 'Employee',
                  workItemId: '92437115157086208',
                  workItemSourceReferenceId: '443',
                  workItemType: 'Task',
                  id: '90462688282411008',
                  name: 'Bruno Mars',
                  role: 'PO',
                  isActive: false,
                  hiredAt: '2021-08-18',
                  tenantId: '10002',
                  sourceReferenceId: 15,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 8.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92437115157086208',
                  workItemSourceReferenceId: '443',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 8.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437115157086208',
              workItemSourceReferenceId: '443',
              workItemType: 'Task',
              id: '92437115157086208',
              name: 'Betjeningskursus',
              sourceReferenceId: '443',
              tenantId: '10002',
              parentId: '92437112669863936',
              type: 'Task',
              pathToParent: '/92437112669863936/',
              sortOrder: 4,
              level: 2,
              sortablePath: '/Business Central implementering CIS/004',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
          ],
          values: {
            '2022Nov09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
          },
          Budget: 33.32,
          TotalBooked: 84.15,
          TotalActualWorkload: 0.0,
          TotalEAC: 84.15,
          total: { displayValue: 84.15 },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '92437112669863936',
          workItemSourceReferenceId: '68',
          workItemType: 'Project',
          id: '92437112669863936',
          name: 'Business Central implementering CIS',
          sourceReferenceId: '68',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Business Central implementering CIS',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
        },
        {
          StartsAt: '2021-10-22T00:00:00',
          EndsAt: '2022-01-14T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2021-10-23T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap50',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap50',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap50',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap50',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92436998492520448',
                  workItemSourceReferenceId: '437',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462688282411008',
                  resourceSourceReferenceId: 15,
                  resourceType: 'Employee',
                  workItemId: '92436998492520448',
                  workItemSourceReferenceId: '437',
                  workItemType: 'Task',
                  id: '90462688282411008',
                  name: 'Bruno Mars',
                  role: 'PO',
                  isActive: false,
                  hiredAt: '2021-08-18',
                  tenantId: '10002',
                  sourceReferenceId: 15,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 8.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92436998492520448',
                  workItemSourceReferenceId: '437',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 8.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92436998492520448',
              workItemSourceReferenceId: '437',
              workItemType: 'Task',
              id: '92436998492520448',
              name: 'Betjeningskursus',
              sourceReferenceId: '437',
              tenantId: '10002',
              parentId: '92436996043046912',
              type: 'Task',
              pathToParent: '/92436996043046912/',
              sortOrder: 4,
              level: 2,
              sortablePath: '/Business Central Implementering CPH/004',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-10-25T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap50',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap50',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap50',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap50',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92436999130054656',
                  workItemSourceReferenceId: '438',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 6.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92436999130054656',
                  workItemSourceReferenceId: '438',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 12.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92436999130054656',
                  workItemSourceReferenceId: '438',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 12.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92436999130054656',
              workItemSourceReferenceId: '438',
              workItemType: 'Task',
              id: '92436999130054656',
              name: 'Opstartshjælp',
              sourceReferenceId: '438',
              tenantId: '10002',
              parentId: '92436996043046912',
              type: 'Task',
              pathToParent: '/92436996043046912/',
              sortOrder: 5,
              level: 2,
              sortablePath: '/Business Central Implementering CPH/005',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-11-26T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap50',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap50',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap50',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap50',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92436999742423040',
                  workItemSourceReferenceId: '439',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 8.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92436999742423040',
                  workItemSourceReferenceId: '439',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 8.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92436999742423040',
              workItemSourceReferenceId: '439',
              workItemType: 'Task',
              id: '92436999742423040',
              name: 'Projektledelse',
              sourceReferenceId: '439',
              tenantId: '10002',
              parentId: '92436996043046912',
              type: 'Task',
              pathToParent: '/92436996043046912/',
              sortOrder: 6,
              level: 2,
              sortablePath: '/Business Central Implementering CPH/006',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
          ],
          values: {
            '2022Nov09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          total: { displayValue: 0 },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '92436996043046912',
          workItemSourceReferenceId: '67',
          workItemType: 'Project',
          id: '92436996043046912',
          name: 'Business Central Implementering CPH',
          sourceReferenceId: '67',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Business Central Implementering CPH',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
        },
        {
          StartsAt: '2022-03-16T00:00:00',
          EndsAt: '2022-06-16T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2022-03-16T00:00:00',
              EndsAt: '2022-06-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov13': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov20': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov27': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Nov30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec03': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec17': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec24': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2022Dec31': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan14': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan21': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan28': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Jan31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Feb28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar01': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar02': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar04': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar05': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar06': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar07': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar08': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar09': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar10': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar11': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar12': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar15': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar16': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar18': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar19': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar22': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar23': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar25': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar26': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar29': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar30': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Mar31': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr01': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr02': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr03': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr04': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr05': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr06': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr07': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr08': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr09': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr10': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr11': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr12': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr13': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr14': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr15': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr16': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr17': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr18': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr19': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr20': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr21': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr22': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr23': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr24': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr25': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr26': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr27': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr28': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr29': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                    '2023Apr30': {
                      displayValue: 0,
                      heatmapCode: 'bgLightGray',
                      editable: true,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 33.0,
                  TotalEAC: 33.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437333760016384',
                  workItemSourceReferenceId: '470',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Nov30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 1000.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 33.0,
              TotalEAC: 33.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437333760016384',
              workItemSourceReferenceId: '470',
              workItemType: 'Task',
              id: '92437333760016384',
              name: 'Test Task 30 days',
              sourceReferenceId: '470',
              tenantId: '10002',
              parentId: '92437333063761920',
              type: 'Task',
              pathToParent: '/92437333063761920/',
              sortOrder: 1,
              level: 2,
              sortablePath: '/Project for 30 days/001',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
          ],
          values: {
            '2022Nov09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Nov30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar31': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr01': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr02': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr03': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr04': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr05': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr06': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr07': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr08': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr09': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr10': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr11': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr12': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr13': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr14': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr15': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr16': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr17': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr18': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr19': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr20': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr21': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr22': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr23': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr24': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr25': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr26': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr27': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr28': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr29': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr30': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
          },
          Budget: 10000.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 33.0,
          TotalEAC: 33.0,
          total: { displayValue: 0 },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '92437333063761920',
          workItemSourceReferenceId: '75',
          workItemType: 'Project',
          id: '92437333063761920',
          name: 'Project for 30 days',
          sourceReferenceId: '75',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Project for 30 days',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
        },
      ],
    },
    links: [{ href: '/plan/groupbyworkitem', rel: 'self' }],
  },
  DeterministicHash: -1958842122,
  StatusCode: 200,
};
