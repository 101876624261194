import { IEntitiesResponse } from 'src/apis/types/responseBase';

export const GET_COMPETENCE_GROUPS_KEY = 'GET_COMPETENCE_GROUPS';
export const COMPETENCE_GROUPS_URL_PATH = '/api/v2/competence-groups';

export interface ICompetenceGroups extends IEntitiesResponse<ICompetenceGroupsDatum> {}

export interface ICompetenceGroupsDatum {
  groupId?: number;
  groupName?: string;
}
export interface ICompetenceGroupsDatumPut {
  id?: number;
  name?: string;
}
