import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';
import { ICompetencesDatumPut } from '../types';
import { COMPETENCES_URL_PATH, GET_COMPETENCES_KEY } from '../constants';

export const PUT_COMPETENCES_MSW_STATUS_KEY = 'putCompetencesAPI';

const updateCompetence = (updateModel?: ICompetencesDatumPut) =>
  putFetch({
    path: COMPETENCES_URL_PATH,
    key: PUT_COMPETENCES_MSW_STATUS_KEY,
    body: JSON.stringify(updateModel),
  });

export const useUpdateCompetence = () => {
  const queryClient = useQueryClient();

  return useMutation(updateCompetence, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_COMPETENCES_KEY]);
    },
  });
};
