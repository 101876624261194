export default {
  Entities: [
    {
      Properties: {
        TaskID: 62718,
        TaskName: 'Analyze customer portfolio',
        TaskFullName: 'Analyze customer portfolio',
        TaskNo: 'O20070',
        WBSNo: '1',
        TaskStartDate: '2021-01-11T00:00:00',
        TaskEndDate: '2021-03-07T00:00:00',
        TaskBudgetHour: null,
        IsParent: true,
      },
    },
    {
      Properties: {
        TaskID: 63196,
        TaskName: 'Survey and interviews',
        TaskFullName: 'Analyze customer portfolio » Survey and interviews',
        TaskNo: 'O20548',
        WBSNo: '1.1',
        TaskStartDate: '2021-01-11T00:00:00',
        TaskEndDate: '2021-03-07T00:00:00',
        TaskBudgetHour: 15.0,
        IsParent: false,
      },
    },
    {
      Properties: {
        TaskID: 62721,
        TaskName: 'Data extraction',
        TaskFullName: 'Analyze customer portfolio » Data extraction',
        TaskNo: 'O20073',
        WBSNo: '1.2',
        TaskStartDate: '2021-01-28T00:00:00',
        TaskEndDate: '2021-01-31T00:00:00',
        TaskBudgetHour: 40.0,
        IsParent: false,
      },
    },
    {
      Properties: {
        TaskID: 62733,
        TaskName: 'Customer discounts analysis and testing',
        TaskFullName: 'Analyze customer portfolio » Customer discounts analysis and testing',
        TaskNo: 'O20085',
        WBSNo: '1.3',
        TaskStartDate: '2021-01-25T00:00:00',
        TaskEndDate: '2021-02-21T00:00:00',
        TaskBudgetHour: 15.0,
        IsParent: false,
      },
    },
    {
      Properties: {
        TaskID: 62738,
        TaskName: 'Analyze stickiness and customers’ alternatives',
        TaskFullName: 'Analyze stickiness and customers’ alternatives',
        TaskNo: 'O20090',
        WBSNo: '2',
        TaskStartDate: '2021-01-25T00:00:00',
        TaskEndDate: '2021-02-28T00:00:00',
        TaskBudgetHour: null,
        IsParent: true,
      },
    },
    {
      Properties: {
        TaskID: 62739,
        TaskName:
          "Analyze cost of switching to other provider (DKK, both customer's internal costs and external costs)",
        TaskFullName:
          "Analyze stickiness and customers’ alternatives » Analyze cost of switching to other provider (DKK, both customer's internal costs and external costs)",
        TaskNo: 'O20091',
        WBSNo: '2.1',
        TaskStartDate: '2021-01-25T00:00:00',
        TaskEndDate: '2021-02-21T00:00:00',
        TaskBudgetHour: 5.0,
        IsParent: false,
      },
    },
    {
      Properties: {
        TaskID: 62740,
        TaskName: 'Analyze key competitors and their pricing level/model',
        TaskFullName:
          'Analyze stickiness and customers’ alternatives » Analyze key competitors and their pricing level/model',
        TaskNo: 'O20092',
        WBSNo: '2.2',
        TaskStartDate: '2021-02-15T00:00:00',
        TaskEndDate: '2021-02-28T00:00:00',
        TaskBudgetHour: 70.0,
        IsParent: false,
      },
    },
    {
      Properties: {
        TaskID: 62742,
        TaskName: 'Establish price model hypotesis / new packaging and evaluate potential effect',
        TaskFullName:
          'Establish price model hypotesis / new packaging and evaluate potential effect',
        TaskNo: 'O20094',
        WBSNo: '3',
        TaskStartDate: '2021-02-01T00:00:00',
        TaskEndDate: '2021-03-14T00:00:00',
        TaskBudgetHour: null,
        IsParent: true,
      },
    },
    {
      Properties: {
        TaskID: 62743,
        TaskName: 'Create price model and hypothesis to test',
        TaskFullName:
          'Establish price model hypotesis / new packaging and evaluate potential effect » Create price model and hypothesis to test',
        TaskNo: 'O20095',
        WBSNo: '3.1',
        TaskStartDate: '2021-02-01T00:00:00',
        TaskEndDate: '2021-03-14T00:00:00',
        TaskBudgetHour: 50.0,
        IsParent: false,
      },
    },
    {
      Properties: {
        TaskID: 62744,
        TaskName: 'Test hypothesis',
        TaskFullName:
          'Establish price model hypotesis / new packaging and evaluate potential effect » Test hypothesis',
        TaskNo: 'O20096',
        WBSNo: '3.2',
        TaskStartDate: '2021-02-01T00:00:00',
        TaskEndDate: '2021-03-14T00:00:00',
        TaskBudgetHour: 75.0,
        IsParent: false,
      },
    },
    {
      Properties: {
        TaskID: 62758,
        TaskName: 'Implement adjusted price model on customer portfolio',
        TaskFullName: 'Implement adjusted price model on customer portfolio',
        TaskNo: 'O20110',
        WBSNo: '4',
        TaskStartDate: '2021-04-01T00:00:00',
        TaskEndDate: '2021-04-30T00:00:00',
        TaskBudgetHour: null,
        IsParent: true,
      },
    },
    {
      Properties: {
        TaskID: 62759,
        TaskName: 'Communicate price changes',
        TaskFullName:
          'Implement adjusted price model on customer portfolio » Communicate price changes',
        TaskNo: 'O20111',
        WBSNo: '4.1',
        TaskStartDate: '2021-04-01T00:00:00',
        TaskEndDate: '2021-04-01T00:00:00',
        TaskBudgetHour: 75.0,
        IsParent: false,
      },
    },
    {
      Properties: {
        TaskID: 62760,
        TaskName: 'Technical implementation of price changes',
        TaskFullName:
          'Implement adjusted price model on customer portfolio » Technical implementation of price changes',
        TaskNo: 'O20112',
        WBSNo: '4.2',
        TaskStartDate: '2021-04-01T00:00:00',
        TaskEndDate: '2021-04-30T00:00:00',
        TaskBudgetHour: 50.0,
        IsParent: false,
      },
    },
    {
      Properties: {
        TaskID: 62761,
        TaskName: 'Meetings',
        TaskFullName: 'Meetings',
        TaskNo: 'O20113',
        WBSNo: '5',
        TaskStartDate: '2021-01-01T00:00:00',
        TaskEndDate: '2021-03-31T00:00:00',
        TaskBudgetHour: null,
        IsParent: true,
      },
    },
    {
      Properties: {
        TaskID: 62762,
        TaskName: 'Status meetings',
        TaskFullName: 'Meetings » Status meetings',
        TaskNo: 'O20114',
        WBSNo: '5.1',
        TaskStartDate: '2021-01-01T00:00:00',
        TaskEndDate: '2021-03-31T00:00:00',
        TaskBudgetHour: 100.0,
        IsParent: false,
      },
    },
    {
      Properties: {
        TaskID: 62763,
        TaskName: 'Steering committee meetings',
        TaskFullName: 'Meetings » Steering committee meetings',
        TaskNo: 'O20115',
        WBSNo: '5.2',
        TaskStartDate: '2021-01-01T00:00:00',
        TaskEndDate: '2021-03-31T00:00:00',
        TaskBudgetHour: 25.0,
        IsParent: false,
      },
    },
    {
      Properties: {
        TaskID: 62767,
        TaskName: 'Other meetings',
        TaskFullName: 'Meetings » Other meetings',
        TaskNo: 'O20119',
        WBSNo: '5.3',
        TaskStartDate: '2021-01-01T00:00:00',
        TaskEndDate: '2021-03-31T00:00:00',
        TaskBudgetHour: 20.0,
        IsParent: false,
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/projects/13376/tasks-unallocated?userId=62&isIncludeParentTask=true',
      Rel: 'self',
    },
  ],
};
