import { IEntitiesAndPropertiesResponse } from 'src/apis/types/responseBase';

export const GET_COMPETENCES_KEY = 'GET_COMPETENCES';
export const COMPETENCES_URL_PATH = '/api/v2/competences';

export interface ICompetences
  extends IEntitiesAndPropertiesResponse<ICompetencesDatum, ICompetencesProperties> {}

export interface ICompetencesProperties {
  totalRecord: number;
  totalPage: number;
  pageNumber: number;
}

export interface ICompetencesDatum {
  groupId?: number;
  groupName?: string;
  groupCanBeDeleted?: boolean;
  items?: ICompetencesItem[];
}

interface ICompetencesItem {
  id?: number;
  name?: string;
  isActive?: boolean;
  canBeDeleted?: boolean;
}

export interface ICompetencesDatumPut {
  id?: number;
  name?: string;
  isActive?: boolean;
  groupId?: number;
}

export type ICompetencesType = 'category' | 'competence';

export interface IConvertedRow {
  id?: string;
  apiId?: number;
  name?: string;
  category?: number;
  isActive?: boolean;
  canBeDeleted?: boolean;
  type?: ICompetencesType;
  items?: ICompetencesItem[];
}

export interface ICompetencesCategoryItem {
  name?: string;
  id?: number;
}

export type TCompetenceCategory = Pick<IConvertedRow, 'id' | 'name'> & { inputValue?: string };

export type TCompetenceState = 'all' | 'inactive' | 'active';
