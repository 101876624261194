import { RefObject, useEffect } from 'react';

// This hook is created so we can focus a focusable element when the component is mounted in a dialog
// as it is not possible to add `autoFocus` to an element in a dialog, without adding `disableRestoreFocus` to the dialog
// Issue: https://github.com/mui/material-ui/issues/33004
export const useInitialFocus = (
  ref: RefObject<HTMLInputElement> | RefObject<HTMLButtonElement>,
) => {
  useEffect(() => {
    setTimeout(() => {
      ref.current?.focus();
    }, 100);
  }, [ref]);
};
