import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from 'src/components/mui-components';
import { FormControlLabel } from 'src/components/mui-components/Utils';
import { ISavedViewForm, TFormDialogMode, TTextDialogMode } from 'src/apis/savedViewAPI';
import { DialogContentText } from '@mui/material';

interface IViewFormDialog {
  showFormDialog: TFormDialogMode;
  setShowFormDialog: (showFormDialog: TFormDialogMode | '') => void;
  onSubmit: (data: ISavedViewForm) => void;
  form: UseFormReturn<ISavedViewForm, any>;
}

export const ViewFormDialog = ({
  showFormDialog,
  setShowFormDialog,
  onSubmit,
  form,
}: IViewFormDialog) => {
  const { t } = useTranslation('savedView');
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = form;

  const dialogTitle = (view: TFormDialogMode) => {
    switch (view) {
      case 'save':
        return t('SaveViewModalTitle');
      case 'edit':
        return t('UpdateViewModalTitle');
      case 'duplicate':
        return t('DuplicateViewModalTitle');
      default:
        return '';
    }
  };

  return (
    <Dialog
      aria-describedby="dialog-form-description"
      aria-labelledby="dialog-form"
      maxWidth="xs"
      onClose={() => setShowFormDialog('')}
      open={!!showFormDialog}
    >
      <DialogTitle id="dialog-form">{dialogTitle(showFormDialog)}</DialogTitle>
      <DialogContent>
        <Stack gap={2} sx={{ marginTop: 2 }}>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                data-automation-id="SavedViewTitleInput"
                label="Title"
                variant="outlined"
                ariaLabel="view-title"
                required
                error={!!errors.title?.message?.toString()}
                helperText={errors.title?.message?.toString()}
                {...field}
              />
            )}
          />
          <FormControlLabel
            control={
              <Controller
                name="isDefault"
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={t('DialogIsDefaultText')}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setShowFormDialog('')}>
          {t('DialogCancelText')}
        </Button>
        <Button
          variant="contained"
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(onSubmit)}
          autoFocus
        >
          {t('DialogSaveText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface IViewTextDialog {
  showTextDialog: TTextDialogMode;
  setShowTextDialog: (showTextDialog: TTextDialogMode | '') => void;
  onDelete: () => void;
}

export const ViewTextDialog = ({
  showTextDialog,
  setShowTextDialog,
  onDelete,
}: IViewTextDialog) => {
  const { t } = useTranslation('savedView');
  const cancelOnClick = () => {
    setShowTextDialog('');
  };
  return (
    <Dialog
      aria-describedby="dialog-text-description"
      aria-labelledby="dialog-text"
      maxWidth="xs"
      onClose={cancelOnClick}
      open={!!showTextDialog}
    >
      <DialogTitle id="dialog-text">{t('DeleteViewModalTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('DeleteViewModalDesc')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={cancelOnClick}>
          {t('DialogCancelText')}
        </Button>
        <Button variant="contained" onClick={onDelete} autoFocus>
          {t('DialogOkText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
