import { TFunction } from 'i18next';
import { toast } from 'react-toastify';
import { ToastItem } from 'src/components/ui-components/ToastItem';
import { BusinessRuleViolationError } from 'src/utils/errors/errorBase';

const SystemAdminExchangeRateErrorMessage: Record<number, string> = {
  37974: 'InvalidNewPeriodOverlapExistingPeriodErrorMessage',
  37975: 'CannotDeletePeriodWhichIsNotLastPeriodErrorMessage',
  37976: 'CannotDeleteWhenOnlyHaveOneAvailablePeriodErrorMessage',
  37978: 'InvalidNewPeriodDoesNotStartOneDayAfterPreviousPeriodEndDateErrorMessage',
  37980: 'CannotPerformActionOnLockedPeriodErrorMessage',
  38001: 'CannotCreateAutomaticPeriodsLastPeriodExistGapErrorMessage',
};

export const handleError = (error: any, t: TFunction<'systemAdminExchangeRate'>) => {
  if (error instanceof BusinessRuleViolationError) {
    error.violations?.forEach((violation) => {
      const errorMessageKey = `errors.${
        SystemAdminExchangeRateErrorMessage[violation.errorCode] ?? 'UnknownErrorMessage'
      }`;
      toast.error(<ToastItem message={t(errorMessageKey)} />);
    });
  }
};
