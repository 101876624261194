import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFetch } from 'src/utils/fetcher';
import { formatUrl } from 'src/utils/url';
import { GROUP_BY_PROJECT_KEY } from '../get/getGroupByProjectAPI.constants';
import { GROUP_BY_RESOURCE_KEY } from '../get/getGroupByResourceAPI.constants';

const POST_COMPLETE_PROJECT_MIGRATION_URL_PATH =
  '/api/v2/resource-planner/complete-project-migration';
export const POST_COMPLETE_PROJECT_MIGRATION_MSW_STATUS_KEY = 'PostCompleteProjectMigration';

export default () => {
  const path = formatUrl(POST_COMPLETE_PROJECT_MIGRATION_URL_PATH);
  const completeProjectMigration = () =>
    postFetch({
      path,
      key: POST_COMPLETE_PROJECT_MIGRATION_MSW_STATUS_KEY,
    });
  const qc = useQueryClient();

  return useMutation(completeProjectMigration, {
    onSuccess: () => {
      qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
      qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
    },
  });
};
