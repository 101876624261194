import { Stack } from 'src/components/mui-components';
import { Shimmer } from 'src/components/ui-components/Shimmer';

export const Loading = () => (
  <Stack data-automation-id="MilestonesLoadMessage">
    <Shimmer width="thirdQuarter" />
    <Shimmer width="full" />
    <Shimmer width="half" />
    <Shimmer width="thirdQuarter" />
  </Stack>
);
