const items: any = [];
const array = Array(30);

function getRandomArbitrary(min: number, max: number): number {
  return parseFloat((Math.random() * (max - min) + min).toFixed(2));
}

for (let index = 0; index < array.length; index += 1) {
  items.push({
    values: {
      '2023Mar': {
        realized: getRandomArbitrary(-10000, 20000),
        forecast: undefined,
        forecastRestOfTheMonth: undefined,
      },
      '2023Apr': {
        realized: getRandomArbitrary(-10000, 20000),
        forecast: undefined,
        forecastRestOfTheMonth: undefined,
      },
      '2023May': {
        realized: getRandomArbitrary(-10000, 20000),
        forecast: undefined,
        forecastRestOfTheMonth: undefined,
      },
      '2023Jun': {
        realized: getRandomArbitrary(-10000, 20000),
        forecast: getRandomArbitrary(-10000, 20000),
        forecastRestOfTheMonth: getRandomArbitrary(-10000, 20000),
      },
      '2023Jul': {
        realized: undefined,
        forecast: getRandomArbitrary(-10000, 20000),
        forecastRestOfTheMonth: undefined,
      },
      '2023Aug': {
        realized: undefined,
        forecast: getRandomArbitrary(-10000, 20000),
        forecastRestOfTheMonth: undefined,
      },
      '2023Sep': {
        realized: undefined,
        forecast: getRandomArbitrary(-10000, 20000),
        forecastRestOfTheMonth: undefined,
      },
    },
    id: `1000${index}`,
    projectName: `Project name ${index + 1}`,
    customer: `Customer ${index + 1}`,
    projectManager: `Project Manager ${index + 1}`,
    currency: 'DKK',
    totalRealized: getRandomArbitrary(-10000, 20000),
    totalForecast: getRandomArbitrary(-10000, 20000),
    total: getRandomArbitrary(-10000, 20000),
  });
}

export default {
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10002',
      responseType: 'RevenueForecast',
      viewOptions: {
        currency: 'currency-project',
        'period-starts-at': '2023-03-01',
        'period-ends-at': '2023-09-30',
      },
      periods: [
        {
          startsAt: '2023-03-01T00:00:00',
          endsAt: '2023-03-31T00:00:00',
          type: 'Month',
          identifier: '2023Mar',
        },
        {
          startsAt: '2023-04-01T00:00:00',
          endsAt: '2023-04-30T00:00:00',
          type: 'Month',
          identifier: '2023Apr',
        },
        {
          startsAt: '2023-05-01T00:00:00',
          endsAt: '2023-05-31T00:00:00',
          type: 'Month',
          identifier: '2023May',
        },
        {
          startsAt: '2023-06-01T00:00:00',
          endsAt: '2023-06-30T00:00:00',
          type: 'Month',
          identifier: '2023Jun',
          isCurrentMonth: true,
        },
        {
          startsAt: '2023-07-01T00:00:00',
          endsAt: '2023-07-31T00:00:00',
          type: 'Month',
          identifier: '2023Jul',
        },
        {
          startsAt: '2023-08-01T00:00:00',
          endsAt: '2023-08-31T00:00:00',
          type: 'Month',
          identifier: '2023Aug',
        },
        {
          startsAt: '2023-09-01T00:00:00',
          endsAt: '2023-09-30T00:00:00',
          type: 'Month',
          identifier: '2023Sep',
        },
      ],
      children: items,
      summations: [
        {
          values: {
            '2023Mar': {
              realized: getRandomArbitrary(-10000, 20000),
              forecast: undefined,
              forecastRestOfTheMonth: undefined,
            },
            '2023Apr': {
              realized: getRandomArbitrary(-10000, 20000),
              forecast: undefined,
              forecastRestOfTheMonth: undefined,
            },
            '2023May': {
              realized: getRandomArbitrary(-10000, 20000),
              forecast: undefined,
              forecastRestOfTheMonth: undefined,
            },
            '2023Jun': {
              realized: getRandomArbitrary(-10000, 20000),
              forecast: getRandomArbitrary(-10000, 20000),
              forecastRestOfTheMonth: getRandomArbitrary(-10000, 20000),
            },
            '2023Jul': {
              realized: undefined,
              forecast: getRandomArbitrary(-10000, 20000),
              forecastRestOfTheMonth: undefined,
            },
            '2023Aug': {
              realized: undefined,
              forecast: getRandomArbitrary(-10000, 20000),
              forecastRestOfTheMonth: undefined,
            },
            '2023Sep': {
              realized: undefined,
              forecast: getRandomArbitrary(-10000, 20000),
              forecastRestOfTheMonth: undefined,
            },
          },
          id: 'TOTAL',
          projectName: '',
          customer: '',
          projectManager: '',
          currency: '',
          totalRealized: getRandomArbitrary(-10000, 20000),
          totalForecast: getRandomArbitrary(-10000, 20000),
          total: getRandomArbitrary(-10000, 20000),
        },
        {
          values: {
            '2023Mar': {
              realized: getRandomArbitrary(-10000, 20000),
              forecast: undefined,
              forecastRestOfTheMonth: undefined,
            },
            '2023Apr': {
              realized: getRandomArbitrary(-10000, 20000),
              forecast: undefined,
              forecastRestOfTheMonth: undefined,
            },
            '2023May': {
              realized: getRandomArbitrary(-10000, 20000),
              forecast: undefined,
              forecastRestOfTheMonth: undefined,
            },
            '2023Jun': {
              realized: getRandomArbitrary(-10000, 20000),
              forecast: getRandomArbitrary(-10000, 20000),
              forecastRestOfTheMonth: getRandomArbitrary(-10000, 20000),
            },
            '2023Jul': {
              realized: undefined,
              forecast: getRandomArbitrary(-10000, 20000),
              forecastRestOfTheMonth: undefined,
            },
            '2023Aug': {
              realized: undefined,
              forecast: getRandomArbitrary(-10000, 20000),
              forecastRestOfTheMonth: undefined,
            },
            '2023Sep': {
              realized: undefined,
              forecast: getRandomArbitrary(-10000, 20000),
              forecastRestOfTheMonth: undefined,
            },
          },
          id: 'TOTAL_ACCUMULATED',
          projectName: '',
          customer: '',
          projectManager: '',
          currency: '',
          totalRealized: getRandomArbitrary(-10000, 20000),
          totalForecast: getRandomArbitrary(-10000, 20000),
          total: getRandomArbitrary(-10000, 20000),
        },
      ],
    },
    links: [{ href: '/revenue-forecast', rel: 'self' }],
  },
  DeterministicHash: 2699400,
  StatusCode: 200,
};
