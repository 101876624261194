export default {
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10002',
      responseType: 'GroupByWorkItem',
      viewOptions: {
        'grouped-by': 'group-by-work-item',
        'reporting-types': 'utilization',
        'period-types': 'month',
        'unit-types': 'hours',
        'period-starts-at': '2022-11-09',
        'period-ends-at': '2023-04-30',
      },
      periods: [
        {
          startsAt: '2022-11-01T00:00:00',
          endsAt: '2022-11-30T00:00:00',
          type: 'Month',
          identifier: '2022Nov',
        },
        {
          startsAt: '2022-12-01T00:00:00',
          endsAt: '2022-12-31T00:00:00',
          type: 'Month',
          identifier: '2022Dec',
        },
        {
          startsAt: '2023-01-01T00:00:00',
          endsAt: '2023-01-31T00:00:00',
          type: 'Month',
          identifier: '2023Jan',
        },
        {
          startsAt: '2023-02-01T00:00:00',
          endsAt: '2023-02-28T00:00:00',
          type: 'Month',
          identifier: '2023Feb',
        },
        {
          startsAt: '2023-03-01T00:00:00',
          endsAt: '2023-03-31T00:00:00',
          type: 'Month',
          identifier: '2023Mar',
        },
        {
          startsAt: '2023-04-01T00:00:00',
          endsAt: '2023-04-30T00:00:00',
          type: 'Month',
          identifier: '2023Apr',
        },
      ],
      children: [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-11-02T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2022-08-15T00:00:00',
              EndsAt: '2022-09-05T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 8.0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 60.0,
                  TotalBooked: 8.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 8.0,
                  total: { displayValue: 8.0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92437482376790016',
                  workItemSourceReferenceId: '487',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90461904597680128',
                  resourceSourceReferenceId: 9,
                  resourceType: 'Employee',
                  workItemId: '92437482376790016',
                  workItemSourceReferenceId: '487',
                  workItemType: 'Task',
                  id: '90461904597680128',
                  name: 'Erling Sørensen',
                  role: 'Bogholder',
                  isActive: false,
                  hiredAt: '2020-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 9,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 8.0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 50.0,
              TotalBooked: 8.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 8.0,
              total: { displayValue: 8.0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437482376790016',
              workItemSourceReferenceId: '487',
              workItemType: 'Task',
              id: '92437482376790016',
              name: 'FPSA001',
              sourceReferenceId: '487',
              tenantId: '10002',
              parentId: '92437481768615936',
              type: 'Task',
              pathToParent: '/92437481768615936/',
              sortOrder: 1,
              level: 2,
              sortablePath: '/Anson has a Tree/001',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-08-15T00:00:00',
              EndsAt: '2022-09-05T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2022-08-15T00:00:00',
                  EndsAt: '2022-09-05T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 7.6,
                          heatmapCode: 'bgHeatmap15',
                          editable: true,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap10',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 60.0,
                      TotalBooked: 7.6,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 7.6,
                      total: { displayValue: 7.6 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462099863502848',
                      resourceSourceReferenceId: 1,
                      resourceType: 'Employee',
                      workItemId: '92437483580555264',
                      workItemSourceReferenceId: '497',
                      workItemType: 'Task',
                      id: '90462099863502848',
                      name: 'Anders Nielsen',
                      role: 'Projektleder',
                      isActive: false,
                      hiredAt: '2019-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 1,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 7.6, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 50.0,
                  TotalBooked: 7.6,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 7.6,
                  total: { displayValue: 7.6 },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92437483580555264',
                  workItemSourceReferenceId: '497',
                  workItemType: 'Task',
                  id: '92437483580555264',
                  name: 'FPSA0021',
                  sourceReferenceId: '497',
                  tenantId: '10002',
                  parentId: '92437482984964096',
                  type: 'Task',
                  pathToParent: '/92437481768615936/92437482984964096/',
                  sortOrder: 3,
                  level: 3,
                  sortablePath: '/Anson has a Tree/002/003',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
              ],
              values: {
                '2022Nov': { displayValue: 7.6, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 50.0,
              TotalBooked: 7.6,
              TotalActualWorkload: 0.0,
              TotalEAC: 7.6,
              total: { displayValue: 7.6 },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437482984964096',
              workItemSourceReferenceId: '488',
              workItemType: 'Task',
              id: '92437482984964096',
              name: 'FPSA002',
              sourceReferenceId: '488',
              tenantId: '10002',
              parentId: '92437481768615936',
              type: 'Task',
              pathToParent: '/92437481768615936/',
              sortOrder: 2,
              level: 2,
              sortablePath: '/Anson has a Tree/002',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-08-15T00:00:00',
              EndsAt: '2022-09-05T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 60.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92437484184535040',
                  workItemSourceReferenceId: '489',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 50.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437484184535040',
              workItemSourceReferenceId: '489',
              workItemType: 'Task',
              id: '92437484184535040',
              name: 'FPT003',
              sourceReferenceId: '489',
              tenantId: '10002',
              parentId: '92437481768615936',
              type: 'Task',
              pathToParent: '/92437481768615936/',
              sortOrder: 4,
              level: 2,
              sortablePath: '/Anson has a Tree/004',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-08-15T00:00:00',
              EndsAt: '2022-09-05T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2022-08-15T00:00:00',
                  EndsAt: '2022-09-05T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      StartsAt: '2022-08-15T00:00:00',
                      EndsAt: '2022-09-05T00:00:00',
                      hierarchyType: 'workitem',
                      children: [
                        {
                          hierarchyType: 'resource',
                          values: {
                            '2022Nov': {
                              displayValue: 0,
                              heatmapCode: 'bgHeatmap15',
                              editable: true,
                            },
                            '2022Dec': {
                              displayValue: 0,
                              heatmapCode: 'bgHeatmap10',
                              editable: true,
                            },
                            '2023Jan': {
                              displayValue: 0,
                              heatmapCode: 'bgHeatmap00',
                              editable: true,
                            },
                            '2023Feb': {
                              displayValue: 0,
                              heatmapCode: 'bgHeatmap00',
                              editable: true,
                            },
                            '2023Mar': {
                              displayValue: 0,
                              heatmapCode: 'bgHeatmap00',
                              editable: true,
                            },
                            '2023Apr': {
                              displayValue: 0,
                              heatmapCode: 'bgHeatmap00',
                              editable: true,
                            },
                          },
                          Budget: 60.0,
                          TotalBooked: 0.0,
                          TotalActualWorkload: 0.0,
                          TotalEAC: 0.0,
                          total: { displayValue: 0 },
                          canExpand: true,
                          editable: true,
                          canAssign: false,
                          canDelete: true,
                          resourceId: '90462099863502848',
                          resourceSourceReferenceId: 1,
                          resourceType: 'Employee',
                          workItemId: '92437486000668672',
                          workItemSourceReferenceId: '499',
                          workItemType: 'Task',
                          id: '90462099863502848',
                          name: 'Anders Nielsen',
                          role: 'Projektleder',
                          isActive: false,
                          hiredAt: '2019-01-01',
                          tenantId: '10002',
                          sourceReferenceId: 1,
                          type: 'Employee',
                        },
                      ],
                      values: {
                        '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                      },
                      Budget: 50.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: false,
                      canAssign: true,
                      canDelete: false,
                      resourceId: '0',
                      resourceSourceReferenceId: 0,
                      resourceType: 'Unknown',
                      workItemId: '92437486000668672',
                      workItemSourceReferenceId: '499',
                      workItemType: 'Task',
                      id: '92437486000668672',
                      name: 'FPT00411',
                      sourceReferenceId: '499',
                      tenantId: '10002',
                      parentId: '92437485400883200',
                      type: 'Task',
                      pathToParent: '/92437481768615936/92437484796903424/92437485400883200/',
                      sortOrder: 7,
                      level: 4,
                      sortablePath: '/Anson has a Tree/005/006/007',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: false,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92437485400883200',
                  workItemSourceReferenceId: '498',
                  workItemType: 'Task',
                  id: '92437485400883200',
                  name: 'FPT0041',
                  sourceReferenceId: '498',
                  tenantId: '10002',
                  parentId: '92437484796903424',
                  type: 'Task',
                  pathToParent: '/92437481768615936/92437484796903424/',
                  sortOrder: 6,
                  level: 3,
                  sortablePath: '/Anson has a Tree/005/006',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 50.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437484796903424',
              workItemSourceReferenceId: '490',
              workItemType: 'Task',
              id: '92437484796903424',
              name: 'FPT004',
              sourceReferenceId: '490',
              tenantId: '10002',
              parentId: '92437481768615936',
              type: 'Task',
              pathToParent: '/92437481768615936/',
              sortOrder: 5,
              level: 2,
              sortablePath: '/Anson has a Tree/005',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-08-15T00:00:00',
              EndsAt: '2022-09-05T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 60.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92437486667563008',
                  workItemSourceReferenceId: '491',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 50.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437486667563008',
              workItemSourceReferenceId: '491',
              workItemType: 'Task',
              id: '92437486667563008',
              name: 'TMSA005',
              sourceReferenceId: '491',
              tenantId: '10002',
              parentId: '92437481768615936',
              type: 'Task',
              pathToParent: '/92437481768615936/',
              sortOrder: 8,
              level: 2,
              sortablePath: '/Anson has a Tree/008',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-08-15T00:00:00',
              EndsAt: '2022-09-05T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2022-08-15T00:00:00',
                  EndsAt: '2022-09-05T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      StartsAt: '2022-08-15T00:00:00',
                      EndsAt: '2022-09-05T00:00:00',
                      hierarchyType: 'workitem',
                      children: [
                        {
                          StartsAt: '2022-08-15T00:00:00',
                          EndsAt: '2022-09-05T00:00:00',
                          hierarchyType: 'workitem',
                          children: [
                            {
                              StartsAt: '2022-08-15T00:00:00',
                              EndsAt: '2022-09-05T00:00:00',
                              hierarchyType: 'workitem',
                              children: [
                                {
                                  hierarchyType: 'resource',
                                  values: {
                                    '2022Nov': {
                                      displayValue: 0,
                                      heatmapCode: 'bgHeatmap15',
                                      editable: true,
                                    },
                                    '2022Dec': {
                                      displayValue: 0,
                                      heatmapCode: 'bgHeatmap10',
                                      editable: true,
                                    },
                                    '2023Jan': {
                                      displayValue: 0,
                                      heatmapCode: 'bgHeatmap00',
                                      editable: true,
                                    },
                                    '2023Feb': {
                                      displayValue: 0,
                                      heatmapCode: 'bgHeatmap00',
                                      editable: true,
                                    },
                                    '2023Mar': {
                                      displayValue: 0,
                                      heatmapCode: 'bgHeatmap00',
                                      editable: true,
                                    },
                                    '2023Apr': {
                                      displayValue: 0,
                                      heatmapCode: 'bgHeatmap00',
                                      editable: true,
                                    },
                                  },
                                  Budget: 0.0,
                                  TotalBooked: 0.0,
                                  TotalActualWorkload: 0.0,
                                  TotalEAC: 0.0,
                                  total: { displayValue: 0 },
                                  canExpand: true,
                                  editable: true,
                                  canAssign: false,
                                  canDelete: true,
                                  resourceId: '90462099863502848',
                                  resourceSourceReferenceId: 1,
                                  resourceType: 'Employee',
                                  workItemId: '92437489691656192',
                                  workItemSourceReferenceId: '503',
                                  workItemType: 'Task',
                                  id: '90462099863502848',
                                  name: 'Anders Nielsen',
                                  role: 'Projektleder',
                                  isActive: false,
                                  hiredAt: '2019-01-01',
                                  tenantId: '10002',
                                  sourceReferenceId: 1,
                                  type: 'Employee',
                                },
                              ],
                              values: {
                                '2022Nov': {
                                  displayValue: 0,
                                  heatmapCode: '',
                                  editable: false,
                                  value: 1,
                                },
                                '2022Dec': {
                                  displayValue: 0,
                                  heatmapCode: '',
                                  editable: false,
                                  value: 1,
                                },
                                '2023Jan': {
                                  displayValue: 0,
                                  heatmapCode: '',
                                  editable: false,
                                  value: 1,
                                },
                                '2023Feb': {
                                  displayValue: 0,
                                  heatmapCode: '',
                                  editable: false,
                                  value: 1,
                                },
                                '2023Mar': {
                                  displayValue: 0,
                                  heatmapCode: '',
                                  editable: false,
                                  value: 1,
                                },
                                '2023Apr': {
                                  displayValue: 0,
                                  heatmapCode: '',
                                  editable: false,
                                  value: 1,
                                },
                              },
                              Budget: 0.0,
                              TotalBooked: 0.0,
                              TotalActualWorkload: 0.0,
                              TotalEAC: 0.0,
                              total: { displayValue: 0 },
                              canExpand: true,
                              editable: false,
                              canAssign: true,
                              canDelete: false,
                              resourceId: '0',
                              resourceSourceReferenceId: 0,
                              resourceType: 'Unknown',
                              workItemId: '92437489691656192',
                              workItemSourceReferenceId: '503',
                              workItemType: 'Task',
                              id: '92437489691656192',
                              name: '4 parents task',
                              sourceReferenceId: '503',
                              tenantId: '10002',
                              parentId: '92437489091870720',
                              type: 'Task',
                              pathToParent:
                                '/92437481768615936/92437487275737088/92437487879716864/92437488479502336/92437489091870720/',
                              sortOrder: 13,
                              level: 6,
                              sortablePath: '/Anson has a Tree/009/010/011/012/013',
                              isActive: false,
                              startAt: '0001-01-01T00:00:00',
                              endAt: '0001-01-01T00:00:00',
                              status: 'InProgress',
                            },
                          ],
                          values: {
                            '2022Nov': {
                              displayValue: 0,
                              heatmapCode: '',
                              editable: false,
                              value: 1,
                            },
                            '2022Dec': {
                              displayValue: 0,
                              heatmapCode: '',
                              editable: false,
                              value: 1,
                            },
                            '2023Jan': {
                              displayValue: 0,
                              heatmapCode: '',
                              editable: false,
                              value: 1,
                            },
                            '2023Feb': {
                              displayValue: 0,
                              heatmapCode: '',
                              editable: false,
                              value: 1,
                            },
                            '2023Mar': {
                              displayValue: 0,
                              heatmapCode: '',
                              editable: false,
                              value: 1,
                            },
                            '2023Apr': {
                              displayValue: 0,
                              heatmapCode: '',
                              editable: false,
                              value: 1,
                            },
                          },
                          Budget: 0.0,
                          TotalBooked: 0.0,
                          TotalActualWorkload: 0.0,
                          TotalEAC: 0.0,
                          total: { displayValue: 0 },
                          canExpand: true,
                          editable: false,
                          canAssign: false,
                          canDelete: false,
                          resourceId: '0',
                          resourceSourceReferenceId: 0,
                          resourceType: 'Unknown',
                          workItemId: '92437489091870720',
                          workItemSourceReferenceId: '502',
                          workItemType: 'Task',
                          id: '92437489091870720',
                          name: 'TMSA006111',
                          sourceReferenceId: '502',
                          tenantId: '10002',
                          parentId: '92437488479502336',
                          type: 'Task',
                          pathToParent:
                            '/92437481768615936/92437487275737088/92437487879716864/92437488479502336/',
                          sortOrder: 12,
                          level: 5,
                          sortablePath: '/Anson has a Tree/009/010/011/012',
                          isActive: false,
                          startAt: '0001-01-01T00:00:00',
                          endAt: '0001-01-01T00:00:00',
                          status: 'InProgress',
                        },
                      ],
                      values: {
                        '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                      },
                      Budget: 0.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: false,
                      canAssign: false,
                      canDelete: false,
                      resourceId: '0',
                      resourceSourceReferenceId: 0,
                      resourceType: 'Unknown',
                      workItemId: '92437488479502336',
                      workItemSourceReferenceId: '501',
                      workItemType: 'Task',
                      id: '92437488479502336',
                      name: 'TMSA00611',
                      sourceReferenceId: '501',
                      tenantId: '10002',
                      parentId: '92437487879716864',
                      type: 'Task',
                      pathToParent: '/92437481768615936/92437487275737088/92437487879716864/',
                      sortOrder: 11,
                      level: 4,
                      sortablePath: '/Anson has a Tree/009/010/011',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: false,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92437487879716864',
                  workItemSourceReferenceId: '500',
                  workItemType: 'Task',
                  id: '92437487879716864',
                  name: 'TMSA0061',
                  sourceReferenceId: '500',
                  tenantId: '10002',
                  parentId: '92437487275737088',
                  type: 'Task',
                  pathToParent: '/92437481768615936/92437487275737088/',
                  sortOrder: 10,
                  level: 3,
                  sortablePath: '/Anson has a Tree/009/010',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437487275737088',
              workItemSourceReferenceId: '492',
              workItemType: 'Task',
              id: '92437487275737088',
              name: 'TMSA006',
              sourceReferenceId: '492',
              tenantId: '10002',
              parentId: '92437481768615936',
              type: 'Task',
              pathToParent: '/92437481768615936/',
              sortOrder: 9,
              level: 2,
              sortablePath: '/Anson has a Tree/009',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-08-15T00:00:00',
              EndsAt: '2022-09-05T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 100.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92437490316607488',
                  workItemSourceReferenceId: '493',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 100.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437490316607488',
              workItemSourceReferenceId: '493',
              workItemType: 'Task',
              id: '92437490316607488',
              name: 'FPCS009',
              sourceReferenceId: '493',
              tenantId: '10002',
              parentId: '92437481768615936',
              type: 'Task',
              pathToParent: '/92437481768615936/',
              sortOrder: 16,
              level: 2,
              sortablePath: '/Anson has a Tree/016',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-08-15T00:00:00',
              EndsAt: '2022-09-05T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 100.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92437490958336000',
                  workItemSourceReferenceId: '494',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 100.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437490958336000',
              workItemSourceReferenceId: '494',
              workItemType: 'Task',
              id: '92437490958336000',
              name: 'FPII010',
              sourceReferenceId: '494',
              tenantId: '10002',
              parentId: '92437481768615936',
              type: 'Task',
              pathToParent: '/92437481768615936/',
              sortOrder: 17,
              level: 2,
              sortablePath: '/Anson has a Tree/017',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-08-15T00:00:00',
              EndsAt: '2022-09-05T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 100.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92437491558121472',
                  workItemSourceReferenceId: '495',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 100.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437491558121472',
              workItemSourceReferenceId: '495',
              workItemType: 'Task',
              id: '92437491558121472',
              name: 'TMPH011',
              sourceReferenceId: '495',
              tenantId: '10002',
              parentId: '92437481768615936',
              type: 'Task',
              pathToParent: '/92437481768615936/',
              sortOrder: 18,
              level: 2,
              sortablePath: '/Anson has a Tree/018',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-08-15T00:00:00',
              EndsAt: '2022-09-05T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 100.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92437492162101248',
                  workItemSourceReferenceId: '496',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 100.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437492162101248',
              workItemSourceReferenceId: '496',
              workItemType: 'Task',
              id: '92437492162101248',
              name: 'TMPB012',
              sourceReferenceId: '496',
              tenantId: '10002',
              parentId: '92437481768615936',
              type: 'Task',
              pathToParent: '/92437481768615936/',
              sortOrder: 19,
              level: 2,
              sortablePath: '/Anson has a Tree/019',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-11-02T00:00:00',
              EndsAt: '2022-11-02T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '99430124272746496',
                  workItemSourceReferenceId: '505',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '99430124272746496',
                  workItemSourceReferenceId: '505',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 68.55,
                      heatmapCode: 'bgHeatmap60',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 68.55,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 68.55,
                  total: { displayValue: 68.55 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462688282411008',
                  resourceSourceReferenceId: 15,
                  resourceType: 'Employee',
                  workItemId: '99430124272746496',
                  workItemSourceReferenceId: '505',
                  workItemType: 'Task',
                  id: '90462688282411008',
                  name: 'Bruno Mars',
                  role: 'PO',
                  isActive: false,
                  hiredAt: '2021-08-18',
                  tenantId: '10002',
                  sourceReferenceId: 15,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 68.55, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 68.55,
              TotalActualWorkload: 0.0,
              TotalEAC: 68.55,
              total: { displayValue: 68.55 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '99430124272746496',
              workItemSourceReferenceId: '505',
              workItemType: 'Task',
              id: '99430124272746496',
              name: 'Another test',
              sourceReferenceId: '505',
              tenantId: '10002',
              parentId: '92437481768615936',
              type: 'Task',
              pathToParent: '/92437481768615936/',
              sortOrder: 20,
              level: 2,
              sortablePath: '/Anson has a Tree/020',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
          ],
          values: {
            '2022Nov': { displayValue: 76.15, heatmapCode: '', editable: false, value: 1 },
            '2022Dec': { displayValue: 8.0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
          },
          Budget: 0.0,
          TotalBooked: 84.15,
          TotalActualWorkload: 0.0,
          TotalEAC: 84.15,
          total: { displayValue: 84.15 },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '92437481768615936',
          workItemSourceReferenceId: '78',
          workItemType: 'Project',
          id: '92437481768615936',
          name: 'Anson has a Tree',
          sourceReferenceId: '78',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Anson has a Tree',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
        },
        {
          StartsAt: '2021-09-23T00:00:00',
          EndsAt: '2021-12-16T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2021-10-22T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437113315786752',
                  workItemSourceReferenceId: '440',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 4.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92437113315786752',
                  workItemSourceReferenceId: '440',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 4.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437113315786752',
              workItemSourceReferenceId: '440',
              workItemType: 'Task',
              id: '92437113315786752',
              name: 'Teknisk setup af web services integration',
              sourceReferenceId: '440',
              tenantId: '10002',
              parentId: '92437112669863936',
              type: 'Task',
              pathToParent: '/92437112669863936/',
              sortOrder: 1,
              level: 2,
              sortablePath: '/Business Central implementering CIS/001',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-09-23T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 9.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437113923960832',
                  workItemSourceReferenceId: '441',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 4.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463472915054592',
                  resourceSourceReferenceId: 32,
                  resourceType: 'Employee',
                  workItemId: '92437113923960832',
                  workItemSourceReferenceId: '441',
                  workItemType: 'Task',
                  id: '90463472915054592',
                  name: 'Klaus Lingskov',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 32,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 4.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437113923960832',
              workItemSourceReferenceId: '441',
              workItemType: 'Task',
              id: '92437113923960832',
              name: 'Ny BC kunde - system opsætning',
              sourceReferenceId: '441',
              tenantId: '10002',
              parentId: '92437112669863936',
              type: 'Task',
              pathToParent: '/92437112669863936/',
              sortOrder: 2,
              level: 2,
              sortablePath: '/Business Central implementering CIS/002',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-11-29T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437114523746304',
                  workItemSourceReferenceId: '442',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 24.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92437114523746304',
                  workItemSourceReferenceId: '442',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 24.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437114523746304',
              workItemSourceReferenceId: '442',
              workItemType: 'Task',
              id: '92437114523746304',
              name: 'Test- og driftkonvertering ',
              sourceReferenceId: '442',
              tenantId: '10002',
              parentId: '92437112669863936',
              type: 'Task',
              pathToParent: '/92437112669863936/',
              sortOrder: 3,
              level: 2,
              sortablePath: '/Business Central implementering CIS/003',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-10-31T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437115157086208',
                  workItemSourceReferenceId: '443',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462688282411008',
                  resourceSourceReferenceId: 15,
                  resourceType: 'Employee',
                  workItemId: '92437115157086208',
                  workItemSourceReferenceId: '443',
                  workItemType: 'Task',
                  id: '90462688282411008',
                  name: 'Bruno Mars',
                  role: 'PO',
                  isActive: false,
                  hiredAt: '2021-08-18',
                  tenantId: '10002',
                  sourceReferenceId: 15,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 8.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92437115157086208',
                  workItemSourceReferenceId: '443',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 8.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437115157086208',
              workItemSourceReferenceId: '443',
              workItemType: 'Task',
              id: '92437115157086208',
              name: 'Betjeningskursus',
              sourceReferenceId: '443',
              tenantId: '10002',
              parentId: '92437112669863936',
              type: 'Task',
              pathToParent: '/92437112669863936/',
              sortOrder: 4,
              level: 2,
              sortablePath: '/Business Central implementering CIS/004',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-10-10T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437115756871680',
                  workItemSourceReferenceId: '444',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 12.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92437115756871680',
                  workItemSourceReferenceId: '444',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 12.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437115756871680',
              workItemSourceReferenceId: '444',
              workItemType: 'Task',
              id: '92437115756871680',
              name: 'Opstartshjælp',
              sourceReferenceId: '444',
              tenantId: '10002',
              parentId: '92437112669863936',
              type: 'Task',
              pathToParent: '/92437112669863936/',
              sortOrder: 5,
              level: 2,
              sortablePath: '/Business Central implementering CIS/005',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-10-10T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437116373434368',
                  workItemSourceReferenceId: '445',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 7.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90464062642585600',
                  resourceSourceReferenceId: 35,
                  resourceType: 'Employee',
                  workItemId: '92437116373434368',
                  workItemSourceReferenceId: '445',
                  workItemType: 'Task',
                  id: '90464062642585600',
                  name: 'Mads Klavstrup  Mathiasen',
                  role: 'CEO',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 35,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 8.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92437116373434368',
                  workItemSourceReferenceId: '445',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 8.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437116373434368',
              workItemSourceReferenceId: '445',
              workItemType: 'Task',
              id: '92437116373434368',
              name: 'Projektledelse',
              sourceReferenceId: '445',
              tenantId: '10002',
              parentId: '92437112669863936',
              type: 'Task',
              pathToParent: '/92437112669863936/',
              sortOrder: 6,
              level: 2,
              sortablePath: '/Business Central implementering CIS/006',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
          ],
          values: {
            '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          total: { displayValue: 0 },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '92437112669863936',
          workItemSourceReferenceId: '68',
          workItemType: 'Project',
          id: '92437112669863936',
          name: 'Business Central implementering CIS',
          sourceReferenceId: '68',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Business Central implementering CIS',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
        },
        {
          StartsAt: '2021-10-22T00:00:00',
          EndsAt: '2022-01-14T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2021-10-29T00:00:00',
              EndsAt: '2022-01-14T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92436996638638080',
                  workItemSourceReferenceId: '434',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 8.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90464062642585600',
                  resourceSourceReferenceId: 35,
                  resourceType: 'Employee',
                  workItemId: '92436996638638080',
                  workItemSourceReferenceId: '434',
                  workItemType: 'Task',
                  id: '90464062642585600',
                  name: 'Mads Klavstrup  Mathiasen',
                  role: 'CEO',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 35,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 4.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92436996638638080',
                  workItemSourceReferenceId: '434',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 4.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92436996638638080',
              workItemSourceReferenceId: '434',
              workItemType: 'Task',
              id: '92436996638638080',
              name: 'Teknisk setup af web services integration',
              sourceReferenceId: '434',
              tenantId: '10002',
              parentId: '92436996043046912',
              type: 'Task',
              pathToParent: '/92436996043046912/',
              sortOrder: 1,
              level: 2,
              sortablePath: '/Business Central Implementering CPH/001',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-11-01T00:00:00',
              EndsAt: '2021-12-18T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92436997255200768',
                  workItemSourceReferenceId: '435',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 4.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463472915054592',
                  resourceSourceReferenceId: 32,
                  resourceType: 'Employee',
                  workItemId: '92436997255200768',
                  workItemSourceReferenceId: '435',
                  workItemType: 'Task',
                  id: '90463472915054592',
                  name: 'Klaus Lingskov',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 32,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 4.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92436997255200768',
              workItemSourceReferenceId: '435',
              workItemType: 'Task',
              id: '92436997255200768',
              name: 'Ny BC kunde - system opsætning',
              sourceReferenceId: '435',
              tenantId: '10002',
              parentId: '92436996043046912',
              type: 'Task',
              pathToParent: '/92436996043046912/',
              sortOrder: 2,
              level: 2,
              sortablePath: '/Business Central Implementering CPH/002',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-10-22T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92436997896929280',
                  workItemSourceReferenceId: '436',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463472915054592',
                  resourceSourceReferenceId: 32,
                  resourceType: 'Employee',
                  workItemId: '92436997896929280',
                  workItemSourceReferenceId: '436',
                  workItemType: 'Task',
                  id: '90463472915054592',
                  name: 'Klaus Lingskov',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 32,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 24.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92436997896929280',
                  workItemSourceReferenceId: '436',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 24.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92436997896929280',
              workItemSourceReferenceId: '436',
              workItemType: 'Task',
              id: '92436997896929280',
              name: 'Test- og driftkonvertering ',
              sourceReferenceId: '436',
              tenantId: '10002',
              parentId: '92436996043046912',
              type: 'Task',
              pathToParent: '/92436996043046912/',
              sortOrder: 3,
              level: 2,
              sortablePath: '/Business Central Implementering CPH/003',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-10-23T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92436998492520448',
                  workItemSourceReferenceId: '437',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462688282411008',
                  resourceSourceReferenceId: 15,
                  resourceType: 'Employee',
                  workItemId: '92436998492520448',
                  workItemSourceReferenceId: '437',
                  workItemType: 'Task',
                  id: '90462688282411008',
                  name: 'Bruno Mars',
                  role: 'PO',
                  isActive: false,
                  hiredAt: '2021-08-18',
                  tenantId: '10002',
                  sourceReferenceId: 15,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 8.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92436998492520448',
                  workItemSourceReferenceId: '437',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 8.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92436998492520448',
              workItemSourceReferenceId: '437',
              workItemType: 'Task',
              id: '92436998492520448',
              name: 'Betjeningskursus',
              sourceReferenceId: '437',
              tenantId: '10002',
              parentId: '92436996043046912',
              type: 'Task',
              pathToParent: '/92436996043046912/',
              sortOrder: 4,
              level: 2,
              sortablePath: '/Business Central Implementering CPH/004',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-10-25T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92436999130054656',
                  workItemSourceReferenceId: '438',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 6.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92436999130054656',
                  workItemSourceReferenceId: '438',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 12.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92436999130054656',
                  workItemSourceReferenceId: '438',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 12.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92436999130054656',
              workItemSourceReferenceId: '438',
              workItemType: 'Task',
              id: '92436999130054656',
              name: 'Opstartshjælp',
              sourceReferenceId: '438',
              tenantId: '10002',
              parentId: '92436996043046912',
              type: 'Task',
              pathToParent: '/92436996043046912/',
              sortOrder: 5,
              level: 2,
              sortablePath: '/Business Central Implementering CPH/005',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-11-26T00:00:00',
              EndsAt: '2021-12-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92436999742423040',
                  workItemSourceReferenceId: '439',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 8.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463277070417920',
                  resourceSourceReferenceId: 31,
                  resourceType: 'Employee',
                  workItemId: '92436999742423040',
                  workItemSourceReferenceId: '439',
                  workItemType: 'Task',
                  id: '90463277070417920',
                  name: 'Maj Britt  Brøndum',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-05-03',
                  tenantId: '10002',
                  sourceReferenceId: 31,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 8.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92436999742423040',
              workItemSourceReferenceId: '439',
              workItemType: 'Task',
              id: '92436999742423040',
              name: 'Projektledelse',
              sourceReferenceId: '439',
              tenantId: '10002',
              parentId: '92436996043046912',
              type: 'Task',
              pathToParent: '/92436996043046912/',
              sortOrder: 6,
              level: 2,
              sortablePath: '/Business Central Implementering CPH/006',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
          ],
          values: {
            '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          total: { displayValue: 0 },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '92436996043046912',
          workItemSourceReferenceId: '67',
          workItemType: 'Project',
          id: '92436996043046912',
          name: 'Business Central Implementering CPH',
          sourceReferenceId: '67',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Business Central Implementering CPH',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
        },
        {
          StartsAt: '2021-09-15T00:00:00',
          EndsAt: '2022-07-18T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2021-09-15T00:00:00',
              EndsAt: '2022-02-03T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2021-09-15T00:00:00',
                  EndsAt: '2022-02-03T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap15',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap10',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 70.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462099863502848',
                      resourceSourceReferenceId: 1,
                      resourceType: 'Employee',
                      workItemId: '92436878153744384',
                      workItemSourceReferenceId: '423',
                      workItemType: 'Task',
                      id: '90462099863502848',
                      name: 'Anders Nielsen',
                      role: 'Projektleder',
                      isActive: false,
                      hiredAt: '2019-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 1,
                      type: 'Employee',
                    },
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap60',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 0.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462688282411008',
                      resourceSourceReferenceId: 15,
                      resourceType: 'Employee',
                      workItemId: '92436878153744384',
                      workItemSourceReferenceId: '423',
                      workItemType: 'Task',
                      id: '90462688282411008',
                      name: 'Bruno Mars',
                      role: 'PO',
                      isActive: false,
                      hiredAt: '2021-08-18',
                      tenantId: '10002',
                      sourceReferenceId: 15,
                      type: 'Employee',
                    },
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 50.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462295636836352',
                      resourceSourceReferenceId: 10,
                      resourceType: 'Employee',
                      workItemId: '92436878153744384',
                      workItemSourceReferenceId: '423',
                      workItemType: 'Task',
                      id: '90462295636836352',
                      name: 'Frederik Olsen',
                      role: 'Konsulent',
                      isActive: false,
                      hiredAt: '2020-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 10,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 80.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92436878153744384',
                  workItemSourceReferenceId: '423',
                  workItemType: 'Task',
                  id: '92436878153744384',
                  name: 'Analyse',
                  sourceReferenceId: '423',
                  tenantId: '10002',
                  parentId: '92436877474267136',
                  type: 'Task',
                  pathToParent: '/92436876727681024/92436877474267136/',
                  sortOrder: 2,
                  level: 3,
                  sortablePath: '/New Website for Odense/001/002',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
                {
                  StartsAt: '2021-12-02T00:00:00',
                  EndsAt: '2021-12-08T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap15',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap10',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 20.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462099863502848',
                      resourceSourceReferenceId: 1,
                      resourceType: 'Employee',
                      workItemId: '92436878774501376',
                      workItemSourceReferenceId: '424',
                      workItemType: 'Task',
                      id: '90462099863502848',
                      name: 'Anders Nielsen',
                      role: 'Projektleder',
                      isActive: false,
                      hiredAt: '2019-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 1,
                      type: 'Employee',
                    },
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 50.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462295636836352',
                      resourceSourceReferenceId: 10,
                      resourceType: 'Employee',
                      workItemId: '92436878774501376',
                      workItemSourceReferenceId: '424',
                      workItemType: 'Task',
                      id: '90462295636836352',
                      name: 'Frederik Olsen',
                      role: 'Konsulent',
                      isActive: false,
                      hiredAt: '2020-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 10,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 40.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92436878774501376',
                  workItemSourceReferenceId: '424',
                  workItemType: 'Task',
                  id: '92436878774501376',
                  name: 'Rapport',
                  sourceReferenceId: '424',
                  tenantId: '10002',
                  parentId: '92436877474267136',
                  type: 'Task',
                  pathToParent: '/92436876727681024/92436877474267136/',
                  sortOrder: 3,
                  level: 3,
                  sortablePath: '/New Website for Odense/001/003',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
                {
                  StartsAt: '2022-01-10T00:00:00',
                  EndsAt: '2022-02-03T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap15',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap10',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 0.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462099863502848',
                      resourceSourceReferenceId: 1,
                      resourceType: 'Employee',
                      workItemId: '92436879370092544',
                      workItemSourceReferenceId: '425',
                      workItemType: 'Task',
                      id: '90462099863502848',
                      name: 'Anders Nielsen',
                      role: 'Projektleder',
                      isActive: false,
                      hiredAt: '2019-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 1,
                      type: 'Employee',
                    },
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap60',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 0.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462688282411008',
                      resourceSourceReferenceId: 15,
                      resourceType: 'Employee',
                      workItemId: '92436879370092544',
                      workItemSourceReferenceId: '425',
                      workItemType: 'Task',
                      id: '90462688282411008',
                      name: 'Bruno Mars',
                      role: 'PO',
                      isActive: false,
                      hiredAt: '2021-08-18',
                      tenantId: '10002',
                      sourceReferenceId: 15,
                      type: 'Employee',
                    },
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 50.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462295636836352',
                      resourceSourceReferenceId: 10,
                      resourceType: 'Employee',
                      workItemId: '92436879370092544',
                      workItemSourceReferenceId: '425',
                      workItemType: 'Task',
                      id: '90462295636836352',
                      name: 'Frederik Olsen',
                      role: 'Konsulent',
                      isActive: false,
                      hiredAt: '2020-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 10,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 10.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92436879370092544',
                  workItemSourceReferenceId: '425',
                  workItemType: 'Task',
                  id: '92436879370092544',
                  name: 'Fremlæggelse',
                  sourceReferenceId: '425',
                  tenantId: '10002',
                  parentId: '92436877474267136',
                  type: 'Task',
                  pathToParent: '/92436876727681024/92436877474267136/',
                  sortOrder: 4,
                  level: 3,
                  sortablePath: '/New Website for Odense/001/004',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92436877474267136',
              workItemSourceReferenceId: '422',
              workItemType: 'Task',
              id: '92436877474267136',
              name: 'Fase 1',
              sourceReferenceId: '422',
              tenantId: '10002',
              parentId: '92436876727681024',
              type: 'Task',
              pathToParent: '/92436876727681024/',
              sortOrder: 1,
              level: 2,
              sortablePath: '/New Website for Odense/001',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-11-15T00:00:00',
              EndsAt: '2022-05-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2021-11-15T00:00:00',
                  EndsAt: '2022-02-17T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 50.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462295636836352',
                      resourceSourceReferenceId: 10,
                      resourceType: 'Employee',
                      workItemId: '92436880636772352',
                      workItemSourceReferenceId: '427',
                      workItemType: 'Task',
                      id: '90462295636836352',
                      name: 'Frederik Olsen',
                      role: 'Konsulent',
                      isActive: false,
                      hiredAt: '2020-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 10,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 70.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92436880636772352',
                  workItemSourceReferenceId: '427',
                  workItemType: 'Task',
                  id: '92436880636772352',
                  name: 'Design af brugeroplevelse',
                  sourceReferenceId: '427',
                  tenantId: '10002',
                  parentId: '92436879995043840',
                  type: 'Task',
                  pathToParent: '/92436876727681024/92436879995043840/',
                  sortOrder: 7,
                  level: 3,
                  sortablePath: '/New Website for Odense/006/007',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
                {
                  StartsAt: '2022-01-13T00:00:00',
                  EndsAt: '2022-03-16T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 50.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462295636836352',
                      resourceSourceReferenceId: 10,
                      resourceType: 'Employee',
                      workItemId: '92436881295278080',
                      workItemSourceReferenceId: '428',
                      workItemType: 'Task',
                      id: '90462295636836352',
                      name: 'Frederik Olsen',
                      role: 'Konsulent',
                      isActive: false,
                      hiredAt: '2020-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 10,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 100.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92436881295278080',
                  workItemSourceReferenceId: '428',
                  workItemType: 'Task',
                  id: '92436881295278080',
                  name: 'Design af brugergrænseflader',
                  sourceReferenceId: '428',
                  tenantId: '10002',
                  parentId: '92436879995043840',
                  type: 'Task',
                  pathToParent: '/92436876727681024/92436879995043840/',
                  sortOrder: 8,
                  level: 3,
                  sortablePath: '/New Website for Odense/006/008',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
                {
                  StartsAt: '2021-12-29T00:00:00',
                  EndsAt: '2022-05-16T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap15',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap10',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 0.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462099863502848',
                      resourceSourceReferenceId: 1,
                      resourceType: 'Employee',
                      workItemId: '92436881911840768',
                      workItemSourceReferenceId: '429',
                      workItemType: 'Task',
                      id: '90462099863502848',
                      name: 'Anders Nielsen',
                      role: 'Projektleder',
                      isActive: false,
                      hiredAt: '2019-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 1,
                      type: 'Employee',
                    },
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap75',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 0.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90463866403684352',
                      resourceSourceReferenceId: 34,
                      resourceType: 'Employee',
                      workItemId: '92436881911840768',
                      workItemSourceReferenceId: '429',
                      workItemType: 'Task',
                      id: '90463866403684352',
                      name: 'Anett Korterup',
                      role: 'Software',
                      isActive: false,
                      hiredAt: '2021-07-05',
                      tenantId: '10002',
                      sourceReferenceId: 34,
                      type: 'Employee',
                    },
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 50.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462295636836352',
                      resourceSourceReferenceId: 10,
                      resourceType: 'Employee',
                      workItemId: '92436881911840768',
                      workItemSourceReferenceId: '429',
                      workItemType: 'Task',
                      id: '90462295636836352',
                      name: 'Frederik Olsen',
                      role: 'Konsulent',
                      isActive: false,
                      hiredAt: '2020-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 10,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 300.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92436881911840768',
                  workItemSourceReferenceId: '429',
                  workItemType: 'Task',
                  id: '92436881911840768',
                  name: 'Udvikling af løsning',
                  sourceReferenceId: '429',
                  tenantId: '10002',
                  parentId: '92436879995043840',
                  type: 'Task',
                  pathToParent: '/92436876727681024/92436879995043840/',
                  sortOrder: 9,
                  level: 3,
                  sortablePath: '/New Website for Odense/006/009',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92436879995043840',
              workItemSourceReferenceId: '426',
              workItemType: 'Task',
              id: '92436879995043840',
              name: 'Fase 2',
              sourceReferenceId: '426',
              tenantId: '10002',
              parentId: '92436876727681024',
              type: 'Task',
              pathToParent: '/92436876727681024/',
              sortOrder: 6,
              level: 2,
              sortablePath: '/New Website for Odense/006',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-05-18T00:00:00',
              EndsAt: '2022-07-18T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2022-05-18T00:00:00',
                  EndsAt: '2022-06-15T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 30.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462295636836352',
                      resourceSourceReferenceId: 10,
                      resourceType: 'Employee',
                      workItemId: '92436883212075008',
                      workItemSourceReferenceId: '431',
                      workItemType: 'Task',
                      id: '90462295636836352',
                      name: 'Frederik Olsen',
                      role: 'Konsulent',
                      isActive: false,
                      hiredAt: '2020-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 10,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 200.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92436883212075008',
                  workItemSourceReferenceId: '431',
                  workItemType: 'Task',
                  id: '92436883212075008',
                  name: 'Tuning af samlet løsning',
                  sourceReferenceId: '431',
                  tenantId: '10002',
                  parentId: '92436882570346496',
                  type: 'Task',
                  pathToParent: '/92436876727681024/92436882570346496/',
                  sortOrder: 12,
                  level: 3,
                  sortablePath: '/New Website for Odense/011/012',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
                {
                  StartsAt: '2022-06-01T00:00:00',
                  EndsAt: '2022-06-30T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 50.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462295636836352',
                      resourceSourceReferenceId: 10,
                      resourceType: 'Employee',
                      workItemId: '92436883807666176',
                      workItemSourceReferenceId: '432',
                      workItemType: 'Task',
                      id: '90462295636836352',
                      name: 'Frederik Olsen',
                      role: 'Konsulent',
                      isActive: false,
                      hiredAt: '2020-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 10,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92436883807666176',
                  workItemSourceReferenceId: '432',
                  workItemType: 'Task',
                  id: '92436883807666176',
                  name: 'Udarbejdelse af dokumentation',
                  sourceReferenceId: '432',
                  tenantId: '10002',
                  parentId: '92436882570346496',
                  type: 'Task',
                  pathToParent: '/92436876727681024/92436882570346496/',
                  sortOrder: 13,
                  level: 3,
                  sortablePath: '/New Website for Odense/011/013',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
                {
                  StartsAt: '2022-06-16T00:00:00',
                  EndsAt: '2022-07-14T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 50.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90462295636836352',
                      resourceSourceReferenceId: 10,
                      resourceType: 'Employee',
                      workItemId: '92436884403257344',
                      workItemSourceReferenceId: '433',
                      workItemType: 'Task',
                      id: '90462295636836352',
                      name: 'Frederik Olsen',
                      role: 'Konsulent',
                      isActive: false,
                      hiredAt: '2020-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 10,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 150.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92436884403257344',
                  workItemSourceReferenceId: '433',
                  workItemType: 'Task',
                  id: '92436884403257344',
                  name: 'Udrulning',
                  sourceReferenceId: '433',
                  tenantId: '10002',
                  parentId: '92436882570346496',
                  type: 'Task',
                  pathToParent: '/92436876727681024/92436882570346496/',
                  sortOrder: 14,
                  level: 3,
                  sortablePath: '/New Website for Odense/011/014',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92436882570346496',
              workItemSourceReferenceId: '430',
              workItemType: 'Task',
              id: '92436882570346496',
              name: 'Fase 3',
              sourceReferenceId: '430',
              tenantId: '10002',
              parentId: '92436876727681024',
              type: 'Task',
              pathToParent: '/92436876727681024/',
              sortOrder: 11,
              level: 2,
              sortablePath: '/New Website for Odense/011',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
          ],
          values: {
            '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
          },
          Budget: 1000.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          total: { displayValue: 0 },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '92436876727681024',
          workItemSourceReferenceId: '66',
          workItemType: 'Project',
          id: '92436876727681024',
          name: 'New Website for Odense',
          sourceReferenceId: '66',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/New Website for Odense',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
        },
        {
          StartsAt: '2022-03-16T00:00:00',
          EndsAt: '2022-06-16T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2022-03-16T00:00:00',
              EndsAt: '2022-06-16T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 33.0,
                  TotalEAC: 33.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437333760016384',
                  workItemSourceReferenceId: '470',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 1000.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 33.0,
              TotalEAC: 33.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437333760016384',
              workItemSourceReferenceId: '470',
              workItemType: 'Task',
              id: '92437333760016384',
              name: 'Test Task 30 days',
              sourceReferenceId: '470',
              tenantId: '10002',
              parentId: '92437333063761920',
              type: 'Task',
              pathToParent: '/92437333063761920/',
              sortOrder: 1,
              level: 2,
              sortablePath: '/Project for 30 days/001',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
          ],
          values: {
            '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
          },
          Budget: 10000.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 33.0,
          TotalEAC: 33.0,
          total: { displayValue: 0 },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '92437333063761920',
          workItemSourceReferenceId: '75',
          workItemType: 'Project',
          id: '92437333063761920',
          name: 'Project for 30 days',
          sourceReferenceId: '75',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Project for 30 days',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
        },
        {
          StartsAt: '2022-06-30T00:00:00',
          EndsAt: '2022-11-29T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2022-06-30T00:00:00',
              EndsAt: '2022-07-01T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap75',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437402420772864',
                  workItemSourceReferenceId: '473',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437402420772864',
              workItemSourceReferenceId: '473',
              workItemType: 'Task',
              id: '92437402420772864',
              name: 'Research',
              sourceReferenceId: '473',
              tenantId: '10002',
              parentId: '92437401804210176',
              type: 'Task',
              pathToParent: '/92437401804210176/',
              sortOrder: 1,
              level: 2,
              sortablePath: '/Resource Planner Implementation/001',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-06-30T00:00:00',
              EndsAt: '2022-07-14T00:00:00',
              hierarchyType: 'workitem',
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437403037335552',
              workItemSourceReferenceId: '474',
              workItemType: 'Task',
              id: '92437403037335552',
              name: 'Discovery',
              sourceReferenceId: '474',
              tenantId: '10002',
              parentId: '92437401804210176',
              type: 'Task',
              pathToParent: '/92437401804210176/',
              sortOrder: 2,
              level: 2,
              sortablePath: '/Resource Planner Implementation/002',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-07-08T00:00:00',
              EndsAt: '2022-08-31T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 8.0,
                  TotalEAC: 8.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92437403712618496',
                  workItemSourceReferenceId: '475',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 8.0,
              TotalEAC: 8.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437403712618496',
              workItemSourceReferenceId: '475',
              workItemType: 'Task',
              id: '92437403712618496',
              name: 'Development',
              sourceReferenceId: '475',
              tenantId: '10002',
              parentId: '92437401804210176',
              type: 'Task',
              pathToParent: '/92437401804210176/',
              sortOrder: 3,
              level: 2,
              sortablePath: '/Resource Planner Implementation/003',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-07-14T00:00:00',
              EndsAt: '2022-09-15T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462688282411008',
                  resourceSourceReferenceId: 15,
                  resourceType: 'Employee',
                  workItemId: '92437404354347008',
                  workItemSourceReferenceId: '476',
                  workItemType: 'Task',
                  id: '90462688282411008',
                  name: 'Bruno Mars',
                  role: 'PO',
                  isActive: false,
                  hiredAt: '2021-08-18',
                  tenantId: '10002',
                  sourceReferenceId: 15,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437404354347008',
              workItemSourceReferenceId: '476',
              workItemType: 'Task',
              id: '92437404354347008',
              name: 'Testing',
              sourceReferenceId: '476',
              tenantId: '10002',
              parentId: '92437401804210176',
              type: 'Task',
              pathToParent: '/92437401804210176/',
              sortOrder: 4,
              level: 2,
              sortablePath: '/Resource Planner Implementation/004',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-09-15T00:00:00',
              EndsAt: '2022-09-16T00:00:00',
              hierarchyType: 'workitem',
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437404954132480',
              workItemSourceReferenceId: '477',
              workItemType: 'Task',
              id: '92437404954132480',
              name: 'Review',
              sourceReferenceId: '477',
              tenantId: '10002',
              parentId: '92437401804210176',
              type: 'Task',
              pathToParent: '/92437401804210176/',
              sortOrder: 5,
              level: 2,
              sortablePath: '/Resource Planner Implementation/005',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-09-19T00:00:00',
              EndsAt: '2022-09-22T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap15',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap10',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 16.0,
                  TotalEAC: 16.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462099863502848',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '92437405558112256',
                  workItemSourceReferenceId: '478',
                  workItemType: 'Task',
                  id: '90462099863502848',
                  name: 'Anders Nielsen',
                  role: 'Projektleder',
                  isActive: false,
                  hiredAt: '2019-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 1,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 16.0,
              TotalEAC: 16.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437405558112256',
              workItemSourceReferenceId: '478',
              workItemType: 'Task',
              id: '92437405558112256',
              name: 'Implementation',
              sourceReferenceId: '478',
              tenantId: '10002',
              parentId: '92437401804210176',
              type: 'Task',
              pathToParent: '/92437401804210176/',
              sortOrder: 6,
              level: 2,
              sortablePath: '/Resource Planner Implementation/006',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-09-20T00:00:00',
              EndsAt: '2022-09-30T00:00:00',
              hierarchyType: 'workitem',
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437406162092032',
              workItemSourceReferenceId: '479',
              workItemType: 'Task',
              id: '92437406162092032',
              name: 'Training',
              sourceReferenceId: '479',
              tenantId: '10002',
              parentId: '92437401804210176',
              type: 'Task',
              pathToParent: '/92437401804210176/',
              sortOrder: 7,
              level: 2,
              sortablePath: '/Resource Planner Implementation/007',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-07-08T00:00:00',
              EndsAt: '2022-09-30T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90461904597680128',
                  resourceSourceReferenceId: 9,
                  resourceType: 'Employee',
                  workItemId: '92437406770266112',
                  workItemSourceReferenceId: '483',
                  workItemType: 'Task',
                  id: '90461904597680128',
                  name: 'Erling Sørensen',
                  role: 'Bogholder',
                  isActive: false,
                  hiredAt: '2020-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 9,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437406770266112',
              workItemSourceReferenceId: '483',
              workItemType: 'Task',
              id: '92437406770266112',
              name: 'To be deleted 2',
              sourceReferenceId: '483',
              tenantId: '10002',
              parentId: '92437401804210176',
              type: 'Task',
              pathToParent: '/92437401804210176/',
              sortOrder: 8,
              level: 2,
              sortablePath: '/Resource Planner Implementation/008',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-07-08T00:00:00',
              EndsAt: '2022-09-30T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2022-07-08T00:00:00',
                  EndsAt: '2022-09-30T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 0.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90461904597680128',
                      resourceSourceReferenceId: 9,
                      resourceType: 'Employee',
                      workItemId: '92437408078888960',
                      workItemSourceReferenceId: '482',
                      workItemType: 'Task',
                      id: '90461904597680128',
                      name: 'Erling Sørensen',
                      role: 'Bogholder',
                      isActive: false,
                      hiredAt: '2020-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 9,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92437408078888960',
                  workItemSourceReferenceId: '482',
                  workItemType: 'Task',
                  id: '92437408078888960',
                  name: 'To be deleted',
                  sourceReferenceId: '482',
                  tenantId: '10002',
                  parentId: '92437407386828800',
                  type: 'Task',
                  pathToParent: '/92437401804210176/92437407386828800/',
                  sortOrder: 10,
                  level: 3,
                  sortablePath: '/Resource Planner Implementation/009/010',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437407386828800',
              workItemSourceReferenceId: '484',
              workItemType: 'Task',
              id: '92437407386828800',
              name: 'Delete Paren',
              sourceReferenceId: '484',
              tenantId: '10002',
              parentId: '92437401804210176',
              type: 'Task',
              pathToParent: '/92437401804210176/',
              sortOrder: 9,
              level: 2,
              sortablePath: '/Resource Planner Implementation/009',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-07-21T00:00:00',
              EndsAt: '2022-09-30T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2022-07-21T00:00:00',
                  EndsAt: '2022-09-30T00:00:00',
                  hierarchyType: 'workitem',
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92437409433649152',
                  workItemSourceReferenceId: '486',
                  workItemType: 'Task',
                  id: '92437409433649152',
                  name: 'To be hidden sub',
                  sourceReferenceId: '486',
                  tenantId: '10002',
                  parentId: '92437408745783296',
                  type: 'Task',
                  pathToParent: '/92437401804210176/92437408745783296/',
                  sortOrder: 12,
                  level: 3,
                  sortablePath: '/Resource Planner Implementation/011/012',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437408745783296',
              workItemSourceReferenceId: '485',
              workItemType: 'Task',
              id: '92437408745783296',
              name: 'To be hidden',
              sourceReferenceId: '485',
              tenantId: '10002',
              parentId: '92437401804210176',
              type: 'Task',
              pathToParent: '/92437401804210176/',
              sortOrder: 11,
              level: 2,
              sortablePath: '/Resource Planner Implementation/011',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2022-10-09T00:00:00',
              EndsAt: '2022-11-29T00:00:00',
              hierarchyType: 'workitem',
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437410050211840',
              workItemSourceReferenceId: '504',
              workItemType: 'Task',
              id: '92437410050211840',
              name: 'New Tasks',
              sourceReferenceId: '504',
              tenantId: '10002',
              parentId: '92437401804210176',
              type: 'Task',
              pathToParent: '/92437401804210176/',
              sortOrder: 13,
              level: 2,
              sortablePath: '/Resource Planner Implementation/013',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
          ],
          values: {
            '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 24.0,
          TotalEAC: 24.0,
          total: { displayValue: 0 },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '92437401804210176',
          workItemSourceReferenceId: '77',
          workItemType: 'Project',
          id: '92437401804210176',
          name: 'Resource Planner Implementation',
          sourceReferenceId: '77',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Resource Planner Implementation',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
        },
        {
          StartsAt: '2021-10-06T00:00:00',
          EndsAt: '2022-01-06T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2021-10-06T00:00:00',
              EndsAt: '2022-01-06T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 68.55,
                      heatmapCode: 'bgHeatmap75',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 68.55,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 68.55,
                  total: { displayValue: 68.55 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437249630666752',
                  workItemSourceReferenceId: '457',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap60',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462688282411008',
                  resourceSourceReferenceId: 15,
                  resourceType: 'Employee',
                  workItemId: '92437249630666752',
                  workItemSourceReferenceId: '457',
                  workItemType: 'Task',
                  id: '90462688282411008',
                  name: 'Bruno Mars',
                  role: 'PO',
                  isActive: false,
                  hiredAt: '2021-08-18',
                  tenantId: '10002',
                  sourceReferenceId: 15,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 68.55, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 68.55,
              TotalActualWorkload: 0.0,
              TotalEAC: 68.55,
              total: { displayValue: 68.55 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437249630666752',
              workItemSourceReferenceId: '457',
              workItemType: 'Task',
              id: '92437249630666752',
              name: 'No allocation',
              sourceReferenceId: '457',
              tenantId: '10002',
              parentId: '92437249022492672',
              type: 'Task',
              pathToParent: '/92437249022492672/',
              sortOrder: 1,
              level: 2,
              sortablePath: '/RP template test/001',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
          ],
          values: {
            '2022Nov': { displayValue: 68.55, heatmapCode: '', editable: false, value: 1 },
            '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
          },
          Budget: 0.0,
          TotalBooked: 68.55,
          TotalActualWorkload: 0.0,
          TotalEAC: 68.55,
          total: { displayValue: 68.55 },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '92437249022492672',
          workItemSourceReferenceId: '73',
          workItemType: 'Project',
          id: '92437249022492672',
          name: 'RP template test',
          sourceReferenceId: '73',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/RP template test',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
        },
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2021-11-24T00:00:00',
              EndsAt: '2022-02-24T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2021-11-24T00:00:00',
                  EndsAt: '2022-02-24T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      StartsAt: '2021-11-24T00:00:00',
                      EndsAt: '2022-02-24T00:00:00',
                      hierarchyType: 'workitem',
                      children: [
                        {
                          hierarchyType: 'resource',
                          values: {
                            '2022Nov': {
                              displayValue: 0,
                              heatmapCode: 'bgHeatmap15',
                              editable: true,
                            },
                            '2022Dec': {
                              displayValue: 0,
                              heatmapCode: 'bgHeatmap10',
                              editable: true,
                            },
                            '2023Jan': {
                              displayValue: 0,
                              heatmapCode: 'bgHeatmap00',
                              editable: true,
                            },
                            '2023Feb': {
                              displayValue: 0,
                              heatmapCode: 'bgHeatmap00',
                              editable: true,
                            },
                            '2023Mar': {
                              displayValue: 0,
                              heatmapCode: 'bgHeatmap00',
                              editable: true,
                            },
                            '2023Apr': {
                              displayValue: 0,
                              heatmapCode: 'bgHeatmap00',
                              editable: true,
                            },
                          },
                          Budget: 0.0,
                          TotalBooked: 0.0,
                          TotalActualWorkload: 0.0,
                          TotalEAC: 0.0,
                          total: { displayValue: 0 },
                          canExpand: true,
                          editable: true,
                          canAssign: false,
                          canDelete: true,
                          resourceId: '90462099863502848',
                          resourceSourceReferenceId: 1,
                          resourceType: 'Employee',
                          workItemId: '92437278953046016',
                          workItemSourceReferenceId: '465',
                          workItemType: 'Task',
                          id: '90462099863502848',
                          name: 'Anders Nielsen',
                          role: 'Projektleder',
                          isActive: false,
                          hiredAt: '2019-01-01',
                          tenantId: '10002',
                          sourceReferenceId: 1,
                          type: 'Employee',
                        },
                      ],
                      values: {
                        '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                      },
                      Budget: 0.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: false,
                      canAssign: true,
                      canDelete: false,
                      resourceId: '0',
                      resourceSourceReferenceId: 0,
                      resourceType: 'Unknown',
                      workItemId: '92437278953046016',
                      workItemSourceReferenceId: '465',
                      workItemType: 'Task',
                      id: '92437278953046016',
                      name: 'Try again',
                      sourceReferenceId: '465',
                      tenantId: '10002',
                      parentId: '92437278349066240',
                      type: 'Task',
                      pathToParent: '/92437277082386432/92437277690560512/92437278349066240/',
                      sortOrder: 3,
                      level: 4,
                      sortablePath: '/Simple test 1/001/002/003',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: false,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92437278349066240',
                  workItemSourceReferenceId: '458',
                  workItemType: 'Task',
                  id: '92437278349066240',
                  name: 'Opgave 1',
                  sourceReferenceId: '458',
                  tenantId: '10002',
                  parentId: '92437277690560512',
                  type: 'Task',
                  pathToParent: '/92437277082386432/92437277690560512/',
                  sortOrder: 2,
                  level: 3,
                  sortablePath: '/Simple test 1/001/002',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437277690560512',
              workItemSourceReferenceId: '464',
              workItemType: 'Task',
              id: '92437277690560512',
              name: 'With one',
              sourceReferenceId: '464',
              tenantId: '10002',
              parentId: '92437277082386432',
              type: 'Task',
              pathToParent: '/92437277082386432/',
              sortOrder: 1,
              level: 2,
              sortablePath: '/Simple test 1/001',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-11-24T00:00:00',
              EndsAt: '2022-02-24T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2021-11-24T00:00:00',
                  EndsAt: '2022-02-24T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      StartsAt: '2021-11-24T00:00:00',
                      EndsAt: '2022-02-24T00:00:00',
                      hierarchyType: 'workitem',
                      children: [
                        {
                          StartsAt: '2021-11-24T00:00:00',
                          EndsAt: '2022-02-24T00:00:00',
                          hierarchyType: 'workitem',
                          children: [
                            {
                              hierarchyType: 'resource',
                              values: {
                                '2022Nov': {
                                  displayValue: 0,
                                  heatmapCode: 'bgHeatmap15',
                                  editable: true,
                                },
                                '2022Dec': {
                                  displayValue: 0,
                                  heatmapCode: 'bgHeatmap10',
                                  editable: true,
                                },
                                '2023Jan': {
                                  displayValue: 0,
                                  heatmapCode: 'bgHeatmap00',
                                  editable: true,
                                },
                                '2023Feb': {
                                  displayValue: 0,
                                  heatmapCode: 'bgHeatmap00',
                                  editable: true,
                                },
                                '2023Mar': {
                                  displayValue: 0,
                                  heatmapCode: 'bgHeatmap00',
                                  editable: true,
                                },
                                '2023Apr': {
                                  displayValue: 0,
                                  heatmapCode: 'bgHeatmap00',
                                  editable: true,
                                },
                              },
                              Budget: 0.0,
                              TotalBooked: 0.0,
                              TotalActualWorkload: 0.0,
                              TotalEAC: 0.0,
                              total: { displayValue: 0 },
                              canExpand: true,
                              editable: true,
                              canAssign: false,
                              canDelete: true,
                              resourceId: '90462099863502848',
                              resourceSourceReferenceId: 1,
                              resourceType: 'Employee',
                              workItemId: '92437281503182848',
                              workItemSourceReferenceId: '462',
                              workItemType: 'Task',
                              id: '90462099863502848',
                              name: 'Anders Nielsen',
                              role: 'Projektleder',
                              isActive: false,
                              hiredAt: '2019-01-01',
                              tenantId: '10002',
                              sourceReferenceId: 1,
                              type: 'Employee',
                            },
                          ],
                          values: {
                            '2022Nov': {
                              displayValue: 0,
                              heatmapCode: '',
                              editable: false,
                              value: 1,
                            },
                            '2022Dec': {
                              displayValue: 0,
                              heatmapCode: '',
                              editable: false,
                              value: 1,
                            },
                            '2023Jan': {
                              displayValue: 0,
                              heatmapCode: '',
                              editable: false,
                              value: 1,
                            },
                            '2023Feb': {
                              displayValue: 0,
                              heatmapCode: '',
                              editable: false,
                              value: 1,
                            },
                            '2023Mar': {
                              displayValue: 0,
                              heatmapCode: '',
                              editable: false,
                              value: 1,
                            },
                            '2023Apr': {
                              displayValue: 0,
                              heatmapCode: '',
                              editable: false,
                              value: 1,
                            },
                          },
                          Budget: 0.0,
                          TotalBooked: 0.0,
                          TotalActualWorkload: 0.0,
                          TotalEAC: 0.0,
                          total: { displayValue: 0 },
                          canExpand: true,
                          editable: false,
                          canAssign: true,
                          canDelete: false,
                          resourceId: '0',
                          resourceSourceReferenceId: 0,
                          resourceType: 'Unknown',
                          workItemId: '92437281503182848',
                          workItemSourceReferenceId: '462',
                          workItemType: 'Task',
                          id: '92437281503182848',
                          name: 'Subbie',
                          sourceReferenceId: '462',
                          tenantId: '10002',
                          parentId: '92437280857260032',
                          type: 'Task',
                          pathToParent:
                            '/92437277082386432/92437279557025792/92437280198754304/92437280857260032/',
                          sortOrder: 7,
                          level: 5,
                          sortablePath: '/Simple test 1/004/005/006/007',
                          isActive: false,
                          startAt: '0001-01-01T00:00:00',
                          endAt: '0001-01-01T00:00:00',
                          status: 'InProgress',
                        },
                      ],
                      values: {
                        '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                      },
                      Budget: 0.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: false,
                      canAssign: false,
                      canDelete: false,
                      resourceId: '0',
                      resourceSourceReferenceId: 0,
                      resourceType: 'Unknown',
                      workItemId: '92437280857260032',
                      workItemSourceReferenceId: '466',
                      workItemType: 'Task',
                      id: '92437280857260032',
                      name: 'One more on the way',
                      sourceReferenceId: '466',
                      tenantId: '10002',
                      parentId: '92437280198754304',
                      type: 'Task',
                      pathToParent: '/92437277082386432/92437279557025792/92437280198754304/',
                      sortOrder: 6,
                      level: 4,
                      sortablePath: '/Simple test 1/004/005/006',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                    },
                    {
                      StartsAt: '2021-11-24T00:00:00',
                      EndsAt: '2022-02-24T00:00:00',
                      hierarchyType: 'workitem',
                      values: {
                        '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                        '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                      },
                      Budget: 0.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: false,
                      canAssign: true,
                      canDelete: false,
                      resourceId: '0',
                      resourceSourceReferenceId: 0,
                      resourceType: 'Unknown',
                      workItemId: '92437282111356928',
                      workItemSourceReferenceId: '460',
                      workItemType: 'Task',
                      id: '92437282111356928',
                      name: 'Maybe it is',
                      sourceReferenceId: '460',
                      tenantId: '10002',
                      parentId: '92437280198754304',
                      type: 'Task',
                      pathToParent: '/92437277082386432/92437279557025792/92437280198754304/',
                      sortOrder: 8,
                      level: 4,
                      sortablePath: '/Simple test 1/004/005/008',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: false,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92437280198754304',
                  workItemSourceReferenceId: '463',
                  workItemType: 'Task',
                  id: '92437280198754304',
                  name: 'Ip test',
                  sourceReferenceId: '463',
                  tenantId: '10002',
                  parentId: '92437279557025792',
                  type: 'Task',
                  pathToParent: '/92437277082386432/92437279557025792/',
                  sortOrder: 5,
                  level: 3,
                  sortablePath: '/Simple test 1/004/005',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437279557025792',
              workItemSourceReferenceId: '459',
              workItemType: 'Task',
              id: '92437279557025792',
              name: 'Simple contact',
              sourceReferenceId: '459',
              tenantId: '10002',
              parentId: '92437277082386432',
              type: 'Task',
              pathToParent: '/92437277082386432/',
              sortOrder: 4,
              level: 2,
              sortablePath: '/Simple test 1/004',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-11-24T00:00:00',
              EndsAt: '2022-02-24T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2021-11-24T00:00:00',
                  EndsAt: '2022-02-24T00:00:00',
                  hierarchyType: 'workitem',
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92437283323510784',
                  workItemSourceReferenceId: '461',
                  workItemType: 'Task',
                  id: '92437283323510784',
                  name: 'Here it ',
                  sourceReferenceId: '461',
                  tenantId: '10002',
                  parentId: '92437282715336704',
                  type: 'Task',
                  pathToParent: '/92437277082386432/92437282715336704/',
                  sortOrder: 10,
                  level: 3,
                  sortablePath: '/Simple test 1/009/010',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437282715336704',
              workItemSourceReferenceId: '467',
              workItemType: 'Task',
              id: '92437282715336704',
              name: 'New Task',
              sourceReferenceId: '467',
              tenantId: '10002',
              parentId: '92437277082386432',
              type: 'Task',
              pathToParent: '/92437277082386432/',
              sortOrder: 9,
              level: 2,
              sortablePath: '/Simple test 1/009',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
            {
              StartsAt: '2021-11-30T00:00:00',
              EndsAt: '2022-02-24T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2021-11-30T00:00:00',
                  EndsAt: '2022-02-24T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2022Nov': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2022Dec': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Jan': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Feb': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Mar': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                        '2023Apr': {
                          displayValue: 0,
                          heatmapCode: 'bgHeatmap00',
                          editable: false,
                          value: 1,
                        },
                      },
                      Budget: 0.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      total: { displayValue: 0 },
                      canExpand: true,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '90461904597680128',
                      resourceSourceReferenceId: 9,
                      resourceType: 'Employee',
                      workItemId: '92437284535664640',
                      workItemSourceReferenceId: '469',
                      workItemType: 'Task',
                      id: '90461904597680128',
                      name: 'Erling Sørensen',
                      role: 'Bogholder',
                      isActive: false,
                      hiredAt: '2020-01-01',
                      tenantId: '10002',
                      sourceReferenceId: 9,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                    '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '92437284535664640',
                  workItemSourceReferenceId: '469',
                  workItemType: 'Task',
                  id: '92437284535664640',
                  name: 'Task to be deleted',
                  sourceReferenceId: '469',
                  tenantId: '10002',
                  parentId: '92437283931684864',
                  type: 'Task',
                  pathToParent: '/92437277082386432/92437283931684864/',
                  sortOrder: 12,
                  level: 3,
                  sortablePath: '/Simple test 1/011/012',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                },
              ],
              values: {
                '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 0.0,
              total: { displayValue: 0 },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437283931684864',
              workItemSourceReferenceId: '468',
              workItemType: 'Task',
              id: '92437283931684864',
              name: 'Task to be hidden',
              sourceReferenceId: '468',
              tenantId: '10002',
              parentId: '92437277082386432',
              type: 'Task',
              pathToParent: '/92437277082386432/',
              sortOrder: 11,
              level: 2,
              sortablePath: '/Simple test 1/011',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
          ],
          values: {
            '2022Nov': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          total: { displayValue: 0 },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '92437277082386432',
          workItemSourceReferenceId: '74',
          workItemType: 'Project',
          id: '92437277082386432',
          name: 'Simple test 1',
          sourceReferenceId: '74',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Simple test 1',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
        },
        {
          StartsAt: '2022-05-27T00:00:00',
          EndsAt: '2022-06-30T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2022-05-27T00:00:00',
              EndsAt: '2022-06-30T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 16.0,
                      heatmapCode: 'bgHeatmap75',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 16.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 16.0,
                  total: { displayValue: 16.0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463866403684352',
                  resourceSourceReferenceId: 34,
                  resourceType: 'Employee',
                  workItemId: '92437354865754112',
                  workItemSourceReferenceId: '472',
                  workItemType: 'Task',
                  id: '90463866403684352',
                  name: 'Anett Korterup',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-07-05',
                  tenantId: '10002',
                  sourceReferenceId: 34,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90464258885681152',
                  resourceSourceReferenceId: 36,
                  resourceType: 'Employee',
                  workItemId: '92437354865754112',
                  workItemSourceReferenceId: '472',
                  workItemType: 'Task',
                  id: '90464258885681152',
                  name: 'Brian Thor Christensen',
                  role: 'Software',
                  isActive: false,
                  hiredAt: '2021-09-16',
                  tenantId: '10002',
                  sourceReferenceId: 36,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 0.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90461704860729344',
                  resourceSourceReferenceId: 6,
                  resourceType: 'Employee',
                  workItemId: '92437354865754112',
                  workItemSourceReferenceId: '472',
                  workItemType: 'Task',
                  id: '90461704860729344',
                  name: 'Carl Chan',
                  role: 'Leder',
                  isActive: false,
                  hiredAt: '2020-01-01',
                  tenantId: '10002',
                  sourceReferenceId: 6,
                  type: 'Employee',
                },
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 13.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  total: { displayValue: 0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90462884265459712',
                  resourceSourceReferenceId: 16,
                  resourceType: 'Employee',
                  workItemId: '92437354865754112',
                  workItemSourceReferenceId: '472',
                  workItemType: 'Task',
                  id: '90462884265459712',
                  name: 'Jacky Chan',
                  role: '',
                  isActive: false,
                  hiredAt: '2021-08-19',
                  tenantId: '10002',
                  sourceReferenceId: 16,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 16.0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 16.0,
              TotalActualWorkload: 0.0,
              TotalEAC: 16.0,
              total: { displayValue: 16.0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437354865754112',
              workItemSourceReferenceId: '472',
              workItemType: 'Task',
              id: '92437354865754112',
              name: 'Test 30 days - Task 2',
              sourceReferenceId: '472',
              tenantId: '10002',
              parentId: '92437236502495232',
              type: 'Task',
              pathToParent: '/92437236502495232/',
              sortOrder: 1,
              level: 2,
              sortablePath: '/Z - Project Without Task/001',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
          ],
          values: {
            '2022Nov': { displayValue: 16.0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
          },
          Budget: 0.0,
          TotalBooked: 16.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 16.0,
          total: { displayValue: 16.0 },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '92437236502495232',
          workItemSourceReferenceId: '71',
          workItemType: 'Project',
          id: '92437236502495232',
          name: 'Z - Project Without Task',
          sourceReferenceId: '71',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Z - Project Without Task',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
        },
        {
          StartsAt: '2022-03-16T00:00:00',
          EndsAt: '2022-06-30T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2022-03-16T00:00:00',
              EndsAt: '2022-06-30T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2022Nov': {
                      displayValue: 4.0,
                      heatmapCode: 'bgHeatmap05',
                      editable: false,
                      value: 1,
                    },
                    '2022Dec': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Jan': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Feb': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Mar': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                    '2023Apr': {
                      displayValue: 0,
                      heatmapCode: 'bgHeatmap00',
                      editable: false,
                      value: 1,
                    },
                  },
                  Budget: 4.0,
                  TotalBooked: 4.0,
                  TotalActualWorkload: 1.0,
                  TotalEAC: 5.0,
                  total: { displayValue: 4.0 },
                  canExpand: true,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '90463669967650816',
                  resourceSourceReferenceId: 33,
                  resourceType: 'Employee',
                  workItemId: '92437354270162944',
                  workItemSourceReferenceId: '471',
                  workItemType: 'Task',
                  id: '90463669967650816',
                  name: 'Jens Østergaard',
                  role: 'Leder',
                  isActive: false,
                  hiredAt: '2021-09-16',
                  tenantId: '10002',
                  sourceReferenceId: 33,
                  type: 'Employee',
                },
              ],
              values: {
                '2022Nov': { displayValue: 4.0, heatmapCode: '', editable: false, value: 1 },
                '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
                '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
              },
              Budget: 0.0,
              TotalBooked: 4.0,
              TotalActualWorkload: 1.0,
              TotalEAC: 5.0,
              total: { displayValue: 4.0 },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '92437354270162944',
              workItemSourceReferenceId: '471',
              workItemType: 'Task',
              id: '92437354270162944',
              name: 'Test 30 days - Task 1',
              sourceReferenceId: '471',
              tenantId: '10002',
              parentId: '92437353594880000',
              type: 'Task',
              pathToParent: '/92437353594880000/',
              sortOrder: 1,
              level: 2,
              sortablePath: '/Z - Test 30 days - 2/001',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
          ],
          values: {
            '2022Nov': { displayValue: 4.0, heatmapCode: '', editable: false, value: 1 },
            '2022Dec': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Jan': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Feb': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Mar': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
            '2023Apr': { displayValue: 0, heatmapCode: '', editable: false, value: 1 },
          },
          Budget: 12000.0,
          TotalBooked: 4.0,
          TotalActualWorkload: 1.0,
          TotalEAC: 5.0,
          total: { displayValue: 4.0 },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '92437353594880000',
          workItemSourceReferenceId: '76',
          workItemType: 'Project',
          id: '92437353594880000',
          name: 'Z - Test 30 days - 2',
          sourceReferenceId: '76',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Z - Test 30 days - 2',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
        },
      ],
    },
    links: [{ href: '/plan/groupbyworkitem', rel: 'self' }],
  },
  DeterministicHash: 142699400,
  StatusCode: 200,
};
