export default {
  Entities: [
    {
      Properties: {
        UserId: 1133,
        FullName: 'FirstName_Admin1133 LastName',
        Position: '',
        LegalEntity: 'TimeLog A/S',
        Department: '46. KL Development',
        Capacity: {
          AvailableHours: 0.0,
          TotalHours: 0.0,
        },
      },
    },
    {
      Properties: {
        UserId: 1222,
        FullName: 'FirstName_Admin1222 LastName',
        Position: '',
        LegalEntity: 'TimeLog A/S',
        Department: '43. DK Development',
        Capacity: {
          AvailableHours: 0.0,
          TotalHours: 0.0,
        },
      },
    },
    {
      Properties: {
        UserId: 1259,
        FullName: 'FirstName_Admin1259 LastName',
        Position: '',
        LegalEntity: 'TimeLog A/S',
        Department: '28. Marketing',
        Capacity: {
          AvailableHours: 0.0,
          TotalHours: 0.0,
        },
      },
    },
    {
      Properties: {
        UserId: 1260,
        FullName: 'FirstName_Admin1260 LastName',
        Position: '',
        LegalEntity: 'TimeLog A/S',
        Department: '10. Business Operations',
        Capacity: {
          AvailableHours: 0.0,
          TotalHours: 0.0,
        },
      },
    },
    {
      Properties: {
        UserId: 1191,
        FullName: 'FirstName_Admin1191 LastName',
        Position: '',
        LegalEntity: 'TimeLog A/S',
        Department: '46. KL Development',
        Capacity: {
          AvailableHours: 0.0,
          TotalHours: 0.0,
        },
      },
    },
    {
      Properties: {
        UserId: 1249,
        FullName: 'FirstName_Employee1249 LastName',
        Position: '',
        LegalEntity: 'TimeLog A/S',
        Department: '46. KL Development',
        Capacity: {
          AvailableHours: 0.0,
          TotalHours: 0.0,
        },
        Competences: [
          {
            Id: 2,
            Name: 'Call Pilot - Drift',
            Value: 3,
          },
          {
            Id: 11,
            Name: 'Dansk',
            Value: 3,
          },
        ],
      },
    },
    {
      Properties: {
        UserId: 1221,
        FullName: 'FirstName_Admin1221 LastName',
        Position: '',
        LegalEntity: 'TimeLog A/S',
        Department: '46. KL Development',
        Capacity: {
          AvailableHours: 0.0,
          TotalHours: 0.0,
        },
      },
    },
    {
      Properties: {
        UserId: 1224,
        FullName: 'FirstName_Employee1224 LastName',
        Position: '',
        LegalEntity: 'TimeLog A/S',
        Department: '45. Product management',
        Capacity: {
          AvailableHours: 0.0,
          TotalHours: 0.0,
        },
      },
    },
    {
      Properties: {
        UserId: 1250,
        FullName: 'FirstName_Admin1250 LastName',
        Position: '',
        LegalEntity: 'TimeLog A/S',
        Department: '95. System users',
        Capacity: {
          AvailableHours: 0.0,
          TotalHours: 0.0,
        },
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/resource-planner/resources/recently-registered?taskId=64096',
      Rel: 'self',
    },
  ],
};
