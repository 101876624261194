export default {
  Class: 'iapireadmodel',
  Properties: {
    Data: {
      UserId: 58,
      LastRegistration: '2021-03-10T09:05:23.6733333',
      CurrentPeriodDelayedTimeTracking: 0.0,
      PreviousPeriodDelayedTimeTracking: 0.0,
      CurrentPeriodRegistrationCount: 0,
    },
    Type: 'TimeTrackingDelay',
    Width: 0,
    Height: 0,
    SortOrder: 0,
    PaginationInfo: {},
  },
  Links: [
    {
      Href: 'http://localhost:5181/api/v1/insights/time-tracking-delay-widget-data?siteid=ff653f19-431b-4d58-9dda-9e41796456ab',
      Rel: 'self',
    },
  ],
};
