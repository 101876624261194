import { forwardRef, SelectHTMLAttributes } from 'react';
import cx from 'classnames';
import logger from 'src/utils/logger';
import {
  FormFieldControl,
  FormFieldLabel,
  FormFieldValidation,
  FormField,
} from 'src/components/ui-components/FormField';
import { TStatus } from 'src/assets/variables/status';
import { TInputFieldWidth, TInputSizes } from 'src/assets/variables/input';
import { useAutoId } from 'src/hooks/useAutoId';
import styles from './Select.module.scss';

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  /** Label */
  label: string;
  /** Options */
  options: Array<{ value: string | number; name: string; disabled?: boolean }>;
  /** Element name for form handling */
  name: string;
  /** Element id attribute if you need control */
  id?: string;
  /** Status (ENUM: Success, Warning, Error) */
  status?: TStatus;
  /** Slim version without borders */
  noBorder?: boolean;
  /** Input width */
  inputWidth?: TInputFieldWidth;
  /** Size of Select.
   * Use enum for picking one
   */
  inputSize?: TInputSizes;
  /** Hide label visually */
  hiddenLabel?: boolean;
  /** Feedback message */
  validationMsg?: string;
}
type Ref = HTMLSelectElement;
export const Select = forwardRef<Ref, SelectProps>(
  (
    {
      id,
      label,
      options,
      name,
      status,
      noBorder,
      inputWidth,
      inputSize = 'normal',
      hiddenLabel,
      validationMsg,
      ...props
    },
    ref,
  ) => {
    const autoId = useAutoId(id);
    const ariaDescribedBy = status && validationMsg ? ` inputDesc-${autoId}` : undefined;
    // We want to have a warning in the console if a label is empty string.
    if (label === '' && !hiddenLabel) logger.warn('`label` must never be an empty string!');
    return (
      <FormField>
        <FormFieldLabel hiddenLabel={hiddenLabel}>
          <label htmlFor={autoId}>{label}</label>
        </FormFieldLabel>
        <FormFieldControl
          inputWidth={inputWidth}
          className={cx(styles.selectWrapper, {
            [styles.noBorder]: noBorder,
          })}
        >
          <select
            name={name}
            id={autoId}
            className={cx(styles.select, { [styles[inputSize]]: inputSize })}
            aria-describedby={status ? ariaDescribedBy : undefined}
            aria-invalid={status === 'error'}
            ref={ref}
            {...props}
          >
            {options?.map((option) => (
              <option
                key={`${autoId}-${option.value}`}
                value={option.value}
                disabled={option.disabled}
              >
                {option.name}
              </option>
            ))}
          </select>
        </FormFieldControl>
        {!!validationMsg && (
          <FormFieldValidation validationMsg={validationMsg} id={`inputDesc-${autoId}`} />
        )}
      </FormField>
    );
  },
);
export default Select;
