import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';
import { EXCHANGE_RATE_GET_PERIODS_KEY } from '../get/getPeriods';
import { GET_EXCHANGE_RATE_MATRIX_ACTIONS_KEY } from '../get/getMatrixActionsAPI';

const DELETE_PERIOD = '/api/v1/exchange-rate/period';
export const DELETE_PERIOD_MSW_STATUS_KEY = 'exchangeRateUpdateByIdAPI';

const deletePeriod = (periodId: number) =>
  deleteFetch({
    path: `${DELETE_PERIOD}${concatQuerys([{ param: 'periodId', value: periodId.toString() }])}`,
    key: DELETE_PERIOD_MSW_STATUS_KEY,
  });

export const useDeletePeriod = () => {
  const queryClient = useQueryClient();

  return useMutation(deletePeriod, {
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries([EXCHANGE_RATE_GET_PERIODS_KEY]),
        queryClient.invalidateQueries([GET_EXCHANGE_RATE_MATRIX_ACTIONS_KEY]),
      ]),
  });
};
