import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Icon } from 'src/components/ui-components/Icon';
import { Text } from 'src/components/ui-components/Text';
import { auxClickPreventNewTabFocus, onClickPreventNewTabFocus } from 'src/utils/clickEvent';
import { useGetBookmarkAPI } from 'src/apis/bookmarksAPI';

import { useMobileNavigationStore } from 'src/stores/MobileNavigationStore';
import { useSetSubMenuToActive } from 'src/components/layout/Header/hooks';
import TruncatedNavLink from 'src/components/ui-components/TruncatedNavLink';
import { useIframeEvent } from './BookmarkHookHelper';
import MobileNavTreeToggle from '../MobileNavTreeToggle';
import styles from '../../Navigation.module.scss';
import bookmarkStyle from './Bookmark.module.scss';

export const BookmarkNav = () => {
  const { t } = useTranslation('header');
  const mobileNavigationState = useMobileNavigationStore();
  const mobileNavIsOpen = mobileNavigationState?.isMobileNavigationOpen;
  const { bookmarks, isSuccess } = useGetBookmarkAPI();
  useIframeEvent();

  const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);
  const element = useRef<HTMLLIElement>(null);

  const activeFunction = useCallback(() => {
    setSubMenuIsOpen(true);
  }, []);

  const inActiveFunction = useCallback(() => {
    setSubMenuIsOpen(false);
  }, []);

  useSetSubMenuToActive({
    element,
    activeFunction,
    inActiveFunction,
    isSuccess,
  });

  return (
    <li className={styles.ParentItem} data-automation-id="BookmarkNav" ref={element}>
      <span
        className={cx(styles.ParentLink, styles.ParentLink__hasIcon, styles.ParentLinkHasSubNav)}
      >
        <Icon iconName="bookmark" aria-hidden="true" size="large" />
        <span className={styles.ParentLinkText}>Bookmarks</span>
      </span>
      <MobileNavTreeToggle
        id="bookmarks-subnav"
        name={t('PrimaryNavigationBookmark')}
        tabIndex={mobileNavIsOpen ? 0 : -1}
        data-automation-id="BookmarkNavToggleButton"
      />

      <ul
        id="bookmarks-subnav"
        className={cx(styles.ChildList, styles.ChildListMaxWidth)}
        data-automation-id="BookmarkNavList"
        data-is-open={subMenuIsOpen}
      >
        <li
          className={cx(styles.ChildItem, bookmarkStyle.BookmarkTitle)}
          key="bookmarkTitle"
          data-automation-id="BookmarkTitle"
        >
          <Text medium size="subHeading" data-automation-id="BookmarkTitleText">
            {t('PrimaryNavigationBookmark')}
          </Text>
        </li>
        {bookmarks?.map(({ name, id, url }) => (
          <li className={styles.ChildItem} key={`bookmark-link-${id}`}>
            <TruncatedNavLink
              className={styles.ChildLink}
              to={url}
              onClick={onClickPreventNewTabFocus}
              onAuxClick={auxClickPreventNewTabFocus}
              data-automation-id={`bookmark-link-item-${id}`}
            >
              {name}
            </TruncatedNavLink>
          </li>
        ))}
        {bookmarks?.length <= 0 ? (
          <li
            className={styles.ChildItem}
            key="bookmarkEmptyResult"
            data-automation-id="BookmarkEmptyResult"
          >
            <div className={bookmarkStyle.BookmarkEmptyState}>
              <Text>{t('PrimaryNavigationBookmarkEmptyState')}</Text>
            </div>
          </li>
        ) : null}
      </ul>
    </li>
  );
};
