import { useEffect, useMemo, useState } from 'react';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { FilterLayout } from 'src/components/layout/FilterLayout';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { IFilterProps, IMenuItem } from 'src/reducers/FilterReducer/FilterReducer';
import { ContentCopyRounded, Delete, Edit } from '@mui/icons-material';
import { safeParseJson } from 'src/utils/object';
import {
  ISavedView,
  ISavedViewForm,
  SnackbarMode,
  TFormDialogMode,
  TTextDialogMode,
  TSavedViewAction,
} from 'src/apis/savedViewAPI';
import { v4 as uuidv4 } from 'uuid';
import { ToastifyAlert } from 'src/components/mui-components';
import { useGetShortNameWithoutUrl } from 'src/apis/indexAPI';
import isEmpty from 'lodash/isEmpty';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toast } from 'react-toastify';
import { IFilterItemProperties } from 'src/apis/types/filterListAPI';
import { useGetLegalEntity } from 'src/apis/usersAPI/meAPI';

import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import { Spinner } from 'src/components/ui-components';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { TFilterType } from 'src/apis/savedViewAPI/savedViewAPI';
import { INITIAL_SAVED_VIEW, SNACKBAR_CONFIG } from './constants';
import {
  GetSPSavedViewsKey,
  SPSelectedFilterListStateKey,
  SPViewOptionsStateKey,
  SPFilterIsOpen,
} from './localStorageKeys';

import { sortViews } from '../ResourcePlanner/helper/sortViews';
import { IOptionsChangeParameters, TSnackbarKeys } from './types/searchProjects';

import { ViewFormDialog, ViewTextDialog } from './components/SavedViewDialog';
import { Table } from './components/Table';

export const SearchProjects = () => {
  const { t: tSearchProjects } = useTranslation('searchProjects');
  const { t } = useTranslation('savedView');

  localStorage.setItem(SPFilterIsOpen, 'true');
  const { shortNameWithoutUrl } = useGetShortNameWithoutUrl();
  const localStorageSavedViews =
    localStorage.getItem(GetSPSavedViewsKey(shortNameWithoutUrl)) ?? '';

  const pageIdentifier = useGetCurrentPageIdentifier();
  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);

  const [spLoading, setSpLoading] = useState(true);

  // Patch for no saved views
  const flattenedFilterItemsList = filterList.reduce(
    (a, { filterItems }) => [...a, ...(filterItems ?? [])],
    [] as IFilterItemProperties[],
  );

  const { legalEntityId, legalEntityName } = useGetLegalEntity();

  // Pre-fill Project Status filter
  const projectStatusFilter = flattenedFilterItemsList.find(({ name }) => name === 'ProjectStatus');

  const { dropdownList: projectStatusDropdown } = useGetDropdownWithSearchAPI(
    {
      key: `filterInput${projectStatusFilter?.id}`,
      MSWKey: `FILTER_INPUT_${projectStatusFilter?.id}`,
      path: `/${projectStatusFilter?.contentUrl}?pageIdentifier=${pageIdentifier}`,
    },
    !!projectStatusFilter?.contentUrl,
  );

  const selectFilterProjectStatusOptions = projectStatusDropdown?.find((item) =>
    item.label.includes('Active projects'),
  );

  // Pre-fill legal entity filter
  const legalEntityFilter = flattenedFilterItemsList.find(
    ({ name }) => name === 'LegalEntityProject',
  );

  const { dropdownList: legalEntityDropdown } = useGetDropdownWithSearchAPI(
    {
      key: `filterInput${legalEntityFilter?.id}`,
      MSWKey: `FILTER_INPUT_${legalEntityFilter?.id}`,
      path: `/${legalEntityFilter?.contentUrl}?pageIdentifier=${pageIdentifier}`,
      searchText: legalEntityName,
    },
    !!legalEntityFilter?.contentUrl,
  );

  const selectFilterLegalEntityOptions = legalEntityDropdown?.find(
    (item) => item.value === String(legalEntityId),
  );

  const initialFilter: Partial<Record<TFilterType, IFilterProps>> = {};

  if (selectFilterProjectStatusOptions && projectStatusFilter) {
    initialFilter.ProjectStatus = {
      label: projectStatusFilter.name,
      values: [
        {
          label: selectFilterProjectStatusOptions.label,
          value: selectFilterProjectStatusOptions.value,
        },
      ],
      contentUrl: projectStatusFilter.contentUrl,
      childFilters: projectStatusFilter.childFilters,
      parentFilters: projectStatusFilter.parentFilters,
      type: projectStatusFilter.type,
      isInclude: true,
    };
  }

  if (selectFilterLegalEntityOptions && legalEntityFilter) {
    initialFilter.LegalEntityProject = {
      label: legalEntityFilter.name,
      values: [
        {
          label: selectFilterLegalEntityOptions.label,
          value: selectFilterLegalEntityOptions.value,
        },
      ],
      contentUrl: legalEntityFilter.contentUrl,
      childFilters: legalEntityFilter.childFilters,
      parentFilters: legalEntityFilter.parentFilters,
      type: legalEntityFilter.type,
      isInclude: true,
    };
  }

  const initialSavedView = [
    {
      ...INITIAL_SAVED_VIEW,
      id: uuidv4(),
      title: t('InitialViewTitle'),
      filters: {},
    },
  ];

  const [savedViews, setSavedViews] = useState<ISavedView[]>(
    !!localStorageSavedViews && localStorageSavedViews !== ''
      ? (safeParseJson(localStorageSavedViews) as ISavedView[])
      : initialSavedView,
  );

  const sortedViews = useMemo((): ISavedView[] => sortViews(savedViews), [savedViews]);

  const initialActiveView = !isEmpty(sortedViews) ? sortedViews[0].id : '';

  const [activeView, setActiveView] = useState<string>(initialActiveView || '');

  const savedViewOptions = sortedViews?.find((v) => v.id === activeView)?.viewOptions;
  const [changedViewOptions, setChangedViewOptions] = useState<any | undefined>(
    savedViewOptions ?? undefined,
  );

  const updateSavedViews = (sv: ISavedView[]) => {
    setSavedViews(() => {
      localStorage.setItem(GetSPSavedViewsKey(shortNameWithoutUrl), JSON.stringify(sv));
      return sv;
    });
  };

  const onViewOptionsChange = (optionItems: IOptionsChangeParameters[]) => {
    const options = optionItems.map((item) => ({ [item.name]: item.value }));
    const optionsToObject = Object.assign({}, ...options);

    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  const [showFormDialog, setShowFormDialog] = useState<TFormDialogMode | ''>('');
  const [showTextDialog, setShowTextDialog] = useState<TTextDialogMode | ''>('');

  const displayToast = (type: SnackbarMode) => {
    const config = SNACKBAR_CONFIG.find((c) => c.type === type);
    const { title, description } = config ?? {};
    toast.success(
      <ToastifyAlert
        title={t(title as TSnackbarKeys)}
        description={t(description as TSnackbarKeys)}
      />,
      {
        autoClose: 5000,
        closeButton: false,
      },
    );
  };

  // region Save View Form
  const formSchema = z.object({
    title: z.string().min(1, { message: 'Please enter title' }),
    isDefault: z.boolean(),
  });

  const form = useForm({
    defaultValues: {
      title: '',
      isDefault: false,
    } as ISavedViewForm,
    resolver: zodResolver(formSchema),
  });

  const menuItems: IMenuItem[] = [
    {
      id: 'edit',
      label: t('UpdateViewMenuText'),
      icon: <Edit />,
      action: () => {
        const viewIndex = sortedViews.find((v) => v.id === activeView);
        if (viewIndex) {
          form.setValue('title', viewIndex.title, { shouldDirty: true, shouldValidate: true });
          form.setValue('isDefault', viewIndex.isDefault);
          setShowFormDialog('edit');
        }
      },
    },
    {
      id: 'duplicate',
      label: t('DuplicateViewMenuText'),
      icon: <ContentCopyRounded />,
      action: () => {
        const viewIndex = sortedViews.find((v) => v.id === activeView);
        if (viewIndex) {
          form.setValue('title', viewIndex.title, { shouldDirty: true, shouldValidate: true });
          form.setValue('isDefault', viewIndex.isDefault);
          setShowFormDialog('duplicate');
        }
      },
    },
    {
      id: 'delete',
      label: t('DeleteViewMenuText'),
      icon: <Delete />,
      action: () => {
        setShowTextDialog('delete');
      },
    },
  ];

  const updateActiveView = (viewId?: string) => {
    if (viewId) {
      setActiveView(viewId);
    }
  };

  const onSubmit = (data: ISavedViewForm) => {
    // set default to false for all views
    const viewData: ISavedView[] = savedViews.map((view) => ({
      ...view,
      isDefault: data.isDefault ? false : view.isDefault,
    }));

    const filters = localStorage.getItem(SPSelectedFilterListStateKey);
    const newID = uuidv4();
    let updatedData: ISavedView[];

    switch (showFormDialog) {
      case 'save':
        if (!filters) {
          return;
        }
        updatedData = [
          ...viewData,
          {
            id: newID,
            title: data.title,
            isDefault: data.isDefault,
            createdDate: new Date(),
            updatedDate: new Date(),
            filters: safeParseJson(filters),
            viewOptions: changedViewOptions,
          },
        ];
        displayToast('save');
        updateActiveView(newID);
        break;

      case 'edit':
        updatedData = viewData.map((view) => {
          if (view.id === activeView) {
            return {
              ...view,
              title: data.title,
              isDefault: data.isDefault,
              updatedDate: new Date(),
            };
          }
          return view;
        });
        displayToast('edit');
        break;

      default:
        updatedData = [
          ...viewData,
          {
            id: newID,
            title: data.title,
            isDefault: data.isDefault,
            createdDate: new Date(),
            updatedDate: new Date(),
            filters: safeParseJson(filters ?? ''),
            viewOptions: changedViewOptions,
          },
        ];
        displayToast('duplicate');
        updateActiveView(newID);
        break;
    }

    updateSavedViews(updatedData);
    form.reset();
    setShowFormDialog('');
    setShowTextDialog('');
  };

  const onDelete = () => {
    setShowTextDialog('');
    const updatedData = savedViews
      .filter((view) => view.id !== activeView)
      .map((view) => ({
        ...view,
        updatedDate: new Date(), // when order is changed, change the updated date
      }));
    updateSavedViews(updatedData);
    updateActiveView(sortViews(updatedData)[0]?.id ?? '');
    displayToast('delete');
  };

  const onChangesUpdate = () => {
    const updatedSaveView = savedViews.map((v) => {
      const view = { ...v };
      if (view.id === activeView) {
        view.viewOptions = changedViewOptions;
        view.filters = safeParseJson(localStorage.getItem(SPSelectedFilterListStateKey) ?? '');
        view.updatedDate = new Date();
      }
      return view;
    });

    updateSavedViews(updatedSaveView);
    setShowTextDialog('');
    displayToast('changes');
  };

  const handleViewAction = (action: TSavedViewAction) => {
    if (action === 'saveAs') {
      form.reset();
      setShowFormDialog('save');
      return;
    }
    onChangesUpdate();
  };

  useEffect(() => {
    if (
      (!isEmpty(selectFilterProjectStatusOptions) || !isEmpty(selectFilterLegalEntityOptions)) &&
      initialFilter &&
      isEmpty(savedViews[0]?.filters) &&
      new Date(savedViews[0]?.createdDate)?.getTime() ===
        new Date(savedViews[0]?.updatedDate)?.getTime()
    ) {
      updateSavedViews([
        {
          ...savedViews[0],
          filters: initialFilter,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFilterProjectStatusOptions, selectFilterLegalEntityOptions]);

  useEffect(() => {
    if (activeView || !sortedViews?.length) {
      return;
    }
    updateActiveView(sortedViews[0].id);
  }, [activeView, sortedViews]);

  useEffect(() => {
    if (!savedViewOptions) {
      return;
    }
    setChangedViewOptions(savedViewOptions);
  }, [savedViewOptions]);

  useEffect(() => {
    if (!changedViewOptions) {
      return;
    }
    localStorage.setItem(SPViewOptionsStateKey, JSON.stringify(changedViewOptions));
    setSpLoading(false);
  }, [changedViewOptions]);

  return (
    <ResponseHandler isLoading={isLoading} isError={isError} isEmpty={filterList.length <= 0}>
      <FilterLayout
        filterList={filterList}
        selectedFilterList={sortedViews?.[0]?.filters}
        localStorageNamePrefix="search-projects"
        allowSavedViews
        savedViewsList={sortedViews}
        activeView={activeView}
        menuItems={menuItems}
        handleViewAction={handleViewAction}
        setActiveView={updateActiveView}
        allowSearch
        searchText={tSearchProjects('FilterFullTextSearchText')}
        initialSearchValue={changedViewOptions?.searchText}
        viewOptionsChange={onViewOptionsChange}
        changedViewOptions={changedViewOptions}
      >
        {!spLoading ? (
          <Table
            selectedViewOptions={changedViewOptions}
            onViewOptionsChange={onViewOptionsChange}
          />
        ) : (
          <Spinner />
        )}

        {/* For form dialog */}
        {showFormDialog && (
          <ViewFormDialog
            showFormDialog={showFormDialog}
            setShowFormDialog={setShowFormDialog}
            onSubmit={onSubmit}
            form={form}
          />
        )}

        {/* For text alert dialog */}
        {showTextDialog && (
          <ViewTextDialog
            showTextDialog={showTextDialog}
            setShowTextDialog={setShowTextDialog}
            onDelete={onDelete}
          />
        )}
      </FilterLayout>
    </ResponseHandler>
  );
};
