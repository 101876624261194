import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFetch } from 'src/utils/fetcher';
import { COMPETENCES_URL_PATH, GET_COMPETENCES_KEY } from '../constants';

export const DELETE_COMPETENCES_MSW_STATUS_KEY = 'deleteCompetences';

export const DELETE_COMPETENCES_KEY = 'DELETE_COMPETENCES';

const deleteCompetence = (itemId?: number) =>
  deleteFetch({
    path: COMPETENCES_URL_PATH,
    key: DELETE_COMPETENCES_MSW_STATUS_KEY,
    itemId: `/${itemId}`,
  });

export const useDeleteCompetence = () => {
  const qc = useQueryClient();

  return useMutation(deleteCompetence, {
    onSuccess: () => {
      qc.invalidateQueries([GET_COMPETENCES_KEY]);
    },
  });
};
