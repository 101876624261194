import { FlagOutlined } from '@mui/icons-material';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetMilestones } from 'src/apis/milestonesAPI';
import { Button, Select, Stack } from 'src/components/mui-components';
import {
  getDateOneWeekFromStartOfWeek,
  getDateThirtyDaysFromNow,
  getDateTwoWeeksFromStartOfWeek,
} from 'src/utils/date';
import { WidgetHeader } from '../WidgetHeader';
import { Error, FeedItems, Loading, NoResults } from './components';

interface IMilestoneFeedStatus {
  date: string;
}

const MilestoneFeedStatus = ({ date }: IMilestoneFeedStatus) => {
  const { t } = useTranslation('frontpage');
  const { milestones, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useGetMilestones(date);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }
  if (milestones && milestones[0] !== undefined) {
    return (
      <Stack gap={4}>
        <FeedItems items={milestones} />

        {isFetchingNextPage && <Loading />}

        {hasNextPage && (
          <Stack direction="row" justifyContent="right">
            <Button
              size="small"
              data-automation-id="MilestonesLoadMoreButton"
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}
            >
              {t('MilestonesLoadMoreButton')}
            </Button>
          </Stack>
        )}
      </Stack>
    );
  }
  return <NoResults />;
};

const PeriodSelect = ({
  date,
  setDate,
}: {
  date: string;
  setDate: (event: SelectChangeEvent<any>, child: ReactNode) => void;
}) => {
  const { t } = useTranslation('frontpage');

  return (
    <Select
      size="small"
      name="milestones-period-change"
      value={date}
      onChange={setDate}
      data-automation-id="MilestonesHeaderSelect"
      inputProps={{ 'aria-label': t('MilestonesSelectLabel') }}
    >
      <MenuItem
        value={getDateOneWeekFromStartOfWeek()}
        data-automation-id="MilestonesHeaderSelectOption1"
      >
        {t('MilestonesSelectOptionThisWeek')}
      </MenuItem>
      <MenuItem
        value={getDateTwoWeeksFromStartOfWeek()}
        data-automation-id="MilestonesHeaderSelectOption2"
      >
        {t('MilestonesSelectOptionThisAndNextWeek')}
      </MenuItem>
      <MenuItem
        value={getDateThirtyDaysFromNow()}
        data-automation-id="MilestonesHeaderSelectOption3"
      >
        {t('MilestonesSelectOptionNext30Days')}
      </MenuItem>
    </Select>
  );
};

export const MilestoneFeed = () => {
  const [date, setDate] = useState(getDateOneWeekFromStartOfWeek());
  const { t } = useTranslation('frontpage');

  return (
    <Stack gap={3}>
      <WidgetHeader
        widgetKey="Milestone"
        heading={t('MilestonesHeading')}
        headingAs="h2"
        icon={<FlagOutlined data-automation-id="MilestonesHeaderIcon" />}
        action={
          <PeriodSelect date={date} setDate={(e: SelectChangeEvent) => setDate(e.target.value)} />
        }
        data-automation-id="MilestonesHeader"
      />
      <MilestoneFeedStatus date={date} />
    </Stack>
  );
};
