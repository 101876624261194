import classNames from 'classnames';
import { ReactNode, RefObject, useEffect, useRef } from 'react';
import { Paper } from 'src/components/mui-components';
import styles from './SidePanel.module.scss';
import { useFocusHandling } from './helpers/useFocusHandling';
import { SidePanelStoreProps, initSidePanelStore } from './reducer';
import SidePanelProvider, {
  useIsSidePanelOpen,
  useSidePanelDispatch,
  useSidePanelPosition,
} from './store';

interface ISidePanel extends Partial<Pick<HTMLDivElement, 'className'>> {
  sideBar: ReactNode;
  initValue?: SidePanelStoreProps;
  children: ReactNode;
  activateSidePanelButtonRef?: RefObject<HTMLButtonElement>;
}

const RenderPanel = ({
  children,
  sideBar,
  initValue = initSidePanelStore,
  className,
  activateSidePanelButtonRef,
}: ISidePanel) => {
  const sidePanelRef = useRef<HTMLDivElement>(null);
  const dispatch = useSidePanelDispatch();

  useEffect(() => {
    if (dispatch) {
      dispatch({ type: 'INIT_VALUE', payload: initValue });
    }
  }, [dispatch, initValue]);

  const filterPanelPosition = useSidePanelPosition();
  const isSidePanelOpen = useIsSidePanelOpen();

  useFocusHandling({ sidePanelRef, activateSidePanelButtonRef, dispatch, isSidePanelOpen });

  return (
    <div
      data-automation-id={`SidePanel${filterPanelPosition}`}
      className={classNames(className, {
        [styles.mainWrapperWithSideBar]: isSidePanelOpen,
        [styles.mainWrapperWithSideBarLeft]: filterPanelPosition === 'LEFT',
      })}
    >
      {children}
      {isSidePanelOpen ? (
        <Paper
          elevation={8}
          className={classNames(styles.sidePanel, {
            [styles.sidePanelLeft]: filterPanelPosition === 'LEFT',
            [styles.sidePanelRight]: filterPanelPosition === 'RIGHT',
          })}
          ref={sidePanelRef}
          tabIndex={-1}
        >
          {sideBar}
        </Paper>
      ) : null}
    </div>
  );
};

export default ({
  children,
  sideBar,
  initValue,
  className,
  activateSidePanelButtonRef,
}: ISidePanel) => (
  <SidePanelProvider>
    <RenderPanel
      sideBar={sideBar}
      initValue={initValue}
      className={className}
      activateSidePanelButtonRef={activateSidePanelButtonRef}
    >
      {children}
    </RenderPanel>
  </SidePanelProvider>
);
