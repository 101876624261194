import { FC, ReactNode, HTMLAttributes } from 'react';
import classnames from 'classnames';
import { TModalSizes } from 'src/assets/variables/size';
import styles from './Box.module.scss';

// Box wrapper
interface BoxInterface {
  (props: BoxProps): JSX.Element;
  Item: FC<BoxItemProps>;
  Actions: FC<BoxProps>;
}

export interface BoxProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode;
  size?: TModalSizes | 'fullWidth';
}

export const Box: BoxInterface = ({ size = 'fullWidth', ...restProps }) => (
  <div
    className={classnames(styles.wrapper, {
      [styles[size]]: size,
    })}
    {...restProps}
  />
);

// Box item
export interface BoxItemProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode;
  /** Compact version of box item */
  compact?: boolean;
  /** Has actions inside box item */
  hasActions?: boolean;
}

Box.Item = ({ compact, hasActions, ...props }) => (
  <div
    className={classnames(styles.item, {
      [styles.compact]: compact,
      [styles.hasActions]: hasActions,
    })}
    {...props}
  />
);

Box.Actions = (props) => <div className={styles.actions} {...props} />;
