/* eslint-disable @typescript-eslint/no-unused-vars */
// This const to show the options for the dropdowns
const bookingStatusOptions = ['Booked', 'Drafted', 'NotBooked'];
const billableStatusOptions = [
  'NonBillableRegistrationOnNonBillableTask',
  'BillableRegistrationOnBillableTask',
  'NonBillableRegistrationOnBillableTask',
  'BillableRegistrationOnNonBillableTask',
  'NonBillableRegistrationOnBillableTaskWithZeroHourlyRate',
];

export default {
  Entities: [
    {
      Properties: {
        ActualAmount: 90000,
        ActualHours: 200,
        AdditionalText: 'additionalText',
        BillableStatus: 'Booked',
        BookedInvoiceId: 3211,
        BookedVoucherId: 0,
        BookingStatus: 'NonBillableRegistrationOnNonBillableTask',
        Comment: 'Comment',
        Currency: 'DKK',
        Date: '01-01-2022',
        DraftInvoiceId: 0,
        DraftVoucherId: 2198,
        EmployeeInitials: 'KLA',
        EmployeeName: 'Kian Leong',
        Factor: 2,
        HourlyRate: '450,00',
        NonBillable: false,
        ProjectName: 'SYN - Lønbehandling Outsourcing (FPVI DKK)',
        TaskName: 'Indsamling af løndata',
        TimeRegistrationId: 1,
      },
    },
  ],
  Properties: {
    AdditionalCommentFieldName: 'AddComment',
    TimeRegistrationsTotalActualHours: '218.06',
    TimeRegistrationsTotalActualAmount: '100.01',
  },
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/projects/1/time-registrations',
      Rel: 'self',
    },
  ],
};
