import { useAttrs, useCommands, useCurrentSelection } from '@remirror/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from 'src/components/mui-components';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InsertLink } from '@mui/icons-material';

export const UpdateLink = () => {
  const url = (useAttrs().link()?.href as string) ?? '';
  const [href, setHref] = useState<string>(url);
  const { empty } = useCurrentSelection();
  const [isOpen, setIsOpen] = useState(false);

  const { updateLink, removeLink, selectLink, focus } = useCommands();

  useEffect(() => setHref(url), [url]);

  const onLinkInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHref(e.target.value);
  };

  const close = () => {
    setIsOpen(false);
    focus();
  };

  const removeUrl = () => {
    removeLink();
    close();
  };

  const insertLink = () => {
    selectLink();
    if (href === '') {
      removeUrl();
    } else {
      updateLink({ href });
      close();
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation('richTextEditor');

  return (
    <>
      <IconButton
        data-automation-id="ibtnFormatInsertLink"
        title={t('FormatInsertLinkLabel')}
        disabled={empty}
        onClick={() => setIsOpen(true)}
      >
        <InsertLink />
      </IconButton>

      <Dialog open={isOpen} onClose={close} data-automation-id="mdlUpdateLink">
        <DialogTitle>{t('HeadingSetLinkAddressLabel')}</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <TextField
            data-automation-id="txtLinkAddress"
            hiddenLabel
            autoFocus
            id="name"
            fullWidth
            size="small"
            ariaLabel={t('LinkAddressLabel')}
            ref={inputRef}
            onChange={onLinkInputChange}
            value={href}
          />
        </DialogContent>
        <DialogActions>
          <Button data-automation-id="btngRemoveUrl" onClick={removeUrl} size="small">
            {t('RemoveLinkLabel')}
          </Button>
          <Button
            data-automation-id="btnpInsertLink"
            onClick={insertLink}
            variant="contained"
            size="small"
          >
            {t('ConfirmLinkLabel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
