import { useGetLocale } from 'src/components/global/LocaleProvider';
import { HTMLAttributes } from 'react';

interface NumberTextProps extends HTMLAttributes<HTMLSpanElement> {
  value: number;
  options?: Intl.NumberFormatOptions;
}

export const NumberText = ({ value, options, className, style, ...restProps }: NumberTextProps) => {
  const locale = useGetLocale();

  if (value == null) {
    return null;
  }

  return <span {...restProps}>{value.toLocaleString(locale, options)}</span>;
};
