import { RefObject, useEffect } from 'react';
import { eventKeyTab } from 'src/constants/keyboardKey';
import { getKeyboardFocusableElements } from 'src/utils/accessibility';

export const useFocusTrapping = (ref: RefObject<HTMLDivElement>) => {
  useEffect(() => {
    const element = ref.current;

    const handleTab = (event: KeyboardEvent) => {
      const [firstElement, ...restElements] = getKeyboardFocusableElements(element);
      const { key, target, shiftKey } = event;
      const lastElement = restElements[restElements.length - 1];
      if (key === eventKeyTab && !shiftKey && target === lastElement) {
        event.preventDefault();
        (firstElement as HTMLButtonElement).focus();
      }

      if (key === eventKeyTab && shiftKey && target === firstElement) {
        event.preventDefault();
        (lastElement as HTMLButtonElement).focus();
      }
    };

    if (element) {
      element.addEventListener('keydown', handleTab);
    }

    return () => element?.removeEventListener('keydown', handleTab);
  }, [ref]);
};
