import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IUser, USER_URL_PATH, USER_MSW_STATUS_KEY, GET_USER_KEY } from '../types';

const userFetch = (userId?: string): Promise<IUser> =>
  getFetch({
    path: USER_URL_PATH,
    itemId: `/${userId}`,
    key: USER_MSW_STATUS_KEY,
  });

export const useGetUser = (userId?: string) => {
  const { data, ...restProps } = useQuery([GET_USER_KEY, userId], () => userFetch(userId));

  return {
    data: data?.properties || {},
    fullName: `${data?.properties?.firstName} ${data?.properties?.lastName}`,
    fullNameWithInitials: `${data?.properties?.firstName} ${data?.properties?.lastName} (${data?.properties?.initials})`,
    ...restProps,
  };
};
