export default {
  Entities: [
    {
      Properties: {
        TaskID: 64096,
        TaskName: 'Work',
        TaskFullName: 'Work',
        TaskNo: 'O21445',
        WBSNo: '1',
        TaskStartDate: '2024-01-19T00:00:00',
        TaskEndDate: '2024-01-26T00:00:00',
        TaskBudgetHour: 0.0,
        IsParent: false,
      },
    },
    {
      Properties: {
        TaskID: 64102,
        TaskName: 'asdfasdf',
        TaskFullName: 'asdfasdf',
        TaskNo: 'O21451',
        WBSNo: '3',
        TaskStartDate: '2024-01-19T00:00:00',
        TaskEndDate: '2024-01-26T00:00:00',
        TaskBudgetHour: 0.0,
        IsParent: false,
      },
    },
    {
      Properties: {
        TaskID: 64101,
        TaskName: 'Test',
        TaskFullName: 'Test',
        TaskNo: 'O21450',
        WBSNo: '2',
        TaskStartDate: '2024-01-19T00:00:00',
        TaskEndDate: '2024-01-26T00:00:00',
        TaskBudgetHour: 0.0,
        IsParent: false,
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/resource-planner/tasks/recently-registered?projectId=13499&userId=1234',
      Rel: 'self',
    },
  ],
};
