import Cookies from 'js-cookie';
import { APP_URL } from 'src/constants/cookies';
import {
  designSixPageSettingsRoutes,
  personalSettingsRoutes,
  systemAdministrationRoutes,
} from 'src/constants/paths';
import { endWith } from 'src/utils/string';

export const formatUrl = (url = '') => {
  const appUrl = Cookies.get(APP_URL) ?? '';
  const replaceUrl = url?.replace(appUrl, '');

  if (replaceUrl.startsWith('http')) {
    const { pathname } = new URL(replaceUrl);
    return pathname;
  }

  return replaceUrl;
};

interface IsContentURLProps {
  pathname: string;
  subMenusPageUrl?: string[];
}

interface IUrlCompareProps {
  currentUrl: string;
  originPath: string;
}
interface IIsUrlPathMatchedProps {
  currentUrl: string;
  originUrl: string;
}

export const isUrlPathMatched = ({ currentUrl, originUrl }: IIsUrlPathMatchedProps) => {
  const [currentPathWithoutQueryString] = currentUrl.split('?');
  const [currentPath] = currentPathWithoutQueryString.split('#');
  const [originPathWithoutQueryString] = originUrl.split('?');
  const [originPath] = originPathWithoutQueryString.split('#');
  return currentPath.replace(/\/$/, '') === originPath.replace(/\/$/, '');
};

interface IUrlPathCompareProps {
  currentUrl: string;
  originUrl: string;
}

export const replaceURLEntityID = (url: string, entityId = '') =>
  url.replace(/\/\{\S+\}/g, entityId ? `/${entityId}` : '');

export const getPathAndEntityId = (url: string) => {
  const [firstUrlFilter] = formatUrl(url).split('?');
  const [urlPath] = firstUrlFilter.split('#');
  const lastPart = urlPath.split('/').slice(-1).pop() ?? '';
  const isValidEntitiyId = !Number.isNaN(parseInt(lastPart, 10));

  return {
    urlPath: isValidEntitiyId ? urlPath.replace(`/${lastPart}`, '') : urlPath,
    entityId: isValidEntitiyId ? lastPart : '',
  };
};

export const urlPathCompare = ({ currentUrl, originUrl }: IUrlPathCompareProps) => {
  const [currnetPath] = currentUrl.split('?');
  const [originPath] = originUrl.split('?');
  return currnetPath === originPath;
};

export const urlCompare = ({ currentUrl, originPath }: IUrlCompareProps) => {
  const [path] = currentUrl.split('?');
  if (originPath.indexOf('?') > 0) {
    return endWith(currentUrl, originPath);
  }
  return endWith(path, originPath);
};

export const isContentURL = ({ subMenusPageUrl = [], pathname }: IsContentURLProps) =>
  subMenusPageUrl?.some((pageUrl) => urlCompare({ currentUrl: pathname, originPath: pageUrl }));

export const isConfigurationPage = (pathname: string) =>
  [...designSixPageSettingsRoutes, ...systemAdministrationRoutes, ...personalSettingsRoutes]?.some(
    (path) => pathname.indexOf(path.replace('/*', '')) >= 0,
  );

export const encodeString = (text = '') => encodeURI(text);

export interface IQueryString {
  param: string;
  value: string | undefined;
  isFirst?: boolean;
  isAppended?: boolean;
}

export const concatSubQuery = ({
  param,
  value,
  isFirst = false,
  isAppended = false,
}: IQueryString) => {
  if (isFirst && param && !isAppended) return `?${param}=${encodeURIComponent(value ?? '')}`;
  if (param && isAppended) return `&${param}=${encodeURIComponent(value ?? '')}`;
  return value && param ? `&${param}=${encodeURIComponent(value)}` : '';
};

export const concatQuerys = (queryStringList: IQueryString[], isAppended = false) =>
  queryStringList
    ?.map((query, index) =>
      index === 0 && !isAppended
        ? concatSubQuery({ ...query, isFirst: true })
        : concatSubQuery(query),
    )
    .join('');

type TQuery = Record<string, string | number | boolean | undefined>;

export const getPathWithQueries = (url: string, queries: TQuery) => {
  const filteredQueries: TQuery = Object.keys(queries).reduce((a, b) => {
    if (queries[b] !== undefined && queries[b] !== null && queries[b] !== '') {
      return { ...a, [b]: queries[b] };
    }
    return a;
  }, {});

  const q = Object.keys(filteredQueries).map((k) => ({
    param: k,
    value: String(filteredQueries[k]),
  }));

  return `${url}${concatQuerys(q)}`;
};
