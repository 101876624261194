export default {
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10007',
      responseType: 'GroupByWorkItem',
      viewOptions: {
        'grouped-by': 'group-by-work-item',
        'reporting-types': 'availability',
        'period-types': 'week',
        'unit-types': 'hours',
        'period-starts-at': '2024-04-19',
        'period-ends-at': '2024-05-12',
      },
      periods: [
        {
          startsAt: '2024-04-15T00:00:00',
          endsAt: '2024-04-21T00:00:00',
          type: 'Week',
          identifier: '2024W16',
        },
        {
          startsAt: '2024-04-22T00:00:00',
          endsAt: '2024-04-28T00:00:00',
          type: 'Week',
          identifier: '2024W17',
        },
        {
          startsAt: '2024-04-29T00:00:00',
          endsAt: '2024-05-05T00:00:00',
          type: 'Week',
          identifier: '2024W18',
        },
        {
          startsAt: '2024-05-06T00:00:00',
          endsAt: '2024-05-12T00:00:00',
          type: 'Week',
          identifier: '2024W19',
        },
      ],
      children: [
        {
          StartsAt: '2023-05-15T00:00:00',
          EndsAt: '2023-08-15T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2023-05-01T00:00:00',
              EndsAt: '2023-08-31T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2023-05-01T00:00:00',
                  EndsAt: '2023-08-31T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2024W16': {
                          displayValue: 11.4,
                          value: 11.4,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W18': {
                          displayValue: 37.3,
                          value: 37.3,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W19': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                      },
                      Budget: 0.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      NotPlanned: 0.0,
                      PositiveUnplannedHours: 0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 115.9,
                        value: 115.9,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '292093425069588480',
                      resourceSourceReferenceId: 1,
                      resourceType: 'Employee',
                      workItemId: '292094146565373954',
                      workItemSourceReferenceId: '928',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      id: '292093425069588480',
                      name: 'Anders Nielsen (ANI)',
                      role: 'Projektleder',
                      isActive: false,
                      hiredAt: '2019-01-01',
                      tenantId: '10007',
                      sourceReferenceId: 1,
                      type: 'Employee',
                    },
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2024W16': {
                          displayValue: 8.36,
                          value: 8.36,
                          heatmapCode: 'bgAvailability75',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W18': {
                          displayValue: 37.3,
                          value: 37.3,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W19': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                      },
                      Budget: 20.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 38.0,
                      TotalEAC: 38.0,
                      NotPlanned: -18.0,
                      PositiveUnplannedHours: 0,
                      NegativeUnplannedHours: -18.0,
                      total: {
                        displayValue: 112.86,
                        value: 112.86,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '292093395466190848',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '292094146565373954',
                      workItemSourceReferenceId: '928',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      id: '292093395466190848',
                      name: 'Todd Boehly (tbl)',
                      role: '',
                      isActive: false,
                      hiredAt: '2023-01-01',
                      tenantId: '10007',
                      sourceReferenceId: 73,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W18': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W19': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                  },
                  Budget: 20.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 38.0,
                  TotalEAC: 38.0,
                  NotPlanned: -18.0,
                  PositiveUnplannedHours: 0,
                  NegativeUnplannedHours: -18.0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '292094146565373954',
                  workItemSourceReferenceId: '928',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: '',
                  id: '292094146565373954',
                  name: 'Develop                                (O0905)',
                  sourceReferenceId: '928',
                  tenantId: '10007',
                  parentId: '292094146565373953',
                  type: 'Task',
                  pathToParent: '/292094146477293568/292094146565373953/',
                  sortOrder: 2,
                  level: 3,
                  sortablePath: '/RP BI53784/001/002',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
                {
                  StartsAt: '2023-05-01T00:00:00',
                  EndsAt: '2023-08-31T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2024W16': {
                          displayValue: 11.4,
                          value: 11.4,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W18': {
                          displayValue: 37.3,
                          value: 37.3,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W19': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                      },
                      Budget: 0.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      NotPlanned: 0.0,
                      PositiveUnplannedHours: 0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 115.9,
                        value: 115.9,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '292093425069588480',
                      resourceSourceReferenceId: 1,
                      resourceType: 'Employee',
                      workItemId: '292094146565373955',
                      workItemSourceReferenceId: '929',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      id: '292093425069588480',
                      name: 'Anders Nielsen (ANI)',
                      role: 'Projektleder',
                      isActive: false,
                      hiredAt: '2019-01-01',
                      tenantId: '10007',
                      sourceReferenceId: 1,
                      type: 'Employee',
                    },
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2024W16': {
                          displayValue: 11.4,
                          value: 11.4,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W18': {
                          displayValue: 37.3,
                          value: 37.3,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W19': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                      },
                      Budget: 10.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      NotPlanned: 10.0,
                      PositiveUnplannedHours: 10.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 115.9,
                        value: 115.9,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '292093423463170048',
                      resourceSourceReferenceId: 6,
                      resourceType: 'Employee',
                      workItemId: '292094146565373955',
                      workItemSourceReferenceId: '929',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      id: '292093423463170048',
                      name: 'Carl Chan (CCH)',
                      role: 'Leder',
                      isActive: false,
                      hiredAt: '2020-01-01',
                      tenantId: '10007',
                      sourceReferenceId: 6,
                      type: 'Employee',
                    },
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2024W16': {
                          displayValue: 8.36,
                          value: 8.36,
                          heatmapCode: 'bgAvailability75',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W18': {
                          displayValue: 37.3,
                          value: 37.3,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W19': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                      },
                      Budget: 10.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 9.0,
                      TotalEAC: 9.0,
                      NotPlanned: 1.0,
                      PositiveUnplannedHours: 1.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 112.86,
                        value: 112.86,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '292093395466190848',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '292094146565373955',
                      workItemSourceReferenceId: '929',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      id: '292093395466190848',
                      name: 'Todd Boehly (tbl)',
                      role: '',
                      isActive: false,
                      hiredAt: '2023-01-01',
                      tenantId: '10007',
                      sourceReferenceId: 73,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W18': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W19': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                  },
                  Budget: 10.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 9.0,
                  TotalEAC: 9.0,
                  NotPlanned: 1.0,
                  PositiveUnplannedHours: 11.0,
                  NegativeUnplannedHours: 0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '292094146565373955',
                  workItemSourceReferenceId: '929',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: '',
                  id: '292094146565373955',
                  name: 'Fix (O0906)',
                  sourceReferenceId: '929',
                  tenantId: '10007',
                  parentId: '292094146565373953',
                  type: 'Task',
                  pathToParent: '/292094146477293568/292094146565373953/',
                  sortOrder: 3,
                  level: 3,
                  sortablePath: '/RP BI53784/001/003',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
                {
                  StartsAt: '2023-05-01T00:00:00',
                  EndsAt: '2023-08-31T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2024W16': {
                          displayValue: 8.36,
                          value: 8.36,
                          heatmapCode: 'bgAvailability75',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W18': {
                          displayValue: 37.3,
                          value: 37.3,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W19': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                      },
                      Budget: 20.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      NotPlanned: 20.0,
                      PositiveUnplannedHours: 20.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 112.86,
                        value: 112.86,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '292093395466190848',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '292094146565373956',
                      workItemSourceReferenceId: '930',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      id: '292093395466190848',
                      name: 'Todd Boehly (tbl)',
                      role: '',
                      isActive: false,
                      hiredAt: '2023-01-01',
                      tenantId: '10007',
                      sourceReferenceId: 73,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W18': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W19': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                  },
                  Budget: 20.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  NotPlanned: 20.0,
                  PositiveUnplannedHours: 20.0,
                  NegativeUnplannedHours: 0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '292094146565373956',
                  workItemSourceReferenceId: '930',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: '',
                  id: '292094146565373956',
                  name: 'Testing (O0907)',
                  sourceReferenceId: '930',
                  tenantId: '10007',
                  parentId: '292094146565373953',
                  type: 'Task',
                  pathToParent: '/292094146477293568/292094146565373953/',
                  sortOrder: 4,
                  level: 3,
                  sortablePath: '/RP BI53784/001/004',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
                {
                  StartsAt: '2023-05-01T00:00:00',
                  EndsAt: '2023-08-31T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2024W16': {
                          displayValue: 8.36,
                          value: 8.36,
                          heatmapCode: 'bgAvailability75',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W18': {
                          displayValue: 37.3,
                          value: 37.3,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W19': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                      },
                      Budget: 5.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      NotPlanned: 5.0,
                      PositiveUnplannedHours: 5.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 112.86,
                        value: 112.86,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '292093395466190848',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '292094146565373957',
                      workItemSourceReferenceId: '931',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      id: '292093395466190848',
                      name: 'Todd Boehly (tbl)',
                      role: '',
                      isActive: false,
                      hiredAt: '2023-01-01',
                      tenantId: '10007',
                      sourceReferenceId: 73,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W18': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W19': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                  },
                  Budget: 5.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 0.0,
                  NotPlanned: 5.0,
                  PositiveUnplannedHours: 5.0,
                  NegativeUnplannedHours: 0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '292094146565373957',
                  workItemSourceReferenceId: '931',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: '',
                  id: '292094146565373957',
                  name: 'Regression (O0908)',
                  sourceReferenceId: '931',
                  tenantId: '10007',
                  parentId: '292094146565373953',
                  type: 'Task',
                  pathToParent: '/292094146477293568/292094146565373953/',
                  sortOrder: 5,
                  level: 3,
                  sortablePath: '/RP BI53784/001/005',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
                {
                  StartsAt: '2023-05-01T00:00:00',
                  EndsAt: '2023-08-31T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2024W16': {
                          displayValue: 8.36,
                          value: 8.36,
                          heatmapCode: 'bgAvailability75',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W18': {
                          displayValue: 37.3,
                          value: 37.3,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W19': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                      },
                      Budget: 25.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 8.0,
                      TotalEAC: 8.0,
                      NotPlanned: 17.0,
                      PositiveUnplannedHours: 17.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 112.86,
                        value: 112.86,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '292093395466190848',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '292094146565373958',
                      workItemSourceReferenceId: '932',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      id: '292093395466190848',
                      name: 'Todd Boehly (tbl)',
                      role: '',
                      isActive: false,
                      hiredAt: '2023-01-01',
                      tenantId: '10007',
                      sourceReferenceId: 73,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W18': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W19': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                  },
                  Budget: 25.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 8.0,
                  TotalEAC: 8.0,
                  NotPlanned: 17.0,
                  PositiveUnplannedHours: 17.0,
                  NegativeUnplannedHours: 0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '292094146565373958',
                  workItemSourceReferenceId: '932',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: '',
                  id: '292094146565373958',
                  name: 'Maintenance (O0909)',
                  sourceReferenceId: '932',
                  tenantId: '10007',
                  parentId: '292094146565373953',
                  type: 'Task',
                  pathToParent: '/292094146477293568/292094146565373953/',
                  sortOrder: 6,
                  level: 3,
                  sortablePath: '/RP BI53784/001/006',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
              ],
              values: {
                '2024W16': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
                '2024W17': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
                '2024W18': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
                '2024W19': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
              },
              Budget: 80.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 55.0,
              TotalEAC: 55.0,
              NotPlanned: 25.0,
              PositiveUnplannedHours: 43.0,
              NegativeUnplannedHours: -18.0,
              total: {
                displayValue: 0,
                value: 0,
              },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '292094146565373953',
              workItemSourceReferenceId: '926',
              workItemType: 'Task',
              hasChildWithEndDateOnTodayOrAfter: false,
              rootParentName: '',
              id: '292094146565373953',
              name: 'Development (O0903)',
              sourceReferenceId: '926',
              tenantId: '10007',
              parentId: '292094146477293568',
              type: 'Task',
              pathToParent: '/292094146477293568/',
              sortOrder: 1,
              level: 2,
              sortablePath: '/RP BI53784/001',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
              lastUserSetIsActive: false,
            },
            {
              StartsAt: '2023-05-01T00:00:00',
              EndsAt: '2023-08-31T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2023-05-01T00:00:00',
                  EndsAt: '2023-08-31T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2024W16': {
                          displayValue: 8.36,
                          value: 8.36,
                          heatmapCode: 'bgAvailability75',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W18': {
                          displayValue: 37.3,
                          value: 37.3,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W19': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                      },
                      Budget: 5.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 4.0,
                      TotalEAC: 4.0,
                      NotPlanned: 1.0,
                      PositiveUnplannedHours: 1.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 112.86,
                        value: 112.86,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '292093395466190848',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '292094146565373960',
                      workItemSourceReferenceId: '933',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      id: '292093395466190848',
                      name: 'Todd Boehly (tbl)',
                      role: '',
                      isActive: false,
                      hiredAt: '2023-01-01',
                      tenantId: '10007',
                      sourceReferenceId: 73,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W18': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W19': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                  },
                  Budget: 5.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 4.0,
                  TotalEAC: 4.0,
                  NotPlanned: 1.0,
                  PositiveUnplannedHours: 1.0,
                  NegativeUnplannedHours: 0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '292094146565373960',
                  workItemSourceReferenceId: '933',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: '',
                  id: '292094146565373960',
                  name: 'Release notes (O0910)',
                  sourceReferenceId: '933',
                  tenantId: '10007',
                  parentId: '292094146565373959',
                  type: 'Task',
                  pathToParent: '/292094146477293568/292094146565373959/',
                  sortOrder: 8,
                  level: 3,
                  sortablePath: '/RP BI53784/007/008',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
                {
                  StartsAt: '2023-05-01T00:00:00',
                  EndsAt: '2023-08-31T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      hierarchyType: 'resource',
                      values: {
                        '2024W16': {
                          displayValue: 8.36,
                          value: 8.36,
                          heatmapCode: 'bgAvailability75',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W18': {
                          displayValue: 37.3,
                          value: 37.3,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                        '2024W19': {
                          displayValue: 33.6,
                          value: 33.6,
                          heatmapCode: 'bgAvailability100',
                          editable: true,
                        },
                      },
                      Budget: 15.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 4.0,
                      TotalEAC: 4.0,
                      NotPlanned: 11.0,
                      PositiveUnplannedHours: 11.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 112.86,
                        value: 112.86,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '292093395466190848',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '292094146565373961',
                      workItemSourceReferenceId: '934',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      id: '292093395466190848',
                      name: 'Todd Boehly (tbl)',
                      role: '',
                      isActive: false,
                      hiredAt: '2023-01-01',
                      tenantId: '10007',
                      sourceReferenceId: 73,
                      type: 'Employee',
                    },
                  ],
                  values: {
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W18': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W19': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                  },
                  Budget: 15.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 4.0,
                  TotalEAC: 4.0,
                  NotPlanned: 11.0,
                  PositiveUnplannedHours: 11.0,
                  NegativeUnplannedHours: 0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: true,
                  editable: false,
                  canAssign: true,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '292094146565373961',
                  workItemSourceReferenceId: '934',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: '',
                  id: '292094146565373961',
                  name: 'Patch (O0911)',
                  sourceReferenceId: '934',
                  tenantId: '10007',
                  parentId: '292094146565373959',
                  type: 'Task',
                  pathToParent: '/292094146477293568/292094146565373959/',
                  sortOrder: 9,
                  level: 3,
                  sortablePath: '/RP BI53784/007/009',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
              ],
              values: {
                '2024W16': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
                '2024W17': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
                '2024W18': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
                '2024W19': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
              },
              Budget: 20.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 8.0,
              TotalEAC: 8.0,
              NotPlanned: 12.0,
              PositiveUnplannedHours: 12.0,
              NegativeUnplannedHours: 0,
              total: {
                displayValue: 0,
                value: 0,
              },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '292094146565373959',
              workItemSourceReferenceId: '927',
              workItemType: 'Task',
              hasChildWithEndDateOnTodayOrAfter: false,
              rootParentName: '',
              id: '292094146565373959',
              name: 'Release (O0904)',
              sourceReferenceId: '927',
              tenantId: '10007',
              parentId: '292094146477293568',
              type: 'Task',
              pathToParent: '/292094146477293568/',
              sortOrder: 7,
              level: 2,
              sortablePath: '/RP BI53784/007',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
              lastUserSetIsActive: false,
            },
            {
              StartsAt: '2023-11-08T00:00:00',
              EndsAt: '2023-11-08T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2023-11-08T00:00:00',
                  EndsAt: '2023-11-08T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      StartsAt: '2023-11-08T00:00:00',
                      EndsAt: '2023-11-08T00:00:00',
                      hierarchyType: 'workitem',
                      children: [
                        {
                          hierarchyType: 'resource',
                          values: {
                            '2024W16': {
                              displayValue: 8.36,
                              value: 8.36,
                              heatmapCode: 'bgAvailability75',
                              editable: true,
                            },
                            '2024W17': {
                              displayValue: 33.6,
                              value: 33.6,
                              heatmapCode: 'bgAvailability100',
                              editable: true,
                            },
                            '2024W18': {
                              displayValue: 37.3,
                              value: 37.3,
                              heatmapCode: 'bgAvailability100',
                              editable: true,
                            },
                            '2024W19': {
                              displayValue: 33.6,
                              value: 33.6,
                              heatmapCode: 'bgAvailability100',
                              editable: true,
                            },
                          },
                          Budget: 15.0,
                          TotalBooked: 0.0,
                          TotalActualWorkload: 2.0,
                          TotalEAC: 2.0,
                          NotPlanned: 13.0,
                          PositiveUnplannedHours: 13.0,
                          NegativeUnplannedHours: 0,
                          total: {
                            displayValue: 112.86,
                            value: 112.86,
                          },
                          canExpand: false,
                          editable: true,
                          canAssign: false,
                          canDelete: true,
                          resourceId: '292093395466190848',
                          resourceSourceReferenceId: 73,
                          resourceType: 'Employee',
                          workItemId: '292094146565373964',
                          workItemSourceReferenceId: '948',
                          workItemType: 'Task',
                          hasChildWithEndDateOnTodayOrAfter: false,
                          id: '292093395466190848',
                          name: 'Todd Boehly (tbl)',
                          role: '',
                          isActive: false,
                          hiredAt: '2023-01-01',
                          tenantId: '10007',
                          sourceReferenceId: 73,
                          type: 'Employee',
                        },
                      ],
                      values: {
                        '2024W16': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: false,
                        },
                        '2024W17': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: false,
                        },
                        '2024W18': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: false,
                        },
                        '2024W19': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: false,
                        },
                      },
                      Budget: 15.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 2.0,
                      TotalEAC: 2.0,
                      NotPlanned: 13.0,
                      PositiveUnplannedHours: 13.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 0,
                        value: 0,
                      },
                      canExpand: true,
                      editable: false,
                      canAssign: true,
                      canDelete: false,
                      resourceId: '0',
                      resourceSourceReferenceId: 0,
                      resourceType: 'Unknown',
                      workItemId: '292094146565373964',
                      workItemSourceReferenceId: '948',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      rootParentName: '',
                      id: '292094146565373964',
                      name: 'Sub testing 1 (O0925)',
                      sourceReferenceId: '948',
                      tenantId: '10007',
                      parentId: '292094146565373963',
                      type: 'Task',
                      pathToParent: '/292094146477293568/292094146565373962/292094146565373963/',
                      sortOrder: 12,
                      level: 4,
                      sortablePath: '/RP BI53784/010/011/012',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                      lastUserSetIsActive: false,
                    },
                    {
                      StartsAt: '2023-11-08T00:00:00',
                      EndsAt: '2023-11-08T00:00:00',
                      hierarchyType: 'workitem',
                      children: [
                        {
                          StartsAt: '2023-11-08T00:00:00',
                          EndsAt: '2023-11-08T00:00:00',
                          hierarchyType: 'workitem',
                          children: [
                            {
                              StartsAt: '2023-11-08T00:00:00',
                              EndsAt: '2023-11-08T00:00:00',
                              hierarchyType: 'workitem',
                              children: [
                                {
                                  hierarchyType: 'resource',
                                  values: {
                                    '2024W16': {
                                      displayValue: 8.36,
                                      value: 8.36,
                                      heatmapCode: 'bgAvailability75',
                                      editable: true,
                                    },
                                    '2024W17': {
                                      displayValue: 33.6,
                                      value: 33.6,
                                      heatmapCode: 'bgAvailability100',
                                      editable: true,
                                    },
                                    '2024W18': {
                                      displayValue: 37.3,
                                      value: 37.3,
                                      heatmapCode: 'bgAvailability100',
                                      editable: true,
                                    },
                                    '2024W19': {
                                      displayValue: 33.6,
                                      value: 33.6,
                                      heatmapCode: 'bgAvailability100',
                                      editable: true,
                                    },
                                  },
                                  Budget: 0.0,
                                  TotalBooked: 0.0,
                                  TotalActualWorkload: 2.0,
                                  TotalEAC: 2.0,
                                  NotPlanned: -2.0,
                                  PositiveUnplannedHours: 0,
                                  NegativeUnplannedHours: -2.0,
                                  total: {
                                    displayValue: 112.86,
                                    value: 112.86,
                                  },
                                  canExpand: false,
                                  editable: true,
                                  canAssign: false,
                                  canDelete: true,
                                  resourceId: '292093395466190848',
                                  resourceSourceReferenceId: 73,
                                  resourceType: 'Employee',
                                  workItemId: '292094146565373967',
                                  workItemSourceReferenceId: '951',
                                  workItemType: 'Task',
                                  hasChildWithEndDateOnTodayOrAfter: false,
                                  id: '292093395466190848',
                                  name: 'Todd Boehly (tbl)',
                                  role: '',
                                  isActive: false,
                                  hiredAt: '2023-01-01',
                                  tenantId: '10007',
                                  sourceReferenceId: 73,
                                  type: 'Employee',
                                },
                              ],
                              values: {
                                '2024W16': {
                                  displayValue: 0,
                                  value: 0,
                                  heatmapCode: '',
                                  editable: false,
                                },
                                '2024W17': {
                                  displayValue: 0,
                                  value: 0,
                                  heatmapCode: '',
                                  editable: false,
                                },
                                '2024W18': {
                                  displayValue: 0,
                                  value: 0,
                                  heatmapCode: '',
                                  editable: false,
                                },
                                '2024W19': {
                                  displayValue: 0,
                                  value: 0,
                                  heatmapCode: '',
                                  editable: false,
                                },
                              },
                              Budget: 10.0,
                              TotalBooked: 0.0,
                              TotalActualWorkload: 2.0,
                              TotalEAC: 2.0,
                              NotPlanned: 8.0,
                              PositiveUnplannedHours: 0,
                              NegativeUnplannedHours: -2.0,
                              total: {
                                displayValue: 0,
                                value: 0,
                              },
                              canExpand: true,
                              editable: false,
                              canAssign: true,
                              canDelete: false,
                              resourceId: '0',
                              resourceSourceReferenceId: 0,
                              resourceType: 'Unknown',
                              workItemId: '292094146565373967',
                              workItemSourceReferenceId: '951',
                              workItemType: 'Task',
                              hasChildWithEndDateOnTodayOrAfter: false,
                              rootParentName: '',
                              id: '292094146565373967',
                              name: 'Sub task 2.1.1 (O0928)',
                              sourceReferenceId: '951',
                              tenantId: '10007',
                              parentId: '292094146565373966',
                              type: 'Task',
                              pathToParent:
                                '/292094146477293568/292094146565373962/292094146565373963/292094146565373965/292094146565373966/',
                              sortOrder: 15,
                              level: 6,
                              sortablePath: '/RP BI53784/010/011/013/014/015',
                              isActive: false,
                              startAt: '0001-01-01T00:00:00',
                              endAt: '0001-01-01T00:00:00',
                              status: 'InProgress',
                              lastUserSetIsActive: false,
                            },
                          ],
                          values: {
                            '2024W16': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: false,
                            },
                            '2024W17': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: false,
                            },
                            '2024W18': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: false,
                            },
                            '2024W19': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: false,
                            },
                          },
                          Budget: 10.0,
                          TotalBooked: 0.0,
                          TotalActualWorkload: 2.0,
                          TotalEAC: 2.0,
                          NotPlanned: 8.0,
                          PositiveUnplannedHours: 8.0,
                          NegativeUnplannedHours: 0,
                          total: {
                            displayValue: 0,
                            value: 0,
                          },
                          canExpand: true,
                          editable: false,
                          canAssign: false,
                          canDelete: false,
                          resourceId: '0',
                          resourceSourceReferenceId: 0,
                          resourceType: 'Unknown',
                          workItemId: '292094146565373966',
                          workItemSourceReferenceId: '950',
                          workItemType: 'Task',
                          hasChildWithEndDateOnTodayOrAfter: false,
                          rootParentName: '',
                          id: '292094146565373966',
                          name: 'Sub task 2.1 (O0927)',
                          sourceReferenceId: '950',
                          tenantId: '10007',
                          parentId: '292094146565373965',
                          type: 'Task',
                          pathToParent:
                            '/292094146477293568/292094146565373962/292094146565373963/292094146565373965/',
                          sortOrder: 14,
                          level: 5,
                          sortablePath: '/RP BI53784/010/011/013/014',
                          isActive: false,
                          startAt: '0001-01-01T00:00:00',
                          endAt: '0001-01-01T00:00:00',
                          status: 'InProgress',
                          lastUserSetIsActive: false,
                        },
                      ],
                      values: {
                        '2024W16': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: false,
                        },
                        '2024W17': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: false,
                        },
                        '2024W18': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: false,
                        },
                        '2024W19': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: false,
                        },
                      },
                      Budget: 10.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 2.0,
                      TotalEAC: 2.0,
                      NotPlanned: 8.0,
                      PositiveUnplannedHours: 8.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 0,
                        value: 0,
                      },
                      canExpand: true,
                      editable: false,
                      canAssign: false,
                      canDelete: false,
                      resourceId: '0',
                      resourceSourceReferenceId: 0,
                      resourceType: 'Unknown',
                      workItemId: '292094146565373965',
                      workItemSourceReferenceId: '949',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      rootParentName: '',
                      id: '292094146565373965',
                      name: 'Sub testing 2 (O0926)',
                      sourceReferenceId: '949',
                      tenantId: '10007',
                      parentId: '292094146565373963',
                      type: 'Task',
                      pathToParent: '/292094146477293568/292094146565373962/292094146565373963/',
                      sortOrder: 13,
                      level: 4,
                      sortablePath: '/RP BI53784/010/011/013',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                      lastUserSetIsActive: false,
                    },
                  ],
                  values: {
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W18': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                    '2024W19': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: false,
                    },
                  },
                  Budget: 25.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 4.0,
                  TotalEAC: 4.0,
                  NotPlanned: 21.0,
                  PositiveUnplannedHours: 21.0,
                  NegativeUnplannedHours: 0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: true,
                  editable: false,
                  canAssign: false,
                  canDelete: false,
                  resourceId: '0',
                  resourceSourceReferenceId: 0,
                  resourceType: 'Unknown',
                  workItemId: '292094146565373963',
                  workItemSourceReferenceId: '947',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: '',
                  id: '292094146565373963',
                  name: 'Sub testing (O0924)',
                  sourceReferenceId: '947',
                  tenantId: '10007',
                  parentId: '292094146565373962',
                  type: 'Task',
                  pathToParent: '/292094146477293568/292094146565373962/',
                  sortOrder: 11,
                  level: 3,
                  sortablePath: '/RP BI53784/010/011',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
              ],
              values: {
                '2024W16': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
                '2024W17': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
                '2024W18': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
                '2024W19': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
              },
              Budget: 25.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 4.0,
              TotalEAC: 4.0,
              NotPlanned: 21.0,
              PositiveUnplannedHours: 21.0,
              NegativeUnplannedHours: 0,
              total: {
                displayValue: 0,
                value: 0,
              },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '292094146565373962',
              workItemSourceReferenceId: '946',
              workItemType: 'Task',
              hasChildWithEndDateOnTodayOrAfter: false,
              rootParentName: '',
              id: '292094146565373962',
              name: 'testing (O0923)',
              sourceReferenceId: '946',
              tenantId: '10007',
              parentId: '292094146477293568',
              type: 'Task',
              pathToParent: '/292094146477293568/',
              sortOrder: 10,
              level: 2,
              sortablePath: '/RP BI53784/010',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
              lastUserSetIsActive: false,
            },
          ],
          values: {
            '2024W16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 1000.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 67.0,
          TotalEAC: 67.0,
          NotPlanned: 933.0,
          PositiveUnplannedHours: 58.0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '292094146477293568',
          workItemSourceReferenceId: '108',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '292094146477293568',
          name: 'RP BI53784 (P23.0002)',
          sourceReferenceId: '108',
          tenantId: '10007',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/RP BI53784',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2024-04-17T00:00:00',
          EndsAt: '2024-07-17T00:00:00',
          hierarchyType: 'workitem',
          children: [
            {
              StartsAt: '2024-04-17T00:00:00',
              EndsAt: '2024-07-17T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  hierarchyType: 'resource',
                  values: {
                    '2024W16': {
                      displayValue: 8.36,
                      value: 8.36,
                      heatmapCode: 'bgAvailability75',
                      editable: true,
                    },
                    '2024W17': {
                      displayValue: 33.6,
                      value: 33.6,
                      heatmapCode: 'bgAvailability100',
                      editable: true,
                    },
                    '2024W18': {
                      displayValue: 37.3,
                      value: 37.3,
                      heatmapCode: 'bgAvailability100',
                      editable: true,
                    },
                    '2024W19': {
                      displayValue: 33.6,
                      value: 33.6,
                      heatmapCode: 'bgAvailability100',
                      editable: true,
                    },
                  },
                  Budget: 10.0,
                  TotalBooked: 3.04,
                  TotalActualWorkload: 0.0,
                  TotalEAC: 3.04,
                  NotPlanned: 6.96,
                  PositiveUnplannedHours: 6.96,
                  NegativeUnplannedHours: 0,
                  total: {
                    displayValue: 112.86,
                    value: 112.86,
                  },
                  canExpand: false,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '292093395466190848',
                  resourceSourceReferenceId: 73,
                  resourceType: 'Employee',
                  workItemId: '292096912062939136',
                  workItemSourceReferenceId: '954',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  id: '292093395466190848',
                  name: 'Todd Boehly (tbl)',
                  role: '',
                  isActive: false,
                  hiredAt: '2023-01-01',
                  tenantId: '10007',
                  sourceReferenceId: 73,
                  type: 'Employee',
                },
              ],
              values: {
                '2024W16': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
                '2024W17': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
                '2024W18': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
                '2024W19': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: false,
                },
              },
              Budget: 10.0,
              TotalBooked: 3.04,
              TotalActualWorkload: 0.0,
              TotalEAC: 3.04,
              NotPlanned: 6.96,
              PositiveUnplannedHours: 6.96,
              NegativeUnplannedHours: 0,
              total: {
                displayValue: 0,
                value: 0,
              },
              canExpand: true,
              editable: false,
              canAssign: true,
              canDelete: false,
              resourceId: '0',
              resourceSourceReferenceId: 0,
              resourceType: 'Unknown',
              workItemId: '292096912062939136',
              workItemSourceReferenceId: '954',
              workItemType: 'Task',
              hasChildWithEndDateOnTodayOrAfter: false,
              rootParentName: '',
              id: '292096912062939136',
              name: 'New task 1 (O0931)',
              sourceReferenceId: '954',
              tenantId: '10007',
              parentId: '292095748093247489',
              type: 'Task',
              pathToParent: '/292095748093247489/',
              sortOrder: 3,
              level: 2,
              sortablePath: '/Test 2324591393/003',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
              lastUserSetIsActive: false,
            },
          ],
          values: {
            '2024W16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 3.04,
          TotalActualWorkload: 0.0,
          TotalEAC: 3.04,
          NotPlanned: -3.04,
          PositiveUnplannedHours: 6.96,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '292095748093247489',
          workItemSourceReferenceId: '110',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '292095748093247489',
          name: 'Test 2324591393 (P24.0001)',
          sourceReferenceId: '110',
          tenantId: '10007',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Test 2324591393',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ],
    },
    links: [
      {
        href: 'http://test1.resourceplanner2.aks.timelog.com:80/plan/partialgroupbyworkitem?periodstartsat=2024-04-19&periodendsat=2024-05-12&periodtypes=week&unittypes=hours&reportingtypes=availability&showprojectno=true&showtaskno=true&firstload=true',
        rel: 'self',
      },
    ],
  },
  DeterministicHash: -931510522,
  StatusCode: 200,
};
