import { useLocation, useNavigate } from 'react-router-dom';

// This component is used to navigate back to the previous page using the router
// it has a fallback option to navigate to a specific page if there is no previous page
export const useGoBack = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const thereIsAPrevPage = location.key !== 'default';

  if (thereIsAPrevPage) {
    return () => navigate(-1);
  }
  return ({ fallback }: { fallback?: string }) => navigate(fallback || '/');
};
