import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFetch } from 'src/utils/fetcher';
import { INewManualPeriodResponse } from '../exchangeRateAPI';
import { EXCHANGE_RATE_GET_PERIODS_KEY } from '../get/getPeriods';
import { GET_EXCHANGE_RATE_MATRIX_ACTIONS_KEY } from '../get/getMatrixActionsAPI';

const POST_EXCHANGE_RATE_NEW_MANUAL_PERIOD = '/api/v1/exchange-rate/manual-period';
export const POST_EXCHANGE_RATE_NEW_MANUAL_MSW_STATUS_KEY = 'postNewManualPeriod';

const postExchangeRateNewManualPeriod = (endDate: string): Promise<INewManualPeriodResponse> =>
  postFetch({
    path: POST_EXCHANGE_RATE_NEW_MANUAL_PERIOD,
    key: POST_EXCHANGE_RATE_NEW_MANUAL_MSW_STATUS_KEY,
    body: JSON.stringify({
      endDate,
    }),
  });

export const usePostExchangeRateNewManualPeriod = () => {
  const queryClient = useQueryClient();

  return useMutation(postExchangeRateNewManualPeriod, {
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries([EXCHANGE_RATE_GET_PERIODS_KEY]),
        queryClient.invalidateQueries([GET_EXCHANGE_RATE_MATRIX_ACTIONS_KEY]),
      ]),
  });
};
