import { ClassNames } from 'react-day-picker';
import dpStyles from 'react-day-picker/dist/style.module.css';
import styles from './classNames.module.scss';

export const classNames: ClassNames = {
  ...dpStyles,
  root: `${dpStyles.root} ${styles.wrapper}`,
  caption_label: `${dpStyles.caption_label} ${styles.captionLabel}`,
  button: `${dpStyles.button} ${styles.button}`,
  cell: `${dpStyles.cell} ${styles.cell}`,
  head_cell: `${dpStyles.head_cell} ${styles.headCell}`,
  day: `${dpStyles.day} ${styles.day}`,
  day_today: styles.dayToday,
  day_outside: styles.dayOutside,
  day_selected: styles.daySelected,
  day_range_start: styles.rangeDateStart,
  day_range_end: styles.rangeDateEnd,
  day_range_middle: styles.rangeDateMiddle,
  nav_button: `${dpStyles.nav_button} ${styles.navButton}`,
  weeknumber: `${dpStyles.weeknumber} ${styles.weekNumber}`,
  multiple_months: `${dpStyles.multiple_months} ${styles.hasMultipleMonths}`,
  nav: `${dpStyles.nav} ${styles.nav}`,
};
