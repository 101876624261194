import { Table } from 'src/components/ui-components/Table';
import { memo } from 'react';
import {
  IResourcePlannerPeriodColumn,
  IResourcePlannerTotalResult,
} from 'src/apis/types/resourcePlannerAPI';
import { totalRowValueRenderer } from '../../helper/totalRowValueRenderer';
import styles from './ResourceTableGroupedByEmployee.module.scss';

interface IMemoizedFooterRow {
  totalColumnTranslatedName: string;
  unitType: string;
  siteLocale: string;
  totalBudget: number | undefined;
  totalBooked: number | undefined;
  totalActualWorkload: number | undefined;
  totalNotPlanned: number | undefined;
  periods: IResourcePlannerPeriodColumn[];
  totalRow: Partial<IResourcePlannerTotalResult>;
  totalRowDisplayValue: number | undefined;
}

const MemoizedFooterRow = memo(
  ({
    totalColumnTranslatedName,
    unitType,
    siteLocale,
    totalBudget,
    totalBooked,
    totalActualWorkload,
    totalNotPlanned,
    periods,
    totalRow,
    totalRowDisplayValue,
  }: IMemoizedFooterRow) => (
    <Table.Foot>
      <Table.Row>
        <Table.Cell>
          <span className={styles.totalFooterText}>{totalColumnTranslatedName}</span>
        </Table.Cell>
        <Table.Cell />
        <Table.Cell />
        <Table.Cell />
        <Table.Cell alignment="right">
          {totalRowValueRenderer(totalBudget, unitType, siteLocale, false)}
        </Table.Cell>
        <Table.Cell alignment="right">
          {totalRowValueRenderer(totalBooked, unitType, siteLocale, false)}
        </Table.Cell>
        <Table.Cell alignment="right">
          {totalRowValueRenderer(totalActualWorkload, unitType, siteLocale, false)}
        </Table.Cell>
        <Table.Cell alignment="right">
          {totalRowValueRenderer(totalNotPlanned, unitType, siteLocale, false)}
        </Table.Cell>
        {periods.map((col) => (
          <Table.Cell key={`totalRow_period_${col.identifier}`} alignment="right">
            {col?.identifier !== undefined &&
              totalRowValueRenderer(
                totalRow?.model?.properties?.row?.values !== undefined
                  ? totalRow.model.properties.row.values[col.identifier]?.displayValue
                  : 0,
                unitType,
                siteLocale,
              )}
          </Table.Cell>
        ))}
        <Table.Cell alignment="right" type="total">
          {totalRowValueRenderer(totalRowDisplayValue, unitType, siteLocale)}
        </Table.Cell>
      </Table.Row>
    </Table.Foot>
  ),
  (prevProp, nextProp) => {
    const isSameTotalColumnTranslatedName =
      prevProp.totalColumnTranslatedName === nextProp.totalColumnTranslatedName;

    const isSameUnitType = prevProp.unitType === nextProp.unitType;

    const isSameSiteLocale = prevProp.siteLocale === nextProp.siteLocale;

    const isSameTotalBooked = prevProp.totalBooked === nextProp.totalBooked;

    const isSameTotalActualWorkload = prevProp.totalActualWorkload === nextProp.totalActualWorkload;

    const isSameTotalRowDisplayValue =
      prevProp.totalRowDisplayValue === nextProp.totalRowDisplayValue;

    let isSamePeriodValues = true;
    const prevPropPeriodValue: any = {};
    prevProp.periods.forEach((oldPeriodValue) => {
      prevPropPeriodValue[oldPeriodValue.identifier] = oldPeriodValue.identifier;
    });

    nextProp.periods.forEach((newPeriodValue) => {
      if (prevPropPeriodValue[newPeriodValue.identifier] === undefined) {
        isSamePeriodValues = false;
      }
    });

    let isSamePeriodsLength = true;
    isSamePeriodsLength =
      prevProp &&
      nextProp &&
      prevProp?.periods &&
      nextProp?.periods &&
      prevProp?.periods.length === nextProp?.periods.length;

    return (
      isSameTotalColumnTranslatedName &&
      isSameUnitType &&
      isSameSiteLocale &&
      isSameTotalBooked &&
      isSameTotalActualWorkload &&
      isSameTotalRowDisplayValue &&
      isSamePeriodValues &&
      isSamePeriodsLength
    );
  },
);

export default MemoizedFooterRow;
