import { FC, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import cx from 'classnames';
import { ThemeColors } from 'src/assets/variables/colors';

import { HeaderWithNavigation } from 'src/components/layout/Header/Header';
import AppShell from 'src/components/layout/AppShell';

import styles from './LayoutPage.module.scss';

import { useIframeEvent } from './LayoutPageHookHelper';

interface LayoutPageProps {
  title?: string;
  theme?: ThemeColors;
  hasCustomTable?: boolean;
}

export const LayoutPage: FC<LayoutPageProps> = ({
  title,
  theme = 'ThemePink',
  hasCustomTable = false,
  ...props
}) => {
  const mainContentRef = useRef<HTMLDivElement>(null);

  useIframeEvent();

  return (
    <AppShell title={title} mainRefElement={mainContentRef}>
      <HeaderWithNavigation />

      <main
        ref={mainContentRef}
        className={cx(
          styles.Content,
          { [styles.HasCustomTable]: hasCustomTable },
          // The theme should be set on the content as other content is global = pink
          theme,
        )}
        data-automation-id="LayoutPageMainContainer"
        {...props}
      >
        {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
        <Outlet />
      </main>
    </AppShell>
  );
};
