export const AssignAndPlanIcon = ({ size }: { size: number }) => (
  <svg height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
    <path d="M19 3C20.1 3 21 3.9 21 5V8H10V3H19Z" fill="white" fillOpacity={0.85} />
    <path d="M5 21C3.9 21 3 20.1 3 19V10H8V21H5Z" fill="white" fillOpacity={0.85} />
    <path d="M3 5V8H8V3H5C3.9 3 3 3.9 3 5Z" fill="white" fillOpacity={0.85} />
    <path
      d="M14.86 12.1398L17.65 9.34977C17.84 9.15977 18.16 9.15977 18.35 9.35977L21.14 12.1498C21.46 12.4598 21.24 12.9998 20.79 12.9998H19V14.9998C19 17.2098 17.21 18.9998 15 18.9998H13V20.7998C13 21.2398 12.46 21.4598 12.15 21.1498L9.35996 18.3598C9.15996 18.1598 9.15996 17.8498 9.35996 17.6498L12.15 14.8598C12.46 14.5398 13 14.7598 13 15.2098V16.9998H15C16.1 16.9998 17 16.0998 17 14.9998V12.9998H15.21C14.76 12.9998 14.54 12.4598 14.86 12.1398Z"
      fill="white"
      fillOpacity={0.85}
    />
  </svg>
);

export const AssignNowIcon = ({ size }: { size: number }) => (
  <svg height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21,8v-3c0-1.1-.9-2-2-2h-4.2c-.4-1.2-1.5-2-2.8-2s-2.4.8-2.8,2h-4.2c-.1,0-.3,0-.4,0-.4,0-.7.3-1,.6-.2.2-.3.4-.4.6,0,.2-.2.5-.2.8v14c0,.3,0,.5.2.8.1.2.2.4.4.6.3.3.6.5,1,.5.1,0,.3,0,.4,0h4.6v-2h-4.6V5h14v3M12,4.2c-.4,0-.8-.3-.8-.8s.3-.8.8-.8.8.3.8.8-.3.8-.8.8Z"
      fill="white"
      fillOpacity={0.85}
    />
    <path
      d="M18,14.8h2c.5,0,1,.4,1,1s-.5,1-1,1h-2v2c0,.5-.5,1-1,1s-1-.5-1-1v-2h-2c-.6,0-1-.5-1-1s.4-1,1-1h2v-2c0-.6.5-1,1-1s1,.4,1,1v2Z"
      fill="white"
      fillOpacity={0.85}
    />
    <path d="M21,10" fill="white" fillOpacity={0.85} />
  </svg>
);
