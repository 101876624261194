import { useCallback, ReactNode, RefObject, useState } from 'react';
import { useGetSearchCategory, useUpdateSearchCategory } from 'src/apis/searchAPI/categoriesAPI';
import { ISearchCategoryItem } from 'src/apis/types/searchCategoriesAPI';
import { Heading } from 'src/components/ui-components/Heading';
import { Icon } from 'src/components/ui-components/Icon';
import { Stack } from 'src/components/ui-components/Stack';
import { useTranslation } from 'react-i18next';
import {
  useIsCategoryFilterOpen,
  useMainSearchDispatch,
  useCategoriesList,
} from 'src/stores/MainSearchStore';
import { translationAnyText } from 'src/utils/translation';
import cx from 'classnames';
import { getKeyboardFocusableElements } from 'src/utils/accessibility';
import styles from './SearchCategoryPanel.module.scss';
import {
  onKeyDown,
  useDefaultCategoryItemFocus,
  useResetActiveIndex,
} from './SearchCategoryPanelHookHelper';

const icons: Record<ISearchCategoryItem['name'], ReactNode> = {
  Customer: <Icon iconName="customer" size="small" />,
  Invoice: <Icon iconName="finance" size="small" />,
  Employee: <Icon iconName="employee" size="small" />,
  Project: <Icon iconName="project" size="small" />,
  Contact: <Icon iconName="contact" size="small" />,
};

const updateCategoryList = (
  categoryList: ISearchCategoryItem[],
  targetName: string,
  targetSelectedStatus: boolean,
): ISearchCategoryItem[] =>
  categoryList?.map(({ name, isSelected }) =>
    targetName === name ? { name, isSelected: targetSelectedStatus } : { name, isSelected },
  );

export const updateCategoryListForTest = updateCategoryList;

const SearchCategorySearchBox = ({ name, isSelected }: ISearchCategoryItem) => {
  const { t } = useTranslation('header');
  const categoryList = useCategoriesList();
  const { mutate: searchCategoryAPIUpdate } = useUpdateSearchCategory();
  const searchStoreDispatch = useMainSearchDispatch();

  const onSelectChange = useCallback(() => {
    if (searchStoreDispatch) {
      const latestList = updateCategoryList(categoryList, name, !isSelected);
      searchStoreDispatch({ type: 'UPDATE_CATEGORY_LIST', payload: latestList });
      searchCategoryAPIUpdate(latestList);
    }
  }, [name, searchStoreDispatch, categoryList, searchCategoryAPIUpdate, isSelected]);

  return (
    <button
      type="button"
      id={`SearchCategory${name}`}
      data-automation-id={`SearchCategory${name}`}
      className={cx(
        styles.SearchFilterCategoryItem,
        {
          [styles.SearchFilterhCustomersCategoryItem]: name === 'Customer',
        },
        {
          ThemePurple: name === 'Project',
          ThemeGreen: name === 'Contact',
          ThemeRed: name === 'Employee',
          ThemeOrange: name === 'Invoice',
        },
      )}
      onClick={onSelectChange}
      aria-pressed={isSelected}
    >
      <span className={styles.Checkmark} data-automation-id={`SearchCategoryCheckbox${name}`}>
        <Icon iconName="check" />
      </span>
      <span className={styles.SearchFilterCategoryItemInner}>
        {icons[name]}
        <span>{translationAnyText(t, `SearchCategory${name}sLabel`)}</span>
      </span>
    </button>
  );
};

interface SearchCategoryPanelProps {
  searchFilterCategoriesRef: RefObject<HTMLDivElement>;
  focusInput?: () => void;
}

const reloadKeyboardSelectedElement = (selectedElement: Element) => {
  if (selectedElement) {
    (selectedElement as HTMLElement)?.focus();
  }
};

export default ({ searchFilterCategoriesRef, focusInput }: SearchCategoryPanelProps) => {
  const { t } = useTranslation('header');
  const isSearchCategoryPanelOpen = useIsCategoryFilterOpen();
  const { isSearchCategoryListEmpty } = useGetSearchCategory();
  const categoryList = useCategoriesList();
  const [activeIndex, setActiveIndex] = useState(0);

  useResetActiveIndex(isSearchCategoryPanelOpen, setActiveIndex);
  useDefaultCategoryItemFocus(isSearchCategoryPanelOpen, searchFilterCategoriesRef);

  const handleKeyDown = (event: any) => {
    const focusableElementsCount = getKeyboardFocusableElements(
      searchFilterCategoriesRef?.current,
    ).length;
    onKeyDown({
      focusableElementsCount,
      activeIndex,
      setActiveIndex,
      event,
      focusInput,
    });
  };

  if (isSearchCategoryPanelOpen && searchFilterCategoriesRef)
    reloadKeyboardSelectedElement(
      getKeyboardFocusableElements(searchFilterCategoriesRef?.current)?.[activeIndex],
    );

  return !isSearchCategoryListEmpty && isSearchCategoryPanelOpen ? (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions
    <div
      data-automation-id="MainSearchCategoryPanel"
      id="MainSearchCategoryFilter"
      className={styles.SearchCategories}
      ref={searchFilterCategoriesRef}
      onKeyDown={handleKeyDown}
      tabIndex={-1}
    >
      <Stack verticalMargin="medium">
        <Heading level={2} as={3} medium>
          {t('SearchCategoryFilterHeading')}
        </Heading>
        <div>
          <ul className={styles.SearchCategoriesList}>
            {categoryList?.map(({ name, isSelected }) => (
              <li key={`search-category-${name}`}>
                <SearchCategorySearchBox name={name} isSelected={isSelected} />
              </li>
            ))}
          </ul>
        </div>
      </Stack>
    </div>
  ) : null;
};
