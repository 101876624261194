import { useCommands } from '@remirror/react';
import { FileInput } from 'src/components/ui-components/FileInput';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMAGE_FILE_SIZE_MAX } from 'src/constants/richTextEditor';
import { Button, Dialog, DialogActions, DialogTitle } from 'src/components/mui-components';
import { formatBytes } from 'src/utils/formatBytes';

export const InsertImage = () => {
  const commands = useCommands();
  const [isImageTooBig, setIsImageTooBig] = useState(false);
  const [isNotImageFile, setIsNotImageFile] = useState(false);

  const { t } = useTranslation('richTextEditor');

  const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setIsNotImageFile(true);
      e.target.value = '';
      return;
    }
    if (file.size > IMAGE_FILE_SIZE_MAX) {
      setIsImageTooBig(true);
      e.target.value = '';
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const image = new Image();
      image.onload = () => {
        const dataUrl = event.target?.result as string;
        commands.insertImage({
          src: dataUrl,
        });
        commands.focus();
      };
      image.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);

    e.target.value = '';
  };

  return (
    <>
      <FileInput
        data-automation-id="btnFIinsertImageFile"
        onChange={handleImageFileChange}
        iconButtonProps={{ title: t('InsertImageLabel') }}
      />
      <Dialog open={isNotImageFile} onClose={() => setIsNotImageFile(false)}>
        <DialogTitle>{t('NotAnImageFileLabel')}</DialogTitle>

        <DialogActions>
          <Button
            data-automation-id="btngCancelNotAnImageFile"
            onClick={() => setIsNotImageFile(false)}
            size="small"
          >
            {t('DialogCancelButtonText')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isImageTooBig} onClose={() => setIsImageTooBig(false)}>
        <DialogTitle>
          {t('ImageFileIsTooBigLabel', { SIZE: formatBytes(IMAGE_FILE_SIZE_MAX) })}
        </DialogTitle>

        <DialogActions>
          <Button
            data-automation-id="btngCancelImageTooBig"
            onClick={() => setIsImageTooBig(false)}
            size="small"
          >
            {t('DialogCancelButtonText')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
