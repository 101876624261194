import { Box, Divider, ListSubheader, MenuItem, Stack } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridColDef,
  GridCsvExportMenuItem,
  GridToolbarExportContainer,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useGetProjectActions } from 'src/apis/projectsAPI';
import { Button, Typography, Select } from 'src/components/mui-components';
import { GridExcelExportMenuItem } from 'src/components/mui-components/DataGrid/utils';
import { Dispatch, SetStateAction } from 'react';
import { useExcelConfig } from './config';

interface ISelectedAction {
  value: string;
  body?: { [key: string]: string | boolean };
  uri?: string;
}
interface IToolbarProps {
  selectedRowProject: string;
  columns: GridColDef[];
  handleApplyClick: () => void;
  selectedAction: ISelectedAction;
  setSelectedAction: Dispatch<SetStateAction<ISelectedAction>>;
  setColumnsButtonEl: Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  rowsCount: number;
}

interface ActionsSelectionProps {
  selectedAction: ISelectedAction;
  setSelectedAction: Dispatch<SetStateAction<ISelectedAction>>;
}

const ActionsSelection = ({ selectedAction, setSelectedAction }: ActionsSelectionProps) => {
  const { t } = useTranslation('searchProjects');
  const { actions } = useGetProjectActions();

  return (
    <Box sx={{ minWidth: 120, ml: 2 }}>
      <Select
        defaultValue="0"
        id="grouped-select"
        label="Actions"
        fullWidth
        size="small"
        value={selectedAction.value}
        MenuProps={{
          PaperProps: {
            sx: {
              '.MuiMenuItem-root span': {
                marginLeft: 2,
              },
            },
          },
        }}
        data-automation-id="ActionsSelectionSelect"
      >
        <MenuItem
          value="0"
          onClick={() =>
            setSelectedAction({
              value: '0',
            })
          }
        >
          {t('DropdownSelectActionText')}
        </MenuItem>
        {actions.map((action) => {
          if (action?.actions) {
            // Grouped actions with subheader
            return [
              <ListSubheader key={`header-${action.name}`}>
                <Typography color="primary">{t(`Dropdown${action.name}Text`)}</Typography>
              </ListSubheader>,
              ...action.actions.map(
                (subAction: {
                  name: string;
                  body?: { [key: string]: string | boolean };
                  uri?: string;
                }) => {
                  let displayText;
                  if (subAction.uri === 'status') {
                    displayText = t(`Status${subAction.name}Text`);
                  } else if (subAction.uri === 'resource-planner') {
                    displayText = t(`Dropdown${subAction.name}Text`);
                  } else {
                    displayText = subAction.name;
                  }

                  return (
                    <MenuItem
                      key={subAction.name}
                      value={`${subAction.name}`}
                      onClick={() =>
                        setSelectedAction({
                          value: `${subAction.name}`,
                          body: subAction.body,
                          uri: subAction.uri,
                        })
                      }
                    >
                      <span>{displayText}</span>
                    </MenuItem>
                  );
                },
              ),
            ];
          }

          return (
            <MenuItem
              key={action.name}
              value={action.name}
              onClick={() =>
                setSelectedAction({
                  value: action.name,
                })
              }
            >
              {t(`Dropdown${action.name}Text`)}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

export const Toolbar = ({
  selectedRowProject,
  columns,
  handleApplyClick,
  selectedAction,
  setSelectedAction,
  setColumnsButtonEl,
  rowsCount,
}: IToolbarProps) => {
  const { t } = useTranslation('searchProjects');

  return (
    <GridToolbarContainer>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Stack
          direction="row"
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Typography sx={{ mr: 2 }}>
            <strong>
              {t('HeaderTitle')} ({rowsCount})
            </strong>
          </Typography>
          <>
            <ActionsSelection
              selectedAction={selectedAction}
              setSelectedAction={setSelectedAction}
            />
            <Button
              variant="text"
              disabled={selectedRowProject?.length <= 0 || selectedAction.value === '0'}
              onClick={() => handleApplyClick()}
            >
              {t('ActionApplyButton')}
            </Button>
          </>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <GridToolbarColumnsButton ref={setColumnsButtonEl} />
          <GridToolbarDensitySelector />
          <GridToolbarExportContainer>
            <GridCsvExportMenuItem options={{ disableToolbarButton: true }} />
            <GridExcelExportMenuItem columns={columns} config={useExcelConfig()} />
          </GridToolbarExportContainer>
        </Stack>
      </Box>
    </GridToolbarContainer>
  );
};
