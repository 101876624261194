import { Clear, InfoOutlined, Search } from '@mui/icons-material';
import {
  Box,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Switch,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  IProjectSearchResProperties,
  IUnallocatedTasksResProperties,
  useGetRecentProjects,
  useGetRecentTasks,
  useGetUnallocatedTasks,
  usePostProjectSearch,
} from 'src/apis/resourcePlannerAPI';
import elements from 'src/assets/styles/variables/colors/global/elements.module.scss';
import grays from 'src/assets/styles/variables/colors/global/grays.module.scss';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from 'src/components/mui-components';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { useAssignFlowStore } from 'src/stores/ResourcePlannerStore/AssignFlowStore';
import { getDateStringFromSiteLocale } from 'src/utils/date';
import { useDebounce } from 'use-debounce';
import { useGetFlattenedFilterItems, useKeyboardControl } from '../../hooks';
import { AssignDialog } from '../AssignDialog';
import { FilterItem } from '../FilterItems/FilterItem';
import { defaultFilters } from './SearchForTaskDialog.constants';
import styles from './SearchForTaskDialog.module.scss';
import { ETaskFilterNames, TTaskDefaultFilters } from './SearchForTaskDialog.types';

interface ISearchForTaskDialog {
  dialogIsOpen: boolean;
  setDialogIsOpen: (isOpen: boolean) => void;
}

export const SearchForTaskDialog = ({ dialogIsOpen, setDialogIsOpen }: ISearchForTaskDialog) => {
  const { t } = useTranslation('assignFlow');
  const siteLocale = useGetLocale();
  const { name, resourceSourceReferenceId } = useAssignFlowStore();

  const taskFilterItems = useGetFlattenedFilterItems(ETaskFilterNames);
  const showDepartmentFilter = !!taskFilterItems.find(
    ({ filterName }) => filterName === 'ProjectDepartment',
  )?.id;
  const showLegalEntityFilter = !!taskFilterItems.find(
    ({ filterName }) => filterName === 'LegalEntityProject',
  )?.id;

  const [projectQuery, setProjectQuery] = useState('');
  const [debouncedProjectQuery] = useDebounce(projectQuery, 500);
  const [selectedFilters, setSelectedFilters] =
    useState<Partial<TTaskDefaultFilters>>(defaultFilters);
  const [selectedProject, setSelectedProject] = useState<IProjectSearchResProperties>();

  const filtersIsActive =
    Object.values(selectedFilters).some((i) => !!i.values.length) || !!debouncedProjectQuery;

  const [taskQuery, setTaskQuery] = useState('');
  const [debouncedTaskQuery] = useDebounce(taskQuery, 500);
  const [showParentTasks, setShowParentTasks] = useState(false);

  const { data: recentProjects, isFetching: recentProjectsIsFetching } =
    useGetRecentProjects(dialogIsOpen);

  const {
    data: projectSearch,
    isError: projectSearchIsError,
    isFetching: projectSearchIsFetching,
  } = usePostProjectSearch(
    {
      customer: selectedFilters.Customer,
      department: selectedFilters.ProjectDepartment,
      legalEntity: selectedFilters.LegalEntityProject,
      projectManager: selectedFilters.ProjectManager,
      projectType: selectedFilters.ProjectType,
      query: debouncedProjectQuery,
    },
    dialogIsOpen && filtersIsActive,
  );

  const projectListFetching = recentProjectsIsFetching || projectSearchIsFetching;
  const projectList =
    ((filtersIsActive ? projectSearch : recentProjects) ?? []).map(({ properties }) => ({
      ...properties,
    })) ?? [];

  const { data: recentTasks, isFetching: recentTasksIsFetching } = useGetRecentTasks(
    {
      projectId: selectedProject?.projectID ?? '',
      userId: resourceSourceReferenceId,
    },
    dialogIsOpen,
  );

  const {
    data: taskListData,
    isError: taskListIsError,
    isFetching: taskListIsFetching,
  } = useGetUnallocatedTasks(
    {
      isIncludeParentTask: showParentTasks,
      projectId: selectedProject?.projectID ?? '',
      searchText: debouncedTaskQuery,
      userId: resourceSourceReferenceId,
    },
    dialogIsOpen && !!debouncedTaskQuery,
  );

  const taskListFetching = recentTasksIsFetching || taskListIsFetching;
  const taskList = ((debouncedTaskQuery ? taskListData : recentTasks) ?? [])
    .map(({ properties }) => ({ ...properties }))
    .filter((task) => !task.isParent);

  const showQueryClearButton = selectedProject ? !!taskQuery : !!projectQuery;

  const cleanUp = () => {
    setDialogIsOpen(false);
    setProjectQuery('');
    setSelectedFilters(defaultFilters);
    setSelectedProject(undefined);
    setTaskQuery('');
    setShowParentTasks(false);
  };

  const dialogOnClose = () => {
    cleanUp();
  };

  const projectOnClick = (p: IProjectSearchResProperties) => {
    const selected = projectList.find((i) => i.projectID === p?.projectID);
    setSelectedProject(selected);
  };

  const getParentTaskName = ({ taskFullName, taskName }: IUnallocatedTasksResProperties) =>
    taskFullName.replace(taskName, '');

  const {
    dialogOnKeyDown,
    initialFocusElement,
    searchContainerResults,
    selectItemIndex,
    setSelectItemIndex,
    textFieldOnKeyDown,
  } = useKeyboardControl({
    backAction: () => setSelectedProject(undefined),
  });

  useEffect(() => {
    initialFocusElement.current?.select();
  }, [initialFocusElement, selectedProject]);

  useEffect(() => {
    if (projectListFetching || taskListFetching) {
      return;
    }
    setSelectItemIndex(-1);
  }, [projectListFetching, setSelectItemIndex, taskListFetching]);

  return (
    <Dialog
      data-automation-id="SearchForTaskDialog"
      fullWidth
      maxWidth="md"
      onClose={dialogOnClose}
      onKeyDown={dialogOnKeyDown}
      open={dialogIsOpen}
      PaperProps={{ sx: { overflowY: 'unset' } }}
    >
      <DialogTitle component={Box} data-automation-id="SearchForTaskDialogTitle">
        <Stack gap={2}>
          <Typography lineHeight={1.6} variant="h2">
            <Trans
              i18nKey="AssignEmployeeToTaskHeader"
              key="AssignEmployeeToTaskHeader"
              defaults={t('AssignEmployeeToTaskHeader')}
              values={{ employee: name, projectName: selectedProject?.projectName }}
              components={[
                <strong key={0}>{name}</strong>,
                <strong key={1}>{selectedProject?.projectName}</strong>,
              ]}
            />
          </Typography>
          <Stack alignItems="center" direction="row" gap={2}>
            <TextField
              ariaLabel={t(
                !selectedProject ? 'SearchForProjectsInputLabel' : 'SearchForTasksInputLabel',
              )}
              data-automation-id="SuperSearchForTasks"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showQueryClearButton ? (
                      <IconButton
                        data-automation-id="SearchForTasksInputClearButton"
                        onClick={() => (selectedProject ? setTaskQuery('') : setProjectQuery(''))}
                        size="small"
                        title={t('ClearSearchButtonText')}
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    ) : null}
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
                inputProps: { autoFocus: true },
              }}
              inputRef={initialFocusElement}
              label={t(
                !selectedProject ? 'SearchForProjectsInputLabel' : 'SearchForTasksInputLabel',
              )}
              onChange={(e) =>
                selectedProject ? setTaskQuery(e.target.value) : setProjectQuery(e.target.value)
              }
              onKeyDown={textFieldOnKeyDown}
              value={!selectedProject ? projectQuery : taskQuery}
            />
            <Tooltip
              tabIndex={0}
              title={
                <Stack>
                  <Typography component="h3" fontWeight={700} lineHeight={1.5} variant="h4">
                    {selectedProject
                      ? t('SearchForTasksTooltipHeading')
                      : t('SearchForProjectsTooltipHeading')}
                  </Typography>
                  <Typography variant="small" sx={{ whiteSpace: 'pre-line' }}>
                    {selectedProject ? t('SearchForTasksTooltip') : t('SearchForProjectsTooltip')}
                  </Typography>
                </Stack>
              }
            >
              <InfoOutlined />
            </Tooltip>
          </Stack>
          {!selectedProject ? (
            <Stack direction="row" flex={1} flexWrap="wrap">
              {taskFilterItems.map((i) => (
                <FilterItem
                  {...i}
                  data-automation-id={`FilterItem-${i.filterName}`}
                  filterName={i.filterName}
                  key={String(i.id)}
                  onChange={(v) => {
                    setSelectedFilters((prev) => ({
                      ...prev,
                      [i.filterName]: v,
                    }));
                  }}
                />
              ))}
            </Stack>
          ) : (
            <FormControlLabel
              control={
                <Switch
                  onChange={(_, checked) => setShowParentTasks(checked)}
                  value={showParentTasks}
                />
              }
              label={t('ShowWBStructureLabel')}
            />
          )}
        </Stack>
      </DialogTitle>
      <DialogContent dividers onMouseEnter={() => setSelectItemIndex(-1)} sx={{ pt: 2, zIndex: 0 }}>
        {!selectedProject ? (
          <ResponseHandler
            EmptyComponent={<Typography textAlign="center">{t('NoResults')}</Typography>}
            isEmpty={!projectList.length}
            isError={projectSearchIsError}
            isLoading={projectListFetching}
            LoadingComponent={<CircularProgress />}
          >
            <List className={styles.list} ref={searchContainerResults}>
              {projectList.map((p, i) => (
                <ListItem
                  data-automation-id={`ProjectItem-${p.projectID}`}
                  disablePadding
                  key={p.projectID}
                  onClick={() => projectOnClick(p)}
                >
                  <ListItemButton
                    role="button"
                    sx={{
                      backgroundColor: selectItemIndex === i ? 'rgba(0, 0, 0, 0.04)' : '',
                      borderBlockEnd: `1px solid ${elements.colorBorder}`,
                      px: 1.5,
                      py: 1,
                    }}
                  >
                    <Stack flex={1} gap={0}>
                      <Box>
                        <HighlightMatchingText matchName={projectQuery} name={p.projectName} />
                        {p.projectNo ? (
                          <HighlightMatchingText matchName={taskQuery} name={` (${p.projectNo})`} />
                        ) : null}
                      </Box>
                      <Typography variant="small">
                        <HighlightMatchingText matchName={projectQuery} name={p.customerName} />
                        {p.customerNo ? (
                          <HighlightMatchingText
                            matchName={taskQuery}
                            name={` (${p.customerNo})`}
                          />
                        ) : null}
                      </Typography>
                      <Stack direction="row" flexWrap="wrap">
                        <Typography className={styles.separatedLabels} variant="small">
                          {showDepartmentFilter && p.legalEntityName ? (
                            <HighlightMatchingText
                              matchName={projectQuery}
                              name={p.legalEntityName}
                            />
                          ) : null}
                          {showLegalEntityFilter && p.departmentName ? (
                            <HighlightMatchingText
                              matchName={projectQuery}
                              name={p.departmentName}
                            />
                          ) : null}
                          {p.projectTypeName ? (
                            <HighlightMatchingText
                              matchName={projectQuery}
                              name={p.projectTypeName}
                            />
                          ) : null}
                        </Typography>
                      </Stack>
                    </Stack>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </ResponseHandler>
        ) : (
          <ResponseHandler
            EmptyComponent={<Typography textAlign="center">{t('NoResults')}</Typography>}
            isEmpty={!taskList.length}
            isError={taskListIsError}
            isLoading={taskListFetching}
            LoadingComponent={<CircularProgress />}
          >
            <List className={styles.list} ref={searchContainerResults}>
              {taskList?.map((task, i) => (
                <ListItem
                  data-automation-id={`TaskItem-${task.taskID}`}
                  disablePadding
                  key={task.taskID}
                  sx={{ position: 'relative' }}
                >
                  <AssignDialog
                    dates={[new Date(task.taskStartDate), new Date(task.taskEndDate)]}
                    from={name}
                    projectId={selectedProject.projectID}
                    selected={selectItemIndex === i}
                    showDepartmentFilter={showDepartmentFilter}
                    showLegalEntityFilter={showLegalEntityFilter}
                    taskId={task.taskID}
                    to={task.taskName}
                    userId={resourceSourceReferenceId}
                  >
                    <Box className={styles.taskItem}>
                      <HighlightMatchingText matchName={taskQuery} name={task.wBSNo} />
                      <Stack gap={0.25}>
                        <Box>
                          <Typography color={grays.colorGray55} component="span">
                            {showParentTasks ? (
                              <HighlightMatchingText
                                matchName={taskQuery}
                                name={getParentTaskName(task)}
                              />
                            ) : null}
                          </Typography>
                          <HighlightMatchingText matchName={taskQuery} name={task.taskName} />
                          {task.taskNo ? (
                            <HighlightMatchingText
                              matchName={taskQuery}
                              name={` (${task.taskNo})`}
                            />
                          ) : null}
                        </Box>
                        <Stack direction="row" flexWrap="wrap">
                          <Typography className={styles.separatedLabels} variant="small">
                            <span>
                              {t('TaskSchedule', {
                                startDate: getDateStringFromSiteLocale(
                                  new Date(task.taskStartDate),
                                  siteLocale,
                                ),
                                endDate: getDateStringFromSiteLocale(
                                  new Date(task.taskEndDate),
                                  siteLocale,
                                ),
                              })}
                            </span>
                            <span>{t('TaskBudget', { budget: task.taskBudgetHour ?? 0 })}</span>
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </AssignDialog>
                </ListItem>
              ))}
            </List>
          </ResponseHandler>
        )}
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          flex={1}
          justifyContent={selectedProject ? 'space-between' : 'flex-end'}
        >
          {selectedProject ? (
            <Button data-automation-id="BackButton" onClick={() => setSelectedProject(undefined)}>
              {t('BackButtonText')}
            </Button>
          ) : null}
          <Button data-automation-id="CancelButton" onClick={cleanUp} variant="outlined">
            {t('CancelButtonText')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
