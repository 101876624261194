import { Routes, Route } from 'react-router-dom';
import ScreenHeader from 'src/components/layout/ScreenHeader';
import { RevenueForecast } from 'src/screens/RevenueForecast';
import Report from 'src/screens/Report';
import { designSevenPagePath } from 'src/designSevenPagePath';
import LegacyPage from 'src/components/layout/LegacyPage';
import PageRoute from '../PageRoute';
import LegacyRoute from '../LegacyRoute';

export const ReportsRouter = () => (
  <Routes>
    <Route
      path={designSevenPagePath.reports.revenueForecast}
      element={
        <>
          <ScreenHeader />
          <PageRoute
            pageTitle="Revenue forecast"
            pageId="29a0ea59-edfb-4539-8a6e-eea04e0dfe07"
            pageIdentifier="RevenueForecast"
          >
            <RevenueForecast />
          </PageRoute>
        </>
      }
    />

    <Route
      path={designSevenPagePath.reports.exampleReport}
      element={
        <>
          <ScreenHeader />
          <PageRoute
            pageTitle="Reports"
            pageId="5f11ec8e-27f0-47b2-9bb2-667ed9f4800c"
            pageIdentifier="DesignSevenExampleReport"
          >
            <Report />
          </PageRoute>
        </>
      }
    />

    <Route
      path="*"
      element={
        <LegacyRoute>
          <LegacyPage data-automation-id="LegacyPage" />
        </LegacyRoute>
      }
    />
  </Routes>
);
