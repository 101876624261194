import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePageDispatch } from 'src/stores/PageStore';
import LegacyPage from 'src/components/layout/LegacyPage';
import { useVerifyDesignSevenPage } from 'src/apis/pagesAPI/designSevenAPI';
import LoadingSpinner from 'src/components/ui-components/LoadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';

interface PageRouteProps {
  pageId: string;
  pageIdentifier: string;
  pageTitle: string;
  children: JSX.Element;
  fallbackUrl?: string;
}

export default ({ children, pageIdentifier, pageId, pageTitle, fallbackUrl }: PageRouteProps) => {
  const { isDesignSeven, isLoading } = useVerifyDesignSevenPage(pageIdentifier);
  const pageDispatch = usePageDispatch();
  const { pathname, search } = useLocation();
  const { t } = useTranslation('screenHeaderTitle');
  const navigate = useNavigate();

  useEffect(() => {
    if (pageDispatch) {
      pageDispatch({
        type: 'SET_CURRENT_PAGE',
        payload: { pageIdentifier, pageId, pageTitle: translationAnyText(t, pageTitle) },
      });
      pageDispatch({
        type: 'SET_LAST_VISITED_PAGE',
        payload: { pagePath: pathname + search, pageIdentifier },
      });
    }
  });

  // Fallback to specific page if not design seven
  useEffect(() => {
    if (!isLoading && !isDesignSeven && fallbackUrl) {
      navigate(fallbackUrl);
    }
  }, [isLoading, isDesignSeven, navigate, fallbackUrl]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return isDesignSeven ? children : <LegacyPage data-automation-id="FallBackLegacyPage" />;
};
