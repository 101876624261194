import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TIconName } from 'src/assets/variables/icon';
import { IconButton } from 'src/components/mui-components';
import { Icon } from 'src/components/ui-components';
import { AssignFlowStore, IAssignFlowValue } from 'src/stores/ResourcePlannerStore/AssignFlowStore';
import { AssignFlowHideTooltipKey } from '../../localStorageKeys';
import { RemoveTaskOrResourceDialog } from './components/RemoveTaskOrResourceDialog';
import { SearchForResourceDialog } from './components/SearchForResourceDialog';
import { SearchForTaskDialog } from './components/SearchForTaskDialog';

interface IAssignFlow {
  canAssign: boolean;
  canDelete: boolean;
  isWorkItem: boolean;
  isTask: boolean;
  value: IAssignFlowValue;
}

export const AssignFlow = ({ canAssign, canDelete, isTask, isWorkItem, value }: IAssignFlow) => {
  const { t } = useTranslation('assignFlow');

  const assignFlowTooltipKey = localStorage.getItem(AssignFlowHideTooltipKey);
  const [hideTooltip, setHideTooltip] = useState(assignFlowTooltipKey === 'true');
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const itemId = isWorkItem ? value.workItemSourceReferenceId : value.resourceSourceReferenceId;

  const actionIcon: {
    iconName: TIconName;
    id: string;
    label: string;
    action: () => void;
  } = useMemo(() => {
    if (canDelete) {
      return {
        iconName: isWorkItem ? 'inventoryMinus' : 'personOutlineMinus',
        id: 'RemoveTaskOrEmployee',
        label: isWorkItem ? t('RemoveTask') : t('RemoveEmployee'),
        action: () => setDialogIsOpen(true),
      };
    }

    if (isTask) {
      return {
        iconName: 'personOutlinePlus',
        id: 'AssignTaskToEmployee',
        label: isWorkItem ? t('AssignToEmployee') : t('AssignToTask'),
        action: () => setDialogIsOpen(true),
      };
    }

    // isEmployee
    return {
      iconName: 'inventoryPlus',
      id: 'AssignEmployeeToTask',
      label: isWorkItem ? t('AssignToEmployee') : t('AssignToTask'),
      action: () => setDialogIsOpen(true),
    };
  }, [canDelete, isTask, isWorkItem, t]);

  const dialog = useMemo(() => {
    const dialogProps = { dialogIsOpen, setDialogIsOpen };

    if (canDelete) {
      return <RemoveTaskOrResourceDialog {...dialogProps} />;
    }

    if (isTask) {
      return <SearchForResourceDialog {...dialogProps} />;
    }

    // isEmployee
    return <SearchForTaskDialog {...dialogProps} />;
  }, [canDelete, dialogIsOpen, isTask]);

  useEffect(() => {
    localStorage.setItem(AssignFlowHideTooltipKey, String(hideTooltip));
  }, [dialogIsOpen, hideTooltip]);

  return (
    <AssignFlowStore value={{ ...value, hideTooltip, setHideTooltip }}>
      {canAssign || canDelete ? (
        <>
          <IconButton
            color="inherit"
            data-automation-id={`${actionIcon.id}IconButton-${itemId}`}
            onClick={actionIcon.action}
            size="small"
            title={actionIcon.label}
          >
            <Icon iconName={actionIcon.iconName} />
          </IconButton>
          {dialog}
        </>
      ) : null}
    </AssignFlowStore>
  );
};
