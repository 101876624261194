import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFetch } from 'src/utils/fetcher';
import { GET_COMPETENCES_KEY } from 'src/apis/competencesAPI/types';
import { COMPETENCE_GROUPS_URL_PATH, GET_COMPETENCE_GROUPS_KEY } from '../types';

export const DELETE_COMPETENCE_GROUPS_MSW_STATUS_KEY = 'deleteCompetenceGroups';

export const DELETE_COMPETENCE_GROUPS_KEY = 'DELETE_COMPETENCES';

const deleteCompetenceGroups = (itemId?: number) =>
  deleteFetch({
    path: COMPETENCE_GROUPS_URL_PATH,
    key: DELETE_COMPETENCE_GROUPS_MSW_STATUS_KEY,
    itemId: `/${itemId}`,
  });

export const useDeleteCompetenceGroups = () => {
  const qc = useQueryClient();

  return useMutation(deleteCompetenceGroups, {
    onSuccess: () => {
      qc.invalidateQueries([GET_COMPETENCE_GROUPS_KEY]);
      qc.invalidateQueries([GET_COMPETENCES_KEY]);
    },
  });
};
