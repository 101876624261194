import { useMutation } from '@tanstack/react-query';
import { postFetch } from 'src/utils/fetcher';
import { concatQuerys, formatUrl } from 'src/utils/url';

const POST_CREATE_WORK_ITEM_RELATIONSHIP_URL_PATH =
  '/api/v2/resource-planner/resync-data-on-allocation';

export const POST_CREATE_WORK_ITEM_RELATIONSHIP_MSW_STATUS_KEY = 'ResyncDataOnAllocation';

export default () => {
  const path = formatUrl(POST_CREATE_WORK_ITEM_RELATIONSHIP_URL_PATH);
  const postResyncDataOnAllocation = ({
    workItemSourceReferenceId,
    workItemType,
    resourceSourceReferenceId,
  }: {
    workItemSourceReferenceId: string;
    workItemType: string;
    resourceSourceReferenceId: string;
  }) =>
    postFetch({
      path: `${path}${concatQuerys([
        {
          param: 'workItemSourceReferenceId',
          value: workItemSourceReferenceId,
        },
        {
          param: 'workItemType',
          value: workItemType,
        },
        { param: 'ResourceSourceReferenceId', value: resourceSourceReferenceId },
      ])}`,
      key: POST_CREATE_WORK_ITEM_RELATIONSHIP_MSW_STATUS_KEY,
    });

  return useMutation(postResyncDataOnAllocation, {
    onMutate: async (variables) => variables,
  });
};
