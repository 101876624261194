import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IFeatureResult } from '../types';
import { GET_FEATURES_KEY } from '../const';

const GET_FEATURES_RESOURCE_MANAGEMENT_URL_PATH = '/api/v2/features/resource-management';
export const GET_FEATURES_RESOURCE_MANAGEMENT_MSW_STATUS_KEY = 'getResourceManagementFeatureAPI';

const getResourceManagementFeature = (): Promise<IFeatureResult> =>
  getFetch({
    path: GET_FEATURES_RESOURCE_MANAGEMENT_URL_PATH,
    key: GET_FEATURES_RESOURCE_MANAGEMENT_MSW_STATUS_KEY,
  });

export const useGetResourceManagementFeature = () => {
  const { data, ...rest } = useQuery([GET_FEATURES_KEY], getResourceManagementFeature);

  const featureData = data?.properties ? data.properties?.featureList : [];

  return {
    data: featureData,
    ...rest,
  };
};
