import { useGetFirstName } from 'src/apis/usersAPI/meAPI';
import { Shimmer } from 'src/components/ui-components';
import { Stack, Typography } from 'src/components/mui-components';
import { useGreeting } from './helpers/useGreeting';
import styles from './NameArea.module.scss';

export default () => {
  const { firstName, isLoading } = useGetFirstName();

  return (
    <Typography variant="h1">
      <Stack component="span" direction="row" flexWrap="wrap">
        {useGreeting(isLoading)}
        {isLoading ? (
          <Shimmer minHeight="display" isInlineBlock className={styles.nameShimmer} />
        ) : (
          <Typography
            fontWeight={700}
            variant="h1"
            component="span"
            data-automation-id="DashboardFirstName"
            noWrap
          >
            {firstName}
          </Typography>
        )}
      </Stack>
    </Typography>
  );
};
