export const competenceGroupsFullResult = {
  Entities: [
    {
      Properties: {
        GroupId: 1,
        GroupName: 'Language Skills',
        CanBeDeleted: false,
      },
    },
    {
      Properties: {
        GroupId: 2,
        GroupName: 'Project Management',
        CanBeDeleted: false,
      },
    },
    {
      Properties: {
        GroupId: 3,
        GroupName: 'Development',
        CanBeDeleted: false,
      },
    },
    {
      Properties: {
        GroupId: 4,
        GroupName: 'Change Management Tools',
        CanBeDeleted: false,
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost:3000/api/v2/competence-groups',
      Rel: 'self',
    },
  ],
};
