import {
  Alert as MuiAlert,
  AlertProps as MuiAlertProps,
  AlertTitle as MuiAlertTitle,
  AlertTitleProps as MuiAlertTitleProps,
} from '@mui/material';
import { forwardRef } from 'react';

export const Alert = forwardRef<HTMLDivElement, MuiAlertProps>(({ ...rest }, ref) => (
  <MuiAlert ref={ref} {...rest} />
));
export const AlertTitle = ({ ...rest }: MuiAlertTitleProps) => <MuiAlertTitle {...rest} />;
