import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';
import { EXCHANGE_RATE_MATRIX_BY_PERIOD_ID_KEY } from '../get/getMatrixExchangeRatesByPeriodId';

const PUT_EXCHANGE_RATE_RESET_URL_PATH = '/api/v1/exchange-rate/reset-exchange-rates';
export const PUT_EXCHANGE_RATE_RESET_MSW_STATUS_KEY = 'putExchangeRateResetAPI';
export const PUT_EXCHANGE_RATE_RESET_KEY = 'PUT_EXCHANGE_RATE_RESET_KEY';

const updateResetExchangeRate = (periodId: number) =>
  putFetch({
    path: `${PUT_EXCHANGE_RATE_RESET_URL_PATH}${concatQuerys([
      { param: 'periodId', value: periodId.toString() },
    ])}`,
    key: PUT_EXCHANGE_RATE_RESET_MSW_STATUS_KEY,
  });

export const useUpdateResetExchangeRate = () => {
  const queryClient = useQueryClient();

  return useMutation(updateResetExchangeRate, {
    onSuccess: () => {
      // Timeout to retrieve proceed currency matrix data
      setTimeout(() => {
        queryClient.invalidateQueries([EXCHANGE_RATE_MATRIX_BY_PERIOD_ID_KEY]);
      }, 2000);
    },
  });
};
