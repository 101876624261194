import { Alert, AlertTitle } from 'src/components/mui-components';
import { useTranslation } from 'react-i18next';

interface IWidgetEmpty {
  noDataMessage?: string;
}

const WidgetEmpty = ({ noDataMessage }: IWidgetEmpty) => {
  const { t } = useTranslation('reportingWidgets');
  return (
    <Alert severity="info">
      <AlertTitle>{t('EmptyStateAlertTitle')}</AlertTitle>
      {noDataMessage ?? t('EmptyStateGeneralAlertMessage')}
    </Alert>
  );
};

export default WidgetEmpty;
