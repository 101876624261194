import { FC, HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import { TInputFieldWidth } from 'src/assets/variables/input';
import { TStatus } from 'src/assets/variables/status';
import { TIconName } from 'src/assets/variables/icon';
import { Flex } from 'src/components/ui-components/Flex';
import { Icon } from 'src/components/ui-components/Icon';
import { Text } from 'src/components/ui-components/Text';
import styles from './FormField.module.scss';

interface FormFieldProps extends HTMLAttributes<HTMLDivElement> {
  /** Form item content */
  children: ReactNode;
}

export const FormField: FC<FormFieldProps> = (props) => <div className="FormField" {...props} />;

interface FormFieldLabelProps extends HTMLAttributes<HTMLElement> {
  /** Label container content (<Label> JSX component) */
  children: ReactNode;
  /** Hide label visually */
  hiddenLabel?: boolean;
}

export const FormFieldLabel: FC<FormFieldLabelProps> = ({ hiddenLabel, ...props }) => (
  <div
    className={classNames(styles.label, {
      [styles.hidden]: hiddenLabel,
    })}
    {...props}
  />
);

interface FormFieldControlProps extends HTMLAttributes<HTMLDivElement> {
  /** Actual form control content */
  children: ReactNode | JSX.Element;
  /** Input width */
  inputWidth?: TInputFieldWidth;
}

export const FormFieldControl: FC<FormFieldControlProps> = ({ inputWidth = 'auto', ...props }) => (
  <div
    className={classNames(styles.control, {
      [styles[inputWidth ?? 'auto']]: inputWidth,
    })}
    {...props}
  />
);

interface FormFieldValidationProps extends HTMLAttributes<HTMLDivElement> {
  validationMsg: string;
}

export const FormFieldValidation: FC<FormFieldValidationProps> = ({ validationMsg, ...props }) => (
  <div className={styles.validation} {...props}>
    {validationMsg}
  </div>
);

interface FormFieldValidationPopOutProps extends FormFieldValidationProps {
  status: TStatus;
}

const statusIconMap: Record<TStatus, TIconName> = {
  error: 'error',
  success: 'circleCheck',
  warning: 'warning',
  default: 'info',
};

export const FormFieldValidationPopOut: FC<FormFieldValidationPopOutProps> = ({
  validationMsg,
  status,
  ...props
}) => (
  <div className={styles.validationPopOut} {...props}>
    <Flex>
      <Icon size="small" iconName={statusIconMap[status]} tone={status} />
      <Text wrapWords tone={status} size="tips">
        {validationMsg}
      </Text>
    </Flex>
  </div>
);
