import { ListItem, ListItemButton, ListItemText, Paper } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IFilterItemProperties } from 'src/apis/types/filterListAPI';
import { eventKeyEscape, eventKeyTab } from 'src/constants/keyboardKey';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import { useActivePanelID, useFilterDispatch } from 'src/stores/FilterStore';
import { getKeyboardFocusableElements } from 'src/utils/accessibility';
import { stringToPascal } from 'src/utils/string';
import { translationAnyText } from 'src/utils/translation';
import { InputContainer } from '../../../InputContainer';
import styles from './InputButton.module.scss';

export const InputButton = ({
  id,
  name: fieldLabel,
  contentUrl,
  childFilters,
  parentFilters,
  type,
}: IFilterItemProperties) => {
  const { t } = useTranslation('filter');
  const activePanelID = useActivePanelID();
  const filterId = `FilterID${id}`;
  const ref = useRef<HTMLLIElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useFilterDispatch();

  const onClickOutside = () => {
    if (dispatch && activePanelID === filterId) {
      dispatch({ type: 'DEACTIVATE_PANEL_ID' });
    }
  };

  useOnClickOutside(ref, onClickOutside);

  useEffect(() => {
    const element = dropdownRef.current;
    const [firstElement, ...restElements] = getKeyboardFocusableElements(element);
    const lastElement = restElements[restElements.length - 1];
    const handleTabWithShiftKeyDown = (e: KeyboardEvent) => {
      if (e.key === eventKeyTab && e.shiftKey && dispatch && activePanelID) {
        e.preventDefault();
        dispatch({ type: 'DEACTIVATE_PANEL_ID' });

        if (buttonRef.current) {
          buttonRef.current.focus();
        }
      }
    };

    const handleTabKeyDown = (e: KeyboardEvent) => {
      if (e.key === eventKeyTab && !e.shiftKey && dispatch && activePanelID) {
        dispatch({ type: 'DEACTIVATE_PANEL_ID' });
      }
    };

    if (firstElement) {
      (firstElement as HTMLElement).addEventListener('keydown', handleTabWithShiftKeyDown);
    }

    if (lastElement) {
      (lastElement as HTMLElement).addEventListener('keydown', handleTabKeyDown);
    }

    return () => {
      (firstElement as HTMLElement)?.removeEventListener('keydown', handleTabWithShiftKeyDown);
      (lastElement as HTMLElement)?.removeEventListener('keydown', handleTabKeyDown);
    };
  }, [dispatch, activePanelID, filterId]);

  const toggleFilterPanel = () => {
    if (dispatch) {
      if (activePanelID !== filterId) {
        dispatch({
          type: 'SET_ACTIVATE_PANEL_ID',
          payload: { filterID: filterId },
        });

        dispatch({ type: 'DEACTIVATE_CONTAINER_ID' });
      } else {
        dispatch({
          type: 'DEACTIVATE_PANEL_ID',
        });
      }
    }
  };

  useEffect(() => {
    const element = ref.current;
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === eventKeyEscape && dispatch && activePanelID) {
        e.stopPropagation();
        dispatch({ type: 'DEACTIVATE_PANEL_ID' });

        if (buttonRef.current) {
          buttonRef.current.focus();
        }
      }
    };

    if (element) {
      element.addEventListener('keydown', handleKeyDown);
    }

    return () => element?.removeEventListener('keydown', handleKeyDown);
  }, [dispatch, activePanelID, filterId]);

  return (
    <ListItem
      className={styles.inputWrapper}
      disablePadding
      disableGutters
      data-automation-id={`Input${filterId}`}
      ref={ref}
    >
      <ListItemButton
        ref={buttonRef}
        onClick={toggleFilterPanel}
        data-automation-id={`InputButton${filterId}`}
        aria-controls={filterId}
        aria-expanded={activePanelID === filterId}
        selected={activePanelID === filterId}
        sx={{ borderRadius: 1 }}
      >
        <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
          {translationAnyText(t, `FilterInputName${stringToPascal(fieldLabel)}`)}
        </ListItemText>
      </ListItemButton>

      {activePanelID === filterId && (
        <Paper
          elevation={0}
          id={filterId}
          className={styles.inputField}
          data-automation-id={`FilterPanel${filterId}`}
          hidden={activePanelID !== filterId}
          ref={dropdownRef}
        >
          <InputContainer
            filterId={filterId}
            fieldLabel={fieldLabel}
            contentUrl={contentUrl}
            childFilters={childFilters}
            parentFilters={parentFilters}
            activateInputContainerRef={buttonRef}
            type={type}
            data-automation-id={`SideBarFilterAutoComplete${stringToPascal(fieldLabel)}`}
          />
        </Paper>
      )}
    </ListItem>
  );
};
