import { useRef } from 'react';
import LayoutSettings from 'src/components/layout/LayoutSettings';
import VerticalNavigation from 'src/components/layout/VerticalNavigation';
import { usePersonalSettingsMenu } from 'src/apis/menusAPI/personalSettingsAPI';
import { Spinner, Text, Icon } from 'src/components/ui-components';
import LegacyPage from 'src/components/layout/LegacyPage';
import { useTranslation } from 'react-i18next';

const icons = {
  PersonalPreference: <Icon iconName="setting" />,
  PersonalProfile: <Icon iconName="person" />,
  ChangePassword: <Icon iconName="security" />,
  EditEmployeeCV: <Icon iconName="cv" />,
};

const NavigationPersonalSetting = () => {
  const { personalSettingsMenu, isLoading, error } = usePersonalSettingsMenu();
  const navigationBarRef = useRef<HTMLElement>(null);
  const { t } = useTranslation('personalSettingsNavigation');

  if (error) {
    return <Text>Something went wrong</Text>;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return personalSettingsMenu.length > 0 ? (
    <VerticalNavigation
      data={personalSettingsMenu}
      icons={icons}
      navigationName={t('PersonalPreference')}
      navigationBarRef={navigationBarRef}
    />
  ) : null;
};

export const NavigationPersonalSettingForTest = NavigationPersonalSetting;

export const PersonalSettings = () => {
  const { t } = useTranslation('personalSettingsNavigation');

  return (
    <LayoutSettings navigation={<NavigationPersonalSetting />} title={t('PersonalHeading')}>
      <LegacyPage data-automation-id="LegacyPage" />
    </LayoutSettings>
  );
};
