export default {
  Properties: {
    UserId: 1234,
    AllocationId: 81710,
    AllocatedHours: 42.0,
    RegisteredHours: 6.9,
    HourlyRateId: 5012554,
    HourlyRateName: '30 Database backups',
    HourlyRateAmount: 850.0,
    HourlyRateCurrencyAbb: 'DKK',
  },
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/projects/13376/employee-allocation?userId=1234&taskId=63952',
      Rel: 'self',
    },
  ],
};
