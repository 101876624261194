import { ReactNode, createContext, useContext } from 'react';
import { IAssignFlowValue, INITIAL_ASSIGN_FLOW_STORE } from './AssignFlowStore.types';

interface IAssignFlow {
  children: ReactNode;
  value?: IAssignFlowValue;
}

const Context = createContext<IAssignFlowValue>(INITIAL_ASSIGN_FLOW_STORE);

export const useAssignFlowStore = () => useContext(Context);

export const AssignFlowStore = ({ children, value = INITIAL_ASSIGN_FLOW_STORE }: IAssignFlow) => (
  <Context.Provider value={value}>{children}</Context.Provider>
);
