export default {
  Entities: [
    {
      Properties: {
        Name: 'DeleteAction',
      },
    },
    {
      Properties: {
        Name: 'ChangeStatusTo',
        Actions: [
          {
            Name: 'Quote',
            Uri: 'status',
            Body: {
              Status: 0,
            },
          },
          {
            Name: 'Approved',
            Uri: 'status',
            Body: {
              Status: 1,
            },
          },
          {
            Name: 'InProgress',
            Uri: 'status',
            Body: {
              Status: 2,
            },
          },
          {
            Name: 'OnHold',
            Uri: 'status',
            Body: {
              Status: 3,
            },
          },
          {
            Name: 'Completed',
            Uri: 'status',
            Body: {
              Status: 4,
            },
          },
          {
            Name: 'Archived',
            Uri: 'status',
            Body: {
              Status: 5,
            },
          },
          {
            Name: 'Cancelled',
            Uri: 'status',
            Body: {
              Status: 6,
            },
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'ChangeStageTo',
        Actions: [
          {
            Name: 'Idea',
            Uri: 'stage',
            Body: {
              Stage: 1,
            },
          },
          {
            Name: 'Prestudy',
            Uri: 'stage',
            Body: {
              Stage: 2,
            },
          },
          {
            Name: 'Execution',
            Uri: 'stage',
            Body: {
              Stage: 5,
            },
          },
          {
            Name: 'Alpha release (Product)',
            Uri: 'stage',
            Body: {
              Stage: 9,
            },
          },
          {
            Name: 'Adoption process',
            Uri: 'stage',
            Body: {
              Stage: 6,
            },
          },
          {
            Name: 'On hold',
            Uri: 'stage',
            Body: {
              Stage: 7,
            },
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'ResourcePlanner',
        Actions: [
          {
            Name: 'EnableResourcePlanner',
            Uri: 'resource-planner',
            Body: {
              IsAddingToResourcePlanner: true,
            },
          },
          {
            Name: 'DisableResourcePlanner',
            Uri: 'resource-planner',
            Body: {
              IsAddingToResourcePlanner: false,
            },
          },
        ],
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/projects/actions',
      Rel: 'self',
    },
  ],
};
