import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';
import { ICurrencyMatrixExchangeRatesResponse } from '../exchangeRateAPI';
import { EXCHANGE_RATE_MATRIX_BY_PERIOD_ID_KEY } from '../get/getMatrixExchangeRatesByPeriodId';

const EXCHANGE_RATE_UPDATE_BY_ID_URL_PATH = '/api/v1/exchange-rate/exchange-rate-by-id';
export const EXCHANGE_RATE_UPDATE_BY_ID_MSW_STATUS_KEY = 'exchangeRateUpdateByIdAPI';

export interface UpdateExchangeRateByIdVariables {
  exchangeRateId: number;
  newAmount: number;
  inverseCurrencyPairExchangeRateId: number;
  periodId: number;
}

const updateExchangeRateById = (
  updateModel: UpdateExchangeRateByIdVariables,
): Promise<ICurrencyMatrixExchangeRatesResponse> =>
  putFetch({
    path: EXCHANGE_RATE_UPDATE_BY_ID_URL_PATH,
    key: EXCHANGE_RATE_UPDATE_BY_ID_MSW_STATUS_KEY,
    body: JSON.stringify({
      ExchangeRateId: updateModel.exchangeRateId,
      NewAmount: updateModel.newAmount,
      InverseCurrencyPairExchangeRateId: updateModel.inverseCurrencyPairExchangeRateId,
    }),
  });

export const useUpdateExchangeRateById = () => {
  const queryClient = useQueryClient();

  return useMutation(updateExchangeRateById, {
    onSuccess: (_, mutationVariables) => {
      queryClient.invalidateQueries([
        EXCHANGE_RATE_MATRIX_BY_PERIOD_ID_KEY,
        mutationVariables.periodId,
      ]);
    },
  });
};
