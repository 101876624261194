import { RPRow } from '../types/resourcePlanner';

export const getShimmerRows = (rowId: string, childCount?: number) => {
  const count = childCount ?? 0;
  const row: RPRow = {
    budget: 0,
    canAssign: false,
    canDelete: false,
    canExpand: false,
    editable: false,
    endAt: '',
    endsAt: '',
    hasChildWithEndDateOnTodayOrAfter: false,
    hierarchyType: 'workitem',
    id: '',
    isActive: false,
    isLoading: true,
    lastUserSetIsActive: false,
    level: 1,
    name: '',
    negativeUnplannedHours: -13.04,
    notPlanned: -13.04,
    pathToParent: '',
    positiveUnplannedHours: 0,
    resourceId: '',
    resourceSourceReferenceId: '',
    resourceType: 'Unknown',
    rootParentName: '',
    sortablePath: '',
    sortOrder: 0,
    sourceReferenceId: '',
    startAt: '',
    startsAt: '',
    status: 'InProgress',
    tenantId: '',
    total: {
      displayValue: 0,
      value: '',
    },
    totalActualWorkload: 13.04,
    totalBooked: 0,
    totalEAC: 13.04,
    translatedStatus: '',
    type: 'Task',
    values: {},
    workItemId: '',
    workItemSourceReferenceId: '',
    workItemType: 'Unknown',
  };

  return Array.from(Array(count)).map(() => row);
};
