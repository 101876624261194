import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { getPathWithQueries } from 'src/utils/url/url';
import { GET_RECENT_TASKS_KEY } from './getRecentTasksAPI.constants';
import { TRecentTasksRes } from './getRecentTasksAPI.types';

const GET_RECENT_TASKS_URL_PATH = '/api/v2/resource-planner/tasks/recently-registered';

interface IGetRecentTasks {
  projectId: string;
  userId: string;
}

const getRecentTasks = ({ projectId, userId }: IGetRecentTasks): Promise<TRecentTasksRes> =>
  getFetch({
    path: getPathWithQueries(GET_RECENT_TASKS_URL_PATH, {
      projectId,
      userId,
    }),
    key: GET_RECENT_TASKS_KEY,
  });

export const useGetRecentTasks = (params: IGetRecentTasks, enableQuery?: boolean) => {
  const { projectId, userId } = params;
  const { data, ...rest } = useQuery(
    [GET_RECENT_TASKS_KEY, projectId, userId],
    () => getRecentTasks(params),
    { enabled: enableQuery && !!projectId && !!userId },
  );

  return { data: data?.entities, ...rest };
};
