import { useEffect, useState } from 'react';
import { Feature } from 'src/apis/featureAPI/types';

// Custom hook for managing features switch value
export const useFeatures = (data: Feature[]) => {
  const [checkedFeatures, setCheckedFeatures] = useState<Record<string, boolean>>({});
  const [featureValues, setFeatureValues] = useState<Record<string, number>>({});

  useEffect(() => {
    if (data) {
      const newCheckedFeatures: Record<string, boolean> = data.reduce(
        (acc: Record<string, boolean>, feature) => {
          acc[feature.name] = feature.enabled;
          return acc;
        },
        {},
      );

      const newFeatureValues: Record<string, number> = data.reduce(
        (acc: Record<string, number>, feature) => {
          if (feature.value !== undefined) {
            acc[feature.name] = feature.value;
          }
          return acc;
        },
        {},
      );

      if (JSON.stringify(newCheckedFeatures) !== JSON.stringify(checkedFeatures)) {
        setCheckedFeatures(newCheckedFeatures);
      }

      if (JSON.stringify(newFeatureValues) !== JSON.stringify(featureValues)) {
        setFeatureValues(newFeatureValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { checkedFeatures, setCheckedFeatures, featureValues, setFeatureValues };
};
