import { useLocation } from 'react-router-dom';
import { useGetDesignSevenPages } from 'src/apis/pagesAPI/designSevenAPI';
import LoadingSpinner from 'src/components/ui-components/LoadingSpinner';
import { redirectObject } from 'src/routers/RedirectDesignSeven/redirectObject';

interface AuthRouteProps {
  children: JSX.Element;
}

const LOADING = 'loading';
const CONTINUE = 'continue';

interface IPageItem {
  pageUrl: string;
  pageIdentifier: string;
}

interface IIsAPILoadingLegacyPage {
  pathname: string;
  isLoading: boolean;
  designSevenPageList: Array<IPageItem>;
}

const isAPILoadingLegacyPage = ({
  pathname,
  isLoading,
  designSevenPageList,
}: IIsAPILoadingLegacyPage) => {
  if (isLoading) return LOADING;

  if (designSevenPageList.length > 0) {
    const result = Object.entries(redirectObject).map(([key, { oldPaths }]) => {
      const isContainingPageIdentifier = designSevenPageList?.find(
        ({ pageIdentifier }) => pageIdentifier === key,
      );
      if (isContainingPageIdentifier && oldPaths.some((path) => path === pathname)) return LOADING;
      return null;
    });
    if (result.some((item) => item === LOADING)) {
      return LOADING;
    }
  }

  return CONTINUE;
};

export const isAPILoadingLegacyPageForTest = isAPILoadingLegacyPage;

export const LegacyRoute = ({ children }: AuthRouteProps) => {
  const { pathname } = useLocation();
  const { isLoading: isDesignSevenLoading, designSevenPageList } = useGetDesignSevenPages();
  const isLoading =
    isAPILoadingLegacyPage({ isLoading: isDesignSevenLoading, pathname, designSevenPageList }) ===
    LOADING;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return children;
};

export default LegacyRoute;
