import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';
import { ExchangeRatePeriodPollType } from 'src/screens/SystemAdministration/Finance/ExchangeRates/components/SetupAutomationModal/SetupAutomationModal';
import { GET_EXCHANGE_RATE_MATRIX_ACTIONS_KEY } from '../get/getMatrixActionsAPI';
import { EXCHANGE_RATE_MATRIX_BY_PERIOD_ID_KEY } from '../get/getMatrixExchangeRatesByPeriodId';
import { EXCHANGE_RATE_GET_PERIODS_KEY } from '../get/getPeriods';

const PUT_AUTOMATION_SETTINGS = '/api/v1/exchange-rate/automation-settings';
export const PUT_AUTOMATION_SETTINGS_MSW_STATUS_KEY = 'putAutomationSettings';

interface PutAutomationsSettingsVariables {
  matrixMode: number;
  periodLengthInMonth: number;
}

const updateAutomationSettings = async (putModal: PutAutomationsSettingsVariables) => {
  await putFetch({
    path: PUT_AUTOMATION_SETTINGS,
    key: PUT_AUTOMATION_SETTINGS_MSW_STATUS_KEY,
    body: JSON.stringify(putModal),
  });

  return { putModal };
};

export const useUpdateAutomationSettings = () => {
  const queryClient = useQueryClient();

  const invalidateQueries = () => {
    Promise.all([
      queryClient.invalidateQueries([EXCHANGE_RATE_GET_PERIODS_KEY]),
      queryClient.invalidateQueries([GET_EXCHANGE_RATE_MATRIX_ACTIONS_KEY]),
      queryClient.invalidateQueries([EXCHANGE_RATE_MATRIX_BY_PERIOD_ID_KEY]),
    ]);
  };

  return useMutation(updateAutomationSettings, {
    onSuccess: ({ putModal }) => {
      const { matrixMode } = putModal;
      if (matrixMode === ExchangeRatePeriodPollType.Automatic) {
        setTimeout(() => {
          invalidateQueries();
        }, 1000);
      } else {
        invalidateQueries();
      }
    },
  });
};
