import { useEffect, useRef, useCallback } from 'react';
import { getKeyboardFocusableElements } from 'src/utils/accessibility';
import { getHourAndMinFromTimeTrackerResult } from 'src/utils/time/timeTrackerTime';
import { eventKeyEscape, eventKeyTab } from 'src/constants/keyboardKey';
import ITimeTracker from 'src/apis/types/timeTrackerAPI';
import { TimeSpan } from './types';

export const useKeyboardControl = (
  isActive: boolean,
  setIsActive: (value: React.SetStateAction<boolean>) => void,
  currentTimeTrackerRef: HTMLDivElement | null,
) =>
  useEffect(() => {
    if (currentTimeTrackerRef) {
      if (isActive) {
        const focusableElements = getKeyboardFocusableElements(currentTimeTrackerRef);

        currentTimeTrackerRef.addEventListener('keydown', (event) => {
          const isTabPress = event.key === eventKeyTab;
          if (focusableElements) {
            if (isTabPress && event.shiftKey && event.target === focusableElements[0])
              setIsActive(false);
            else if (isTabPress && event.target === focusableElements[focusableElements.length - 1])
              setIsActive(false);
          }

          if (event.key === eventKeyEscape) {
            setIsActive(false);
            currentTimeTrackerRef.focus();
          }
        });
      }
      currentTimeTrackerRef.removeEventListener('keydown', (event) => {
        if (event.key === eventKeyEscape) {
          setIsActive(false);
        }
      });
      currentTimeTrackerRef.addEventListener('focusout', (event) => {
        if (!currentTimeTrackerRef?.contains(event.relatedTarget as HTMLElement)) {
          setIsActive(false);
        }
      });
    }
  }, [isActive, setIsActive, currentTimeTrackerRef]);

export const useTimeCountDown = (
  timeTrackerData: ITimeTracker | undefined,
  serverTimeZoneId: string | undefined,
  setTimeSpan: React.Dispatch<React.SetStateAction<TimeSpan | undefined>>,
) => {
  const interval: any = useRef();
  const stopCountdown = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }
  }, [interval]);

  const startCountdown = useCallback(() => {
    interval.current = setInterval(() => {
      setTimeSpan(getHourAndMinFromTimeTrackerResult(timeTrackerData, serverTimeZoneId));
    }, 1000 * 15); // refresh every 15 sec
  }, [interval, timeTrackerData, serverTimeZoneId, setTimeSpan]);

  useEffect(() => {
    if (timeTrackerData) {
      startCountdown();
    } else {
      setTimeSpan(undefined);
      stopCountdown();
    }
  }, [timeTrackerData, setTimeSpan, startCountdown, stopCountdown]);
};
