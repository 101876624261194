export const GetSPKey = (key: string, user: string = '') => `search-projects-${user}-${key}`;

export const SPViewOptionsStateKey = 'search-projects-view-options';

export const SPSelectedFilterListStateKey = 'search-projects-selected-filter-list';

export const SPInvalidateOldQueryOnViewOptionChange = 'search-projects-invalidate-old-query';

export const SPFilterIsOpen = 'search-projects-filter-is-open';

export const GetSPSavedViewsKey = (user?: string) => GetSPKey('saved-views', user);
