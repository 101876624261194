import systemAdminResult from 'src/mocks/menus/systemAdministration/systemAdministrationFullResult';
import designSevenFullResult from '../designSevenFullResult';

export const removeDesignSevenPage = (pageIdentifieries: string[]) => {
  const entities = designSevenFullResult.Entities.filter(
    ({ Properties: { PageIdentifier } }) =>
      !pageIdentifieries.some((pageIdentifier) => pageIdentifier === PageIdentifier),
  );
  return {
    Entities: entities,
    Links: designSevenFullResult.Links,
  };
};

export const removeDesignSevenSystemAdminPage = (pageIdentifiers: string[]) => {
  const menuTopLevelSectionFiltered = systemAdminResult.Entities.filter(
    ({ Properties: { PageIdentifier } }) =>
      !pageIdentifiers.some((pageIdentifier) => pageIdentifier === PageIdentifier),
  );

  const subMenusSectionFiltered = menuTopLevelSectionFiltered.map((topMenuItem) => ({
    Properties: {
      ...topMenuItem.Properties,
      SubMenus: topMenuItem.Properties.SubMenus?.filter(
        (subMenuItem) =>
          !pageIdentifiers.some((pageIdentifier) => pageIdentifier === subMenuItem.PageIdentifier),
      ),
    },
  }));

  return {
    Entities: subMenusSectionFiltered,
    Links: systemAdminResult.Links,
  };
};
