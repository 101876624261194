export default {
  Entities: [
    {
      Properties: {
        UserId: 62,
        SuggestedHourlyRateId: 5012558,
        SuggestedHourlyRateName: '50 Internal',
        SuggestedHourlyRateAmount: 50.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/projects/13376/employee-unallocated?taskId=63952&userId=62',
      Rel: 'self',
    },
  ],
};
