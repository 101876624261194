import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFetch } from 'src/utils/fetcher';
import { SEARCH_PROJECTS_KEY } from '../get/getProjectsAPI';

const PROJECTS_URL_PATH = '/api/v2/projects';
export const DELETE_PROJECT_MSW_STATUS_KEY = 'projectDeleteAPI';
export const DELETE_PROJECTS_MSW_STATUS_KEY = 'projectsDeleteAPI';

// Delete single project
const deleteProject = (projectId: string) =>
  deleteFetch({
    path: `${PROJECTS_URL_PATH}/${projectId}`,
    key: DELETE_PROJECT_MSW_STATUS_KEY,
  });

export const useDeleteProject = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteProject, {
    onSuccess: () => Promise.all([queryClient.invalidateQueries([SEARCH_PROJECTS_KEY])]),
  });
};

interface Project {
  projectId: number;
}

// Delete multiple projects
const deleteProjects = (projectsId: Project[]) =>
  deleteFetch({
    path: `${PROJECTS_URL_PATH}?projectsIds=${projectsId
      .map((project) => project.projectId)
      .join(',')}`,
    key: DELETE_PROJECTS_MSW_STATUS_KEY,
  });

export const useDeleteProjects = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteProjects, {
    onSuccess: () => Promise.all([queryClient.invalidateQueries([SEARCH_PROJECTS_KEY])]),
  });
};
