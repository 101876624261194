import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { COMPETENCE_GROUPS_URL_PATH, GET_COMPETENCE_GROUPS_KEY, ICompetenceGroups } from '../types';

const GET_COMPETENCE_GROUPS_MSW_STATUS_KEY = 'getCompetenceGroupsAPI';

const getCompetenceGroups = (): Promise<ICompetenceGroups> =>
  getFetch({
    path: COMPETENCE_GROUPS_URL_PATH,
    key: GET_COMPETENCE_GROUPS_MSW_STATUS_KEY,
  });

export const useGetCompetenceGroups = () => {
  const { data, ...restProps } = useQuery([GET_COMPETENCE_GROUPS_KEY], () => getCompetenceGroups());

  return {
    categories: data?.entities?.flat(),
    ...restProps,
  };
};
