export default {
  Entities: [
    {
      Properties: {
        DisplayText: 'P23.0002 RP BI53784',
        Value: '108',
      },
    },
    {
      Properties: {
        DisplayText: 'P24.0001 Test 2324591393',
        Value: '110',
      },
    },
  ],
  Links: [
    {
      Href: 'https://uat.timelog.com/rp-test1/api/v2/filters/project/content?pageIdentifier=NewResourcePlanner&startDate=2024-04-19&endDate=2024-05-12',
      Rel: 'self',
    },
  ],
};
