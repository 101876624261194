import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';

export default () => {
  const { currentLanguage, isSuccess, refetch } = useGetCurrentLanguage();

  const { i18n } = useTranslation();

  useEffect(() => {
    const handleIframeEvent = (event: MessageEvent) => {
      const {
        data: { eventId },
      } = event;

      if (eventId === 'languageChanged') {
        refetch();
      }
    };

    window.addEventListener('message', handleIframeEvent as EventListener);

    return () => {
      window.removeEventListener('message', handleIframeEvent as EventListener);
    };
  }, [refetch]);

  useEffect(() => {
    if (isSuccess && currentLanguage) {
      if (localStorage.getItem('userLanguage') === currentLanguage) return;
      localStorage.setItem('userLanguage', currentLanguage);
      i18n.changeLanguage(currentLanguage);
    }
  }, [currentLanguage, i18n, isSuccess]);

  return null;
};
