import cx from 'classnames';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ISubMenuCollapseItem } from 'src/apis/types/menusAPI';
import { Link } from 'src/components/ui-components/Link';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { isContentURL, urlCompare } from 'src/utils/url';
import LinkInnerContent from '../LinkInnerContent';

import styles from './CollapseItem.module.scss';

interface CollapseItemProps {
  name: string;
  label: string;
  subMenus?: ISubMenuCollapseItem[];
  icon?: ReactNode;
  matchName?: string;
  onItemSelect: () => void;
}
interface SubMenuProps {
  subMenus?: ISubMenuCollapseItem[];
  id: string;
  hidden: boolean;
  matchName?: string;
  currentUrl: string;
  onItemSelect: () => void;
}

const SubMenuComponent = ({
  subMenus = [],
  id,
  hidden,
  matchName,
  currentUrl,
  onItemSelect,
}: SubMenuProps) =>
  subMenus?.length > 0 ? (
    <div id={id} className={styles.Panel} hidden={hidden} data-automation-id={`SubMenu${id}`}>
      {subMenus?.map(({ pageIdentifier, pageUrl, pagePath, translation }) => (
        <Link
          href={pagePath ?? pageUrl}
          useTextColor
          className={cx(styles.Link, {
            [styles.ActiveLink]: urlCompare({ currentUrl, originPath: pagePath ?? pageUrl }),
          })}
          block
          hidden={hidden}
          key={`Sub${pageIdentifier}`}
          data-automation-id={`SubItem${pageIdentifier}`}
          onClick={onItemSelect}
        >
          {matchName ? (
            <HighlightMatchingText name={translation} matchName={matchName} />
          ) : (
            translation
          )}
        </Link>
      ))}
    </div>
  ) : null;

export const SubMenuComponentForTest = SubMenuComponent;

export const CollapseItem: FC<CollapseItemProps> = ({
  subMenus,
  name: sectionId,
  icon,
  label,
  matchName,
  onItemSelect,
  ...props
}) => {
  const { pathname, search } = useLocation();
  const isCurrentURL = isContentURL({
    subMenusPageUrl: subMenus?.map(({ pageUrl, pagePath }) => pagePath ?? pageUrl),
    pathname: pathname + search,
  });
  const [isActive, setIsActive] = useState(isCurrentURL);
  const [currentURL, setCurrentURL] = useState(window.location.href);

  useEffect(() => {
    if (!urlCompare({ currentUrl: currentURL, originPath: pathname + search })) {
      setCurrentURL(window.location.href);
      setIsActive(
        isContentURL({
          subMenusPageUrl: subMenus?.map(({ pageUrl, pagePath }) => pagePath ?? pageUrl),
          pathname: pathname + search,
        }),
      );
    }
  }, [currentURL, pathname, search, subMenus]);

  useEffect(
    () => (matchName || isCurrentURL ? setIsActive(true) : setIsActive(false)),
    [matchName, isCurrentURL],
  );

  return (
    <div className={styles.Item} {...props}>
      <button
        type="button"
        className={cx(styles.Trigger, {
          [styles.TriggerIsActive]: isActive,
        })}
        aria-controls={sectionId}
        aria-expanded={isActive}
        onClick={() => setIsActive((prev) => !prev)}
        data-automation-id={`CollapseButton${sectionId}`}
      >
        <LinkInnerContent label={label} icon={icon} hasSubnav />
      </button>
      <SubMenuComponent
        subMenus={subMenus}
        id={sectionId}
        hidden={!isActive}
        matchName={matchName}
        currentUrl={currentURL}
        onItemSelect={onItemSelect}
      />
    </div>
  );
};
