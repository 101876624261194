import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { GET_RECENT_PROJECTS_KEY } from './getRecentProjectsAPI.constants';
import { TRecentProjectsRes } from './getRecentProjectsAPI.types';

const GET_RECENT_PROJECTS_URL_PATH = '/api/v2/resource-planner/projects/recently-registered';

const getRecentProjects = (): Promise<TRecentProjectsRes> =>
  getFetch({
    path: GET_RECENT_PROJECTS_URL_PATH,
    key: GET_RECENT_PROJECTS_KEY,
  });

export const useGetRecentProjects = (enableQuery?: boolean) => {
  const { data, ...rest } = useQuery([GET_RECENT_PROJECTS_KEY], () => getRecentProjects(), {
    enabled: enableQuery,
  });

  return { data: data?.entities, ...rest };
};
