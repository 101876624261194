import { ButtonProps, Button as MuiButton } from '@mui/material';
import { forwardRef } from 'react';
import { Box } from '../Box';
import { CircularProgress } from '../CircularProgress';

type ButtonRef = HTMLButtonElement;

export const Button = forwardRef<ButtonRef, ButtonProps>(({ sx, ...rest }, ref) => (
  <MuiButton ref={ref} sx={{ whiteSpace: 'nowrap', ...sx }} {...rest} />
));

export interface ILoadingButton extends ButtonProps {
  isLoading: boolean;
}

export const LoadingButton = forwardRef<ButtonRef, ILoadingButton>(
  ({ isLoading, ...rest }, ref) => (
    <Box sx={{ position: 'relative' }}>
      <Button disabled={isLoading} ref={ref} {...rest} />
      {isLoading && (
        <CircularProgress
          size={24}
          sx={{
            color: 'primary',
            insetBlockStart: '50%',
            insetInlineStart: '50%',
            marginBlockStart: '-12px',
            marginInlineStart: '-12px',
            position: 'absolute',
          }}
        />
      )}
    </Box>
  ),
);
