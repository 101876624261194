import { forwardRef, InputHTMLAttributes } from 'react';
import cx from 'classnames';
import logger from 'src/utils/logger';
import { TStatus } from 'src/assets/variables/status';
import { useAutoId } from 'src/hooks/useAutoId';
import { TextBase } from 'src/components/ui-components/Text/TextBase';
import { FormFieldValidation, FormField } from 'src/components/ui-components/FormField';

import styles from './Switch.module.scss';

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  /** Label */
  label: string;
  /** Element id attribute if you need control */
  id?: string;
  /** Status (ENUM: Success, Warning, Error) */
  status?: TStatus;
  /** Feedback message */
  validationMsg?: string;
  /** Bold label */
  bold?: boolean;
  /** Hide the label visually */
  hiddenLabel?: boolean;
}

type Ref = HTMLInputElement;

export const Switch = forwardRef<Ref, SwitchProps>(
  ({ id, label, status, validationMsg, bold, hiddenLabel, ...props }, ref) => {
    const autoId = useAutoId(id);
    // We want to have a warning in the console if a label is empty string.
    if (label === '' && !hiddenLabel) logger.warn('`label` must never be an empty string!');

    return (
      <FormField>
        <div className={styles.wrapper}>
          <input
            className={styles.control}
            id={autoId}
            type="checkbox"
            aria-describedby={status && validationMsg ? `switchDesc-${autoId}` : undefined}
            ref={ref}
            {...props}
          />
          <label htmlFor={autoId} className={styles.label}>
            <span
              className={cx(styles.pseudoControl, {
                [styles[`${status}` as keyof typeof styles]]: status,
              })}
              aria-hidden="true"
            />

            <TextBase
              bold={bold}
              as="span"
              className={styles.labelText}
              visuallyHidden={hiddenLabel}
            >
              {label}
            </TextBase>
          </label>
        </div>
        {!!validationMsg && (
          <FormFieldValidation validationMsg={validationMsg} id={`switchDesc-${autoId}`} />
        )}
      </FormField>
    );
  },
);

export default Switch;
