export default {
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10007',
      responseType: 'GroupByResource',
      viewOptions: {
        'grouped-by': 'group-by-resource',
        'reporting-types': 'availability',
        'period-types': 'week',
        'unit-types': 'hours',
        'period-starts-at': '2024-04-04',
        'period-ends-at': '2024-04-28',
      },
      periods: [
        {
          startsAt: '2024-04-01T00:00:00',
          endsAt: '2024-04-07T00:00:00',
          type: 'Week',
          identifier: '2024W14',
        },
        {
          startsAt: '2024-04-08T00:00:00',
          endsAt: '2024-04-14T00:00:00',
          type: 'Week',
          identifier: '2024W15',
        },
        {
          startsAt: '2024-04-15T00:00:00',
          endsAt: '2024-04-21T00:00:00',
          type: 'Week',
          identifier: '2024W16',
        },
        {
          startsAt: '2024-04-22T00:00:00',
          endsAt: '2024-04-28T00:00:00',
          type: 'Week',
          identifier: '2024W17',
        },
      ],
      children: [
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 33.6,
              value: 33.6,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 134.4,
            value: 134.4,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149777205329920',
          resourceSourceReferenceId: 50,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149777205329920',
          name: '1 Alam  Tam Future (ATA)',
          role: 'Developer ',
          isActive: false,
          hiredAt: '2021-09-30',
          tenantId: '10007',
          sourceReferenceId: 50,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 33.6,
              value: 33.6,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 134.4,
            value: 134.4,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149761208254464',
          resourceSourceReferenceId: 70,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149761208254464',
          name: '1 Daniel Yap (DAY)',
          role: '',
          isActive: false,
          hiredAt: '2022-08-22',
          tenantId: '10007',
          sourceReferenceId: 70,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 33.6,
              value: 33.6,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 134.4,
            value: 134.4,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149793642807296',
          resourceSourceReferenceId: 71,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149793642807296',
          name: '1 Jimmy Lee (JIL)',
          role: '',
          isActive: false,
          hiredAt: '2022-11-25',
          tenantId: '10007',
          sourceReferenceId: 71,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 141.8,
            value: 141.8,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149782704062464',
          resourceSourceReferenceId: 51,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149782704062464',
          name: 'Aaron Ng (AAN)',
          role: '',
          isActive: false,
          hiredAt: '2021-08-24',
          tenantId: '10007',
          sourceReferenceId: 51,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 8.0,
              value: 8.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 20.0,
              value: 20.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 20.0,
              value: 20.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 16.0,
              value: 16.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 64.0,
            value: 64.0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149777884807168',
          resourceSourceReferenceId: 54,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149777884807168',
          name: 'Amy Aw (AAW)',
          role: '',
          isActive: false,
          hiredAt: '2022-06-16',
          tenantId: '10007',
          sourceReferenceId: 54,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          children: [
            {
              StartsAt: '2023-11-01T00:00:00',
              EndsAt: '2024-02-01T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2023-11-01T00:00:00',
                  EndsAt: '2024-02-01T00:00:00',
                  hierarchyType: 'workitem',
                  values: {
                    '2024W14': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W15': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 60.0,
                  TotalEAC: 60.0,
                  NotPlanned: -10.0,
                  PositiveUnplannedHours: 0,
                  NegativeUnplannedHours: -10.0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: false,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '271149770972594176',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '271197302851371009',
                  workItemSourceReferenceId: '938',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: 'Anders Nielsen',
                  id: '271197302851371009',
                  name: 'FPSA001 (O0915)',
                  sourceReferenceId: '938',
                  tenantId: '10007',
                  parentId: '271197302733930497',
                  type: 'Task',
                  pathToParent: '/271197302733930497/',
                  sortOrder: 1,
                  level: 2,
                  sortablePath: '/_TIMJenm70759EUR/001',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
                {
                  StartsAt: '2023-11-01T00:00:00',
                  EndsAt: '2024-02-01T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      StartsAt: '2023-11-01T00:00:00',
                      EndsAt: '2024-02-01T00:00:00',
                      hierarchyType: 'workitem',
                      values: {
                        '2024W14': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W15': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W16': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                      },
                      Budget: 50.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 61.0,
                      TotalEAC: 61.0,
                      NotPlanned: -11.0,
                      PositiveUnplannedHours: 0,
                      NegativeUnplannedHours: -11.0,
                      total: {
                        displayValue: 0,
                        value: 0,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '271149770972594176',
                      resourceSourceReferenceId: 1,
                      resourceType: 'Employee',
                      workItemId: '271197302851371011',
                      workItemSourceReferenceId: '941',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      rootParentName: 'Anders Nielsen',
                      id: '271197302851371011',
                      name: 'FPSA0021 (O0918)',
                      sourceReferenceId: '941',
                      tenantId: '10007',
                      parentId: '271197302851371010',
                      type: 'Task',
                      pathToParent: '/271197302733930497/271197302851371010/',
                      sortOrder: 3,
                      level: 3,
                      sortablePath: '/_TIMJenm70759EUR/002/003',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                      lastUserSetIsActive: false,
                    },
                  ],
                  values: {
                    '2024W14': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W15': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 61.0,
                  TotalEAC: 61.0,
                  NotPlanned: -11.0,
                  PositiveUnplannedHours: 0,
                  NegativeUnplannedHours: -11.0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: true,
                  editable: false,
                  canAssign: false,
                  canDelete: false,
                  resourceId: '271149770972594176',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '271197302851371010',
                  workItemSourceReferenceId: '940',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: 'Anders Nielsen',
                  id: '271197302851371010',
                  name: 'FPSA002 (O0917)',
                  sourceReferenceId: '940',
                  tenantId: '10007',
                  parentId: '271197302733930497',
                  type: 'Task',
                  pathToParent: '/271197302733930497/',
                  sortOrder: 2,
                  level: 2,
                  sortablePath: '/_TIMJenm70759EUR/002',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
                {
                  StartsAt: '2023-11-01T00:00:00',
                  EndsAt: '2024-02-01T00:00:00',
                  hierarchyType: 'workitem',
                  values: {
                    '2024W14': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W15': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 62.0,
                  TotalEAC: 62.0,
                  NotPlanned: -12.0,
                  PositiveUnplannedHours: 0,
                  NegativeUnplannedHours: -12.0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: false,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '271149770972594176',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '271197302851371012',
                  workItemSourceReferenceId: '942',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: 'Anders Nielsen',
                  id: '271197302851371012',
                  name: 'TMSA003 (O0919)',
                  sourceReferenceId: '942',
                  tenantId: '10007',
                  parentId: '271197302733930497',
                  type: 'Task',
                  pathToParent: '/271197302733930497/',
                  sortOrder: 4,
                  level: 2,
                  sortablePath: '/_TIMJenm70759EUR/004',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
                {
                  StartsAt: '2023-11-01T00:00:00',
                  EndsAt: '2024-02-01T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      StartsAt: '2023-11-01T00:00:00',
                      EndsAt: '2024-02-01T00:00:00',
                      hierarchyType: 'workitem',
                      children: [
                        {
                          StartsAt: '2023-11-01T00:00:00',
                          EndsAt: '2024-02-01T00:00:00',
                          hierarchyType: 'workitem',
                          values: {
                            '2024W14': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: true,
                            },
                            '2024W15': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: true,
                            },
                            '2024W16': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: true,
                            },
                            '2024W17': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: true,
                            },
                          },
                          Budget: 50.0,
                          TotalBooked: 0.0,
                          TotalActualWorkload: 63.0,
                          TotalEAC: 63.0,
                          NotPlanned: -13.0,
                          PositiveUnplannedHours: 0,
                          NegativeUnplannedHours: -13.0,
                          total: {
                            displayValue: 0,
                            value: 0,
                          },
                          canExpand: false,
                          editable: true,
                          canAssign: false,
                          canDelete: true,
                          resourceId: '271149770972594176',
                          resourceSourceReferenceId: 1,
                          resourceType: 'Employee',
                          workItemId: '271197302851371015',
                          workItemSourceReferenceId: '945',
                          workItemType: 'Task',
                          hasChildWithEndDateOnTodayOrAfter: false,
                          rootParentName: 'Anders Nielsen',
                          id: '271197302851371015',
                          name: 'TMSA00411 (O0922)',
                          sourceReferenceId: '945',
                          tenantId: '10007',
                          parentId: '271197302851371014',
                          type: 'Task',
                          pathToParent:
                            '/271197302733930497/271197302851371013/271197302851371014/',
                          sortOrder: 7,
                          level: 4,
                          sortablePath: '/_TIMJenm70759EUR/005/006/007',
                          isActive: false,
                          startAt: '0001-01-01T00:00:00',
                          endAt: '0001-01-01T00:00:00',
                          status: 'InProgress',
                          lastUserSetIsActive: false,
                        },
                      ],
                      values: {
                        '2024W14': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W15': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W16': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                      },
                      Budget: 50.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 63.0,
                      TotalEAC: 63.0,
                      NotPlanned: -13.0,
                      PositiveUnplannedHours: 0,
                      NegativeUnplannedHours: -13.0,
                      total: {
                        displayValue: 0,
                        value: 0,
                      },
                      canExpand: true,
                      editable: false,
                      canAssign: false,
                      canDelete: false,
                      resourceId: '271149770972594176',
                      resourceSourceReferenceId: 1,
                      resourceType: 'Employee',
                      workItemId: '271197302851371014',
                      workItemSourceReferenceId: '944',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      rootParentName: 'Anders Nielsen',
                      id: '271197302851371014',
                      name: 'TMSA0041 (O0921)',
                      sourceReferenceId: '944',
                      tenantId: '10007',
                      parentId: '271197302851371013',
                      type: 'Task',
                      pathToParent: '/271197302733930497/271197302851371013/',
                      sortOrder: 6,
                      level: 3,
                      sortablePath: '/_TIMJenm70759EUR/005/006',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                      lastUserSetIsActive: false,
                    },
                  ],
                  values: {
                    '2024W14': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W15': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 63.0,
                  TotalEAC: 63.0,
                  NotPlanned: -13.0,
                  PositiveUnplannedHours: 0,
                  NegativeUnplannedHours: -13.0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: true,
                  editable: false,
                  canAssign: false,
                  canDelete: false,
                  resourceId: '271149770972594176',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '271197302851371013',
                  workItemSourceReferenceId: '943',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: 'Anders Nielsen',
                  id: '271197302851371013',
                  name: 'TMSA004 (O0920)',
                  sourceReferenceId: '943',
                  tenantId: '10007',
                  parentId: '271197302733930497',
                  type: 'Task',
                  pathToParent: '/271197302733930497/',
                  sortOrder: 5,
                  level: 2,
                  sortablePath: '/_TIMJenm70759EUR/005',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
              ],
              values: {
                '2024W14': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: true,
                },
                '2024W15': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: true,
                },
                '2024W16': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: true,
                },
                '2024W17': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: true,
                },
              },
              Budget: 200.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 246.0,
              TotalEAC: 246.0,
              NotPlanned: -46.0,
              PositiveUnplannedHours: 0,
              NegativeUnplannedHours: -46.0,
              total: {
                displayValue: 0,
                value: 0,
              },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '271149770972594176',
              resourceSourceReferenceId: 1,
              resourceType: 'Employee',
              workItemId: '271197302733930497',
              workItemSourceReferenceId: '109',
              workItemType: 'Project',
              hasChildWithEndDateOnTodayOrAfter: false,
              rootParentName: 'Anders Nielsen',
              id: '271197302733930497',
              name: '_TIMJenm70759EUR (P23.0003)',
              sourceReferenceId: '109',
              tenantId: '10007',
              type: 'Project',
              pathToParent: '/',
              sortOrder: 0,
              level: 1,
              sortablePath: '/_TIMJenm70759EUR',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
              lastUserSetIsActive: false,
            },
            {
              StartsAt: '2023-05-05T00:00:00',
              EndsAt: '2023-08-05T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2023-05-05T00:00:00',
                  EndsAt: '2023-08-05T00:00:00',
                  hierarchyType: 'workitem',
                  values: {
                    '2024W14': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W15': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                  },
                  Budget: 50.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 30.0,
                  TotalEAC: 30.0,
                  NotPlanned: 20.0,
                  PositiveUnplannedHours: 20.0,
                  NegativeUnplannedHours: 0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: false,
                  editable: true,
                  canAssign: false,
                  canDelete: true,
                  resourceId: '271149770972594176',
                  resourceSourceReferenceId: 1,
                  resourceType: 'Employee',
                  workItemId: '271197294861221889',
                  workItemSourceReferenceId: '924',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: 'Anders Nielsen',
                  id: '271197294861221889',
                  name: 'Book (O0901)',
                  sourceReferenceId: '924',
                  tenantId: '10007',
                  parentId: '271197294768947200',
                  type: 'Task',
                  pathToParent: '/271197294768947200/',
                  sortOrder: 1,
                  level: 2,
                  sortablePath: '/TCC 55/001',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
              ],
              values: {
                '2024W14': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: true,
                },
                '2024W15': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: true,
                },
                '2024W16': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: true,
                },
                '2024W17': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: true,
                },
              },
              Budget: 50.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 30.0,
              TotalEAC: 30.0,
              NotPlanned: 20.0,
              PositiveUnplannedHours: 20.0,
              NegativeUnplannedHours: 0,
              total: {
                displayValue: 0,
                value: 0,
              },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '271149770972594176',
              resourceSourceReferenceId: 1,
              resourceType: 'Employee',
              workItemId: '271197294768947200',
              workItemSourceReferenceId: '107',
              workItemType: 'Project',
              hasChildWithEndDateOnTodayOrAfter: false,
              rootParentName: 'Anders Nielsen',
              id: '271197294768947200',
              name: 'TCC 55 (P23.0001)',
              sourceReferenceId: '107',
              tenantId: '10007',
              type: 'Project',
              pathToParent: '/',
              sortOrder: 0,
              level: 1,
              sortablePath: '/TCC 55',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
              lastUserSetIsActive: false,
            },
          ],
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 33.6,
              value: 33.6,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 250.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 276.0,
          TotalEAC: 276.0,
          NotPlanned: -26.0,
          PositiveUnplannedHours: 20.0,
          NegativeUnplannedHours: -46.0,
          total: {
            displayValue: 134.4,
            value: 134.4,
          },
          canExpand: true,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149770972594176',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149770972594176',
          name: 'Anders Nielsen (ANI)',
          role: 'Projektleder',
          isActive: false,
          hiredAt: '2019-01-01',
          tenantId: '10007',
          sourceReferenceId: 1,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 33.6,
              value: 33.6,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 134.4,
            value: 134.4,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149780393000960',
          resourceSourceReferenceId: 65,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149780393000960',
          name: 'Åsu Åsu (Åsu)',
          role: '',
          isActive: false,
          hiredAt: '2022-06-22',
          tenantId: '10007',
          sourceReferenceId: 65,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 33.6,
              value: 33.6,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 134.4,
            value: 134.4,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149787292631040',
          resourceSourceReferenceId: 72,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149787292631040',
          name: 'Belinda (A) Bong (BEBI)',
          role: '',
          isActive: false,
          hiredAt: '2023-01-09',
          tenantId: '10007',
          sourceReferenceId: 72,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 33.6,
              value: 33.6,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 134.4,
            value: 134.4,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149759958351872',
          resourceSourceReferenceId: 6,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149759958351872',
          name: 'Carl Chan (CCH)',
          role: 'Leder',
          isActive: false,
          hiredAt: '2020-01-01',
          tenantId: '10007',
          sourceReferenceId: 6,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 26.2,
              value: 26.2,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 26.2,
              value: 26.2,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 26.2,
              value: 26.2,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 82.6,
            value: 82.6,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149772499320832',
          resourceSourceReferenceId: 9,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149772499320832',
          name: 'Erling Sørensen (ESØ)',
          role: 'Bogholder',
          isActive: false,
          hiredAt: '2020-01-01',
          tenantId: '10007',
          sourceReferenceId: 9,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 33.6,
              value: 33.6,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 134.4,
            value: 134.4,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149767474544640',
          resourceSourceReferenceId: 12,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149767474544640',
          name: 'George Best (IMO)',
          role: 'Developer',
          isActive: false,
          hiredAt: '2022-08-01',
          tenantId: '10007',
          sourceReferenceId: 12,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 8.0,
              value: 8.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 20.0,
              value: 20.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 20.0,
              value: 20.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 16.0,
              value: 16.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 64.0,
            value: 64.0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149769018048512',
          resourceSourceReferenceId: 11,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149769018048512',
          name: 'Gerda Angelsen (GAN)',
          role: 'Ekstern konsulent',
          isActive: false,
          hiredAt: '2020-01-01',
          tenantId: '10007',
          sourceReferenceId: 11,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 33.6,
              value: 33.6,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 134.4,
            value: 134.4,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149762797895680',
          resourceSourceReferenceId: 14,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149762797895680',
          name: 'James  Bong (JAB)',
          role: '',
          isActive: false,
          hiredAt: '2021-09-06',
          tenantId: '10007',
          sourceReferenceId: 14,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 33.6,
              value: 33.6,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 134.4,
            value: 134.4,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149780921483264',
          resourceSourceReferenceId: 37,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149780921483264',
          name: 'Jenny Wong (JEW)',
          role: '',
          isActive: false,
          hiredAt: '2021-09-14',
          tenantId: '10007',
          sourceReferenceId: 37,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 33.6,
              value: 33.6,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 134.4,
            value: 134.4,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149774458060800',
          resourceSourceReferenceId: 53,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149774458060800',
          name: 'Linda Lim (LIL)',
          role: '',
          isActive: false,
          hiredAt: '2022-01-10',
          tenantId: '10007',
          sourceReferenceId: 53,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 33.6,
              value: 33.6,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 134.4,
            value: 134.4,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149765897486336',
          resourceSourceReferenceId: 13,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149765897486336',
          name: 'Test TCC Past (TCC)',
          role: 'QA',
          isActive: false,
          hiredAt: '2021-07-01',
          tenantId: '10007',
          sourceReferenceId: 13,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 33.6,
              value: 33.6,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 134.4,
            value: 134.4,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149784239177728',
          resourceSourceReferenceId: 75,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149784239177728',
          name: 'test test (ttt)',
          role: '',
          isActive: false,
          hiredAt: '2023-06-01',
          tenantId: '10007',
          sourceReferenceId: 75,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          children: [
            {
              StartsAt: '2023-05-15T00:00:00',
              EndsAt: '2023-08-15T00:00:00',
              hierarchyType: 'workitem',
              children: [
                {
                  StartsAt: '2023-05-01T00:00:00',
                  EndsAt: '2023-08-31T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      StartsAt: '2023-05-01T00:00:00',
                      EndsAt: '2023-08-31T00:00:00',
                      hierarchyType: 'workitem',
                      values: {
                        '2024W14': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W15': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W16': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                      },
                      Budget: 20.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 38.0,
                      TotalEAC: 38.0,
                      NotPlanned: -18.0,
                      PositiveUnplannedHours: 0,
                      NegativeUnplannedHours: -18.0,
                      total: {
                        displayValue: 0,
                        value: 0,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '271149784671191040',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '271149866762108930',
                      workItemSourceReferenceId: '928',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      rootParentName: 'Todd Boehly',
                      id: '271149866762108930',
                      name: 'Develop                                (O0905)',
                      sourceReferenceId: '928',
                      tenantId: '10007',
                      parentId: '271149866762108929',
                      type: 'Task',
                      pathToParent: '/271149866678222849/271149866762108929/',
                      sortOrder: 2,
                      level: 3,
                      sortablePath: '/RP BI53784/001/002',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                      lastUserSetIsActive: false,
                    },
                    {
                      StartsAt: '2023-05-01T00:00:00',
                      EndsAt: '2023-08-31T00:00:00',
                      hierarchyType: 'workitem',
                      values: {
                        '2024W14': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W15': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W16': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                      },
                      Budget: 10.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 9.0,
                      TotalEAC: 9.0,
                      NotPlanned: 1.0,
                      PositiveUnplannedHours: 1.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 0,
                        value: 0,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '271149784671191040',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '271149866762108931',
                      workItemSourceReferenceId: '929',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      rootParentName: 'Todd Boehly',
                      id: '271149866762108931',
                      name: 'Fix (O0906)',
                      sourceReferenceId: '929',
                      tenantId: '10007',
                      parentId: '271149866762108929',
                      type: 'Task',
                      pathToParent: '/271149866678222849/271149866762108929/',
                      sortOrder: 3,
                      level: 3,
                      sortablePath: '/RP BI53784/001/003',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                      lastUserSetIsActive: false,
                    },
                    {
                      StartsAt: '2023-05-01T00:00:00',
                      EndsAt: '2023-08-31T00:00:00',
                      hierarchyType: 'workitem',
                      values: {
                        '2024W14': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W15': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W16': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                      },
                      Budget: 20.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      NotPlanned: 20.0,
                      PositiveUnplannedHours: 20.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 0,
                        value: 0,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '271149784671191040',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '271149866762108932',
                      workItemSourceReferenceId: '930',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      rootParentName: 'Todd Boehly',
                      id: '271149866762108932',
                      name: 'Testing (O0907)',
                      sourceReferenceId: '930',
                      tenantId: '10007',
                      parentId: '271149866762108929',
                      type: 'Task',
                      pathToParent: '/271149866678222849/271149866762108929/',
                      sortOrder: 4,
                      level: 3,
                      sortablePath: '/RP BI53784/001/004',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                      lastUserSetIsActive: false,
                    },
                    {
                      StartsAt: '2023-05-01T00:00:00',
                      EndsAt: '2023-08-31T00:00:00',
                      hierarchyType: 'workitem',
                      values: {
                        '2024W14': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W15': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W16': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                      },
                      Budget: 5.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 0.0,
                      TotalEAC: 0.0,
                      NotPlanned: 5.0,
                      PositiveUnplannedHours: 5.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 0,
                        value: 0,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '271149784671191040',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '271149866762108933',
                      workItemSourceReferenceId: '931',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      rootParentName: 'Todd Boehly',
                      id: '271149866762108933',
                      name: 'Regression (O0908)',
                      sourceReferenceId: '931',
                      tenantId: '10007',
                      parentId: '271149866762108929',
                      type: 'Task',
                      pathToParent: '/271149866678222849/271149866762108929/',
                      sortOrder: 5,
                      level: 3,
                      sortablePath: '/RP BI53784/001/005',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                      lastUserSetIsActive: false,
                    },
                    {
                      StartsAt: '2023-05-01T00:00:00',
                      EndsAt: '2023-08-31T00:00:00',
                      hierarchyType: 'workitem',
                      values: {
                        '2024W14': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W15': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W16': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                      },
                      Budget: 25.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 8.0,
                      TotalEAC: 8.0,
                      NotPlanned: 17.0,
                      PositiveUnplannedHours: 17.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 0,
                        value: 0,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '271149784671191040',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '271149866762108934',
                      workItemSourceReferenceId: '932',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      rootParentName: 'Todd Boehly',
                      id: '271149866762108934',
                      name: 'Maintenance (O0909)',
                      sourceReferenceId: '932',
                      tenantId: '10007',
                      parentId: '271149866762108929',
                      type: 'Task',
                      pathToParent: '/271149866678222849/271149866762108929/',
                      sortOrder: 6,
                      level: 3,
                      sortablePath: '/RP BI53784/001/006',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                      lastUserSetIsActive: false,
                    },
                  ],
                  values: {
                    '2024W14': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W15': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                  },
                  Budget: 80.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 55.0,
                  TotalEAC: 55.0,
                  NotPlanned: 25.0,
                  PositiveUnplannedHours: 43.0,
                  NegativeUnplannedHours: -18.0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: true,
                  editable: false,
                  canAssign: false,
                  canDelete: false,
                  resourceId: '271149784671191040',
                  resourceSourceReferenceId: 73,
                  resourceType: 'Employee',
                  workItemId: '271149866762108929',
                  workItemSourceReferenceId: '926',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: 'Todd Boehly',
                  id: '271149866762108929',
                  name: 'Development (O0903)',
                  sourceReferenceId: '926',
                  tenantId: '10007',
                  parentId: '271149866678222849',
                  type: 'Task',
                  pathToParent: '/271149866678222849/',
                  sortOrder: 1,
                  level: 2,
                  sortablePath: '/RP BI53784/001',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
                {
                  StartsAt: '2023-05-01T00:00:00',
                  EndsAt: '2023-08-31T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      StartsAt: '2023-05-01T00:00:00',
                      EndsAt: '2023-08-31T00:00:00',
                      hierarchyType: 'workitem',
                      values: {
                        '2024W14': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W15': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W16': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                      },
                      Budget: 5.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 4.0,
                      TotalEAC: 4.0,
                      NotPlanned: 1.0,
                      PositiveUnplannedHours: 1.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 0,
                        value: 0,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '271149784671191040',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '271149866762108936',
                      workItemSourceReferenceId: '933',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      rootParentName: 'Todd Boehly',
                      id: '271149866762108936',
                      name: 'Release notes (O0910)',
                      sourceReferenceId: '933',
                      tenantId: '10007',
                      parentId: '271149866762108935',
                      type: 'Task',
                      pathToParent: '/271149866678222849/271149866762108935/',
                      sortOrder: 8,
                      level: 3,
                      sortablePath: '/RP BI53784/007/008',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                      lastUserSetIsActive: false,
                    },
                    {
                      StartsAt: '2023-05-01T00:00:00',
                      EndsAt: '2023-08-31T00:00:00',
                      hierarchyType: 'workitem',
                      values: {
                        '2024W14': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W15': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W16': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                      },
                      Budget: 15.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 4.0,
                      TotalEAC: 4.0,
                      NotPlanned: 11.0,
                      PositiveUnplannedHours: 11.0,
                      NegativeUnplannedHours: 0,
                      total: {
                        displayValue: 0,
                        value: 0,
                      },
                      canExpand: false,
                      editable: true,
                      canAssign: false,
                      canDelete: true,
                      resourceId: '271149784671191040',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '271149866762108937',
                      workItemSourceReferenceId: '934',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      rootParentName: 'Todd Boehly',
                      id: '271149866762108937',
                      name: 'Patch (O0911)',
                      sourceReferenceId: '934',
                      tenantId: '10007',
                      parentId: '271149866762108935',
                      type: 'Task',
                      pathToParent: '/271149866678222849/271149866762108935/',
                      sortOrder: 9,
                      level: 3,
                      sortablePath: '/RP BI53784/007/009',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                      lastUserSetIsActive: false,
                    },
                  ],
                  values: {
                    '2024W14': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W15': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                  },
                  Budget: 20.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 8.0,
                  TotalEAC: 8.0,
                  NotPlanned: 12.0,
                  PositiveUnplannedHours: 12.0,
                  NegativeUnplannedHours: 0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: true,
                  editable: false,
                  canAssign: false,
                  canDelete: false,
                  resourceId: '271149784671191040',
                  resourceSourceReferenceId: 73,
                  resourceType: 'Employee',
                  workItemId: '271149866762108935',
                  workItemSourceReferenceId: '927',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: 'Todd Boehly',
                  id: '271149866762108935',
                  name: 'Release (O0904)',
                  sourceReferenceId: '927',
                  tenantId: '10007',
                  parentId: '271149866678222849',
                  type: 'Task',
                  pathToParent: '/271149866678222849/',
                  sortOrder: 7,
                  level: 2,
                  sortablePath: '/RP BI53784/007',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
                {
                  StartsAt: '2023-11-08T00:00:00',
                  EndsAt: '2023-11-08T00:00:00',
                  hierarchyType: 'workitem',
                  children: [
                    {
                      StartsAt: '2023-11-08T00:00:00',
                      EndsAt: '2023-11-08T00:00:00',
                      hierarchyType: 'workitem',
                      children: [
                        {
                          StartsAt: '2023-11-08T00:00:00',
                          EndsAt: '2023-11-08T00:00:00',
                          hierarchyType: 'workitem',
                          values: {
                            '2024W14': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: true,
                            },
                            '2024W15': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: true,
                            },
                            '2024W16': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: true,
                            },
                            '2024W17': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: true,
                            },
                          },
                          Budget: 15.0,
                          TotalBooked: 0.0,
                          TotalActualWorkload: 2.0,
                          TotalEAC: 2.0,
                          NotPlanned: 13.0,
                          PositiveUnplannedHours: 13.0,
                          NegativeUnplannedHours: 0,
                          total: {
                            displayValue: 0,
                            value: 0,
                          },
                          canExpand: false,
                          editable: true,
                          canAssign: false,
                          canDelete: true,
                          resourceId: '271149784671191040',
                          resourceSourceReferenceId: 73,
                          resourceType: 'Employee',
                          workItemId: '271149866762108940',
                          workItemSourceReferenceId: '948',
                          workItemType: 'Task',
                          hasChildWithEndDateOnTodayOrAfter: false,
                          rootParentName: 'Todd Boehly',
                          id: '271149866762108940',
                          name: 'Sub testing 1 (O0925)',
                          sourceReferenceId: '948',
                          tenantId: '10007',
                          parentId: '271149866762108939',
                          type: 'Task',
                          pathToParent:
                            '/271149866678222849/271149866762108938/271149866762108939/',
                          sortOrder: 12,
                          level: 4,
                          sortablePath: '/RP BI53784/010/011/012',
                          isActive: false,
                          startAt: '0001-01-01T00:00:00',
                          endAt: '0001-01-01T00:00:00',
                          status: 'InProgress',
                          lastUserSetIsActive: false,
                        },
                        {
                          StartsAt: '2023-11-08T00:00:00',
                          EndsAt: '2023-11-08T00:00:00',
                          hierarchyType: 'workitem',
                          children: [
                            {
                              StartsAt: '2023-11-08T00:00:00',
                              EndsAt: '2023-11-08T00:00:00',
                              hierarchyType: 'workitem',
                              children: [
                                {
                                  StartsAt: '2023-11-08T00:00:00',
                                  EndsAt: '2023-11-08T00:00:00',
                                  hierarchyType: 'workitem',
                                  values: {
                                    '2024W14': {
                                      displayValue: 0,
                                      value: 0,
                                      heatmapCode: '',
                                      editable: true,
                                    },
                                    '2024W15': {
                                      displayValue: 0,
                                      value: 0,
                                      heatmapCode: '',
                                      editable: true,
                                    },
                                    '2024W16': {
                                      displayValue: 0,
                                      value: 0,
                                      heatmapCode: '',
                                      editable: true,
                                    },
                                    '2024W17': {
                                      displayValue: 0,
                                      value: 0,
                                      heatmapCode: '',
                                      editable: true,
                                    },
                                  },
                                  Budget: 0.0,
                                  TotalBooked: 0.0,
                                  TotalActualWorkload: 2.0,
                                  TotalEAC: 2.0,
                                  NotPlanned: -2.0,
                                  PositiveUnplannedHours: 0,
                                  NegativeUnplannedHours: -2.0,
                                  total: {
                                    displayValue: 0,
                                    value: 0,
                                  },
                                  canExpand: false,
                                  editable: true,
                                  canAssign: false,
                                  canDelete: true,
                                  resourceId: '271149784671191040',
                                  resourceSourceReferenceId: 73,
                                  resourceType: 'Employee',
                                  workItemId: '271149866762108943',
                                  workItemSourceReferenceId: '951',
                                  workItemType: 'Task',
                                  hasChildWithEndDateOnTodayOrAfter: false,
                                  rootParentName: 'Todd Boehly',
                                  id: '271149866762108943',
                                  name: 'Sub task 2.1.1 (O0928)',
                                  sourceReferenceId: '951',
                                  tenantId: '10007',
                                  parentId: '271149866762108942',
                                  type: 'Task',
                                  pathToParent:
                                    '/271149866678222849/271149866762108938/271149866762108939/271149866762108941/271149866762108942/',
                                  sortOrder: 15,
                                  level: 6,
                                  sortablePath: '/RP BI53784/010/011/013/014/015',
                                  isActive: false,
                                  startAt: '0001-01-01T00:00:00',
                                  endAt: '0001-01-01T00:00:00',
                                  status: 'InProgress',
                                  lastUserSetIsActive: false,
                                },
                              ],
                              values: {
                                '2024W14': {
                                  displayValue: 0,
                                  value: 0,
                                  heatmapCode: '',
                                  editable: true,
                                },
                                '2024W15': {
                                  displayValue: 0,
                                  value: 0,
                                  heatmapCode: '',
                                  editable: true,
                                },
                                '2024W16': {
                                  displayValue: 0,
                                  value: 0,
                                  heatmapCode: '',
                                  editable: true,
                                },
                                '2024W17': {
                                  displayValue: 0,
                                  value: 0,
                                  heatmapCode: '',
                                  editable: true,
                                },
                              },
                              Budget: 0.0,
                              TotalBooked: 0.0,
                              TotalActualWorkload: 2.0,
                              TotalEAC: 2.0,
                              NotPlanned: -2.0,
                              PositiveUnplannedHours: 0,
                              NegativeUnplannedHours: -2.0,
                              total: {
                                displayValue: 0,
                                value: 0,
                              },
                              canExpand: true,
                              editable: false,
                              canAssign: false,
                              canDelete: false,
                              resourceId: '271149784671191040',
                              resourceSourceReferenceId: 73,
                              resourceType: 'Employee',
                              workItemId: '271149866762108942',
                              workItemSourceReferenceId: '950',
                              workItemType: 'Task',
                              hasChildWithEndDateOnTodayOrAfter: false,
                              rootParentName: 'Todd Boehly',
                              id: '271149866762108942',
                              name: 'Sub task 2.1 (O0927)',
                              sourceReferenceId: '950',
                              tenantId: '10007',
                              parentId: '271149866762108941',
                              type: 'Task',
                              pathToParent:
                                '/271149866678222849/271149866762108938/271149866762108939/271149866762108941/',
                              sortOrder: 14,
                              level: 5,
                              sortablePath: '/RP BI53784/010/011/013/014',
                              isActive: false,
                              startAt: '0001-01-01T00:00:00',
                              endAt: '0001-01-01T00:00:00',
                              status: 'InProgress',
                              lastUserSetIsActive: false,
                            },
                          ],
                          values: {
                            '2024W14': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: true,
                            },
                            '2024W15': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: true,
                            },
                            '2024W16': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: true,
                            },
                            '2024W17': {
                              displayValue: 0,
                              value: 0,
                              heatmapCode: '',
                              editable: true,
                            },
                          },
                          Budget: 0.0,
                          TotalBooked: 0.0,
                          TotalActualWorkload: 2.0,
                          TotalEAC: 2.0,
                          NotPlanned: -2.0,
                          PositiveUnplannedHours: 0,
                          NegativeUnplannedHours: -2.0,
                          total: {
                            displayValue: 0,
                            value: 0,
                          },
                          canExpand: true,
                          editable: false,
                          canAssign: false,
                          canDelete: false,
                          resourceId: '271149784671191040',
                          resourceSourceReferenceId: 73,
                          resourceType: 'Employee',
                          workItemId: '271149866762108941',
                          workItemSourceReferenceId: '949',
                          workItemType: 'Task',
                          hasChildWithEndDateOnTodayOrAfter: false,
                          rootParentName: 'Todd Boehly',
                          id: '271149866762108941',
                          name: 'Sub testing 2 (O0926)',
                          sourceReferenceId: '949',
                          tenantId: '10007',
                          parentId: '271149866762108939',
                          type: 'Task',
                          pathToParent:
                            '/271149866678222849/271149866762108938/271149866762108939/',
                          sortOrder: 13,
                          level: 4,
                          sortablePath: '/RP BI53784/010/011/013',
                          isActive: false,
                          startAt: '0001-01-01T00:00:00',
                          endAt: '0001-01-01T00:00:00',
                          status: 'InProgress',
                          lastUserSetIsActive: false,
                        },
                      ],
                      values: {
                        '2024W14': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W15': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W16': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                        '2024W17': {
                          displayValue: 0,
                          value: 0,
                          heatmapCode: '',
                          editable: true,
                        },
                      },
                      Budget: 15.0,
                      TotalBooked: 0.0,
                      TotalActualWorkload: 4.0,
                      TotalEAC: 4.0,
                      NotPlanned: 11.0,
                      PositiveUnplannedHours: 13.0,
                      NegativeUnplannedHours: -2.0,
                      total: {
                        displayValue: 0,
                        value: 0,
                      },
                      canExpand: true,
                      editable: false,
                      canAssign: false,
                      canDelete: false,
                      resourceId: '271149784671191040',
                      resourceSourceReferenceId: 73,
                      resourceType: 'Employee',
                      workItemId: '271149866762108939',
                      workItemSourceReferenceId: '947',
                      workItemType: 'Task',
                      hasChildWithEndDateOnTodayOrAfter: false,
                      rootParentName: 'Todd Boehly',
                      id: '271149866762108939',
                      name: 'Sub testing (O0924)',
                      sourceReferenceId: '947',
                      tenantId: '10007',
                      parentId: '271149866762108938',
                      type: 'Task',
                      pathToParent: '/271149866678222849/271149866762108938/',
                      sortOrder: 11,
                      level: 3,
                      sortablePath: '/RP BI53784/010/011',
                      isActive: false,
                      startAt: '0001-01-01T00:00:00',
                      endAt: '0001-01-01T00:00:00',
                      status: 'InProgress',
                      lastUserSetIsActive: false,
                    },
                  ],
                  values: {
                    '2024W14': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W15': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W16': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                    '2024W17': {
                      displayValue: 0,
                      value: 0,
                      heatmapCode: '',
                      editable: true,
                    },
                  },
                  Budget: 15.0,
                  TotalBooked: 0.0,
                  TotalActualWorkload: 4.0,
                  TotalEAC: 4.0,
                  NotPlanned: 11.0,
                  PositiveUnplannedHours: 11.0,
                  NegativeUnplannedHours: 0,
                  total: {
                    displayValue: 0,
                    value: 0,
                  },
                  canExpand: true,
                  editable: false,
                  canAssign: false,
                  canDelete: false,
                  resourceId: '271149784671191040',
                  resourceSourceReferenceId: 73,
                  resourceType: 'Employee',
                  workItemId: '271149866762108938',
                  workItemSourceReferenceId: '946',
                  workItemType: 'Task',
                  hasChildWithEndDateOnTodayOrAfter: false,
                  rootParentName: 'Todd Boehly',
                  id: '271149866762108938',
                  name: 'testing (O0923)',
                  sourceReferenceId: '946',
                  tenantId: '10007',
                  parentId: '271149866678222849',
                  type: 'Task',
                  pathToParent: '/271149866678222849/',
                  sortOrder: 10,
                  level: 2,
                  sortablePath: '/RP BI53784/010',
                  isActive: false,
                  startAt: '0001-01-01T00:00:00',
                  endAt: '0001-01-01T00:00:00',
                  status: 'InProgress',
                  lastUserSetIsActive: false,
                },
              ],
              values: {
                '2024W14': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: true,
                },
                '2024W15': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: true,
                },
                '2024W16': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: true,
                },
                '2024W17': {
                  displayValue: 0,
                  value: 0,
                  heatmapCode: '',
                  editable: true,
                },
              },
              Budget: 115.0,
              TotalBooked: 0.0,
              TotalActualWorkload: 67.0,
              TotalEAC: 67.0,
              NotPlanned: 48.0,
              PositiveUnplannedHours: 48.0,
              NegativeUnplannedHours: 0,
              total: {
                displayValue: 0,
                value: 0,
              },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '271149784671191040',
              resourceSourceReferenceId: 73,
              resourceType: 'Employee',
              workItemId: '271149866678222849',
              workItemSourceReferenceId: '108',
              workItemType: 'Project',
              hasChildWithEndDateOnTodayOrAfter: false,
              rootParentName: 'Todd Boehly',
              id: '271149866678222849',
              name: 'RP BI53784 (P23.0002)',
              sourceReferenceId: '108',
              tenantId: '10007',
              type: 'Project',
              pathToParent: '/',
              sortOrder: 0,
              level: 1,
              sortablePath: '/RP BI53784',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
              lastUserSetIsActive: false,
            },
          ],
          values: {
            '2024W14': {
              displayValue: 18.8,
              value: 18.8,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W15': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W16': {
              displayValue: 41.0,
              value: 41.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024W17': {
              displayValue: 33.6,
              value: 33.6,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
          },
          Budget: 115.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 67.0,
          TotalEAC: 67.0,
          NotPlanned: 48.0,
          PositiveUnplannedHours: 48.0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 134.4,
            value: 134.4,
          },
          canExpand: true,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '271149784671191040',
          resourceSourceReferenceId: 73,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '271149784671191040',
          name: 'Todd Boehly (tbl)',
          role: '',
          isActive: false,
          hiredAt: '2023-01-01',
          tenantId: '10007',
          sourceReferenceId: 73,
          type: 'Employee',
        },
      ],
    },
    links: [
      {
        href: 'http://test1.resourceplanner2.aks.timelog.com:80/plan/partialgroupbyemployee?periodstartsat=2024-04-04&periodendsat=2024-04-28&periodtypes=week&unittypes=hours&reportingtypes=availability&showprojectno=true&showtaskno=true&firstload=true',
        rel: 'self',
      },
    ],
  },
  DeterministicHash: 787539570,
  StatusCode: 200,
};
