import { TFunction } from 'i18next';
import {
  IMenus,
  IMenusProperties,
  IMenusPropertiesWithPagePath,
  ISubMenusWithPagePath,
  IExtendsMenuProps,
} from 'src/apis/types/menusAPI';
import { CustomTypeOptions } from 'react-i18next';
import { removeEmptySpace } from 'src/utils/string';
import { formatUrl } from 'src/utils/url';
import { translationFallBack } from 'src/utils/translation';

export const parseMenu = (
  menu: IMenus | undefined,
  translateFunction: TFunction<keyof CustomTypeOptions['resources']>,
): IMenusPropertiesWithPagePath[] => {
  const parsingMenuItem = <T extends IMenusProperties>({
    pageUrl = '',
    pageIdentifier,
    name,
    subMenus,
  }: T): IExtendsMenuProps => {
    const id = pageIdentifier || removeEmptySpace(name);

    return {
      id,
      pagePath: formatUrl(pageUrl),
      translation: translationFallBack({
        translationKey: subMenus && subMenus?.length > 0 ? `${id}Parent` : pageIdentifier,
        fallback: name,
        translateFunction,
      }),
    };
  };

  return (
    menu?.entities?.map(({ properties: { ...pageRestProps } }) => {
      const { subMenus = [] } = pageRestProps;
      return {
        ...parsingMenuItem(pageRestProps),
        ...pageRestProps,
        subMenus: subMenus?.map(
          (menuItem) => ({ ...parsingMenuItem(menuItem), ...menuItem } as ISubMenusWithPagePath),
        ),
      };
    }) || []
  );
};
