import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { eventKeyEscape } from 'src/constants/keyboardKey';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import {
  useActiveContainerID,
  useFilterDispatch,
  useMatchSelectedFilterKey,
} from 'src/stores/FilterStore';
import { stringToPascal } from 'src/utils/string';
import { translationAnyText } from 'src/utils/translation';
import { TFilterType } from 'src/apis/types/filterListAPI';
import { InputContainer } from '../../../InputContainer';
import { FilterTag } from '../FilterTag';

interface IFilterItem {
  label: string;
  id: string;
  items: Array<{
    label: string;
  }>;
  contentUrl?: string;
  childFilters?: Array<string>;
  parentFilters?: Array<string>;
  type?: TFilterType;
}

export const FilterItem = ({
  label,
  id,
  items,
  contentUrl,
  childFilters = [],
  parentFilters = [],
  type,
}: IFilterItem) => {
  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useFilterDispatch();
  const activeContainerID = useActiveContainerID();
  const { t } = useTranslation('filter');
  const onClickOutside = () => {
    if (activeContainerID === id && dispatch) {
      dispatch({ type: 'DEACTIVATE_CONTAINER_ID' });
    }
  };
  useOnClickOutside(ref, onClickOutside);
  const matchChild = useMatchSelectedFilterKey(childFilters);

  const removeFilterTag = () => {
    if (dispatch) {
      matchChild?.forEach((filterID) => {
        dispatch({ type: 'RESET_FILTER_ID', payload: { filterID } });
      });

      dispatch({ type: 'REMOVE_FILTER', payload: { filterID: id } });
    }
  };

  const onClick = () => {
    if (dispatch) {
      if (activeContainerID === id) {
        dispatch({ type: 'DEACTIVATE_CONTAINER_ID' });
      } else {
        dispatch({ type: 'SET_ACTIVATE_CONTAINER_ID', payload: { filterID: id } });
        dispatch({ type: 'DEACTIVATE_PANEL_ID' });
      }
    }
  };

  useEffect(() => {
    const filterTag = ref?.current;
    if (activeContainerID === id && filterTag) {
      filterTag.focus();

      filterTag.addEventListener('keydown', (e: KeyboardEvent) => {
        const { key } = e;
        if (key === eventKeyEscape && dispatch) {
          e.stopPropagation();
          dispatch({ type: 'DEACTIVATE_CONTAINER_ID' });
        }
      });
    }
  }, [activeContainerID, dispatch, id]);

  return (
    <div ref={ref}>
      <FilterTag
        title={translationAnyText(t, `FilterInputName${stringToPascal(label)}`)}
        items={items}
        removeFilterTag={removeFilterTag}
        onClick={onClick}
        panelIsOpen={activeContainerID === id}
      >
        {activeContainerID === id && (
          <InputContainer
            filterId={id}
            fieldLabel={label}
            contentUrl={contentUrl}
            childFilters={childFilters}
            parentFilters={parentFilters}
            type={type}
            data-automation-id={`MainFilterAutoComplete${stringToPascal(label)}`}
          />
        )}
      </FilterTag>
    </div>
  );
};
