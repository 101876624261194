import {
  Box,
  Chip,
  CircularProgress,
  Typography,
  Tooltip,
  Stack,
} from 'src/components/mui-components';
import { format } from 'date-fns';
import { useGetTimeTrackingDelayReportingWidget } from 'src/apis/reportingWidgetsAPI';
import { Trans, useTranslation } from 'react-i18next';
import { WidgetError } from 'src/screens/Home/components/ReportingWidgetState';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import { getDateLocaleFromUserLanguage } from 'src/utils/date';
import {
  SentimentDissatisfiedOutlined,
  SentimentNeutralOutlined,
  SentimentSatisfiedOutlined,
  SentimentVeryDissatisfiedOutlined,
  SentimentVerySatisfiedOutlined,
  TrendingDown,
  TrendingUp,
} from '@mui/icons-material';
import ReportingWidgetCard from '../ReportingWidgetCard';

interface IChipStatus {
  label: string;
  color: 'warning' | 'success' | 'default' | 'error' | 'primary' | 'secondary' | 'info' | undefined;
  icon: React.ReactElement;
}

export const TimeTrackingDelayReportingWidget = () => {
  const { widgetName, widgetData, isLoading, isError } = useGetTimeTrackingDelayReportingWidget();
  const { currentLanguage } = useGetCurrentLanguage();
  const dateLocale = getDateLocaleFromUserLanguage(currentLanguage);
  const { lastRegistration, currentPeriodDelayedTimeTracking, previousPeriodDelayedTimeTracking } =
    widgetData;

  const getDisplayDateByFormat = (date: Date, dateFormat: string) =>
    format(new Date(date), dateFormat, {
      locale: dateLocale,
    });

  const displayDate =
    lastRegistration == null ? '-' : getDisplayDateByFormat(lastRegistration, 'dd MMMM yyyy');

  const displayMonth = getDisplayDateByFormat(new Date(), 'MMMM'); // always show current month

  const { t } = useTranslation('reportingWidgets');

  const roundedCurrentScore =
    currentPeriodDelayedTimeTracking < 0 ? 0 : Math.round(currentPeriodDelayedTimeTracking);

  const roundedPreviousScore =
    previousPeriodDelayedTimeTracking < 0 ? 0 : Math.round(previousPeriodDelayedTimeTracking);
  const companyTarget = 1;

  const isCurrentPerformanceBetter = roundedPreviousScore > roundedCurrentScore;

  const arrowDirection = () =>
    isCurrentPerformanceBetter ? (
      <Tooltip placement="right" title={t('TimeTrackingDelayTrendDownInfoText')}>
        <TrendingDown color="success" sx={{ width: '40px', height: '40px' }} />
      </Tooltip>
    ) : (
      <Tooltip placement="right" title={t('TimeTrackingDelayTrendUpInfoText')}>
        <TrendingUp color="error" sx={{ width: '40px', height: '40px' }} />
      </Tooltip>
    );

  const getChipStatus = (target: number): IChipStatus => {
    switch (true) {
      case target === 0:
        return {
          label: t('ChipAwesomeLabel'),
          color: 'success',
          icon: <SentimentVerySatisfiedOutlined sx={{ width: '20px', height: '20px' }} />,
        };
      case target >= 1 && target <= 2:
        return {
          label: t('ChipVeryGoodLabel'),
          color: 'success',
          icon: <SentimentSatisfiedOutlined sx={{ width: '20px', height: '20px' }} />,
        };
      case target === 3:
        return {
          label: t('ChipAverageLabel'),
          color: 'warning',
          icon: <SentimentNeutralOutlined sx={{ width: '20px', height: '20px' }} />,
        };
      case target >= 4 && target <= 5:
        return {
          label: t('ChipPoorLabel'),
          color: 'warning',
          icon: <SentimentDissatisfiedOutlined sx={{ width: '20px', height: '20px' }} />,
        };
      case target >= 6:
        return {
          label: t('ChipVeryPoorLabel'),
          color: 'error',
          icon: <SentimentVeryDissatisfiedOutlined sx={{ width: '20px', height: '20px' }} />,
        };
      default:
        return {
          label: t('ChipNoRegistrationLabel'),
          color: 'primary',
          icon: <div />,
        };
    }
  };

  const chipStatus = getChipStatus(
    currentPeriodDelayedTimeTracking === null ? -1 : roundedCurrentScore,
  );

  const daysDifference = (startDate: Date, endDate: Date): number => {
    const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
    const diffInTime = endDate.getTime() - startDate.getTime();

    return Math.round(diffInTime / oneDayInMilliseconds);
  };

  const daysSinceLastRegistration =
    lastRegistration === null ? 0 : daysDifference(new Date(lastRegistration), new Date());

  const renderWidgetContentBasedOnState = () => {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      );
    }
    if (isError) {
      return <WidgetError />;
    }

    return (
      <Stack>
        <Typography>{t('TimeTrackingDelayFirstLabel')}</Typography>
        <br />
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="h1">
            <Trans
              i18nKey="TimeTrackingDelayAboveWidgetLabel"
              key="TimeTrackingDelayAboveWidgetLabel"
              defaults={t('TimeTrackingDelayAboveWidgetLabel')}
              values={{ month: displayMonth.toLocaleUpperCase() }}
            />
          </Typography>

          <Stack direction="row" justifyContent="center" alignItems="center">
            <Typography fontSize="96px" component="div">
              {currentPeriodDelayedTimeTracking === null ? '--' : roundedCurrentScore}
            </Typography>
            {currentPeriodDelayedTimeTracking === null ||
            roundedPreviousScore === roundedCurrentScore ? (
              <div />
            ) : (
              arrowDirection()
            )}
          </Stack>
          <Typography>
            <Trans
              i18nKey="TimeTrackingDelayBelowWidgetLabel"
              key="TimeTrackingDelayBelowWidgetLabel"
              defaults={t('TimeTrackingDelayBelowWidgetLabel')}
              values={{
                daysCount: previousPeriodDelayedTimeTracking === null ? '--' : roundedPreviousScore,
              }}
            />
          </Typography>

          <Typography>
            <Trans
              i18nKey="TimeTrackingDelayCompanyTargetLabel"
              key="TimeTrackingDelayCompanyTargetLabel"
              defaults={t('TimeTrackingDelayCompanyTargetLabel')}
              values={{ daysCount: companyTarget }}
            />
          </Typography>

          <Chip label={chipStatus.label} color={chipStatus.color} icon={chipStatus.icon} />
          <div />
          {lastRegistration != null && (
            <Typography color={daysSinceLastRegistration >= 7 ? 'error' : undefined}>{`${t(
              'TimeTrackingDelayLastLabel',
            )} ${displayDate}`}</Typography>
          )}
        </Stack>
      </Stack>
    );
  };

  return (
    <ReportingWidgetCard
      title={t('TimeTrackingDelay')}
      name={widgetName}
      infoIconText={t('TimeTrackingDelayWidgetDescription')}
      hasAction
    >
      {renderWidgetContentBasedOnState()}
    </ReportingWidgetCard>
  );
};
