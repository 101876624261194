export default {
  Entities: [
    {
      Properties: {
        FilterCategory: 'General',
        FilterItems: [
          {
            Id: 5,
            Name: 'LegalEntity',
            ContentUrl: 'api/v2/filters/legal-entity/content',
            ChildFilters: ['Project', 'ProjectCategory'],
            Type: 'MultiSelectTypeSearch',
          },
          {
            Id: 10,
            Name: 'Customer',
            ContentUrl: 'api/v2/filters/customer/content',
            ChildFilters: ['Project', 'ProjectCategory'],
            Type: 'MultiSelectTypeSearch',
          },
          {
            Id: 12,
            Name: 'Department',
            ContentUrl: 'api/v2/filters/department/content',
            ChildFilters: ['Project', 'ApprovalManager', 'Customer'],
            ParentFilters: ['LegalEntity'],
            Type: 'MultiSelectTypeSearch',
          },
          {
            Id: 13,
            Name: 'CustomerOwner',
            ContentUrl: 'api/v2/filters/customer-owner/content',
            ChildFilters: ['Project', 'ApprovalManager', 'Customer'],
            ParentFilters: ['LegalEntity'],
            Type: 'MultiSelectTypeSearch',
          },
          {
            Id: 14,
            Name: 'Contact',
            ContentUrl: 'api/v2/filters/contact/content',
            ChildFilters: ['Project', 'ApprovalManager', 'Customer'],
            ParentFilters: ['LegalEntity'],
            Type: 'MultiSelectTypeSearch',
          },
          {
            Id: 15,
            Name: 'AccountManager',
            ContentUrl: 'api/v2/filters/account-manager/content',
            ChildFilters: ['Project', 'ApprovalManager', 'Customer'],
            ParentFilters: ['LegalEntity'],
            Type: 'MultiSelectTypeSearch',
          },
          {
            Id: 16,
            Name: 'Partner',
            ContentUrl: 'api/v2/filters/partner/content',
            ChildFilters: ['Project', 'ApprovalManager', 'Customer'],
            ParentFilters: ['LegalEntity'],
            Type: 'MultiSelectTypeSearch',
          },
          {
            Id: 17,
            Name: 'ContractModel',
            ContentUrl: 'api/v2/filters/contract-model/content',
            ChildFilters: ['Project', 'ApprovalManager', 'Customer'],
            ParentFilters: ['LegalEntity'],
            Type: 'MultiSelectTypeSearch',
          },
          {
            Id: 18,
            Name: 'ContractStatus',
            ContentUrl: 'api/v2/filters/contract-status/content',
            ChildFilters: ['Project', 'ApprovalManager', 'Customer'],
            ParentFilters: ['LegalEntity'],
            Type: 'MultiSelectTypeSearch',
          },
        ],
      },
    },
    {
      Properties: {
        FilterCategory: 'Project',
        FilterItems: [
          {
            Id: 3,
            Name: 'ProjectStatus',
            ContentUrl: 'api/v2/filters/project-status/content',
            ChildFilters: ['AccountManager', 'ApprovalManager'],
            ParentFilters: ['ProjectManager', 'ContractType'],
            Type: 'MultiSelectTypeSearch',
          },
          {
            Id: 9,
            Name: 'ProjectManager',
            ContentUrl: 'api/v2/filters/project-manager/content',
            ChildFilters: ['Task', 'TaskType'],
            ParentFilters: ['ContractType', 'Project'],
            Type: 'MultiSelectTypeSearch',
          },
          {
            Id: 48,
            Name: 'ProjectType',
            ContentUrl: 'api/v2/filters/project-type/content',
            ChildFilters: ['ProjectCategory', 'Project'],
            ParentFilters: ['DepartmentEmployee', 'EmployeeType'],
            Type: 'MultiSelectTypeSearch',
          },
          {
            Id: 49,
            Name: 'ProjectCategory',
            ContentUrl: 'api/v2/filters/project-category/content',
            ChildFilters: ['ProjectCategory', 'Project'],
            ParentFilters: ['DepartmentEmployee', 'EmployeeType'],
            Type: 'MultiSelectTypeSearch',
          },
          {
            Id: 50,
            Name: 'ProjectStage',
            ContentUrl: 'api/v2/filters/project-stage/content',
            ChildFilters: ['ProjectCategory', 'Project'],
            ParentFilters: ['DepartmentEmployee', 'EmployeeType'],
            Type: 'MultiSelectTypeSearch',
          },
          {
            Id: 51,
            Name: 'ProjectStage',
            ContentUrl: 'api/v2/filters/project-stage/content',
            ChildFilters: ['ProjectCategory', 'Project'],
            ParentFilters: ['DepartmentEmployee', 'EmployeeType'],
            Type: 'MultiSelectTypeSearch',
          },
          {
            Id: 52,
            Name: 'ProjectInactivityPeriod',
            ContentUrl: 'api/v2/filters/project-inactivity-period/content',
            singleSelect: true,
            Type: 'SingleSelectTypeSearch',
          },
        ],
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/filters/list/DesignSevenExampleReport',
      Rel: 'self',
    },
  ],
};
