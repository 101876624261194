import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { getPathWithQueries } from 'src/utils/url/url';
import { GET_RECENT_RESOURCES_KEY } from './getRecentResourcesAPI.constants';
import { TRecentResourcesRes } from './getRecentResourcesAPI.types';

const GET_RECENT_RESOURCES_URL_PATH = '/api/v2/resource-planner/resources/recently-registered';

interface IGetRecentResources {
  taskId: string;
}

const getRecentResources = ({ taskId }: IGetRecentResources): Promise<TRecentResourcesRes> =>
  getFetch({
    path: getPathWithQueries(GET_RECENT_RESOURCES_URL_PATH, {
      taskId,
    }),
    key: GET_RECENT_RESOURCES_KEY,
  });

export const useGetRecentResources = (params: IGetRecentResources, enableQuery?: boolean) => {
  const { taskId } = params;
  const { data, ...rest } = useQuery(
    [GET_RECENT_RESOURCES_KEY, taskId],
    () => getRecentResources(params),
    { enabled: enableQuery && !!taskId },
  );

  return { data: data?.entities, ...rest };
};
