import { Shimmer, Stack } from 'src/components/ui-components';
import styles from './WidgetWithoutChartShimmer.module.scss';

export default () => (
  <div className={styles.widgetWrapper} data-automation-id="WidgetLoading">
    <Stack verticalMargin="large">
      <Shimmer width="half" minHeight="heading" />
      <Stack verticalMargin="small">
        <Shimmer width="half" minHeight="subHeading" />
        <Shimmer width="thirdQuarter" minHeight="body" />
        <Shimmer width="half" minHeight="subHeading" />
        <Shimmer width="thirdQuarter" minHeight="body" />
      </Stack>
      <Shimmer width="quarter" minHeight="tips" />
    </Stack>
  </div>
);
