import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { IAverageHourlyRate } from 'src/apis/types/averageHourlyRateAPI';
import { concatQuerys } from 'src/utils/url';
import { IPaginationModel } from 'src/apis/types/paginationModelAPI';

const GET_AVERAGE_HOURLY_RATE_REPORTING_WIDGET_URL_PATH =
  '/api/v2/insights-service/average-hourly-rate';
const GET_AVERAGE_HOURLY_RATE_REPORTING_WIDGET_MSW_STATUS_KEY = 'getAverageHourlyRateAPI';

const GET_AVERAGE_HOURLY_RATE_REPORTING_WIDGET_KEY = 'GET_AVERAGE_HOURLY_RATE_REPORTING_WIDGET_KEY';

const getAverageHourlyRateReportingWidget = (
  page: number = 0,
  pageSize: number = 0,
): Promise<IAverageHourlyRate> =>
  getFetch({
    path: `${GET_AVERAGE_HOURLY_RATE_REPORTING_WIDGET_URL_PATH}${concatQuerys([
      { param: '$pagesize', value: pageSize.toString() },
      { param: '$page', value: page.toString() },
    ])}`,
    key: GET_AVERAGE_HOURLY_RATE_REPORTING_WIDGET_MSW_STATUS_KEY,
  });

export const useGetAverageHourlyRateReportingWidget = ({ page, pageSize }: IPaginationModel) => {
  const { data, ...restProps } = useQuery(
    [GET_AVERAGE_HOURLY_RATE_REPORTING_WIDGET_KEY, page, pageSize],
    () => getAverageHourlyRateReportingWidget(page + 1, pageSize),
    { keepPreviousData: true },
  );

  if (data?.properties) {
    return {
      widgetName: data?.properties.type,
      widgetData: data?.properties.data,
      currencyAbb: data?.properties.systemCurrencyAbb,
      paginationInfo: {
        page: data?.properties.paginationInfo.pageNumber,
        totalRecord: data?.properties.paginationInfo.totalRecord,
      },
      ...restProps,
    };
  }

  return {
    widgetName: '',
    widgetData: [],
    currencyAbb: '',
    paginationInfo: {
      page: 0,
      totalRecord: 0,
    },
    ...restProps,
  };
};
