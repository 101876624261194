export default {
  Entities: [
    {
      Properties: {
        CurrencyName: 'Bulgarske lev',
        CurrencyAbb: 'BGN',
        CurrencyId: 2,
        IsBaseCurrency: false,
      },
    },
    {
      Properties: {
        CurrencyName: 'Malaysian ringgit',
        CurrencyAbb: 'MYR',
        CurrencyId: 21,
        IsBaseCurrency: false,
      },
    },
    {
      Properties: {
        CurrencyName: 'Svenske kroner',
        CurrencyAbb: 'SEK',
        CurrencyId: 28,
        IsBaseCurrency: false,
      },
    },
    {
      Properties: {
        CurrencyName: 'Singapore dollar',
        CurrencyAbb: 'SGD',
        CurrencyId: 29,
        IsBaseCurrency: false,
      },
    },
    {
      Properties: {
        CurrencyName: 'Thailandske baht',
        CurrencyAbb: 'THB',
        CurrencyId: 31,
        IsBaseCurrency: false,
      },
    },
    {
      Properties: {
        CurrencyName: 'Danish Kroner',
        CurrencyAbb: 'DKK',
        CurrencyId: 35,
        IsBaseCurrency: true,
      },
    },
    {
      Properties: {
        CurrencyName: 'Euro',
        CurrencyAbb: 'EUR',
        CurrencyId: 9,
        IsBaseCurrency: false,
      },
    },
  ],
  Links: [
    {
      Href: 'http://mockapi/api/v1/exchange-rate/get-distinct-currencies',
      Rel: 'self',
    },
  ],
};
