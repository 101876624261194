/* eslint-disable no-console */
export default {
  info: (info: unknown) => {
    if (import.meta.env.VITE_DISPLAY_LOG) {
      console.log(info);
    }
  },
  warn: (warn: unknown) => {
    console.warn(warn);
  },
  error: (error: unknown) => {
    console.error(error);
  },
};
