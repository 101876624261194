import { Namespace, TFunction } from 'i18next';
import { CustomTypeOptions } from 'react-i18next';

interface ITranslationFallBackProps {
  translateFunction: TFunction<Namespace<keyof CustomTypeOptions['resources']>>;
  fallback: string;
  translationKey: unknown;
}

// This should work much simpler according to
// https://react.i18next.com/latest/typescript
// however, it does not - that's why this approach!
export const translationAnyText = (t: TFunction, text: string) =>
  t(text as unknown as TemplateStringsArray);

export const translationFallBack = ({
  translateFunction,
  fallback,
  translationKey,
}: ITranslationFallBackProps): string => {
  const translatedText = translateFunction(translationKey as TemplateStringsArray);
  return translationKey === translatedText ? fallback : translatedText;
};
