import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { APP_URL } from 'src/constants/cookies';
import { TARGET_DESTINATION } from 'src/constants/localstorage';
import { loginPortalUrl } from 'src/utils/url';

interface AuthRouteProps {
  children: JSX.Element;
}

const getRedirectPath = (pathname: string): 'children' | 'login' => {
  const appUrl = Cookies.get(APP_URL);

  if (appUrl) {
    return 'children';
  }

  localStorage.setItem(TARGET_DESTINATION, pathname);

  return 'login';
};

export const getRedirectPathForTest = getRedirectPath;

export const AuthRoute = ({ children }: AuthRouteProps) => {
  const { pathname } = useLocation();

  const result = getRedirectPath(pathname);

  if (result === 'login') {
    window.location.href = loginPortalUrl;
    return null;
  }

  return children;
};
