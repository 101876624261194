import { Flex, Icon, Text } from 'src/components/ui-components';
import { TIconName } from 'src/assets/variables/icon';

interface IInfo {
  iconName: TIconName;
  description: string;
}

const Info = ({ iconName, description }: IInfo) => (
  <Flex>
    <Flex.Item>
      <Icon size="small" iconName={iconName} />
    </Flex.Item>
    <Flex.Item>
      <Text as="p" wrapWords>
        {description}
      </Text>
    </Flex.Item>
  </Flex>
);

export default Info;
