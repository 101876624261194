import { IPropertiesResponse } from 'src/apis/types/responseBase';

export const USER_URL_PATH = '/api/v1/user';
export const USER_MSW_STATUS_KEY = 'userAPI';
export const GET_USER_KEY = 'USER';

export interface IUser extends IPropertiesResponse<IUserProperties> {}

export interface IUserProperties {
  firstName?: string;
  id?: string;
  initials?: string;
  isActive?: boolean;
  lastName?: string;
  userId?: number;
  userType?: string;
}
