import { ISavedView, IPeriod } from 'src/apis/savedViewAPI';

export const RPProjectViewDisableExpandAllCount = 25;

export const RPEmployeeViewDisableExpandAllCount = RPProjectViewDisableExpandAllCount;

export const RPShimmerRowCount = 1;

export const PERIOD: IPeriod[] = [
  { id: 'this_week', label: 'This week' },
  { id: 'this_and_next_week', label: 'This and next week' },
  { id: 'this_and_next_three_weeks', label: 'This and next three weeks' },
  { id: 'this_month', label: 'This month' },
  { id: 'this_and_next_month', label: 'This and next month' },
  { id: 'this_quarter', label: 'This quarter' },
  { id: 'this_and_next_quarter', label: 'This and next quarter' },
  { id: 'this_year', label: 'This year' },
];

export const SNACKBAR_CONFIG = [
  {
    type: 'save',
    title: 'SaveViewSuccessToastTitle',
    description: 'SaveViewSuccessToastBody',
  },
  {
    type: 'edit',
    title: 'UpdateViewSuccessToastTitle',
    description: 'UpdateViewSuccessToastBody',
  },
  {
    type: 'delete',
    title: 'DeleteViewSuccessToastTitle',
    description: 'DeleteViewSuccessToastBody',
  },
  {
    type: 'changes',
    title: 'ChangesViewSuccessToastTitle',
    description: 'ChangesViewSuccessToastBody',
  },
  {
    type: 'duplicate',
    title: 'DuplicateViewSuccessToastTitle',
    description: 'DuplicateViewSuccessToastBody',
  },
];

export const INITIAL_SAVED_VIEW: ISavedView = {
  title: '',
  period: 'this_and_next_three_weeks',
  isDefault: false,
  createdDate: new Date(),
  updatedDate: new Date(),
  filters: {},
  viewOptions: {
    'grouped-by': 'group-by-resource',
    'included-work-items': 'all-work-items',
    'reporting-types': 'availability',
    'period-types': 'week',
    'unit-types': 'hours',
  },
};
