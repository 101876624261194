import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IActionsResponse } from '../types';

const PROJECTS_URL_PATH = '/api/v2/projects';
export const PROJECT_ACTIONS_MSW_STATUS_KEY = 'projectActionsAPI';
export const PROJECT_ACTIONS_KEY = 'PROJECT_ACTIONS';

const actionsFetch = (): Promise<IActionsResponse> =>
  getFetch({
    path: `${PROJECTS_URL_PATH}/actions`,
    key: PROJECT_ACTIONS_MSW_STATUS_KEY,
  });

export const useGetProjectActions = () => {
  const { data, ...restProps } = useQuery([PROJECT_ACTIONS_KEY], () => actionsFetch(), {
    // any previous data will be kept when fetching new data because the query key changed.
    keepPreviousData: true,
    staleTime: Infinity,
  });

  return {
    actions:
      data?.entities?.map((properties) => ({
        ...properties.properties,
      })) || [],
    ...restProps,
  };
};
