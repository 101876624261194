import { DateRange } from '@mui/x-date-pickers-pro';

export const useGetDefaultDates = ([startsAt, endsAt]: DateRange<Date>) => {
  const defaultStartDate = (() => {
    if (!startsAt || startsAt < new Date()) {
      return new Date();
    }
    return startsAt;
  })();
  const defaultEndDate = (() => {
    if (!endsAt || endsAt < new Date()) {
      return new Date();
    }
    return endsAt;
  })();

  return [defaultStartDate, defaultEndDate];
};
