import { FC, HTMLAttributes, ReactNode, ElementType } from 'react';
import cx from 'classnames';

import { TTextSizes } from 'src/assets/variables/size';
import { TStatus } from 'src/assets/variables/status';

import styles from './Text.module.scss';

export type TTextBaseSize =
  | TTextSizes
  | [TTextSizes, TTextSizes, TTextSizes, TTextSizes]
  | [TTextSizes, null, TTextSizes, TTextSizes]
  | [TTextSizes, TTextSizes, null, TTextSizes]
  | [TTextSizes, TTextSizes, TTextSizes, null]
  | [TTextSizes, null, TTextSizes, null]
  | [TTextSizes, null, null, TTextSizes]
  | [TTextSizes, TTextSizes, null, null];
export interface TextBaseProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode;
  /** Render as which html element? */
  as?: keyof Pick<HTMLElementTagNameMap, 'p' | 'span' | 'div' | 'li' | 'dd' | 'dt'>;
  /** Normal bold text */
  normal?: boolean;
  /** medium text */
  medium?: boolean;
  /** Semibold text */
  semibold?: boolean;
  /** Bold text */
  bold?: boolean;
  /** Italic text */
  italic?: boolean;
  /** Text size */
  size?: TTextBaseSize;
  /** Inherit size from parent */
  inheritSize?: boolean;
  /** Text dimmed */
  dimmed?: boolean;
  /** use [...] on overflow */
  ellipsis?: boolean;
  /** use [...] on overflow after three lines */
  lineEllipsis?: boolean;
  /** wrap words */
  wrapWords?: boolean;
  /** Visually hidden */
  visuallyHidden?: boolean;
  /** Color of text */
  tone?: TStatus;
  /** Take up entire width */
  fullWidth?: boolean;
  /** Used when highlighting a word or sentence */
  emphasize?: boolean;
  textAlign?: 'right' | 'left' | 'center';
}

const getSizeStyleList = (size: TTextBaseSize) => {
  if (Array.isArray(size)) {
    const [mobile, tablet, laptop, desktop] = size;
    return [
      [styles[`size_mobile_${mobile}`]],
      [styles[`size_tablet_${tablet || mobile}`]],
      [styles[`size_laptop_${laptop || tablet || mobile}`]],
      [styles[`size_desktop_${desktop || laptop || tablet || mobile}`]],
    ];
  }
  return [[styles[`size_${size}`]]];
};

export const TextBase: FC<TextBaseProps> = ({
  as: As = 'p',
  normal,
  medium,
  semibold,
  bold,
  italic,
  size = 'body',
  inheritSize,
  dimmed,
  ellipsis,
  lineEllipsis,
  wrapWords,
  visuallyHidden,
  className,
  tone = 'default',
  emphasize,
  textAlign = 'left',
  fullWidth,
  ...props
}) => {
  const Tag = As as ElementType;

  const sizeStyleList = getSizeStyleList(size);

  return (
    <Tag
      className={cx(
        styles.wrapper,
        ...sizeStyleList,
        styles[`textAlign_${textAlign}`],
        {
          [styles.weight_normal]: normal,
          [styles.weight_medium]: medium,
          [styles.weight_semibold]: semibold,
          [styles.weight_bold]: bold,
          [styles.italic]: italic,
          [styles.dimmed]: dimmed,
          [styles.emphasize]: emphasize,
          [styles.ellipsis]: ellipsis,
          [styles.lineEllipsis]: lineEllipsis,
          [styles.wrapWords]: wrapWords,
          [styles.visuallyHidden]: visuallyHidden,
          [styles.isSpan]: As === 'span',
          [styles[`tone_${tone}`]]: tone,
          [styles.isDd]: As === 'dd',
          [styles.fullWidth]: fullWidth,
          [styles.inheritSize]: inheritSize,
        },
        className,
      )}
      {...props}
    />
  );
};
