import cx from 'classnames';
import { Flex } from 'src/components/ui-components/Flex';
import { Icon } from 'src/components/ui-components/Icon';
import { ToastContentProps, TypeOptions } from 'react-toastify';
import { HTMLAttributes, ReactNode } from 'react';

import styles from './ToastItem.module.scss';

export interface SharedToastProps {
  message: string | ReactNode;
  actions?: ReactNode;
}

const icons: { [key in TypeOptions]: JSX.Element | undefined } = {
  error: <Icon iconName="error" tone="error" />,
  success: <Icon iconName="check" tone="success" />,
  warning: <Icon iconName="warning" tone="warning" />,
  info: <Icon iconName="info" tone="default" />,
  default: undefined,
};

interface ToastProps
  extends Partial<ToastContentProps>,
    SharedToastProps,
    HTMLAttributes<HTMLElement> {}

export const ToastItem = ({ toastProps, message, actions, ...props }: ToastProps) => (
  <Flex
    gap="medium"
    horizontalAlignment="justify"
    className={cx(styles.Toast, {
      [styles.Error]: toastProps?.type === 'error',
      [styles.Success]: toastProps?.type === 'success',
      [styles.Warning]: toastProps?.type === 'warning',
    })}
    {...props}
  >
    <Flex gap="medium">
      {toastProps?.type && icons[toastProps.type]}
      <Flex.Item>{message}</Flex.Item>
    </Flex>
    {actions && <Flex>{actions}</Flex>}
  </Flex>
);
