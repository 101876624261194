import { KeyboardEvent, RefObject } from 'react';

interface IFilterOnKeyDown {
  e: KeyboardEvent<HTMLDivElement>;
  popperOnClose: () => void;
  ref: RefObject<HTMLDivElement>;
}

export const filterOnKeyDown = ({ e, popperOnClose, ref }: IFilterOnKeyDown) => {
  if (e.key === 'Escape') {
    e.preventDefault();
    e.stopPropagation();
    popperOnClose();
  }
  if (e.key === 'Tab') {
    setTimeout(() => {
      Array.from(ref.current?.parentNode?.children ?? []).forEach((ce) => {
        const childButtonElement = ce.querySelector('[role="button"]');
        if (document.activeElement === childButtonElement) {
          popperOnClose();
        }
      });
    }, 0);
  }
};
