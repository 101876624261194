export default {
  Properties: {
    UserId: 1,
    Id: '1b9afd4f-5ea9-4447-94bf-2bfd7ce96a25',
    FullName: 'Anders Nielsen',
    FirstName: 'Anders',
    LastName: 'Nielsen',
    Initials: 'ANI',
    Email: 'npo@timelog.dk',
    IsActive: true,
    UserType: 'Employee',
    ProfilePicture:
      'https://app8.timelog.com/npo_sandbox/Settings/UserSettings/DisplayProfilePicture?userID=1&refresh=False&timespan=2024040210',
  },
  Links: [
    {
      Href: 'https://app8.timelog.com/npo_sandbox/api/v2/users/me',
      Rel: 'self',
    },
  ],
};
