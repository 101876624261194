import { Typography } from 'src/components/mui-components';
import { Spinner } from 'src/components/ui-components/Spinner';

interface IResponseHandler {
  children: JSX.Element | null;
  EmptyComponent?: JSX.Element;
  ErrorComponent?: JSX.Element;
  FreshComponent?: JSX.Element;
  isEmpty?: boolean;
  isError?: boolean;
  /**
   * Fresh means no data fetching has occured yet
   */
  isFresh?: boolean;
  isLoading?: boolean;
  LoadingComponent?: JSX.Element;
}

const ResponseHandler = ({
  children,
  EmptyComponent,
  ErrorComponent,
  FreshComponent,
  isEmpty,
  isError,
  isFresh,
  isLoading,
  LoadingComponent,
}: IResponseHandler) => {
  if (isFresh) {
    return (
      FreshComponent || (
        <Typography data-automation-id="DefaultSuspenseFresh">
          Please choose your search criteria
        </Typography>
      )
    );
  }
  if (isLoading) {
    return LoadingComponent || <Spinner data-automation-id="DefaultSuspenseLoading" />;
  }
  if (isError) {
    return (
      ErrorComponent || (
        <Typography data-automation-id="DefaultSuspenseError">Network Error</Typography>
      )
    );
  }
  if (isEmpty) {
    return (
      EmptyComponent || (
        <Typography data-automation-id="DefaultSuspenseEmpty">Data is empty</Typography>
      )
    );
  }
  return children;
};

export default ResponseHandler;
