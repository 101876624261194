export const employeeFullResult = {
  Properties: {
    Id: '1',
    FirstName: 'John',
    LastName: 'Doe',
    FullName: 'John Doe',
    Initials: 'JD',
    Email: 'john@doe.com',
    DepartmentId: 1,
    DepartmentName: 'Department 1',
    JobTitle: 'Job Title 1',
    ManagerName: 'Manager 1',
    ManagerId: 1,
    ManagerInitials: 'M1',
    Phone: '12345678',
    HourlyRate: '1.200,00 Senior Developer',
    HourlyRateId: 1,
    InternalCost: '700,00 Senior Developer',
    InternalCostId: 1,
    NormalWorkingTime: 'Standard week 1',
    NormalWorkingTimeId: 1,
    SalaryGroup: 'Standard',
    SalaryGroupId: 1,
    EmployeeType: 'Full time',
    EmployeeTypeId: 1,
    PublicHolidayCalendar: 'Denmark',
    PublicHolidayCalendarId: 1,
    EmploymentStartDate: '2023-01-01',
    IsActive: true,
    Competences: [
      {
        Category: 'Category 1',
        Id: '1',
        Items: [
          {
            Id: '1.1',
            Name: 'Competence 1',
            Level: 4,
          },
          {
            Id: '1.2',
            Name: 'Competence 2',
            Level: 3,
          },
        ],
      },
      {
        Category: 'Category 2',
        Id: '2',
        Items: [
          {
            Id: '2.1',
            Name: 'Competence 5',
            Level: 4,
          },
          {
            Id: '2.2',
            Name: 'Competence 6',
            Level: 3,
          },
          {
            Id: '2.3',
            Name: 'Competence 7',
            Level: 4,
          },
        ],
      },
      {
        Category: 'Category 3',
        Id: '3',
        Items: [
          {
            Id: '3.1',
            Name: 'Competence 8',
            Level: 4,
          },
          {
            Id: '3.5',
            Name: 'Competence 9',
            Level: 3,
          },
          {
            Id: '3.7',
            Name: 'Competence 10',
            Level: 4,
          },
        ],
      },
    ],
  },

  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/employees',
      Rel: 'self',
    },
  ],
};
