import { useGetLocale } from 'src/components/global/LocaleProvider';
import { HTMLAttributes } from 'react';
import { convertDateToLocaleString } from 'src/utils/date';
import { formatUrl } from 'src/utils/url';
import { Link } from 'src/components/ui-components';
import { Card, CardHeader, Stack, Typography } from 'src/components/mui-components';
import Avatar from '@mui/material/Avatar';
import { getUserAvatar } from 'src/utils/getUserAvatar';

interface ListItemProps extends HTMLAttributes<HTMLElement> {
  name: string;
  employeeId: number;
  navigationItemType?: number;
  normalWorkingTimeHoursInPeriod?: number;
  hours?: number;
  startDate?: string;
  endDate?: string;
  url?: string;
  linkText?: string;
}

export const ListItem = ({
  name,
  employeeId,
  navigationItemType,
  normalWorkingTimeHoursInPeriod,
  hours,
  startDate,
  endDate,
  url,
  linkText,
  ...props
}: ListItemProps) => {
  const siteLocale = useGetLocale();
  const isAbsence = navigationItemType === 1 && startDate && endDate;
  const isOneDay = startDate === endDate;
  const isTimeSheet = hours !== undefined && normalWorkingTimeHoursInPeriod !== undefined;

  return (
    <Card sx={{ maxWidth: 345 }} elevation={1} {...props}>
      <CardHeader
        avatar={
          <Avatar
            // sx={{ width: 24, height: 24 }}
            src={getUserAvatar(employeeId)}
            aria-label="recipe"
          >
            {name[0]}
          </Avatar>
        }
        title={
          url && linkText ? (
            <Link
              title={linkText}
              href={formatUrl(
                `${url}${
                  // startAbsenceDate is being used in TLP
                  isAbsence ? `&startAbsenceDate=${startDate.split('T')[0]}` : ''
                }`,
              )}
              data-automation-id={
                isAbsence ? 'ApproveSingleAbsenceSheetLink' : 'ApproveSingleTimeSheetLink'
              }
            >
              <Typography>{name}</Typography>
            </Link>
          ) : (
            <Typography>{name}</Typography>
          )
        }
        subheader={
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {isTimeSheet && (
              <Typography
                variant="small"
                fontWeight={600}
                data-automation-id="ListItemHours"
              >{`${hours.toFixed(2)}/${normalWorkingTimeHoursInPeriod.toFixed(2)}`}</Typography>
            )}
            {startDate && endDate && (
              <>
                {isOneDay && (
                  <Typography variant="small" data-automation-id="ListItemAbsenceOneDay">
                    {convertDateToLocaleString(new Date(startDate), siteLocale)}
                  </Typography>
                )}
                {!isOneDay && (
                  <Typography variant="small" data-automation-id="ListItemAbsenceRange">
                    {convertDateToLocaleString(new Date(startDate), siteLocale)} -{' '}
                    {convertDateToLocaleString(new Date(endDate), siteLocale)}
                  </Typography>
                )}
              </>
            )}
          </Stack>
        }
      />
    </Card>
  );
};
