import { AccountCircle } from '@mui/icons-material';
import { Avatar, Box, Stack } from '@mui/material';
import { IEmployeeSearchResProperties } from 'src/apis/resourcePlannerAPI';
import { Typography } from 'src/components/mui-components';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { getUserAvatar } from 'src/utils/getUserAvatar';
import { HoursBar } from '../HoursBar';
import styles from './EmployeeCard.module.scss';

type TEmployeeCard = Pick<
  IEmployeeSearchResProperties,
  'capacity' | 'department' | 'legalEntity' | 'fullName' | 'position' | 'initials'
> & {
  addedHours?: number;
  query?: string;
  showDepartmentFilter?: boolean;
  showLegalEntityFilter?: boolean;
  userId?: string;
};

export const EmployeeCard = ({
  addedHours,
  capacity = {
    availableHours: 0,
    totalHours: 0,
  },
  department,
  legalEntity,
  fullName,
  initials,
  position,
  query,
  showDepartmentFilter,
  showLegalEntityFilter,
  userId,
}: TEmployeeCard) => {
  const showDepartment = showDepartmentFilter && department;
  const showLegalEntity = showLegalEntityFilter && legalEntity;

  return (
    <Stack data-automation-id="EmployeeCard" direction="row" flex={1} gap={2}>
      <Stack justifyContent="center">
        {userId ? (
          <Avatar alt={fullName} src={getUserAvatar(userId)} sx={{ height: 32, width: 32 }}>
            {initials ?? fullName[0]}
          </Avatar>
        ) : (
          <AccountCircle color="action" fontSize="large" />
        )}
      </Stack>
      <Stack flex={1} gap={0} justifyContent="center">
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Box>
            <HighlightMatchingText matchName={query} name={fullName} />
            {initials ? <HighlightMatchingText matchName={query} name={` (${initials})`} /> : null}
          </Box>
          <Stack flexBasis={130} flexGrow={0} flexShrink={0} mt={0.25}>
            <HoursBar
              addedHours={addedHours}
              availableHours={capacity.availableHours}
              totalHours={capacity.totalHours}
            />
          </Stack>
        </Stack>
        {position || showDepartment || showLegalEntity ? (
          <Typography className={styles.separatedLabels} variant="small">
            {position ? <HighlightMatchingText matchName={query} name={position} /> : null}
            {showDepartment ? <HighlightMatchingText matchName={query} name={department} /> : null}
            {showLegalEntity ? (
              <HighlightMatchingText matchName={query} name={legalEntity} />
            ) : null}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};
