import { Routes, Route, Navigate } from 'react-router-dom';
import ScreenHeader from 'src/components/layout/ScreenHeader';
import { designSevenPagePath } from 'src/designSevenPagePath';
import LegacyPage from 'src/components/layout/LegacyPage';
import { Employee, Employees, Competences } from 'src/screens/Employees';
import PageRoute from '../PageRoute';
import LegacyRoute from '../LegacyRoute';

export const EmployeesRouter = () => (
  <Routes>
    <Route
      index
      element={
        <>
          <ScreenHeader />
          <PageRoute
            pageTitle="Employees"
            pageId="95a70234-0f6d-4165-935d-2447536209fb"
            pageIdentifier="EmployeesOverview"
          >
            <Employees />
          </PageRoute>
        </>
      }
    />

    <Route path={designSevenPagePath.employees.employee} element={<Employee />} />
    <Route path={designSevenPagePath.employees.competences} element={<Competences />} />

    <Route
      path={designSevenPagePath.employees.resourcePlannerOldUrl}
      element={
        <Navigate
          to={`${designSevenPagePath.projects.root.replace('*', '')}${
            designSevenPagePath.projects.resourcePlanner
          }`}
          replace
        />
      }
    />

    <Route
      path="*"
      element={
        <LegacyRoute>
          <LegacyPage data-automation-id="LegacyPage" />
        </LegacyRoute>
      }
    />
  </Routes>
);
