import { AlertProps as MuiAlertProps } from '@mui/material';
import { ReactNode } from 'react';
import { ToastContentProps } from 'react-toastify';
import { Alert } from '../Alert';
import { Typography } from '../Typography';
import styles from './ToastifyAlert.module.scss';

export interface IToastifyAlertProps extends MuiAlertProps, Partial<ToastContentProps> {
  customIcon?: ReactNode;
  'data-automation-id'?: string;
  description?: ReactNode;
  title?: string;
}

export const ToastifyAlert = ({
  customIcon,
  description,
  title,
  toastProps,
  severity,
  ...rest
}: IToastifyAlertProps) => {
  const getSeverity = () => {
    if (severity) {
      return severity;
    }

    if (toastProps?.type === 'default') {
      return 'info';
    }

    return toastProps?.type;
  };

  return (
    <Alert
      data-automation-id="ToastifyAlert"
      {...rest}
      className={styles.ToastifyAlert}
      icon={customIcon}
      severity={getSeverity()}
    >
      {title && <Typography fontWeight={600}>{title}</Typography>}
      {description && <Typography>{description}</Typography>}
    </Alert>
  );
};
