import { Helmet } from 'react-helmet-async';
import { useGetCurrentPageTitle } from 'src/stores/PageStore';

interface BrowserTitleProps {
  title?: string;
}

export const BrowserTitle = ({ title }: BrowserTitleProps) => {
  const currentPageTitle = useGetCurrentPageTitle();

  const browserTitle = currentPageTitle || title;

  return (
    <Helmet>
      <title>{browserTitle ? `${browserTitle} - TimeLog PSA` : 'TimeLog PSA'}</title>
    </Helmet>
  );
};
