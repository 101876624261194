import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Stack,
  Typography,
} from 'src/components/mui-components';
import { HTMLAttributes, ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TaskAltOutlined } from '@mui/icons-material';
import { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

interface AccordionItemProps extends HTMLAttributes<HTMLElement> {
  buttonAutomationId: string;
  dataAutomationId: string;
  heading: string;
  children?: ReactNode;
  counter?: number;
  expanded: boolean;
  handleChange: (event: React.SyntheticEvent, expanded: boolean) => void;
}

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    position: 'static',
    transform: 'none',
  },
}));

export const AccordionItem = ({
  buttonAutomationId,
  dataAutomationId,
  children,
  heading,
  counter = 0,
  expanded,
  handleChange,
}: AccordionItemProps) => {
  const hasItems = counter > 0;
  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      data-automation-id={dataAutomationId}
      disableGutters
      square
      borderless
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${buttonAutomationId}-content`}
        id={`${buttonAutomationId}-header`}
        data-automation-id={buttonAutomationId}
      >
        <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ width: '100%' }}>
            <Typography component="h3" fontWeight={400} gutterBottom>
              {heading}
            </Typography>

            <Box sx={{ mr: 1, ml: 'auto', display: 'inline-flex' }}>
              {hasItems ? (
                <StyledBadge
                  data-automation-id={`${dataAutomationId}Badge`}
                  badgeContent={counter}
                  color="warning"
                />
              ) : (
                <TaskAltOutlined
                  fontSize="small"
                  color="success"
                  data-automation-id={`${dataAutomationId}SuccessIcon`}
                />
              )}
            </Box>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails id={`${buttonAutomationId}-content`}>
        <Stack gap={2}>{children}</Stack>
      </AccordionDetails>
    </Accordion>
  );
};
