export default {
  Entities: [
    {
      Properties: {
        IntercompanyExchangeRatePeriodId: 313,
        PeriodStartDate: '2022-12-01T00:00:00',
        PeriodEndDate: '2022-12-31T00:00:00',
        IsPeriodLocked: false,
        PreviousPeriodId: 312,
        NextPeriodId: 0,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRatePeriodId: 312,
        PeriodStartDate: '2022-11-01T00:00:00',
        PeriodEndDate: '2022-11-30T00:00:00',
        IsPeriodLocked: false,
        PreviousPeriodId: 311,
        NextPeriodId: 313,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRatePeriodId: 311,
        PeriodStartDate: '2022-10-01T00:00:00',
        PeriodEndDate: '2022-10-31T00:00:00',
        IsPeriodLocked: false,
        PreviousPeriodId: 310,
        NextPeriodId: 312,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRatePeriodId: 310,
        PeriodStartDate: '2022-09-01T00:00:00',
        PeriodEndDate: '2022-09-30T00:00:00',
        IsPeriodLocked: false,
        PreviousPeriodId: 309,
        NextPeriodId: 311,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRatePeriodId: 309,
        PeriodStartDate: '2022-08-01T00:00:00',
        PeriodEndDate: '2022-08-31T00:00:00',
        IsPeriodLocked: false,
        PreviousPeriodId: 293,
        NextPeriodId: 310,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRatePeriodId: 293,
        PeriodStartDate: '2022-07-01T00:00:00',
        PeriodEndDate: '2022-07-31T00:00:00',
        IsPeriodLocked: false,
        PreviousPeriodId: 292,
        NextPeriodId: 309,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRatePeriodId: 292,
        PeriodStartDate: '2022-06-01T00:00:00',
        PeriodEndDate: '2022-06-30T00:00:00',
        IsPeriodLocked: true,
        PreviousPeriodId: 285,
        NextPeriodId: 293,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRatePeriodId: 285,
        PeriodStartDate: '2022-05-01T00:00:00',
        PeriodEndDate: '2022-05-31T00:00:00',
        IsPeriodLocked: false,
        PreviousPeriodId: 284,
        NextPeriodId: 292,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRatePeriodId: 284,
        PeriodStartDate: '2022-04-01T00:00:00',
        PeriodEndDate: '2022-04-30T00:00:00',
        IsPeriodLocked: true,
        PreviousPeriodId: 273,
        NextPeriodId: 285,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRatePeriodId: 273,
        PeriodStartDate: '2022-03-01T00:00:00',
        PeriodEndDate: '2022-03-31T00:00:00',
        IsPeriodLocked: true,
        PreviousPeriodId: 0,
        NextPeriodId: 284,
      },
    },
  ],
  Links: [
    {
      Href: 'http://mockapi/api/v1/exchange-rate/get-periods',
      Rel: 'self',
    },
  ],
};
