import cx from 'classnames';
import { ElementType, forwardRef, ReactNode } from 'react';
import { TIconButtonSize } from 'src/assets/variables/button';
import { Tooltip } from 'src/components/mui-components/Tooltip';
import styles from './IconButton.module.scss';

export interface IconButtonBaseSharedProps {
  /** Icon for button or url string for image */
  children: ReactNode;

  /** Text for button (for a11y) */
  tooltipText: string;

  /** Render as tag */
  as: 'a' | 'button';

  /** Size of button.
   * Use enum for picking one
   */
  size?: TIconButtonSize;

  /** Add border to the icon button
   */
  border?: boolean;

  className?: string;

  round?: boolean;
}

export interface IconButtonAnchorBaseSharedProps extends IconButtonBaseSharedProps {
  href: string;
}

type IconButtonBaseRef = HTMLButtonElement | HTMLAnchorElement;

export const IconButtonBase = forwardRef<IconButtonBaseRef, IconButtonBaseSharedProps>(
  (
    { children, size = 'default', tooltipText, as: As, border, className, round, ...props },
    ref,
  ) => {
    const Tag = As as ElementType;

    return (
      <Tooltip title={tooltipText}>
        <span>
          <Tag
            className={cx(styles.iconButton, className, {
              [styles[`${size}`]]: size,
              [styles.border]: border,
              [styles.round]: round,
            })}
            ref={ref}
            aria-label={tooltipText}
            {...props}
          >
            {children}
          </Tag>
        </span>
      </Tooltip>
    );
  },
);
