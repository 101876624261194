import classNames from 'classnames';
import { Stack } from 'src/components/mui-components';
import HeaderTitle from './components/HeaderTitle';
import MenuButton from './components/MenuButton';
import BookmarkButton from './components/BookmarkButton';
import styles from './ScreenHeader.module.scss';

interface ScreenHeaderProps {
  urlForTest?: string;
}

export default ({ urlForTest }: ScreenHeaderProps) => (
  <div data-automation-id="ScreenHeader" className={classNames(styles.ScreenHeaderContainer)}>
    <Stack direction="row" alignItems="center">
      <HeaderTitle />
      <Stack direction="row" alignItems="center" gap={0}>
        <BookmarkButton />
        <MenuButton urlForTest={urlForTest} />
      </Stack>
    </Stack>
  </div>
);
