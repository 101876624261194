import { useTranslation } from 'react-i18next';
import { useGetCurrentHelpPageUrl } from 'src/stores/PageStore';
import { IconButton } from 'src/components/mui-components';
import { IconButtonProps } from '@mui/material';
import { HelpOutlineOutlined } from '@mui/icons-material';

interface HelpButtonProps extends IconButtonProps {
  'data-automation-id': string;
}

export default ({ size, ...props }: HelpButtonProps) => {
  const { t } = useTranslation('header');
  const helpUrl = useGetCurrentHelpPageUrl();
  return (
    <IconButton
      component="a"
      href={helpUrl}
      target="_blank"
      rel="noopener"
      size={size}
      data-automation-id={props['data-automation-id']}
      title={t('HelpSectionLink')}
    >
      <HelpOutlineOutlined />
    </IconButton>
  );
};
