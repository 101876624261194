export const userFullResult = {
  Properties: {
    UserID: 1,
    ID: '8727d77d-6523-422a-a248-87f7e5970ddb',
    FirstName: 'Peter',
    LastName: 'Summersens',
    Initials: 'psu',
    IsActive: true,
    UserType: 'Employee',
  },
  Links: [
    {
      Href: 'http://localhost/tlp/api/v1/user/1',
      Rel: 'self',
    },
  ],
};
