import { HTMLAttributes } from 'react';
import { Typography, Stack } from 'src/components/mui-components';

interface MultiLineTranslationProps extends HTMLAttributes<HTMLElement> {
  translatedContent: string;
}

export const MultiLineTranslation = ({
  translatedContent,
  ...props
}: MultiLineTranslationProps) => {
  const textToArray = translatedContent.split(/\r?\n/);

  return (
    <Stack gap={2} {...props}>
      {textToArray.map((text, i) => (
        <Typography
          data-automation-id={`MultiLineTranslationParagraph${i}`}
          // eslint-disable-next-line react/no-array-index-key
          key={`${text.replaceAll(' ', '').slice(0, 10)}${i}`}
        >
          {text}
        </Typography>
      ))}
    </Stack>
  );
};
