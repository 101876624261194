import { useMutation, useQueryClient } from '@tanstack/react-query';
import { signalRConnectionIdKey } from 'src/screens/ResourcePlanner/sessionStorageKeys';
import { postFetch } from 'src/utils/fetcher';
import { toPascal } from 'src/utils/object';
import { GROUP_BY_PROJECT_KEY } from '../../get/getGroupByProjectAPI.constants';
import { GROUP_BY_RESOURCE_KEY } from '../../get/getGroupByResourceAPI.constants';
import { GROUP_BY_RESOURCE_TOTAL_KEY } from '../../get/getGroupByResourceTotalAPI.constants';
import { IBulkAssignReq } from './postBulkAssignAPI.types';

const POST_BULK_ASSIGN_URL_PATH = '/api/v2/resource-planner/work-item-relationship/bulk';
const POST_BULK_ASSIGN_KEY = 'BulkAssign';

const postBulkAssign = (reqBody: IBulkAssignReq[] = []) => {
  const hubConnectionId = sessionStorage.getItem(signalRConnectionIdKey);
  const pascalReqBody = reqBody.map((r) => toPascal({ ...r, hubConnectionId }));

  return postFetch({
    path: POST_BULK_ASSIGN_URL_PATH,
    key: POST_BULK_ASSIGN_KEY,
    body: JSON.stringify(pascalReqBody),
  });
};

export const usePostBulkAssign = (onSuccess?: () => void) => {
  const qc = useQueryClient();

  return useMutation(postBulkAssign, {
    onSuccess: () => {
      qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
      qc.invalidateQueries([GROUP_BY_RESOURCE_TOTAL_KEY]);
      qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
      onSuccess?.();
    },
  });
};
