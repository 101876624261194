export const filterEmployeesOverview = {
  Entities: [
    {
      Properties: {
        FilterCategory: 'Employee',
        FilterItems: [
          {
            Id: 5,
            Name: 'Employees legal entity',
            ContentUrl: 'api/v2/filters/employees-legal-entity/content',
            ChildFilters: [],
            isInclude: true,
          },
          {
            Id: 10,
            Name: 'Employees department',
            ContentUrl: 'api/v2/filters/employees-department/content',
            ChildFilters: [],
            isInclude: true,
          },
        ],
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/filters/list/EmployeesOverview',
      Rel: 'self',
    },
  ],
};
