interface TlpErrorResponse {
  code: number;
  details: {
    message: string;
    errorCode: number;
  }[];
}

type BusinessRuleViolation = {
  errorCode: number;
  violationDetails: string;
};

const BusinessRuleViolationError = class extends Error {
  __proto__ = Error;

  statusCode = 422;

  violations?: BusinessRuleViolation[];

  constructor(message: string, violations: BusinessRuleViolation[]) {
    super(message);
    Object.setPrototypeOf(this, BusinessRuleViolationError.prototype);

    this.name = 'BusinessRuleViolationError';
    this.violations = violations;
  }
};

export { BusinessRuleViolationError };
export type { BusinessRuleViolation, TlpErrorResponse };
