import { ISavedView } from 'src/apis/savedViewAPI';
import { IReducedView } from '../types/resourcePlanner';

export const sortViews = (savedView: ISavedView[]) => {
  const { defaultView, nonDefaultViews } = savedView.reduce(
    (acc: IReducedView, v) => {
      if (v.isDefault) {
        acc.defaultView = v;
      } else {
        acc.nonDefaultViews.push(v);
      }
      return acc;
    },
    { defaultView: null, nonDefaultViews: [] },
  );

  const sortedNonDefaultViews = nonDefaultViews.sort((a, b) => {
    const dateA = new Date(a.createdDate);
    const dateB = new Date(b.createdDate);

    return dateB.getTime() - dateA.getTime();
  });

  return defaultView ? [defaultView, ...sortedNonDefaultViews] : sortedNonDefaultViews;
};
