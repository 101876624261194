import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { ITimeRegistrationsResponse } from '../types';

const PROJECTS_URL_PATH = '/api/v2/projects';
export const TIME_REGISTRATIONS_MSW_STATUS_KEY = 'timeRegistrationsAPI';
export const TIME_REGISTRATIONS_KEY = 'TIME_REGISTRATIONS';

const timeRegistrationsFetch = (projectId: string): Promise<ITimeRegistrationsResponse> =>
  getFetch({
    path: `${PROJECTS_URL_PATH}/${projectId}/time-registrations`,
    key: TIME_REGISTRATIONS_MSW_STATUS_KEY,
  });

export const useGetTimeRegistrations = (projectId: string) => {
  const { data, ...restProps } = useQuery([TIME_REGISTRATIONS_KEY, projectId], () =>
    timeRegistrationsFetch(projectId),
  );

  const summations = {
    timeRegistrationId: 'TOTAL',
    actualAmount: parseFloat(data?.properties?.timeRegistrationsTotalActualAmount ?? '0'),
    actualHours: parseFloat(data?.properties?.timeRegistrationsTotalActualHours ?? '0'),
  };

  return {
    timeRegistrations:
      data?.entities?.map((properties) => ({
        ...properties.properties,
      })) || [],
    additionalCommentLabel: data?.properties?.additionalCommentFieldName,
    summations,
    ...restProps,
  };
};
