import { Avatar } from 'src/components/ui-components/Avatar';
import { Flex } from 'src/components/ui-components/Flex';
import { Icon } from 'src/components/ui-components/Icon';
import { Stack } from 'src/components/ui-components/Stack';
import { Text } from 'src/components/ui-components/Text';

interface PersonalInfoLinkContentProps {
  name: string;
  avatar?: string | null;
  email: string;
  companyName: string;
}

const PersonalInfoLinkContent = ({
  avatar,
  name,
  email,
  companyName,
}: PersonalInfoLinkContentProps) => (
  <Flex verticalAlignment="top" data-automation-id="UserMenuOrganisationInfo">
    {avatar ? (
      <Avatar
        src={avatar}
        size="xxLarge"
        data-automation-id="UserMenuOrganisationInfoAvatarImage"
      />
    ) : (
      <Icon
        iconName="darkProfile"
        size="xxLarge"
        data-automation-id="UserMenuOrganisationInfoAvatarIcon"
        color="#666"
      />
    )}

    <Flex.Item fillRest>
      <Stack verticalMargin="xSmall">
        <div>
          <Text bold wrapWords data-automation-id="UserMenuOrganisationInfoName">
            {name}
          </Text>
          <Text dimmed wrapWords data-automation-id="UserMenuOrganisationInfoEmail">
            {email}
          </Text>
        </div>
        <Text dimmed lineEllipsis data-automation-id="UserMenuOrganisationInfoCompanyName">
          {companyName}
        </Text>
      </Stack>
    </Flex.Item>
  </Flex>
);
export default PersonalInfoLinkContent;
