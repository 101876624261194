import { TFunction } from 'i18next';
import { toast } from 'react-toastify';
import { ToastifyAlert } from 'src/components/mui-components';

export const ProjectsErrorMessage: Record<number, string> = {
  30055: 'ProjectWithRegistrationsCannotBeDeletedMessage',
  30056: 'ProjectWithInvoicesCannotBeDeletedMessage',
  30057: 'ProjectWithExpensesCannotBeDeletedMessage',
  30058: 'ProjectWithTravelExpensesCannotBeDeletedMessage',
  30059: 'ProjectWithInstallmentsCannotBeDeletedMessage',
};

export interface IError {
  projectId: string;
  isDeletedSuccessfully: boolean;
  details: {
    errorCode: number;
    message: string;
  }[];
}

export const handleError = (error: IError, t: TFunction<'searchProjects'>) => {
  error?.details?.forEach((violation) => {
    const errorMessageKey = `errors.${
      ProjectsErrorMessage[violation.errorCode] ?? 'UnknownErrorMessage'
    }`;
    toast.error(
      <ToastifyAlert
        description={t(errorMessageKey)}
        data-automation-id="ToastRemoveProjectError"
      />,
      {
        autoClose: 5000,
        closeButton: false,
      },
    );
  });
};
