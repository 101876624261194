export default {
  Properties: {
    IntercompanyExchangeRatePeriodId: 11,
    PeriodStartDate: '2023-06-20T00:00:00',
    PeriodEndDate: '2023-06-30T00:00:00',
    IsPeriodLocked: false,
    PreviousPeriodId: 9,
    NextPeriodId: 0,
  },
  Links: [
    {
      Href: 'http://localhost/tlp/api/v1/exchange-rate/manual-period',
      Rel: 'self',
    },
  ],
};
