import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { forwardRef } from 'react';
import { Tooltip } from 'src/components/mui-components';

interface IconButtonProps extends MuiIconButtonProps {
  'data-automation-id': string;
  disableInteractive?: boolean;
  href?: string;
  target?: string;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ disableInteractive = true, title, ...rest }, ref) => (
    <Tooltip disableInteractive={disableInteractive} title={title}>
      {/* Reason for span here: https://mui.com/material-ui/react-tooltip/#disabled-elements */}
      <span>
        <MuiIconButton ref={ref} aria-label={title} {...rest} />
      </span>
    </Tooltip>
  ),
);
