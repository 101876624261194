import { useTranslation } from 'react-i18next';
import { Typography } from 'src/components/mui-components';

export const Error = () => {
  const { t } = useTranslation('frontpage');
  return (
    <Typography data-automation-id="MilestonesErrorMessage">
      {t('MilestonesErrorMessage')}
    </Typography>
  );
};
