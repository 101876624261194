import {
  Card as MuiCard,
  CardActionArea as MuiCardActionArea,
  CardActionAreaProps as MuiCardActionAreaProps,
  CardActions as MuiCardActions,
  CardActionsProps as MuiCardActionsProps,
  CardContent as MuiCardContent,
  CardContentProps as MuiCardContentProps,
  CardHeader as MuiCardHeader,
  CardHeaderProps as MuiCardHeaderProps,
  CardMedia as MuiCardMedia,
  CardMediaProps as MuiCardMediaProps,
  CardProps as MuiCardProps,
} from '@mui/material';

export const Card = ({ ...rest }: MuiCardProps) => <MuiCard {...rest} />;

export const CardActionArea = ({ ...rest }: MuiCardActionAreaProps) => (
  <MuiCardActionArea {...rest} />
);
export const CardActions = ({ ...rest }: MuiCardActionsProps) => <MuiCardActions {...rest} />;
export const CardContent = ({ ...rest }: MuiCardContentProps) => <MuiCardContent {...rest} />;
export const CardHeader = ({ ...rest }: MuiCardHeaderProps) => <MuiCardHeader {...rest} />;
export const CardMedia = ({ ...rest }: MuiCardMediaProps) => <MuiCardMedia {...rest} />;
