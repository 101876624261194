import { HTMLAttributes } from 'react';
import cx from 'classnames';
import DOMPurify from 'dompurify';
import styles from '../RichText.module.scss';

interface RichTextProps extends HTMLAttributes<HTMLDivElement> {
  html: string;
}
export const RichTextStatic = ({ html, className, ...props }: RichTextProps) => (
  <section
    // This is okay to use as we sanitize the printed html
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}
    className={cx(styles.RichText, className)}
    {...props}
  />
);
