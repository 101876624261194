import { InfoOutlined } from '@mui/icons-material';
import { DialogContentText } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle, IconButton } from 'src/components/mui-components';
import { MultiLineTranslation } from 'src/components/utils/MultiLineTranslation';

export const InfoModal = () => {
  const { t } = useTranslation('notificationCenter');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const close = () => {
    setIsOpen(false);
  };
  return (
    <>
      <IconButton
        title={t('InfoModalTriggerButtonToolTip')}
        data-automation-id="NotificationCenterInfoModalTriggerButton"
        onClick={() => setIsOpen(true)}
      >
        <InfoOutlined fontSize="small" />
      </IconButton>
      <Dialog open={isOpen} onClose={close}>
        <DialogTitle closeText={t('InfoModalCloseButtonToolTip')} closeModal={close}>
          {t('InfoModalHeading')}
        </DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <DialogContentText>
            <MultiLineTranslation translatedContent={t('InfoModalText')} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
