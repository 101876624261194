const array = Array(50);

function getRandomArbitrary(min: number, max: number): number {
  return parseFloat((Math.random() * (max - min) + min).toFixed(2));
}

function generateRows() {
  const rows = [];
  for (let index = 0; index <= array.length; index += 1) {
    rows.push({
      Properties: {
        HasAccountingIssues: Math.random() < 0.2,
        Id: index,
        No: `${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100)}`,
        Name: `Project ${index}`,
        Completion: `${Math.floor(Math.random() * 150)}%`,
        CustomerId: index,
        CustomerName: `Customer ${index}`,
        ProjectId: index,
        ProjectManagerName: `PM ${index} Name`,
        ProjectManagerInitials: `PM ${index}`,
        AccountManagerName: `AM ${index} Name`,
        AccountManagerInitials: `AM ${index}`,
        ProjectTypeName: `${Math.floor(Math.random() * 20) + 10}. Type ${index}`,
        ProjectCategoryName: `Category ${index}`,
        ProjectStageName: `Stage ${index}`,
        BudgetWorkHours: getRandomArbitrary(0, 20000),
        TasksBudgetHours: getRandomArbitrary(0, 20000),
        AllocationsBudgetHours: getRandomArbitrary(0, 20000),
        ProjectActualHours: getRandomArbitrary(0, 20000),
        StatusId: Math.floor(Math.random() * 7),
      },
    });
  }
  return rows;
}

export const getSearchProjects = {
  Entities: generateRows(),
  Properties: {
    ProjectsCount: '16',
    TotalAllocationsBudgetHours: '1386.5',
    TotalBudgetWorkHours: '427',
    TotalProjectActualHours: '993.865633334222',
    TotalTasksBudgetHours: '170',
  },
  Links: [{ href: '/search-projects', rel: 'self' }],
};

export const getSearchProjectsTest = {
  Entities: [
    {
      Properties: {
        HasAccountingIssues: true,
        Id: 1,
        No: '1234-56',
        Name: `Project 1`,
        Completion: '100%',
        CustomerId: '2',
        CustomerName: `Customer 1`,
        ProjectId: 3,
        ProjectManagerName: 'Ang Kian Leong',
        ProjectManagerInitials: 'KLA',
        AccountManagerName: 'AM Name',
        AccountManagerInitials: 'AM',
        ProjectTypeName: 'Type 1',
        ProjectCategoryName: 'Category 2',
        ProjectStageName: 'Stage 1',
        BudgetWorkHours: 20000,
        TasksBudgetHours: 1000,
        AllocationsBudgetHours: 12000,
        ProjectActualHours: 20000,
        StatusId: 3,
      },
    },
  ],
  Properties: {
    projectsCount: '16',
    totalAllocationsBudgetHours: '1386.5',
    totalBudgetWorkHours: '427',
    totalProjectActualHours: '993.865633334222',
    totalTasksBudgetHours: '170',
  },
  Links: [{ href: '/search-projects', rel: 'self' }],
};
