import { Trans, useTranslation } from 'react-i18next';
import { Typography } from 'src/components/mui-components/Typography';
import { translationAnyText } from 'src/utils/translation';

interface FilterFeedbackProps {
  matchChildren: string[] | undefined;
}

export const FilterFeedback = ({ matchChildren }: FilterFeedbackProps) => {
  const { t } = useTranslation('filter');

  const matchChildrenTranslated = matchChildren?.map((child) =>
    translationAnyText(t, `FilterInputName${child}`),
  );

  return matchChildren && matchChildren.length > 0 ? (
    <Typography
      component="p"
      data-automation-id="WarningMessage"
      sx={{ padding: 1, paddingTop: 0 }}
    >
      <Trans
        i18nKey="FilterWillResetMessage"
        ns="filter"
        values={{ filters: matchChildrenTranslated }}
        components={{
          bold: (
            <Typography component="span" fontWeight={700} data-automation-id="WarningFilterID" />
          ),
        }}
      />
    </Typography>
  ) : null;
};
