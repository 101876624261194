export default {
  Class: 'iapireadmodel',
  Properties: {
    Data: {
      AvailableTimeOff: {
        '2023Dec': -14.125,
        '2024Jan': -13.875,
        '2024Feb': -13.875,
        '2024Mar': -13.875,
        '2024Apr': -13.875,
        '2024May': -13.875,
        '2024Jun': -13.875,
        '2024Jul': -13.875,
        '2024Aug': -13.875,
        '2024Sep': -13.875,
        '2024Oct': -13.875,
        '2024Nov': -13.875,
      },
      AssignedVacations: {
        '2023Dec': 2.5,
        '2024Jan': 1.25,
        '2024Feb': 0.0,
        '2024Mar': 0.0,
        '2024Apr': 0.0,
        '2024May': 0.0,
        '2024Jun': 0.0,
        '2024Jul': 0.0,
        '2024Aug': 0.0,
        '2024Sep': 0.0,
        '2024Oct': 0.0,
        '2024Nov': 0.0,
      },
      Absences: {
        '2023Dec': -1.625,
        '2024Jan': -1.0,
        '2024Feb': 0.0,
        '2024Mar': 0.0,
        '2024Apr': 0.0,
        '2024May': 0.0,
        '2024Jun': 0.0,
        '2024Jul': 0.0,
        '2024Aug': 0.0,
        '2024Sep': 0.0,
        '2024Oct': 0.0,
        '2024Nov': 0.0,
      },
      PreviousMonthTotals: {
        '2023Dec': -15.0,
        '2024Jan': -14.125,
        '2024Feb': -13.875,
        '2024Mar': -13.875,
        '2024Apr': -13.875,
        '2024May': -13.875,
        '2024Jun': -13.875,
        '2024Jul': -13.875,
        '2024Aug': -13.875,
        '2024Sep': -13.875,
        '2024Oct': -13.875,
        '2024Nov': -13.875,
      },
    },
    Type: 'VacationCalculation',
    Width: 0,
    Height: 0,
    SortOrder: 0,
    PaginationInfo: {},
  },
  Links: [
    {
      Href: 'http://localhost:5181/api/v1/insights/vacation-calculation-widget-data?siteid=ff653f19-431b-4d58-9dda-9e41796456ab',
      Rel: 'self',
    },
  ],
};
