export const customerSegmentationObject = {
  CustomerSegmentationAccount: {
    account: 'tlp',
    customerProductId: '20923',
    majorVersion: '6',
    minorVersion: '2',
    buildVersion: '100',
    database: 'localhost',
    product: 'TimeLogPSA',
    productionStatus: 'Internal',
    plan: 'EnterprisePSA',
    intEcn: '1',
    intExact: '0',
    intFortnox: '0',
    intVisma: '0',
    intNav: '0',
    intDinero: '0',
    intUniconta: '0',
    intBjörnLundén: '0',
    intDanløn: '1',
    intVismasalary: '0',
    intHogia: '0',
    intZenegy: '0',
    intCrona: '0',
    intDataloen: '0',
    intFortnoxLön: '0',
    intLessorPayroll: '0',
    intSLS: '0',
    mle: '0',
    timelogResponsibleOnSite: '0',
    resourcePlanner: '1',
  },
  customerSegmentationUser: {
    userId: 'tlp_1',
    fullName: 'Peter Summersens',
    firstName: 'Peter',
    userName: 'psu',
    userIdentifier: '1',
    email: 'peter@timelog.dk',
    userLanguage: '1030',
    compactTimesheetView: '0',
    defaultTimesheetView: 'Weekly',
    userCreatedYear: '1',
    userCreatedMonth: '1',
    userCreatedDay: '1',
    roleProject: '1',
    roleContract: '1',
    roleCustomer: '1',
    roleApproval: '1',
    roleEmployee: '1',
    roleEmployeeModify: '1',
    roleExpense: '1',
    roleSystemAdm: '1',
    roleInvoice: '1',
    roleMileage: '1',
    roleMyAccount: '0',
    rolePreviewFeature: '1',
    timeLogResponsible: '0',
    gDPRResponsible: '1',
    userDepartmentName: 'TimeLog A/S',
    moduleCrm: '1',
    moduleOutlook: '0',
    previewFeatureDesignSeven: '1',
    previewFeatureDesignSevenFrontPage: '1',
  },
};
