import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { getPathWithQueries } from 'src/utils/url/url';
import { TUnallocatedEmployeesRes } from './getUnallocatedEmployeesAPI.types';

const GET_UNALLOCATED_EMPLOYEES_URL_PATH = '/api/v2/projects/{projectId}/employee-unallocated';
const GET_UNALLOCATED_EMPLOYEES_KEY = 'UnallocatedEmployees';

interface IGetUnallocatedEmployees {
  projectId: string;
  taskId: string;
  userId: string;
}

const getUnallocatedEmployees = ({
  projectId,
  taskId,
  userId,
}: IGetUnallocatedEmployees): Promise<TUnallocatedEmployeesRes> =>
  getFetch({
    path: getPathWithQueries(GET_UNALLOCATED_EMPLOYEES_URL_PATH.replace('{projectId}', projectId), {
      taskId,
      userId,
    }),
    key: GET_UNALLOCATED_EMPLOYEES_KEY,
  });

export const useGetUnallocatedEmployees = (
  params: IGetUnallocatedEmployees,
  enableQuery?: boolean,
) => {
  const { projectId, taskId, userId } = params;
  const { data, ...rest } = useQuery(
    [GET_UNALLOCATED_EMPLOYEES_KEY, projectId, taskId, userId],
    () => getUnallocatedEmployees(params),
    { enabled: enableQuery && !!projectId && !!taskId && !!userId },
  );

  const properties = data?.entities?.[0]?.properties;
  return { data: properties, ...rest };
};
