import { Alert, AlertTitle } from 'src/components/mui-components';
import { useTranslation } from 'react-i18next';

const WidgetError = () => {
  const { t } = useTranslation('reportingWidgets');
  return (
    <Alert severity="error">
      <AlertTitle>{t('ErrorStateAlertTitle')}</AlertTitle>
      {t('ErrorStateAlertMessage')}
    </Alert>
  );
};

export default WidgetError;
