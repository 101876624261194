import { Outlet } from 'react-router-dom';
import DesignSevenVerification from 'src/components/global/DesignSevenVerification';
import LoginCallbackRedirection from 'src/components/global/LoginCallbackRedirection';
import RefetchControl from 'src/components/global/RefetchControl';
import { ShortcutSystem } from 'src/components/global/ShortcutSystem';
import { ToastContainer } from 'src/components/global/ToastContainer';
import UserLanguage from 'src/components/global/UserLanguage';
import { RedirectDesignSeven } from './RedirectDesignSeven';

export function ReactRouter() {
  return (
    <>
      <ShortcutSystem />
      <RefetchControl />
      <LoginCallbackRedirection />
      <DesignSevenVerification />
      <UserLanguage />
      <RedirectDesignSeven />
      <ToastContainer />
      <Outlet />
    </>
  );
}
