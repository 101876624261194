import { useMutation } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';

const PUT_MILESTONES_DAYS_URL_PATH = '/api/v2/settings/milestones-days';
export const PUT_MILESTONE_DAYS_MSW_STATUS_KEY = 'putMilestoneDaysAPI';
export const PUT_MILESTONE_DAYS_KEY = 'PUT_MILESTONE_DAYS';

const updateMilestoneDays = (value: string) =>
  putFetch({
    path: PUT_MILESTONES_DAYS_URL_PATH,
    key: PUT_MILESTONE_DAYS_MSW_STATUS_KEY,
    body: JSON.stringify({
      value,
    }),
  });

export const useUpdateMilestoneDays = () =>
  useMutation([PUT_MILESTONE_DAYS_KEY], (value: string) => updateMilestoneDays(value));
