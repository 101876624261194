import { TDesignSevenPagePathValue, TTLPPagePathValue } from 'src/designSevenPagePath';

type TPageIdenitifer = 'DesignSevenFrontPage';
interface IRouteObject {
  newPaths: Array<TDesignSevenPagePathValue>;
  oldPaths: Array<TTLPPagePathValue>;
}

type TRedirectObject = Record<TPageIdenitifer, IRouteObject>;

export const redirectObject: TRedirectObject = {
  DesignSevenFrontPage: {
    newPaths: ['/home', '/'],
    oldPaths: ['/Home/FrontPage', '/Home/Index', '/'],
  },
};
