import {
  useGetTimeRegistrationAccuracyForHomeWidget,
  GET_TIME_REGISTRATION_KEY,
} from 'src/apis/timeRegistrationAccuracyAPI';
import WidgetWithoutChart from '../WidgetWithoutChart';
import { WidgetTipsButton } from '../WidgetTipsButton';

const TimeAccuracyWidget = () => {
  const {
    isError: isTimeAccuracyError,
    isLoading: isTimeAccuracyLoading,
    informationList: timeAccuracyInformationList,
  } = useGetTimeRegistrationAccuracyForHomeWidget('weekly');

  return (
    <WidgetWithoutChart
      queryKey={GET_TIME_REGISTRATION_KEY}
      relId="RegistrationAccuracy"
      tips={<WidgetTipsButton widgetKey="RegistrationAccuracy" />}
      informationList={timeAccuracyInformationList}
      isError={isTimeAccuracyError}
      isLoading={isTimeAccuracyLoading}
    />
  );
};

export default TimeAccuracyWidget;
