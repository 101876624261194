import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';
import { GET_COMPETENCES_KEY } from 'src/apis/competencesAPI/types';
import {
  COMPETENCE_GROUPS_URL_PATH,
  GET_COMPETENCE_GROUPS_KEY,
  ICompetenceGroupsDatumPut,
} from '../types';

export const PUT_COMPETENCE_GROUPS_MSW_STATUS_KEY = 'putCompetencesAPI';

const updateCompetenceGroups = (updateModel?: ICompetenceGroupsDatumPut) =>
  putFetch({
    path: COMPETENCE_GROUPS_URL_PATH,
    key: PUT_COMPETENCE_GROUPS_MSW_STATUS_KEY,
    body: JSON.stringify(updateModel),
  });

export const useUpdateCompetenceGroups = () => {
  const qc = useQueryClient();

  return useMutation(updateCompetenceGroups, {
    onSuccess: () => {
      qc.invalidateQueries([GET_COMPETENCE_GROUPS_KEY]);
      qc.invalidateQueries([GET_COMPETENCES_KEY]);
    },
  });
};
