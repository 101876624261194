import { useQuery } from '@tanstack/react-query';
import { IEntities } from 'src/apis/types/responseBase';
import { getFetch } from 'src/utils/fetcher';
import { COMPETENCES_URL_PATH, GET_COMPETENCES_KEY } from '../constants';
import { ICompetences, ICompetencesDatum, IConvertedRow } from '../types';

const GET_COMPETENCES_MSW_STATUS_KEY = 'getCompetencesAPI';

const getCompetences = (): Promise<ICompetences> =>
  getFetch({
    path: `${COMPETENCES_URL_PATH}/by-group`,
    key: GET_COMPETENCES_MSW_STATUS_KEY,
  });

export const useGetCompetences = () => {
  const { data, ...restProps } = useQuery([GET_COMPETENCES_KEY], () => getCompetences());

  const massagedData = (rows: IEntities<ICompetencesDatum>[] | undefined) => {
    if (!rows) {
      return [];
    }

    const massagedRows: IConvertedRow[] = [];

    rows.forEach(({ properties }) => {
      const { groupId, groupName, items, groupCanBeDeleted } = properties;

      massagedRows.push({
        id: `group-${groupId}`,
        apiId: groupId,
        name: groupName,
        category: undefined,
        type: 'category',
        isActive: false,
        canBeDeleted: groupCanBeDeleted,
        items,
      });

      items?.forEach((item) => {
        const { id: itemId, name: itemName, isActive, canBeDeleted } = item;

        massagedRows.push({
          id: `item-${itemId}`,
          apiId: itemId,
          name: itemName,
          category: groupId,
          type: 'competence',
          isActive,
          canBeDeleted,
          items: [],
        });
      });
    });

    return massagedRows;
  };

  return {
    data: data?.entities ?? [],
    rows: massagedData(data?.entities),
    ...restProps,
  };
};
