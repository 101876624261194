import { useState } from 'react';

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuIsOpen = !!anchorEl;

  const menuOnClose = (cb?: () => void) => {
    setAnchorEl(null);
    cb?.();
  };

  return { anchorEl, menuIsOpen, menuOnClose, setAnchorEl };
};
