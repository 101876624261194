export const designSevenPagePath = {
  home: '/home',
  sample: {
    root: 'sample-path/*',
    example: 'example',
    single: 'single/:id',
    wrongPath: 'wrong-path',
  },
  employees: {
    root: 'employees/*',
    employee: ':id',
    competences: ':id/competences',
    resourcePlannerOldUrl: 'resource-planner',
  },
  projects: {
    root: '/projects/*',
    resourcePlanner: 'resource-planner',
    searchProjects: 'search-projects',
  },
  reports: {
    root: 'reports/*',
    exampleReport: 'example-report',
    revenueForecast: 'revenue-forecast',
  },
  projectManagement: {
    root: 'ProjectManagement/*',
    searchProjectsOldUrl: 'search/searchprojects',
  },
  pageSettings: {
    root: 'page-settings/*',
    notFound: 'no-page-settings-found',
  },
  root: '/',
} as const;

export const systemAdminDesignSixPagePath = {
  root: 'Settings/*',
  exchangeRate: 'ExchangeRate/Index', // Change this url to match other new page paths in lower case pattern once design 6 page reaches EOL
} as const;

export const systemAdminDesignSevenPagePath = {
  root: 'system-administration/*',
  projects: {
    resourceManagement: 'projects/resource-management',
  },
  employees: {
    competences: 'employees/competences',
  },
  general: {
    frontpage: 'general/frontpage',
  },
} as const;

export const TLPPagePath = {
  home: '/Home/FrontPage',
  index: '/Home/Index',
  root: '/',
} as const;

export type TDesignSevenPagePathValue =
  typeof designSevenPagePath[keyof typeof designSevenPagePath];
export type TTLPPagePathValue = typeof TLPPagePath[keyof typeof TLPPagePath];
