import { defaultFilterItem } from '../FilterItems/FilterItem';
import { TTaskDefaultFilters } from './SearchForTaskDialog.types';

export const defaultFilters: TTaskDefaultFilters = {
  Customer: defaultFilterItem,
  LegalEntityProject: defaultFilterItem,
  ProjectDepartment: defaultFilterItem,
  ProjectManager: defaultFilterItem,
  ProjectType: defaultFilterItem,
};
