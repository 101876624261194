export default {
  Class: 'iapireadmodel',
  Properties: {
    Data: [
      {
        UserId: 2,
        FirstName: 'Søren',
        LastName: 'Lund',
        FullName: 'Søren Lund',
        Initials: 'SLU',
        AbsenceInfo: [
          {
            Date: '2023-08-09T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-10T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-11T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-12T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-13T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-14T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-15T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-16T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-17T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-18T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-19T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-20T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-21T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-22T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-23T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-24T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-25T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-26T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-27T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-28T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-29T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-30T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-31T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-01T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-02T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-09-03T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-09-04T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-05T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-06T00:00:00',
            Status: 'Working',
          },
        ],
      },
      {
        UserId: 6,
        FirstName: 'Christoffer Hostrup',
        LastName: 'Lanstorp',
        FullName: 'Christoffer Hostrup Lanstorp',
        Initials: 'CHL',
        AbsenceInfo: [
          {
            Date: '2023-08-09T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-10T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-11T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-12T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-13T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-14T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-15T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-16T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-17T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-18T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-19T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-20T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-21T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-22T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-23T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-24T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-25T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-26T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-27T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-28T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-29T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-30T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-31T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-01T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-02T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-09-03T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-09-04T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-05T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-06T00:00:00',
            Status: 'Working',
          },
        ],
      },
      {
        UserId: 33,
        FirstName: 'Thomas S.',
        LastName: 'Gudmandsen',
        FullName: 'Thomas S. Gudmandsen',
        Initials: 'TSG',
        AbsenceInfo: [
          {
            Date: '2023-08-09T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-10T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-11T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-12T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-13T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-14T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-15T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-16T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-17T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-18T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-19T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-20T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-21T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-22T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-23T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-24T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-25T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-26T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-27T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-28T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-29T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-30T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-31T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-01T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-02T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-09-03T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-09-04T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-05T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-06T00:00:00',
            Status: 'Working',
          },
        ],
      },
      {
        UserId: 36,
        FirstName: 'Sascha',
        LastName: 'Elizabeth Houlberg Skydsgaard',
        FullName: 'Sascha Elizabeth Houlberg Skydsgaard',
        Initials: 'SES',
        AbsenceInfo: [
          {
            Date: '2023-08-09T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-10T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-11T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-12T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-13T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-14T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-15T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-16T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-17T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-18T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-19T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-20T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-21T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-22T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-23T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-24T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-25T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-26T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-27T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-08-28T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-29T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-30T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-08-31T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-01T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-02T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-09-03T00:00:00',
            Status: 'Weekend',
          },
          {
            Date: '2023-09-04T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-05T00:00:00',
            Status: 'Working',
          },
          {
            Date: '2023-09-06T00:00:00',
            Status: 'Working',
          },
        ],
      },
    ],
    Type: 'PlannedAbsence',
    Width: 0,
    Height: 0,
    SortOrder: 0,
    PaginationInfo: {
      TotalRecord: '4',
      TotalPage: '1',
      PageNumber: '1',
    },
  },
  Links: [
    {
      Href: 'http://localhost:5181/api/v1/insights/planned-absence-widget-data?$pagesize=5&$page=1&siteid=ff653f19-431b-4d58-9dda-9e41796456ab',
      Rel: 'self',
    },
  ],
};
