import {
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  InputLabel as MuiInputLabel,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';
import { ForwardedRef, Ref, forwardRef } from 'react';
import { DataAutomationId } from 'src/utils/ts-utils';

export type SelectProps<T> = MuiSelectProps<T> &
  DataAutomationId & {
    helperText?: string;
  };

const SelectInner = <T,>({ helperText, ...rest }: SelectProps<T>, ref: Ref<HTMLDivElement>) => {
  const { disabled, error, fullWidth, label, labelId, required, size, variant = 'outlined' } = rest;

  return (
    <MuiFormControl
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      ref={ref}
      required={required}
      size={size}
      variant={variant}
    >
      {label && <MuiInputLabel id={labelId}>{label}</MuiInputLabel>}
      <MuiSelect
        inputProps={{
          'data-automation-id': `${rest['data-automation-id']}Input`,
          ...rest.inputProps,
        }}
        {...rest}
      />
      {helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
    </MuiFormControl>
  );
};

export const Select = forwardRef(SelectInner) as <T>(
  props: SelectProps<T> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof SelectInner>;
