import { DateText } from 'src/components/ui-components/DateText';
import { Icon } from 'src/components/ui-components/Icon';
import { Link } from 'src/components/ui-components/Link';
import { IMilestonesEntities } from 'src/apis/types/milestonesAPI';
import { Stack, Typography } from 'src/components/mui-components';
import styles from './FeedItems.module.scss';

interface IMilestoneFeedItemProps {
  items?: IMilestonesEntities[];
}

export const FeedItems = ({ items }: IMilestoneFeedItemProps) => (
  <Stack gap={4} data-automation-id="MilestonesResults">
    {items?.map(({ name, projectNameFormatted, projectId, milestoneId, dueDate, isOverdue }) => (
      <Stack
        direction="row"
        alignItems="top"
        gap={0}
        key={milestoneId}
        data-automation-id="MilestonesMilestone"
      >
        <div>
          <Icon iconName="milestone" tone="warning" />
        </div>
        <div className={styles.itemContent}>
          <Stack gap={1}>
            <Stack gap={1}>
              <Typography
                variant="h3"
                fontWeight={500}
                data-automation-id={`MilestonesMilestoneName${milestoneId}`}
              >
                <Link
                  href={`/ProjectManagement/Plan/Index/${projectId}`}
                  data-automation-id={`MilestonesMilestoneLink${milestoneId}`}
                >
                  {name}
                </Link>
              </Typography>
              <Typography
                sx={{ opacity: 0.75 }}
                variant="small"
                component="p"
                data-automation-id={`MilestonesProjectName${milestoneId}`}
              >
                <Stack component="span" direction="row" alignItems="top" gap={0.5}>
                  <Icon iconName="project" size="small" />
                  {projectNameFormatted}
                </Stack>
              </Typography>

              <Typography
                sx={{ opacity: isOverdue ? 1 : 0.75 }}
                variant="small"
                component="p"
                color={isOverdue ? 'error' : undefined}
                data-automation-id={`MilestonesDueDate${milestoneId}`}
              >
                <Stack component="span" direction="row" alignItems="top" gap={0.5}>
                  <Icon iconName="calendar" size="small" />

                  <DateText value={new Date(dueDate)} />
                </Stack>
              </Typography>
            </Stack>
          </Stack>
        </div>
      </Stack>
    ))}
  </Stack>
);
