import { getFetch } from 'src/utils/fetcher';
import { IReportingWidgetAll } from 'src/apis/types/reportingWidgetsAPI';
import { useQuery } from '@tanstack/react-query';

const REPORTING_WIDGET_GET_ALL_URL_PATH = '/api/v2/widget/all-active-widgets';
const REPORTING_WIDGET_GET_ALL_MSW_STATUS_KEY = 'reportingWidgetGetAllAPI';

const REPORTING_WIDGET_ALL_KEY = 'REPORTING_WIDGET_ALL_KEY';
export const REPORTING_WIDGET_HAS_ACCESS_KEY = 'REPORTING_WIDGET_HAS_ACCESS_KEY';

const getReportingWidgetAll = (): Promise<IReportingWidgetAll> =>
  getFetch({
    path: REPORTING_WIDGET_GET_ALL_URL_PATH,
    key: REPORTING_WIDGET_GET_ALL_MSW_STATUS_KEY,
  });

let isReportingWidgetEnabled = localStorage.getItem(REPORTING_WIDGET_HAS_ACCESS_KEY) === 'true';

export const useGetReportWidgetAll = () => {
  const { data, ...restProps } = useQuery(
    [REPORTING_WIDGET_ALL_KEY],
    () => getReportingWidgetAll(),
    {
      onSuccess: (fetchedData) => {
        const hasWidgetAccess = fetchedData?.properties?.isReportWidgetActive ?? false;
        localStorage.setItem(REPORTING_WIDGET_HAS_ACCESS_KEY, hasWidgetAccess.toString());

        isReportingWidgetEnabled = hasWidgetAccess;
      },
    },
  );

  return {
    ...data?.properties,
    ...restProps,
    isReportingWidgetEnabled,
  };
};
