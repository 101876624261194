import { useTranslation } from 'react-i18next';
import { Box, ButtonGhost, Flex, Heading, Icon, Text } from 'src/components/ui-components';
import { useFilterDispatch } from 'src/stores/FilterStore';
import styles from './ExcessiveOrNoData.module.scss';

const ExcessiveOrNoData = ({ type }: { type: string }) => {
  const { t } = useTranslation('resourcePlanner');

  const headingText = type === 'ExcessiveData' ? t('HeadingExcessiveData') : t('HeadingNoResults');

  const descriptionText =
    type === 'ExcessiveData' ? t('DescriptionExcessiveData') : t('DescriptionNoResults');

  const iconName = type === 'ExcessiveData' ? 'warning' : 'info';

  const dispatch = useFilterDispatch();

  return (
    <div className={styles.customPadding}>
      <Flex horizontalAlignment="center">
        <Box size="small">
          <Box.Item compact={false}>
            <Flex direction="column" verticalAlignment="top">
              <Flex gap="large">
                <Icon iconName={iconName} size="normal" />
                <Heading level={2} as={2}>
                  {headingText}
                </Heading>
              </Flex>
              <Text>{descriptionText}</Text>
            </Flex>
            {type === 'ExcessiveData' ? null : (
              <Flex horizontalAlignment="right">
                <ButtonGhost onClick={() => dispatch && dispatch({ type: 'RESET_FILTER' })}>
                  {t('ButtonResetFilters')}
                </ButtonGhost>
              </Flex>
            )}
          </Box.Item>
        </Box>
      </Flex>
    </div>
  );
};

export default ExcessiveOrNoData;
