import { ReactNode, forwardRef, AnchorHTMLAttributes } from 'react';
import classnames from 'classnames';
import { TButtonKind, TButtonSizes } from 'src/assets/variables/button';
import { TIconName } from 'src/assets/variables/icon';
import { Icon } from '../Icon';
import styles from '../Button/Button.module.scss';

export interface ButtonLinkBaseProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  kind?: TButtonKind;

  /** Size of button.
   * Use enum for picking one
   */
  size?: TButtonSizes;

  /** Icon element to the left */
  btnIconLeft?: TIconName;

  /** Icon element to the right */
  btnIconRight?: TIconName;
  href: string;
}

export type ButtonLinkBaseRef = HTMLAnchorElement;

export const ButtonLinkBase = forwardRef<ButtonLinkBaseRef, ButtonLinkBaseProps>(
  (
    { children, kind = 'primary', btnIconLeft, btnIconRight, size = 'default', href, ...props },
    ref,
  ) => (
    <a
      href={href}
      ref={ref}
      {...props}
      className={classnames(styles.wrapper, {
        [styles[kind]]: kind,
        [styles[size]]: size,
        [styles.hasIconLeft]: btnIconLeft,
        [styles.hasIconRight]: btnIconRight,
      })}
    >
      {btnIconLeft && <Icon iconName={btnIconLeft} className={styles.icon} aria-hidden="true" />}
      {children}
      {btnIconRight && <Icon iconName={btnIconRight} className={styles.icon} aria-hidden="true" />}
    </a>
  ),
);
