import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { IProjectEconomyHealth } from 'src/apis/types/projectEconomyHealthAPI';
import { concatQuerys } from 'src/utils/url';
import { IPaginationModel } from 'src/apis/types/paginationModelAPI';

const GET_PROJECT_ECONOMY_HEALTH_WIDGET_URL_PATH =
  '/api/v2/insights-service/project-economy-health-invoice';
const GET_PROJECT_ECONOMY_HEALTH_WIDGET_MSW_STATUS_KEY = 'getProjectEconomyHealthAPI';

const GET_PROJECT_ECONOMY_HEALTH_WIDGET_KEY = 'GET_PROJECT_ECONOMY_HEALTH_REPORTING_WIDGET_KEY';

const getProjectEconomyHealthReportingWidget = (
  page: number = 0,
  pageSize: number = 0,
): Promise<IProjectEconomyHealth> =>
  getFetch({
    path: `${GET_PROJECT_ECONOMY_HEALTH_WIDGET_URL_PATH}${concatQuerys([
      { param: '$pagesize', value: pageSize.toString() },
      { param: '$page', value: page.toString() },
    ])}`,
    key: GET_PROJECT_ECONOMY_HEALTH_WIDGET_MSW_STATUS_KEY,
  });

export const useGetProjectEconomyHealthReportingWidget = ({ page, pageSize }: IPaginationModel) => {
  const { data, ...restProps } = useQuery(
    [GET_PROJECT_ECONOMY_HEALTH_WIDGET_KEY, page, pageSize],
    () => getProjectEconomyHealthReportingWidget(page + 1, pageSize),
    { keepPreviousData: true },
  );

  if (data?.properties) {
    return {
      widgetName: data?.properties.type,
      widgetData: data?.properties.data,
      paginationInfo: {
        page: data?.properties.paginationInfo.pageNumber,
        totalRecord: data?.properties.paginationInfo.totalRecord,
      },
      ...restProps,
    };
  }

  return {
    widgetName: '',
    widgetData: [],
    paginationInfo: {
      page: 0,
      totalRecord: 0,
    },
    ...restProps,
  };
};
