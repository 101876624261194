import { InfoOutlined /* , MoreVert */ } from '@mui/icons-material';
import { Fragment, ReactNode, useState } from 'react';
import { Card, CardContent, CardHeader, IconButton } from 'src/components/mui-components';
import ReportingWidgetInfoModal from 'src/screens/Home/components/ReportingWidgetInfoModal';
import { useTranslation } from 'react-i18next';

interface IReportingWidgetCard {
  title: string;
  name: string;
  infoIconText: string;
  children?: ReactNode;
  hasAction?: boolean;
}

export const ReportingWidgetCard = ({
  name,
  title,
  infoIconText,
  children,
  hasAction = false,
}: IReportingWidgetCard) => {
  const { t } = useTranslation('reportingWidgets');
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Card className={`Card-${title}`} variant="outlined" sx={{ height: '100%' }}>
      <CardHeader
        title={title}
        action={
          hasAction && (
            <>
              <Fragment key={`InfoIconButton${name}`}>
                <IconButton
                  title={t('InfoIconTooltipTitle')}
                  size="small"
                  aria-label={`info-icon-button-${name}`}
                  data-automation-id={`InfoIconButton${name}`}
                  onClick={() => setIsOpen(true)}
                >
                  <InfoOutlined />
                </IconButton>
                <ReportingWidgetInfoModal
                  id={name}
                  title={title}
                  infoMessage={infoIconText}
                  openState={isOpen}
                  setOpenState={setIsOpen}
                />
              </Fragment>
              {/*
              // DRO: Will leave the action here for a while since we are going to have this in the next iteration.
              <MuiIconButton size="small" aria-label="action">
              <MoreVert />
            </MuiIconButton> */}
            </>
          )
        }
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default ReportingWidgetCard;
