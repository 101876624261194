export default {
  Entities: [
    {
      Properties: {
        ProjectID: 13348,
        ProjectName: '_TIM - Quality & Test automation strategy 2021',
        ProjectNo: 'P21.0006',
        ProjectTypeID: 23,
        ProjectTypeName: '30. Investment',
        CustomerID: 1,
        CustomerName: 'Name_Customer39128',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 13376,
        ProjectName: '_TIM - Pricing of TimeLog',
        ProjectNo: 'P21.0034',
        ProjectTypeID: 23,
        ProjectTypeName: '30. Investment',
        CustomerID: 1,
        CustomerName: 'Name_Customer39128',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 59,
        DepartmentName: '45. Product management',
      },
    },
    {
      Properties: {
        ProjectID: 13378,
        ProjectName: '_TIM - Marketing Execution 2021',
        ProjectNo: 'P21.0036',
        ProjectTypeID: 22,
        ProjectTypeName: '20. Operations',
        CustomerID: 1,
        CustomerName: 'Name_Customer39128',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 57,
        DepartmentName: '28. Marketing',
      },
    },
    {
      Properties: {
        ProjectID: 13379,
        ProjectName: '_TIM - Market analysis (Danish and German)',
        ProjectNo: 'P21.0037',
        ProjectTypeID: 23,
        ProjectTypeName: '30. Investment',
        CustomerID: 1,
        CustomerName: 'Name_Customer39128',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 34,
        DepartmentName: '20. Sales',
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/resource-planner/project-search',
      Rel: 'self',
    },
  ],
};
