import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'src/components/mui-components';
import styles from './WidgetFeedback.module.scss';

export const WidgetFeedback = () => {
  const { t } = useTranslation('frontpage');
  return (
    <div className={styles.widgetContainer}>
      <Typography
        variant="h3"
        component="h2"
        fontWeight={700}
        data-automation-id="FeedbackWidgetTitle"
      >
        {t('FeedbackWidgetTitle')}
      </Typography>

      <Typography data-automation-id="FeedbackWidgetContext">
        {t('FeedbackWidgetContext')}
      </Typography>
      <div className={styles.button}>
        <Button variant="contained" data-automation-id="FeedbackWidgetButton">
          {t('FeedbackWidgetButton')}
        </Button>
      </div>
    </div>
  );
};
