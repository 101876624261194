import { DateRange, DateRangeValidationError } from '@mui/x-date-pickers-pro';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'src/components/mui-components';

interface IAvailabilityDateRangePicker {
  onChange: (d: DateRange<Date>) => void;
  value: DateRange<Date>;
  onError?: (error: boolean) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
  required?: boolean;
}

export const AvailabilityDateRangePicker = ({
  onChange,
  onError,
  onKeyDown,
  required = false,
  value,
}: IAvailabilityDateRangePicker) => {
  const { t } = useTranslation('assignFlow');

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(value);
  const [error, setError] = useState<DateRangeValidationError>([null, null]);
  const [startError, endError] = error;
  const hasError = Boolean(startError || endError);

  const errorHelperText = (() => {
    if (!hasError) {
      return [];
    }
    if (startError === 'invalidDate' || endError === 'invalidDate') {
      return [t('NotValidDateFormatMessage')];
    }
    // Handle all other errors
    if (startError !== 'disablePast' && endError !== 'disablePast') {
      return [t('NotValidDateMessage')];
    }
    // Handle dates before today errors
    const errorMessages: string[] = [];
    if (startError === 'disablePast') {
      errorMessages.push(t('StartDateIsBeforeAllowedDateMessage'));
    }
    if (endError === 'disablePast') {
      errorMessages.push(t('EndDateIsBeforeAllowedDateMessage'));
    }
    return errorMessages;
  })();

  // Only trigger parent state change when
  // picker is closed and there are no errors
  useEffect(() => {
    if (open || hasError) {
      return;
    }
    onChange(date);
  }, [date, onError, hasError, onChange, open]);

  return (
    <DateRangePicker
      disablePast
      error={hasError}
      helperText={errorHelperText}
      localeText={{
        start: t('FilterItems.StartDate'),
        end: t('FilterItems.EndDate'),
      }}
      onChange={(d, { validationError: e }) => {
        setDate(d);
        setError(e);
        onError?.(Boolean(e[0] || e[1]));
      }}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      slotProps={{
        textField: { id: 'FilterItem-AvailabilityPicker', onKeyDown, required, size: 'small' },
      }}
      value={date}
    />
  );
};
