export const competencesByGroupFullResult = {
  Entities: [
    {
      Properties: {
        GroupId: 1,
        GroupName: 'Language Skills',
        GroupCanBeDeleted: false,
        Items: [
          {
            Id: 1,
            Name: 'Danish',
            IsActive: true,
            CanBeDeleted: false,
          },
          {
            Id: 13,
            Name: 'English',
            IsActive: true,
            CanBeDeleted: true,
          },
          {
            Id: 12,
            Name: 'Latvian',
            IsActive: true,
            CanBeDeleted: true,
          },
          {
            Id: 5,
            Name: 'Swedish',
            IsActive: false,
            CanBeDeleted: false,
          },
          {
            Id: 6,
            Name: 'German',
            IsActive: true,
            CanBeDeleted: false,
          },
        ],
      },
    },
    {
      Properties: {
        GroupId: 2,
        GroupName: 'Project Management',
        GroupCanBeDeleted: false,
        Items: [
          {
            Id: 3,
            Name: 'Agile / Scrum',
            IsActive: true,
            CanBeDeleted: false,
          },
          {
            Id: 2,
            Name: 'Prince2',
            IsActive: true,
            CanBeDeleted: false,
          },
          {
            Id: 4,
            Name: 'Finance Control',
            IsActive: true,
            CanBeDeleted: false,
          },
        ],
      },
    },
    {
      Properties: {
        GroupId: 3,
        GroupName: 'Development',
        GroupCanBeDeleted: false,
        Items: [
          {
            Id: 7,
            Name: '.NET',
            IsActive: true,
            CanBeDeleted: false,
          },
          {
            Id: 9,
            Name: 'Java',
            IsActive: true,
            CanBeDeleted: false,
          },
          {
            Id: 8,
            Name: 'Python',
            IsActive: true,
            CanBeDeleted: false,
          },
        ],
      },
    },
    {
      Properties: {
        GroupId: 4,
        GroupName: 'Change Management Tools',
        GroupCanBeDeleted: false,
        Items: [
          {
            Id: 11,
            Name: 'Change Management Mastering',
            IsActive: true,
            CanBeDeleted: false,
          },
          {
            Id: 10,
            Name: 'Lego Serious Play',
            IsActive: true,
            CanBeDeleted: false,
          },
        ],
      },
    },
    {
      Properties: {
        GroupId: 5,
        GroupName: 'Marketing',
        GroupCanBeDeleted: true,
        Items: [],
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost:3000/api/v2/competences/by-group',
      Rel: 'self',
    },
  ],
};
