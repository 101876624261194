import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetHourlyRates } from 'src/apis/resourcePlannerAPI';
import { Autocomplete } from 'src/components/mui-components';
import { IHourlyRateItem } from './HourlyRateAutocomplete.types';

interface IHourlyRateAutocomplete {
  dialogIsOpen: boolean;
  onChange: (value: IHourlyRateItem) => void;
  taskId: string;
  value: IHourlyRateItem;
  suggestedHourlyRateId?: string;
}

export const HourlyRateAutocomplete = ({
  dialogIsOpen,
  onChange,
  suggestedHourlyRateId,
  taskId,
  value,
}: IHourlyRateAutocomplete) => {
  const { t } = useTranslation('assignFlow');

  const { data, isFetching } = useGetHourlyRates({ suggestedHourlyRateId, taskId }, dialogIsOpen);
  const hourlyRates = useMemo(
    () =>
      (data ?? []).map(({ properties }) => ({
        label: `${properties.amount} ${properties.name}`,
        value: properties.contractHourlyRateID,
      })),
    [data],
  );

  return (
    <Autocomplete
      data-automation-id={`HourlyRate-${taskId}-Autocomplete`}
      disableClearable
      disabled={isFetching || !hourlyRates?.length}
      getOptionLabel={(opt) => (opt as IHourlyRateItem).label}
      inputStyles={{ height: 23 }}
      isOptionEqualToValue={(o, v) => o.value === v.value}
      label={t('HourlyRateSelectLabel')}
      loading={isFetching}
      onChange={(_, v) => onChange(v as IHourlyRateItem)}
      options={hourlyRates}
      placeholder={t('HourlyRateSelectLabel')}
      size="small"
      value={value}
    />
  );
};
