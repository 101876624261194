import { Dispatch, RefObject, useEffect } from 'react';
import { eventKeyEscape } from 'src/constants/keyboardKey';
import { SidePanelActionProps } from '../reducer';

interface UseFocusHandlingProps {
  isSidePanelOpen: boolean;
  sidePanelRef: RefObject<HTMLDivElement>;
  activateSidePanelButtonRef?: RefObject<HTMLButtonElement>;
  dispatch: Dispatch<SidePanelActionProps> | undefined;
}

export const useFocusHandling = ({
  isSidePanelOpen,
  sidePanelRef,
  activateSidePanelButtonRef,
  dispatch,
}: UseFocusHandlingProps) => {
  useEffect(() => {
    const sidePanel = sidePanelRef?.current;
    if (isSidePanelOpen && sidePanel) {
      sidePanel.focus();

      sidePanelRef.current.addEventListener('keydown', ({ key }: KeyboardEvent) => {
        if (key === eventKeyEscape && dispatch) {
          dispatch({ type: 'CLOSE_PANEL' });

          if (activateSidePanelButtonRef?.current) {
            activateSidePanelButtonRef.current.focus();
          }
        }
      });
    }
  }, [isSidePanelOpen, dispatch, activateSidePanelButtonRef, sidePanelRef]);
};
