import {
  CircularProgressProps,
  CircularProgress as MuiCircularProgress,
  Stack,
} from '@mui/material';

export const CircularProgress = ({ ...rest }: CircularProgressProps) => (
  <Stack alignItems="center">
    <MuiCircularProgress {...rest} />
  </Stack>
);
