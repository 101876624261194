import { Close } from '@mui/icons-material';
import { ToastContainer as ToastifyToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CloseButtonProps } from 'react-toastify/dist/components';
import { IconButton } from 'src/components/mui-components/IconButton';

const ToastCloseButton = ({ closeToast, type }: Partial<CloseButtonProps>) => (
  <IconButton
    color={type}
    data-automation-id="ToastCloseButton"
    onClick={closeToast}
    sx={{
      position: 'absolute',
      right: 2,
      top: '50%',
      transform: 'translateY(-50%)',
      border: 'none',
    }}
    title="Close"
  >
    <Close fontSize="small" />
  </IconButton>
);

export const ToastContainer = () => (
  <ToastifyToastContainer
    position="bottom-center"
    autoClose={false}
    hideProgressBar
    newestOnTop
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    closeButton={<ToastCloseButton />}
    icon={false}
    style={{ zIndex: 9999 }}
    bodyStyle={{ padding: 0 }}
    toastStyle={{ padding: 0 }}
  />
);
