export default {
  Entities: [
    {
      Properties: {
        ProjectID: 13499,
        ProjectName: 'DRO TEST WBS',
        ProjectNo: 'P24.0001',
        ProjectTypeID: 23,
        ProjectTypeName: '30. Investment',
        CustomerID: 1,
        CustomerName: 'Name_Customer30244',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 13504,
        ProjectName: 'Test project for approval on project',
        ProjectNo: 'P24.0006',
        ProjectTypeID: 21,
        ProjectTypeName: '10. Customer',
        CustomerID: 40292,
        CustomerName: 'Name_Customer16185',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 13503,
        ProjectName: 'Test Two wrong vouchers',
        ProjectNo: 'P24.0005',
        ProjectTypeID: 21,
        ProjectTypeName: '10. Customer',
        CustomerID: 40292,
        CustomerName: 'Name_Customer16185',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 13502,
        ProjectName: 'DRO-Test wrong numbers on Voucher',
        ProjectNo: 'P24.0004',
        ProjectTypeID: 23,
        ProjectTypeName: '30. Investment',
        CustomerID: 1,
        CustomerName: 'Name_Customer30244',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 13501,
        ProjectName: 'LSM DRO ',
        ProjectNo: 'P24.0003',
        ProjectTypeID: 21,
        ProjectTypeName: '10. Customer',
        CustomerID: 40292,
        CustomerName: 'Name_Customer16185',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 13500,
        ProjectName: 'MDU DRO',
        ProjectNo: 'P24.0002',
        ProjectTypeID: 21,
        ProjectTypeName: '10. Customer',
        CustomerID: 53327,
        CustomerName: 'Name_Customer1284',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 13498,
        ProjectName: 'Internal Test DRO',
        ProjectNo: 'P23.0006',
        ProjectTypeID: 23,
        ProjectTypeName: '30. Investment',
        CustomerID: 1,
        CustomerName: 'Name_Customer30244',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 13497,
        ProjectName: 'Test for ALI',
        ProjectNo: 'P23.0005',
        ProjectTypeID: 23,
        ProjectTypeName: '30. Investment',
        CustomerID: 1,
        CustomerName: 'Name_Customer30244',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 13494,
        ProjectName: 'teqtqteqew',
        ProjectNo: 'P23.0004',
        ProjectTypeID: 12,
        ProjectTypeName: 'XX Intern - Salg',
        CustomerID: 1,
        CustomerName: 'Name_Customer30244',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 13493,
        ProjectName: 'Test droooooo',
        ProjectNo: 'P23.0003',
        ProjectTypeID: 12,
        ProjectTypeName: 'XX Intern - Salg',
        CustomerID: 12762,
        CustomerName: 'Name_Customer22696',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 13478,
        ProjectName: 'TEST DRO LORHHH',
        ProjectNo: 'P23.0001',
        ProjectTypeID: 23,
        ProjectTypeName: '30. Investment',
        CustomerID: 1,
        CustomerName: 'Name_Customer30244',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 13477,
        ProjectName: 'Test droooooo 2222',
        ProjectNo: 'P22.0004',
        ProjectTypeID: 21,
        ProjectTypeName: '10. Customer',
        CustomerID: 51960,
        CustomerName: 'Name_Customer24645',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 13474,
        ProjectName: 'Test droooooo',
        ProjectNo: 'P22.0001',
        ProjectTypeID: 21,
        ProjectTypeName: '10. Customer',
        CustomerID: 54315,
        CustomerName: 'Name_Customer9928',
        CustomerNo: 'Number',
        LegalEntityID: 1,
        LegalEntityName: 'TimeLog A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/resource-planner/projects/recently-registered',
      Rel: 'self',
    },
  ],
};
