export default {
  Properties: {
    FeatureList: [
      {
        name: 'Competences',
        identifier: 473,
        enabled: true,
        canUserOverride: true,
      },
    ],
  },
  Links: [
    {
      Href: 'http://mockapi/api/v2/features/resource-management',
      Rel: 'self',
    },
  ],
};
