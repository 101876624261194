import { Table, TCellIndentation, THorizontalAlignment } from 'src/components/ui-components';
import { memo, ReactNode } from 'react';

interface IRenderBodyFirstColumn {
  depth: TCellIndentation;
  alignment: THorizontalAlignment;
  children: ReactNode;
}

const RenderBodyFirstColumn = memo(({ depth, alignment, children }: IRenderBodyFirstColumn) => (
  <Table.Header alignment={alignment} indent={depth} scope="row">
    {children}
  </Table.Header>
));

export default RenderBodyFirstColumn;
