import { ClickAwayListener, Paper, Popper } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterTag } from 'src/components/layout/FilterLayout/components/MainContainer/components/FilterTag';
import { FeatureControl } from 'src/components/utils/FeatureControl';
import { useMenu } from 'src/hooks/useMenu';
import { filterOnKeyDown } from '../../../helpers';
import styles from './FilterItemCompetence.module.scss';
import { CompetenceDialog, IFilterCompetence } from './components/CompetenceDialog';

interface IFilterItemCompetence {
  onChange: (c: IFilterCompetence[]) => void;
}

export const FilterItemCompetence = ({ onChange }: IFilterItemCompetence) => {
  const { t } = useTranslation('assignFlow');

  const ref = useRef<HTMLDivElement>(null);
  const { anchorEl, menuIsOpen, menuOnClose, setAnchorEl } = useMenu();

  const [competence, setCompetence] = useState<IFilterCompetence[]>([]);

  const filterTagItems = (() => {
    if (!competence.length) {
      return [{ label: t('FilterItems.Any') }];
    }
    return competence.map((c) => {
      if (!c.levels.length) {
        return { label: `${c.label}` };
      }
      if (c.levels.length === 5) {
        return { label: `${c.label} (${t('FilterItems.Any')})` };
      }
      return { label: `${c.label} (${c.levels.toString()})` };
    });
  })();

  const popperOnClose = () => {
    menuOnClose(() => {
      onChange(competence);
    });
  };

  const clearCompetence = () => {
    setCompetence([]);
    onChange([]);
  };

  return (
    <FeatureControl indexControlName="Competences">
      <FilterTag
        data-automation-id="FilterItem-Competence"
        items={filterTagItems}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        panelIsOpen={menuIsOpen}
        ref={ref}
        removeFilterTag={competence.length ? clearCompetence : undefined}
        title={t('FilterItems.Competence')}
      >
        {menuIsOpen ? (
          <Popper
            anchorEl={anchorEl}
            className={styles.popper}
            data-automation-id="FilterItem-Competence-Popper"
            disablePortal
            onKeyDown={(e) => filterOnKeyDown({ e, popperOnClose, ref })}
            open={menuIsOpen}
            placement="bottom-start"
          >
            <ClickAwayListener onClickAway={popperOnClose}>
              <Paper className={styles.paper} elevation={4}>
                <CompetenceDialog
                  competence={competence}
                  onClose={popperOnClose}
                  setCompetence={setCompetence}
                />
              </Paper>
            </ClickAwayListener>
          </Popper>
        ) : null}
      </FilterTag>
    </FeatureControl>
  );
};
