import { FC, HtmlHTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import cx from 'classnames';
import { themeColors } from 'src/components/ui-components';
import {
  useMobileNavigationStore,
  useMobileNavigationDispatch,
} from 'src/stores/MobileNavigationStore';

import ToggleNavigation from 'src/components/layout/Header/components/Navigation/components/ToggleNavigation';
import { HeaderWithHeading } from 'src/components/layout/Header';
import { navId } from 'src/components/layout/VerticalNavigation';
import AppShell from 'src/components/layout/AppShell';

import styles from './LayoutSettings.module.scss';

import { useIframeEvent } from './LayoutSettingHookHelper';

interface SharedProps extends HtmlHTMLAttributes<HTMLElement> {
  children: ReactNode;
  navigation: ReactNode;
  title: string;
  isHelpVisible?: boolean;
}

export const LayoutSettings: FC<SharedProps> = ({
  children,
  navigation,
  title,
  isHelpVisible = true,
  ...props
}) => {
  const mobileNavigationDispatch = useMobileNavigationDispatch();
  const mobileNavigationState = useMobileNavigationStore();
  const mobileNavIsOpen = mobileNavigationState?.isMobileNavigationOpen;

  const mainContentRef = useRef<HTMLDivElement>(null);
  const TriggerRef = useRef<HTMLButtonElement>(null);
  const WrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mobileNavIsOpen && WrapperRef) {
      if (WrapperRef.current) {
        WrapperRef.current.addEventListener('focusout', (event) => {
          if (!WrapperRef.current?.contains(event.relatedTarget as HTMLElement)) {
            if (TriggerRef && TriggerRef.current) {
              TriggerRef.current.focus();
            }

            // Focus has left the element
            return (
              mobileNavigationDispatch &&
              mobileNavigationDispatch({
                type: 'CLOSED',
              })
            );
          }

          return false;
        });

        WrapperRef.current.addEventListener('keydown', (event) => {
          if (event.key === 'Escape') {
            if (TriggerRef && TriggerRef.current) {
              TriggerRef.current.focus();
            }
            return (
              mobileNavigationDispatch &&
              mobileNavigationDispatch({
                type: 'CLOSED',
              })
            );
          }

          return false;
        });
      }
    }
  }, [mobileNavIsOpen, mobileNavigationDispatch]);

  useIframeEvent();

  return (
    <AppShell title={title} mainRefElement={mainContentRef}>
      {/* This layout always uses minimal header */}
      <HeaderWithHeading heading={title} isHelpVisible={isHelpVisible} />

      <div className={styles.LayoutSettings} {...props}>
        <ToggleNavigation className={styles.ToggleButton} navId={navId} ref={TriggerRef} />
        {/* Navigation type will differ from each settins type, so we inject it as a prop */}
        <div
          className={cx(styles.LayoutSettings__nav, { [styles.IsOpen]: mobileNavIsOpen })}
          ref={WrapperRef}
          data-automation-id="SettingsNavigationWrapper"
        >
          {navigation}
        </div>
        {/* As settings is global we will set the theme to pink */}
        <main className={cx(styles.LayoutSettings__content, themeColors.Pink)} ref={mainContentRef}>
          {children}
        </main>
      </div>
    </AppShell>
  );
};
