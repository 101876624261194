import { SearchInput } from 'src/components/ui-components/SearchInput';
import { IconButton } from 'src/components/ui-components/IconButton';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import {
  ChangeEvent,
  RefObject,
  useState,
  useRef,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getKeyboardFocusableElements } from 'src/utils/accessibility';
import {
  useCategoriesList,
  useIsCategoryFilterOpen,
  useMainSearchDispatch,
} from 'src/stores/MainSearchStore';
import {
  blurSelectElement,
  reloadKeyboardSelectedElement,
  keyboardAction,
} from 'src/utils/keyboardUpDownEvent';
import { shortcutElementNames } from 'src/components/global/ShortcutSystem';
import { useResetInputEvent } from './SearchHookHelper';
import SearchResultPanel from './components/SearchResultPanel';
import SearchCategoryPanel from './components/SearchCategoryPanel';
import styles from './Search.module.scss';

interface SearchProps {
  inputRef: RefObject<HTMLInputElement>;
  searchFilterRef: RefObject<HTMLDivElement>;
  selectItemIndex: number;
  setSelectItemIndex: Dispatch<SetStateAction<number>>;
  searchValueTesting?: string;
}

export default ({
  inputRef,
  searchFilterRef,
  selectItemIndex = -1,
  setSelectItemIndex,
  searchValueTesting = '',
}: SearchProps) => {
  const { t } = useTranslation('header');
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>(searchValueTesting);
  const [isPauseSearch, setIsPauseSearch] = useState<boolean>(false);
  const searchContainer = useRef<HTMLDivElement>(null);
  const searchFiltersButton = useRef<HTMLButtonElement>(null);
  const searchContainerResults = useRef<HTMLUListElement>(null);
  const mainSearchDispatch = useMainSearchDispatch();
  const isSearchCategoryOpen = useIsCategoryFilterOpen();

  if (!searchValueTesting) useResetInputEvent(setSearchValue);

  const resetSearch = useCallback(() => {
    if (mainSearchDispatch) {
      mainSearchDispatch({
        type: 'RESET_SEARCH',
      });
    }
  }, [mainSearchDispatch]);

  const toggleSearchCategory = useCallback(
    () =>
      mainSearchDispatch &&
      mainSearchDispatch({
        type: 'TOGGLE_CATEGORY',
      }),
    [mainSearchDispatch],
  );

  const onItemSelect = useCallback(() => {
    resetSearch();
    setSearchValue('');
  }, [resetSearch, setSearchValue]);

  const focusInput = useCallback(() => {
    if (mainSearchDispatch)
      mainSearchDispatch({
        type: 'CLOSE_CATEGORY',
      });
    inputRef.current?.focus();
  }, [inputRef, mainSearchDispatch]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e?.target?.value);
    setIsPauseSearch(true);
    setTimeout(() => setIsPauseSearch(false), 500);
  };

  const onKeyDown = (event: any) => {
    const focusableElements = getKeyboardFocusableElements(searchContainerResults?.current);
    const currentSelectedItem = focusableElements[selectItemIndex] as HTMLElement;
    if (focusableElements.length > 0) {
      const isNeedToBlur = keyboardAction({
        event,
        setSelectItemIndex,
        selectItemIndex,
        onItemSelect,
        navigate,
        focusableElements,
      });
      if (isNeedToBlur) blurSelectElement(currentSelectedItem);
    }
  };

  const onFocusHandle = () => {
    if (mainSearchDispatch)
      mainSearchDispatch({
        type: 'CLOSE_CATEGORY',
      });
  };

  reloadKeyboardSelectedElement(
    getKeyboardFocusableElements(searchContainerResults?.current)?.[selectItemIndex],
    inputRef,
  );

  const filterIsActive = useCategoriesList()?.some((el) => el.isSelected);

  return (
    <div ref={searchContainer} role="search" data-automation-id="SearchContainer">
      <div className={styles.Search}>
        <div className={styles.SearchInput}>
          <SearchInput
            label={t('SearchInputLabel')}
            name={shortcutElementNames.mainSearch}
            hiddenLabel
            placeholder={t('SearchInputPlaceholder')}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            onFocus={onFocusHandle}
            value={searchValue}
            ref={inputRef}
            autoComplete="off"
            data-automation-id="SuperSearchInput"
            aria-autocomplete="none"
            clearInputText={t('ClearSearchInputButton')}
            clearInput={() => {
              setSearchValue('');
            }}
            noBorderRadiusRight
          />
        </div>

        <IconButton
          data-automation-id="SuperSearchCategoryButton"
          onClick={toggleSearchCategory}
          iconName="filter"
          border
          className={cx(styles.SearchCategoryButton, {
            [styles.SearchCategoryButtonHasActiveFilter]: filterIsActive,
          })}
          aria-expanded={isSearchCategoryOpen}
          aria-controls="MainSearchCategoryFilter"
          tooltipText={t('SearchCategoriesFilterButton')}
          ref={searchFiltersButton}
        />
      </div>
      <SearchResultPanel
        isPauseSearch={isPauseSearch}
        searchValue={searchValue}
        onItemSelect={onItemSelect}
        searchContainerResults={searchContainerResults}
      />
      <SearchCategoryPanel searchFilterCategoriesRef={searchFilterRef} focusInput={focusInput} />
    </div>
  );
};
