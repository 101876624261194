import { Clear, InfoOutlined, Search } from '@mui/icons-material';
import { Box, InputAdornment, List, ListItem, Stack, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { usePostEmployeeSearch } from 'src/apis/resourcePlannerAPI';
import { useGetRecentResources } from 'src/apis/resourcePlannerAPI/assignFlowAPI/get/getRecentResourcesAPI';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from 'src/components/mui-components';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { useAssignFlowStore } from 'src/stores/ResourcePlannerStore/AssignFlowStore';
import { useDebounce } from 'use-debounce';
import { useGetDefaultDates, useGetFlattenedFilterItems, useKeyboardControl } from '../../hooks';
import { AssignDialog } from '../AssignDialog';
import { EmployeeWithCompetence } from '../EmployeeWithCompetence';
import { FilterItem, defaultFilterItem } from '../FilterItems/FilterItem';
import { FilterItemAvailability, IFilterAvailability } from '../FilterItems/FilterItemAvailability';
import { FilterItemCompetence, IFilterCompetence } from '../FilterItems/FilterItemCompetence';
import { defaultFilters } from './SearchForResourceDialog.constants';
import styles from './SearchForResourceDialog.module.scss';
import { EResourceFilterNames, TResourceDefaultFilters } from './SearchForResourceDialog.types';

interface ISearchForResourceDialog {
  dialogIsOpen: boolean;
  setDialogIsOpen: (isOpen: boolean) => void;
}

export const SearchForResourceDialog = ({
  dialogIsOpen,
  setDialogIsOpen,
}: ISearchForResourceDialog) => {
  const { t } = useTranslation('assignFlow');
  const { endsAt, name, parentProject, startsAt, workItemSourceReferenceId } = useAssignFlowStore();
  const [defaultStartDate, defaultEndDate] = useGetDefaultDates([startsAt, endsAt]);

  const resourceFilterItems = useGetFlattenedFilterItems(EResourceFilterNames);
  const showDepartmentFilter = !!resourceFilterItems.find(
    ({ filterName }) => filterName === 'ProjectDepartment',
  )?.id;
  const showLegalEntityFilter = !!resourceFilterItems.find(
    ({ filterName }) => filterName === 'LegalEntityProject',
  )?.id;

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 500);
  const [selectedFilters, setSelectedFilters] =
    useState<Partial<TResourceDefaultFilters>>(defaultFilters);
  const [competence, setCompetence] = useState<IFilterCompetence>({
    isInclude: true,
    values: [],
  } as IFilterCompetence);
  const [availability, setAvailability] = useState<IFilterAvailability>({
    periodEnd: defaultEndDate,
    periodStart: defaultStartDate,
  });

  const { periodEnd, periodStart } = availability;
  const startDateChanged = periodStart.toDateString() !== defaultStartDate.toDateString();
  const endDateChanged = periodEnd.toDateString() !== defaultEndDate.toDateString();
  const filtersIsActive =
    Object.values(selectedFilters).some((i) => !!i.values.length) ||
    !!debouncedQuery ||
    !!competence.values.length ||
    !!availability.hours ||
    !!startDateChanged ||
    !!endDateChanged;

  const { data: recentResources, isFetching: recentResourcesIsFetching } = useGetRecentResources(
    { taskId: workItemSourceReferenceId },
    dialogIsOpen,
  );

  const {
    data: employeeSearch,
    isError: employeeSearchIsError,
    isFetching: employeeSearchIsFetching,
  } = usePostEmployeeSearch(
    {
      competence,
      department: selectedFilters.ProjectDepartment,
      endDate: availability.periodEnd,
      hours: availability.hours,
      legalEntity: selectedFilters.LegalEntityProject,
      manager: selectedFilters.ApprovalManager,
      query: debouncedQuery,
      startDate: availability.periodStart,
      taskId: workItemSourceReferenceId,
    },
    dialogIsOpen && filtersIsActive,
  );

  const employeeListFetching = recentResourcesIsFetching || employeeSearchIsFetching;
  const employeeList = ((filtersIsActive ? employeeSearch : recentResources) ?? []).map(
    ({ properties }) => properties,
  );

  const cleanUp = () => {
    setQuery('');
    setSelectedFilters(defaultFilters);
    setCompetence(defaultFilterItem);
    setAvailability({
      periodEnd: defaultEndDate,
      periodStart: defaultStartDate,
    });
    setDialogIsOpen(false);
  };

  const {
    dialogOnKeyDown,
    initialFocusElement,
    searchContainerResults,
    selectItemIndex,
    setSelectItemIndex,
    textFieldOnKeyDown,
  } = useKeyboardControl({});

  useEffect(() => {
    if (employeeListFetching) {
      return;
    }
    setSelectItemIndex(-1);
  }, [employeeListFetching, setSelectItemIndex]);

  return (
    <Dialog
      data-automation-id="SearchForResourceDialog"
      fullWidth
      maxWidth="md"
      onClose={cleanUp}
      onKeyDown={dialogOnKeyDown}
      open={dialogIsOpen}
      PaperProps={{ sx: { overflowY: 'unset' } }}
    >
      <DialogTitle component={Box} data-automation-id="SearchForResourceDialogTitle">
        <Stack gap={2}>
          <Typography lineHeight={1.6} variant="h2">
            <Trans
              i18nKey="AssignTaskToEmployeeHeader"
              key="AssignTaskToEmployeeHeader"
              defaults={t('AssignTaskToEmployeeHeader')}
              values={{ task: name }}
              components={[<strong key={0}>{name}</strong>]}
            />
          </Typography>
          <Stack alignItems="center" direction="row" gap={2}>
            <TextField
              ariaLabel={t('SearchForEmployeesInputLabel')}
              data-automation-id="SuperSearchForResource"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {query ? (
                      <IconButton
                        data-automation-id="SearchForResourceInputClearButton"
                        onClick={() => setQuery('')}
                        size="small"
                        title={t('ClearSearchButtonText')}
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    ) : null}
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
                inputProps: { autoFocus: true },
              }}
              inputRef={initialFocusElement}
              label={t('SearchForEmployeesInputLabel')}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={textFieldOnKeyDown}
              value={query}
            />
            <Tooltip
              tabIndex={0}
              title={
                <Stack>
                  <Typography component="h3" fontWeight={700} lineHeight={1.5} variant="h4">
                    {t('SearchForEmployeesTooltipHeading')}
                  </Typography>
                  <Typography variant="small" sx={{ whiteSpace: 'pre-line' }}>
                    {t('SearchForEmployeesTooltip')}
                  </Typography>
                </Stack>
              }
            >
              <InfoOutlined />
            </Tooltip>
          </Stack>
          <Stack direction="row" flex={1} flexWrap="wrap">
            {resourceFilterItems.map((i) => (
              <FilterItem
                {...i}
                data-automation-id={`FilterItem-${i.filterName}`}
                filterName={i.filterName}
                key={String(i.id)}
                onChange={(v) =>
                  setSelectedFilters((prev) => ({
                    ...prev,
                    [i.filterName]: v,
                  }))
                }
              />
            ))}
            <FilterItemCompetence
              onChange={(c) => {
                setCompetence({
                  isInclude: true,
                  values: c,
                });
              }}
            />
            <FilterItemAvailability
              dates={[startsAt, endsAt]}
              onChange={setAvailability}
              value={availability}
            />
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent dividers onMouseEnter={() => setSelectItemIndex(-1)} sx={{ pt: 2, zIndex: 0 }}>
        <ResponseHandler
          EmptyComponent={<Typography textAlign="center">{t('NoResults')}</Typography>}
          isError={employeeSearchIsError}
          isEmpty={!employeeList.length}
          isLoading={employeeListFetching}
          LoadingComponent={<CircularProgress />}
        >
          <List className={styles.list} ref={searchContainerResults}>
            {employeeList.map((resource, i) => (
              <ListItem
                data-automation-id={`ResourceItem-${resource.userId}`}
                disablePadding
                key={resource.userId}
                sx={{ position: 'relative' }}
              >
                <AssignDialog
                  dates={[startsAt, endsAt]}
                  from={name}
                  projectId={parentProject?.id ?? ''}
                  selected={selectItemIndex === i}
                  showDepartmentFilter={showDepartmentFilter}
                  showLegalEntityFilter={showLegalEntityFilter}
                  taskId={workItemSourceReferenceId}
                  to={resource.fullName}
                  userId={resource.userId}
                >
                  <EmployeeWithCompetence
                    query={query}
                    resource={resource}
                    showDepartmentFilter={showDepartmentFilter}
                    showLegalEntityFilter={showLegalEntityFilter}
                  />
                </AssignDialog>
              </ListItem>
            ))}
          </List>
        </ResponseHandler>
      </DialogContent>
      <DialogActions>
        <Button data-automation-id="CancelButton" onClick={cleanUp} variant="outlined">
          {t('CancelButtonText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
