import { useQuery } from '@tanstack/react-query';
import { getApiDateFormat } from 'src/utils/date/date';
import { getFetch } from 'src/utils/fetcher';
import { getPathWithQueries } from 'src/utils/url/url';
import { TCapacityRes } from './getCapacityAPI.types';

const GET_CAPACITY_URL_PATH = '/api/v2/resource-planner/user/{userId}/capacity';
const GET_CAPACITY_KEY = 'Capacity';

interface IGetCapacity {
  endDate: Date | null;
  startDate: Date | null;
  userId: string;
}

const getCapacity = ({ endDate, startDate, userId }: IGetCapacity): Promise<TCapacityRes> =>
  getFetch({
    path: getPathWithQueries(GET_CAPACITY_URL_PATH.replace('{userId}', userId), {
      periodEndDate: getApiDateFormat(endDate ?? new Date()),
      periodStartDate: getApiDateFormat(startDate ?? new Date()),
    }),
    key: GET_CAPACITY_KEY,
  });

export const useGetCapacity = (params: IGetCapacity, enableQuery?: boolean) => {
  const { startDate, endDate, userId } = params;
  const { data: d, ...rest } = useQuery(
    [GET_CAPACITY_KEY, startDate, endDate, userId],
    () => getCapacity(params),
    { enabled: enableQuery },
  );

  return { data: d?.properties, ...rest };
};
