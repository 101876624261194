import { TIconSizes } from 'src/assets/variables/size';
import cx from 'classnames';
import { Text } from 'src/components/ui-components/Text';
import style from './Shimmer.module.scss';

interface IShimmer {
  width?: 'quarter' | 'half' | 'thirdQuarter' | 'full' | TIconSizes;
  height?: 'quarter' | 'half' | 'thirdQuarter' | 'full' | 'auto';
  isSquare?: boolean;
  minHeight?: 'tips' | 'body' | 'subHeading' | 'heading' | 'display';
  isRound?: boolean;
  message?: string;
  isInlineBlock?: boolean;
  className?: string;
}

export default ({
  width = 'full',
  height = 'auto',
  isRound = false,
  minHeight = 'body',
  message = 'loading',
  isSquare,
  isInlineBlock = true,
  className,
}: IShimmer) => (
  <span
    className={cx(
      style.animate,
      style[`${height}Height`],
      style[`${width}Width`],
      style[`${minHeight}FontMinHeight`],
      {
        [style.isRound]: isRound,
        [style.isInlineBlock]: isInlineBlock,
        [style.isSquare]: isSquare,
      },
      className,
    )}
  >
    <Text aria-busy="true" aria-live="polite" visuallyHidden as="span">
      {message}
    </Text>
  </span>
);
