import { useEffect, Dispatch, SetStateAction, RefObject, KeyboardEvent } from 'react';
import { eventKeyUp, eventKeyDown, eventKeyTab, eventKeyEscape } from 'src/constants/keyboardKey';
import { getKeyboardFocusableElements } from 'src/utils/accessibility';

export const useResetActiveIndex = (
  isSearchCategoryPanelOpen: boolean,
  setActiveIndex: Dispatch<SetStateAction<number>>,
) =>
  useEffect(() => {
    if (!isSearchCategoryPanelOpen) {
      setActiveIndex(0);
    }
  }, [isSearchCategoryPanelOpen, setActiveIndex]);

export const useDefaultCategoryItemFocus = (
  isSearchCategoryPanelOpen: boolean,
  searchFilterCategoriesRef: RefObject<HTMLDivElement>,
) =>
  useEffect(() => {
    if (isSearchCategoryPanelOpen && searchFilterCategoriesRef) {
      const [firstElement] = getKeyboardFocusableElements(searchFilterCategoriesRef.current);

      setTimeout(() => {
        (firstElement as HTMLElement)?.focus();
      }, 10);
    }
  }, [isSearchCategoryPanelOpen, searchFilterCategoriesRef]);

interface IOnKeyDownProps {
  focusableElementsCount: number;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  event: KeyboardEvent<HTMLDivElement>;
  focusInput?: () => void;
}

export const onKeyDown = ({
  focusableElementsCount,
  activeIndex,
  setActiveIndex,
  event,
  focusInput,
}: IOnKeyDownProps) => {
  const { key } = event;
  if (key === eventKeyTab && event.shiftKey) {
    event.preventDefault();
    if (focusInput) focusInput();
  } else if (key === eventKeyUp) {
    event.preventDefault();
    if (activeIndex <= 0) {
      setActiveIndex(focusableElementsCount - 1);
    } else {
      setActiveIndex((prev) => prev - 1);
    }
  } else if (key === eventKeyDown || key === eventKeyTab) {
    event.preventDefault();
    if (activeIndex >= focusableElementsCount - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex((prev) => prev + 1);
    }
  } else if (key === eventKeyEscape) {
    event.preventDefault();
    if (focusInput) focusInput();
  }
};
