import { ChangeEvent, useRef, useState, useCallback } from 'react';
import { Heading } from 'src/components/ui-components/Heading';
import { Stack } from 'src/components/ui-components/Stack';
import { SearchInput } from 'src/components/ui-components/SearchInput';
import { Flex } from 'src/components/ui-components/Flex';
import { Icon } from 'src/components/ui-components/Icon';
import { useNavigate } from 'react-router-dom';
import {
  keyboardAction,
  blurSelectElement,
  reloadKeyboardSelectedElement,
} from 'src/utils/keyboardUpDownEvent';
import { getKeyboardFocusableElements } from 'src/utils/accessibility';
import SearchResult from './components/SearchResult';
import { IVerticalNavigationSearchBar } from './VerticalNavigationSearchBarType';

export default ({ t, ...restProps }: IVerticalNavigationSearchBar) => {
  const [searchText, setSearchText] = useState('');
  const [selectItemIndex, setSelectItemIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigationBarRef = useRef<HTMLElement>(null);
  const navigate = useNavigate();

  const onItemSelect = useCallback(() => {
    setSearchText('');
  }, [setSearchText]);

  const onKeyDown = (event: any) => {
    const focusableElements = getKeyboardFocusableElements(navigationBarRef?.current);
    const currentSelectedItem = focusableElements[selectItemIndex] as HTMLElement;
    if (focusableElements.length > 0) {
      const isNeedToBlur = keyboardAction({
        event,
        setSelectItemIndex,
        selectItemIndex,
        onItemSelect,
        navigate,
        focusableElements,
      });
      if (isNeedToBlur) blurSelectElement(currentSelectedItem);
    }

    inputRef.current?.focus();
  };

  reloadKeyboardSelectedElement(
    getKeyboardFocusableElements(navigationBarRef?.current)?.[selectItemIndex],
    inputRef,
  );

  return (
    <Stack>
      <Flex data-automation-id="Title">
        <Icon iconName="page" />
        <Heading level={2} as={3}>
          {t('NavigationHeading')}
        </Heading>
      </Flex>
      <SearchInput
        name="SearchInput"
        hiddenLabel
        label={t('SearchLabel')}
        placeholder={t('SearchPlaceholder')}
        onKeyDown={onKeyDown}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchText(e.currentTarget.value)}
        value={searchText}
        data-automation-id="SearchBar"
        ref={inputRef}
        clearInputText={t('ClearSearchInputButton')}
        clearInput={() => {
          setSearchText('');
        }}
        autoComplete="off"
        aria-autocomplete="none"
      />
      <SearchResult
        searchText={searchText}
        t={t}
        {...restProps}
        navigationBarRef={navigationBarRef}
        onItemSelect={onItemSelect}
      />
    </Stack>
  );
};
