import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  SxProps,
} from '@mui/material';

export interface TypographyProps {
  fontWeight?: TTypographyFontWeight;
  /** Italic text */
  fontStyle?: TTypographyFontStyle;
}

type TTypographyFontWeight = 700 | 600 | 500 | 400;
type TTypographyFontStyle = 'normal' | 'italic';

export const Typography = <C extends React.ElementType>(
  props: MuiTypographyProps<C, { component?: C }> & TypographyProps,
) => {
  const { fontWeight = 400, fontStyle = 'normal', sx } = props;
  // will need to include more property like ellipsis,wrapWords,inheritSize. Making it simpler at the initial stage.
  const sxObject: SxProps = {
    fontWeight,
    fontStyle,
  };
  return <MuiTypography sx={{ ...sxObject, ...sx }} {...props} />;
};
