import { Stack } from 'src/components/mui-components';

import { GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-pro';

export const Toolbar = () => (
  <Stack direction="row" alignItems="center" justifyContent="end" flexWrap="wrap">
    <GridToolbarDensitySelector />
    <GridToolbarExport
      printOptions={{ disableToolbarButton: true }}
      csvOptions={{ utf8WithBom: true }}
    />
  </Stack>
);
