import { useCallback, useRef, useState } from 'react';
import cx from 'classnames';
import { useSetSubMenuToActive } from 'src/components/layout/Header/hooks';
import { auxClickPreventNewTabFocus, onClickPreventNewTabFocus } from 'src/utils/clickEvent';
import { Link } from 'src/components/ui-components/Link';
import { Icon } from 'src/components/ui-components/Icon';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { removeEmptySpace } from 'src/utils/string';
import { useCreateMenu } from 'src/apis/menusAPI/createAPI';
import {
  IMenusProperties,
  IMenusPropertiesWithPagePath,
  ISubMenusWithPagePath,
} from 'src/apis/types/menusAPI';
import { isContentURL } from 'src/utils/url';

import MobileNavTreeToggle from '../MobileNavTreeToggle';
import styles from '../../Navigation.module.scss';

interface IsQuickCreateMenuOpenProps {
  quickCreateNavData: IMenusProperties[];
  pathname: string;
}

const isQuickCreateMenuOpen = ({
  quickCreateNavData,
  pathname,
}: IsQuickCreateMenuOpenProps): boolean =>
  pathname
    ? quickCreateNavData?.some(({ subMenus }) =>
        isContentURL({ subMenusPageUrl: subMenus?.map((menu) => menu.pageUrl), pathname }),
      )
    : false;

export const isQuickCreateMenuOpenForTest = isQuickCreateMenuOpen;

interface ICreateSubNavItemProps {
  subMenus: ISubMenusWithPagePath[];
  id: string;
  subMenuIsOpen: boolean;
}

const SubMenusItem = ({ subMenus, id, subMenuIsOpen }: ICreateSubNavItemProps) => {
  // We need this to only have border on the first import item in menu
  const firstImportIndex = subMenus?.findIndex((obj) => obj.pageIdentifier.startsWith('Import'));
  return (
    subMenus && (
      <ul
        id={id}
        className={styles.GrandChildList}
        data-automation-id={`HeaderCreateSubList${id}List`}
        data-is-open={subMenuIsOpen}
      >
        {subMenus?.map(({ pagePath, pageIdentifier, name, translation }, index) => {
          const isImportItem = pageIdentifier?.startsWith('Import');

          return (
            <li
              className={cx(styles.GrandChildItem, {
                [styles.ChildItem__hasBorderTop]: firstImportIndex === index,
              })}
              data-automation-id={`HeaderCreateSubList${pageIdentifier}Item`}
              key={`CreateSubNav${removeEmptySpace(name)}`}
            >
              <Link
                href={pagePath}
                className={cx(styles.GrandChildLink, styles.GrandChildLink__hasIcon)}
                data-automation-id={`HeaderCreateSubList${pageIdentifier}Link`}
                id={`GrandChildItem${removeEmptySpace(name)}`}
                onClick={onClickPreventNewTabFocus}
                onAuxClick={auxClickPreventNewTabFocus}
                useTextColor
              >
                {isImportItem ? (
                  <Icon
                    iconName="import"
                    size="small"
                    data-automation-id="HeaderCreateSubListIconImport"
                  />
                ) : (
                  <Icon iconName="plus" aria-hidden="true" size="small" />
                )}
                <span className={styles.ParentLinkText}>{translation}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    )
  );
};

interface ICreateSubNavListItemProps {
  subMenus: ISubMenusWithPagePath[] | undefined;
  name: string;
  translation: string;
  hasLoadedItems: boolean;
  isParentActive: boolean;
}

const CreateSubNavListItem = ({
  subMenus,
  name,
  translation,
  hasLoadedItems,
  isParentActive,
}: ICreateSubNavListItemProps) => {
  const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);
  const element = useRef<HTMLLIElement>(null);

  const activeFunction = useCallback(() => {
    setSubMenuIsOpen(true);
  }, []);

  const inActiveFunction = useCallback(() => {
    setSubMenuIsOpen(false);
  }, []);

  useSetSubMenuToActive({
    element,
    activeFunction,
    inActiveFunction,
    isSuccess: hasLoadedItems,
  });

  return (
    <li
      className={cx(styles.ChildItem, {
        [styles.ChildItemActive]: isParentActive,
      })}
      data-automation-id={`HeaderCreateSubList${name}Item`}
      ref={element}
    >
      <span className={`${styles.ChildLink} ${styles.ChildLinkHasSubNav}`}>
        <span className={styles.ParentLinkText}>{translation}</span>
      </span>

      <MobileNavTreeToggle
        id={name}
        name={translation}
        inititalOpen={isParentActive}
        data-automation-id={`HeaderCreateSubList${name}Button`}
      />
      <SubMenusItem subMenus={subMenus || []} id={name} subMenuIsOpen={subMenuIsOpen} />
    </li>
  );
};

interface ICreateSubNavListProps {
  quickCreateMenu: IMenusPropertiesWithPagePath[];
  hasLoadedItems: boolean;
  isOpen?: boolean;
}

const CreateSubNavList = ({ quickCreateMenu, isOpen, hasLoadedItems }: ICreateSubNavListProps) => {
  const { pathname } = useLocation();
  return (
    <ul
      id="create-subnav"
      className={cx(
        styles.ChildList,
        styles.ChildList__hasGrandChildList,
        styles.ChildListWithXOverflow,
      )}
      data-automation-id="HeaderCreateSubList"
      data-is-open={isOpen}
    >
      {quickCreateMenu?.map(({ subMenus, name, translation }) => {
        // We need to find out where we are so
        // we can open the correct expand item on load
        const subPath = subMenus?.find(({ pagePath }) => pagePath === pathname);
        const isParentActive = !!subPath;

        return (
          <CreateSubNavListItem
            subMenus={subMenus}
            isParentActive={isParentActive}
            name={name}
            translation={translation}
            hasLoadedItems={hasLoadedItems}
            key={`CreateNav${removeEmptySpace(name)}`}
          />
        );
      })}
    </ul>
  );
};

export const CreateSubNav = () => {
  const { pathname } = useLocation();

  const { quickCreateMenu, isQuickCreateMenuEmpty, isSuccess } = useCreateMenu();
  const { t } = useTranslation('header');

  const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);
  const element = useRef<HTMLLIElement>(null);

  const activeFunction = useCallback(() => {
    setSubMenuIsOpen(true);
  }, []);

  const inActiveFunction = useCallback(() => {
    setSubMenuIsOpen(false);
  }, []);

  useSetSubMenuToActive({
    element,
    activeFunction,
    inActiveFunction,
    isSuccess,
  });

  const quickCreateMenuIsActive = quickCreateMenu?.find(
    ({ subMenus }) =>
      // We need to find out where we are so
      // we can highlight the top item on load
      !!subMenus?.find(({ pagePath }) => pagePath === pathname),
  );

  return !isQuickCreateMenuEmpty ? (
    <li
      className={cx(styles.ParentItem, { [styles.ParentItemActive]: quickCreateMenuIsActive })}
      data-automation-id="HeaderCreateSub"
      ref={element}
    >
      <span
        className={`${styles.ParentLink} ${styles.ParentLink__hasIcon} ${styles.ParentLinkHasSubNav}`}
      >
        <Icon
          iconName="circlePlus"
          aria-hidden="true"
          size="large"
          data-automation-id="HeaderCreateSubIcon"
        />
        <span className={styles.ParentLinkText}>{t('QuickCreateNavigationHeading')}</span>
      </span>

      <MobileNavTreeToggle
        id="create-subnav"
        name={t('QuickCreateNavigationHeading')}
        inititalOpen={isQuickCreateMenuOpen({ quickCreateNavData: quickCreateMenu, pathname })}
        data-automation-id="HeaderCreateSubTopLevelButton"
      />
      <CreateSubNavList
        quickCreateMenu={quickCreateMenu}
        isOpen={subMenuIsOpen}
        hasLoadedItems={isSuccess}
      />
    </li>
  ) : null;
};
