import { Table, TCellIndentation, THorizontalAlignment } from 'src/components/ui-components';
import { memo, ReactNode } from 'react';

interface IRenderBodyFirstColumn {
  rowId: string;
  depth: TCellIndentation;
  alignment: THorizontalAlignment;
  children: ReactNode;
}

const RenderBodyFirstColumn = memo(
  ({ rowId, depth, alignment, children }: IRenderBodyFirstColumn) => (
    <Table.Header alignment={alignment} id={`firstColumnRow${rowId}`} indent={depth} scope="row">
      {children}
    </Table.Header>
  ),
);

export default RenderBodyFirstColumn;
