import { useIsValidIndexControlName } from 'src/apis/indexAPI';
import { isDev } from 'src/utils/env';
import { IFeatureControl } from '../../types';

const SpanComponent = ({ idForFalseScenario }: Pick<IFeatureControl, 'idForFalseScenario'>) => {
  if (idForFalseScenario && isDev)
    return <span data-automation-id={`IndexControlDisable${idForFalseScenario}`} />;
  return null;
};

export default ({
  children,
  indexControlName,
  actionName,
  idForFalseScenario = '',
}: Pick<
  IFeatureControl,
  'children' | 'indexControlName' | 'actionName' | 'idForFalseScenario'
>) => {
  const { isError, isValidIndexControlName } = useIsValidIndexControlName(
    indexControlName,
    actionName,
  );

  if (isError) {
    return null;
  }
  return isValidIndexControlName ? (
    children
  ) : (
    <SpanComponent idForFalseScenario={idForFalseScenario} />
  );
};
