import { forwardRef } from 'react';
import MuiToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import { Tooltip } from 'src/components/mui-components/Tooltip';
import { TooltipProps } from '@mui/material/Tooltip';

type TooltipToggleButtonProps = ToggleButtonProps & {
  tooltipProps: Omit<TooltipProps, 'children'>;
};

type ButtonRef = HTMLButtonElement;

export const ToggleButton = forwardRef<ButtonRef, TooltipToggleButtonProps>(
  ({ tooltipProps, ...props }, ref) =>
    props.disabled ? (
      <MuiToggleButton ref={ref} {...props} />
    ) : (
      <Tooltip {...tooltipProps}>
        <MuiToggleButton ref={ref} {...props} />
      </Tooltip>
    ),
);
