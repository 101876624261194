import { RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from 'src/components/mui-components/Stack';
import { useFullFilterList } from 'src/stores/FilterStore';
import { translationAnyText } from 'src/utils/translation';
import { Header } from './components/Header';
import { InputGroup } from './components/InputGroup';
import { useFocusTrapping } from './helpers/focusTrapping';

interface IFilterList {
  activateSidePanelButtonRef?: RefObject<HTMLButtonElement>;
}

export const FilterList = ({ activateSidePanelButtonRef }: IFilterList) => {
  const filterList = useFullFilterList();
  const { t } = useTranslation('filter');
  const sideBarRef = useRef<HTMLDivElement>(null);

  useFocusTrapping(sideBarRef);

  return (
    <div ref={sideBarRef}>
      <Stack gap={3} data-automation-id="SidePanel">
        <Header activateSidePanelButtonRef={activateSidePanelButtonRef} />
        {filterList.map(({ filterCategory = '', filterItems }) => (
          <InputGroup
            key={filterCategory}
            filterCategory={translationAnyText(t, `FilterCategory${filterCategory}`)}
            filterItems={filterItems || []}
          />
        ))}
      </Stack>
    </div>
  );
};
