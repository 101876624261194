import { Box } from '@mui/material';
import { IEmployeeSearchResProperties } from 'src/apis/resourcePlannerAPI';
import { FeatureControl } from 'src/components/utils/FeatureControl';
import { CompetenceList } from '../CompetenceList';
import { EmployeeCard } from '../EmployeeCard';
import styles from './EmployeeWithCompetence.module.scss';

interface IEmployeeWithCompetence {
  resource: IEmployeeSearchResProperties;
  onClick?: (r: IEmployeeSearchResProperties) => void;
  query?: string;
  showDepartmentFilter?: boolean;
  showLegalEntityFilter?: boolean;
}

export const EmployeeWithCompetence = ({
  onClick,
  query,
  resource,
  showDepartmentFilter,
  showLegalEntityFilter,
}: IEmployeeWithCompetence) => (
  <Box
    className={styles.gridBox}
    data-automation-id="EmployeeWithCompetence"
    onClick={() => onClick?.(resource)}
    role="button"
  >
    <Box className={styles.box}>
      <EmployeeCard
        capacity={resource.capacity}
        department={resource.department}
        legalEntity={resource.legalEntity}
        fullName={resource.fullName}
        initials={resource.initials}
        position={resource.position}
        query={query}
        showDepartmentFilter={showDepartmentFilter}
        showLegalEntityFilter={showLegalEntityFilter}
        userId={resource.userId}
      />
    </Box>
    <FeatureControl indexControlName="Competences">
      <Box className={styles.box}>
        <CompetenceList competenceList={resource.competences ?? []} />
      </Box>
    </FeatureControl>
  </Box>
);
