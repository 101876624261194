import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { APP_URL } from 'src/constants/cookies';
import { TARGET_DESTINATION } from 'src/constants/localstorage';

const getLoginCallBackPath = (): string | null => {
  const appUrl = Cookies.get(APP_URL);

  if (appUrl) {
    const storedTargetDestination = localStorage.getItem(TARGET_DESTINATION);

    if (storedTargetDestination) {
      return storedTargetDestination;
    }
  }

  return null;
};

export const getRedirectPathForTest = getLoginCallBackPath;

export default () => {
  const navigate = useNavigate();
  const result = getLoginCallBackPath();
  useEffect(() => {
    if (result) {
      localStorage.removeItem(TARGET_DESTINATION);
      navigate(result);
    }
  }, [result, navigate]);

  return null;
};
