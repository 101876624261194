import { KeyboardEvent, useRef, useState } from 'react';
import { eventKeyEnter } from 'src/constants/keyboardKey';
import { useInitialFocus } from 'src/hooks/useInitialFocus';
import { getKeyboardFocusableElements } from 'src/utils/accessibility';
import {
  blurSelectElement,
  keyboardAction,
  reloadKeyboardSelectedElement,
} from 'src/utils/keyboardUpDownEvent';

interface IUseKeyboardControl {
  backAction?: () => void;
}

export const useKeyboardControl = ({ backAction }: IUseKeyboardControl) => {
  // Initial focus
  const initialFocusElement = useRef<HTMLInputElement | null>(null);
  useInitialFocus(initialFocusElement);

  const searchContainerResults = useRef<HTMLUListElement>(null);
  const [selectItemIndex, setSelectItemIndex] = useState<number>(-1);

  const textFieldOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const focusableElements = getKeyboardFocusableElements(
      searchContainerResults?.current,
      "[role='button']",
    );
    const currentSelectedItem = focusableElements[selectItemIndex];
    if (focusableElements.length === 1 && event.key === 'Enter') {
      focusableElements[0].click();
      return;
    }
    if (focusableElements.length > 0) {
      if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
        return;
      }
      const isNeedToBlur = keyboardAction({
        event,
        setSelectItemIndex,
        selectItemIndex,
        focusableElements,
      });
      if (isNeedToBlur) {
        blurSelectElement(currentSelectedItem);
      }
    }
  };

  const dialogOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowLeft' && event.altKey) {
      event.preventDefault();
      event.stopPropagation();
      backAction?.();
    }

    if (event.key === eventKeyEnter && (event.ctrlKey || event.metaKey)) {
      getKeyboardFocusableElements(
        searchContainerResults?.current,
        '[class*="ariaSelected"] button[type="button"]',
      )?.[1].click();
    }
  };

  reloadKeyboardSelectedElement(
    getKeyboardFocusableElements(searchContainerResults?.current, "[role='button']")?.[
      selectItemIndex
    ],
    initialFocusElement,
  );

  return {
    dialogOnKeyDown,
    initialFocusElement,
    searchContainerResults,
    selectItemIndex,
    setSelectItemIndex,
    textFieldOnKeyDown,
  };
};
