import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useGetShortNameWithoutUrl } from 'src/apis/indexAPI';
import { useGetCurrentLanguage, useGetCustomerSegmentation } from 'src/apis/userSettingsAPI';
import { useGetUserInfo } from 'src/apis/usersAPI/meAPI';
import { isDev } from 'src/utils/env';

const DigitalAdoptionPlugin = () => {
  const { userInfo, isSuccess: idIsSuccess } = useGetUserInfo();
  const { lcid, isSuccess: lcidIsSuccess } = useGetCurrentLanguage();
  const navigate = useNavigate();
  const { shortNameWithoutUrl, isSuccess: shortNameIsSuccess } = useGetShortNameWithoutUrl();
  const { customerSegmentation, isSuccess: customerSegmentationSuccess } =
    useGetCustomerSegmentation();

  const loadScript =
    idIsSuccess && customerSegmentationSuccess && lcidIsSuccess && shortNameIsSuccess;

  useEffect(() => {
    const handleIframeEvent = (event: MessageEvent) => {
      const {
        data: { eventId },
      } = event;

      if (eventId === 'languageChanged') {
        // Pendo does not reinitialize its script when the language is changed
        // therefore we need to reload the page to make sure that the script is reinitialized
        navigate(0);
      }
    };

    window.addEventListener('message', handleIframeEvent as EventListener);

    return () => {
      window.removeEventListener('message', handleIframeEvent as EventListener);
    };
  }, [navigate]);

  const pendoScript = {
    visitor: {
      id: `${shortNameWithoutUrl}_${userInfo.userId}`,
      // this need to be a string, as the same value in TLP is a string
      userLanguage: lcid?.toString(),
      platform: 'web',
      ...(customerSegmentation && customerSegmentation.user),
    },
    account: {
      id: shortNameWithoutUrl,
      ...(customerSegmentation && customerSegmentation.account),
    },
  };

  if (isDev) {
    return null;
  }

  return (
    <Helmet>
      <script type="text/javascript">{`
        (function(apiKey){
          (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
          v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
        })('${import.meta.env.VITE_PENDO_API_KEY}');
      `}</script>

      {loadScript && (
        <script type="text/javascript">{`
          pendo.initialize(${JSON.stringify(pendoScript)});
        `}</script>
      )}
    </Helmet>
  );
};

export default DigitalAdoptionPlugin;
