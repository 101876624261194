import { useTranslation } from 'react-i18next';
import { useDeleteBookmarkAPI, useIsBookmarked, usePostBookmarkAPI } from 'src/apis/bookmarksAPI';
import { IconButton } from 'src/components/mui-components';
import { useGetCurrentPageId } from 'src/stores/PageStore';

import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';

export default () => {
  const { t } = useTranslation('header');
  const {
    location: { href },
  } = window;
  const { isBookmarked, deleteBookmarkUrl } = useIsBookmarked(href);
  const pageId = useGetCurrentPageId();
  const { mutate: deleteBookmark } = useDeleteBookmarkAPI(deleteBookmarkUrl);
  const { mutate: postBookmark } = usePostBookmarkAPI({ pageId, url: href });

  const tooltipText = isBookmarked
    ? t('ScreenHeaderBookmarkUnSave')
    : t('ScreenHeaderBookmarkSave');

  return (
    <IconButton
      title={tooltipText}
      data-automation-id="ScreenHeaderBookmarkButton"
      onClick={() => (isBookmarked ? deleteBookmark() : postBookmark())}
      size="small"
    >
      {isBookmarked ? <BookmarkRemoveIcon /> : <BookmarkAddIcon />}
    </IconButton>
  );
};
