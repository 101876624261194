import { useQuery } from '@tanstack/react-query';
import { IFilterCategory } from 'src/apis/types/filterListAPI';
import { getFetch } from 'src/utils/fetcher';

const FILTER_URL_PATH = (pageIdentifier: string) => `/api/v2/filters/list/${pageIdentifier}`;
export const FILTER_MSW_STATUS_KEY = 'filterAPI';

export const FILTER_KEY = 'FILTER';

export const useGetFilterAPI = (pageIdentifier: string) => {
  const filterFetch = (): Promise<IFilterCategory> =>
    getFetch({ path: FILTER_URL_PATH(pageIdentifier), key: FILTER_MSW_STATUS_KEY });

  const { data, ...restProps } = useQuery([`${FILTER_KEY}_${pageIdentifier}`], filterFetch, {
    enabled: !!pageIdentifier,
  });

  return {
    filterList:
      data?.entities?.map(({ properties = {} }) => ({
        ...properties,
      })) || [],
    ...restProps,
  };
};
