export default {
  Entities: [
    {
      Properties: {
        ContractHourlyRateID: 5001698,
        ProjectSubContractID: 239,
        Name: 'Intern tid',
        Amount: 0.0,
        Type: 'Standard',
      },
    },
    {
      Properties: {
        ContractHourlyRateID: 5012558,
        ProjectSubContractID: 239,
        Name: '50 Internal',
        Amount: 50.0,
        Type: 'Standard',
      },
    },
    {
      Properties: {
        ContractHourlyRateID: 5001699,
        ProjectSubContractID: 239,
        Name: 'Rejsetid',
        Amount: 300.0,
        Type: 'Standard',
      },
    },
    {
      Properties: {
        ContractHourlyRateID: 5001700,
        ProjectSubContractID: 239,
        Name: 'Sekretær',
        Amount: 350.0,
        Type: 'Standard',
      },
    },
    {
      Properties: {
        ContractHourlyRateID: 5001701,
        ProjectSubContractID: 239,
        Name: 'Projektassistent',
        Amount: 850.0,
        Type: 'Standard',
      },
    },
    {
      Properties: {
        ContractHourlyRateID: 5001702,
        ProjectSubContractID: 239,
        Name: 'Juniorkonsulent',
        Amount: 1000.0,
        Type: 'Standard',
      },
    },
    {
      Properties: {
        ContractHourlyRateID: 5001703,
        ProjectSubContractID: 239,
        Name: 'TCC',
        Amount: 1000.0,
        Type: 'Standard',
      },
    },
    {
      Properties: {
        ContractHourlyRateID: 5001704,
        ProjectSubContractID: 239,
        Name: 'Projektleder',
        Amount: 1200.0,
        Type: 'Standard',
      },
    },
    {
      Properties: {
        ContractHourlyRateID: 5001705,
        ProjectSubContractID: 239,
        Name: 'Seniorkonsulent',
        Amount: 1500.0,
        Type: 'Standard',
      },
    },
  ],
  Links: [
    {
      Href: 'https://uat.timelog.com/rp-test1/api/v2/contract-hourly-rates/dropdown-content?taskId=928',
      Rel: 'self',
    },
  ],
};
