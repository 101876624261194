import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IExchangeRateActionsResponse } from '../exchangeRateAPI';

const GET_EXCHANGE_RATE_MATRIX_ACTIONS_URL_PATH = '/api/v1/exchange-rate/matrix-actions';
export const GET_EXCHANGE_RATE_MATRIX_ACTIONS_MSW_STATUS_KEY = 'matrixActionsAPI';
export const GET_EXCHANGE_RATE_MATRIX_ACTIONS_KEY = 'EXCHANGE_RATE_MATRIX_ACTIONS';

const getMatrixActions = (): Promise<IExchangeRateActionsResponse> =>
  getFetch({
    path: GET_EXCHANGE_RATE_MATRIX_ACTIONS_URL_PATH,
    key: GET_EXCHANGE_RATE_MATRIX_ACTIONS_MSW_STATUS_KEY,
  });

export const useGetMatrixActions = () => {
  const { data, ...rest } = useQuery([GET_EXCHANGE_RATE_MATRIX_ACTIONS_KEY], getMatrixActions);

  const matrixActions = data?.entities
    ? data.entities.map(({ properties }) => ({ ...properties }))
    : [];

  return {
    matrixActions,
    ...rest,
  };
};
