import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import {
  ITimeRegistrationAccuracy,
  ITimeRegistrationAccuracyItem,
} from 'src/apis/types/timeRegistrationAccuracyAPI';
import { IInformation } from 'src/screens/Home/HomeType';
import { TTimeDuration, getStartAndEndDateRange, convertDateToLocaleString } from 'src/utils/date';
import { useGetLocale } from 'src/components/global/LocaleProvider';

const TIME_REGISTRATION_URL_PATH = '/api/v2/widget-data/time-registration-accuracy';
export const GET_TIME_REGISTRATION_MSW_STATUS_KEY = 'getTimeRegistrationAPI';
export const GET_TIME_REGISTRATION_KEY = 'GET_TIME_REGISTRATION_KEY';

const getTimeRegistrationUrl = (timeRegisterType: TTimeDuration) => {
  const { startDate, endDate } = getStartAndEndDateRange(timeRegisterType);
  return `${TIME_REGISTRATION_URL_PATH}?startDate=${startDate}&endDate=${endDate}`;
};

export const getTimeRegistrationUrlForTest = getTimeRegistrationUrl;

const getLastRegistrationDate = (locale: string, lastRegistrationDate?: string): string =>
  lastRegistrationDate ? convertDateToLocaleString(new Date(lastRegistrationDate), locale) : '--';

export const getLastRegistrationDateForTest = getLastRegistrationDate;

const widgetInformationListNumberFormat: Intl.NumberFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

const getWidgetInformationList = (
  {
    lastRegistrationDate,
    accuracyCurrentPeriod,
    accuracyPreviousPeriod,
  }: Pick<
    ITimeRegistrationAccuracyItem,
    'lastRegistrationDate' | 'accuracyCurrentPeriod' | 'accuracyPreviousPeriod'
  >,
  locale: string,
): Array<IInformation> => [
  {
    value: accuracyCurrentPeriod.toLocaleString(locale, widgetInformationListNumberFormat),
    key: 'Trend',
    trending: accuracyPreviousPeriod >= accuracyCurrentPeriod ? 'Yes' : 'No',
  },
  {
    value: accuracyPreviousPeriod.toLocaleString(locale, widgetInformationListNumberFormat),
    key: 'Last7Days',
  },
  {
    value: getLastRegistrationDate(locale, lastRegistrationDate),
    key: 'LastRegistration',
  },
];

export const getWidgetInformationListForTest = getWidgetInformationList;

const getTimeRegistrationAccuracy = (
  timeRegisterType: TTimeDuration,
): Promise<ITimeRegistrationAccuracy> =>
  getFetch({
    path: getTimeRegistrationUrl(timeRegisterType),
    key: GET_TIME_REGISTRATION_MSW_STATUS_KEY,
  });

export const useGetTimeRegistrationAccuracyForHomeWidget = (timeRegisterType: TTimeDuration) => {
  const { data, ...restProps } = useQuery([GET_TIME_REGISTRATION_KEY, timeRegisterType], () =>
    getTimeRegistrationAccuracy(timeRegisterType),
  );

  const locale = useGetLocale();

  if (data?.properties) {
    return {
      informationList: getWidgetInformationList(data.properties, locale),
      ...restProps,
    };
  }
  return {
    informationList: [],
    ...restProps,
  };
};
