import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiLineTranslation } from 'src/components/utils/MultiLineTranslation';
import { useInitialFocus } from 'src/hooks/useInitialFocus';
import { TCompetenceDialogType } from 'src/screens/SystemAdministration/Employees/Competences/components/types';
import { ICompetenceToRemove } from '../../Competences';

interface IRemoveCompetenceDialog {
  dialogIsOpen: TCompetenceDialogType | null;
  handleRemoveCompetenceDialogClose: () => void;
  competenceToRemove: ICompetenceToRemove | undefined;
  remove: (item?: ICompetenceToRemove) => void;
}

export const RemoveCompetenceDialog = ({
  dialogIsOpen,
  handleRemoveCompetenceDialogClose,
  competenceToRemove,
  remove,
}: IRemoveCompetenceDialog) => {
  const { t } = useTranslation('employeeCompetences');
  // Initial focus
  const initialFocusElement = useRef<HTMLButtonElement | null>(null);
  useInitialFocus(initialFocusElement);

  return (
    <Dialog
      open={dialogIsOpen === 'remove'}
      onClose={handleRemoveCompetenceDialogClose}
      data-automation-id="EmployeeCompetenceRemoveDialog"
    >
      {/* , { ITEM_NAME: competenceToRemove?.name } */}
      <DialogTitle>
        {competenceToRemove?.type === 'category' && t('modals.RemoveItemCategoryHeader')}
        {competenceToRemove?.type === 'competence' && t('modals.RemoveItemCompetenceHeader')}
        {competenceToRemove?.type === 'all' && t('modals.RemoveAllCompetenceHeader')}
      </DialogTitle>
      <DialogContent>
        {competenceToRemove?.type === 'category' && (
          <DialogContentText>
            <MultiLineTranslation
              translatedContent={t('modals.RemoveItemCategoryContentText', {
                CATEGORY_NAME: competenceToRemove?.name,
              })}
            />
          </DialogContentText>
        )}

        {competenceToRemove?.type === 'competence' && (
          <DialogContentText>
            <MultiLineTranslation
              translatedContent={t('modals.RemoveItemCompetenceContentText', {
                COMPETENCE_NAME: competenceToRemove?.name,
              })}
            />
          </DialogContentText>
        )}

        {competenceToRemove?.type === 'all' && (
          <DialogContentText>
            <MultiLineTranslation translatedContent={t('modals.RemoveAllCompetenceContentText')} />
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleRemoveCompetenceDialogClose}
          data-automation-id="EmployeeCompetenceRemoveCancelButton"
        >
          {t('modals.RemoveItemButtonCancelText')}
        </Button>
        <Button
          variant="contained"
          onClick={() => remove(competenceToRemove)}
          data-automation-id="EmployeeCompetenceRemoveDeleteButton"
          ref={initialFocusElement}
        >
          {t('modals.RemoveItemButtonDeleteText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
