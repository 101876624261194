export default {
  Entities: [
    {
      Properties: {
        Id: 0,
        Title: 'View name',
        Period: '2',
        IsDefault: true,
        createdDate: '2023-10-12T17:15:33.008Z',
        Filters: {
          ProjectManager: {
            label: 'ProjectManager',
            id: 5,
            values: [
              {
                label: 'Peter Summersen',
                value: '373',
              },
            ],
          },
          Employee: {
            label: 'Employee',
            id: 12,
            values: [
              {
                label: 'employee 2',
                value: 'employeeOption2',
              },
            ],
          },
          ContractType: {
            label: 'ContractType',
            id: 10,
            values: [
              {
                label: 'contract-type 5',
                value: 'contract-typeOption5',
              },
            ],
          },
          Project: {
            label: 'Project',
            id: 3,
            values: [
              {
                label: '0003 Daniel from scratch',
                value: '3',
              },
            ],
          },
          ProjectCategory: {
            label: 'ProjectCategory',
            id: 9,
            values: [
              {
                label: 'project-category 2',
                value: 'project-categoryOption2',
              },
            ],
          },
        },
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v1/save-view',
      Rel: 'self',
    },
  ],
};
