import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import generatePeriodLabel from 'src/screens/ResourcePlanner/helper/generatePeriodLabel';
import { useGetVacationCalculationReportingWidget } from 'src/apis/reportingWidgetsAPI';
import { useTranslation } from 'react-i18next';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { SxProps } from '@mui/material';
import { dataGridCellFontWeightMediumClassName } from 'src/components/mui-components';
import ReportingWidgetWithTable from '../ReportingWidgetWithTable';

const parseRowData = (data: any) => {
  const keys = Object.keys(data);
  const rowData = keys.map((key: string) => ({
    category: key,
    ...data[key],
  }));
  return rowData;
};

export const VacationCalculationReportingWidget = () => {
  const { widgetName, widgetData, isError, isLoading } = useGetVacationCalculationReportingWidget();
  const locale = useGetLocale();
  const { t } = useTranslation('reportingWidgets');
  const sx: SxProps = {
    height: '240px',
  };

  const categoryNameMapping = (category: string) => {
    switch (category) {
      case 'previousMonthTotals':
        return t('LabelPreviousMonthTotal');
      case 'assignedVacations':
        return t('LabelAssignedVacation');
      case 'absences':
        return t('LabelAbsence');
      case 'availableTimeOff':
        return t('LabelAvailableTimeOff');
      default:
        return category;
    }
  };

  const isEmptyState = Object.keys(widgetData).length === 0;
  const rows = isEmptyState ? [] : parseRowData(widgetData);

  const getColumns = (data: any) => {
    const { category, ...rest } = data;
    const newDataObject = {
      category,
      value: rest,
    };

    const restColumnData = Object.keys(newDataObject.value).map((key) => ({
      headerName: generatePeriodLabel(key, 'month'),
      field: key,
      width: 72,
      headerAlign: 'right',
      align: 'right',
      cellClassName: (params: GridRenderCellParams) => (Number(params.value) < 0 ? 'error' : ''),
      renderCell: (params: GridRenderCellParams) =>
        Number(params.value.toFixed(2)).toLocaleString(locale),
    }));

    const columns = [
      {
        headerName: '',
        key: 'category',
        width: 250,
        cellClassName: dataGridCellFontWeightMediumClassName,
        renderCell: (params: GridRenderCellParams) => categoryNameMapping(params.value),
      },
      ...restColumnData,
    ];

    return columns;
  };

  const cols = isEmptyState ? [] : getColumns(rows[0]);

  return (
    <ReportingWidgetWithTable
      customSx={sx}
      name={widgetName}
      title={t('VacationCalculation')}
      data={rows}
      columnData={cols}
      infoIconText={t('VacationCalculationWidgetDescription')}
      isEmpty={isEmptyState}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
