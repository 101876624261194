import { useQuery } from '@tanstack/react-query';
import { IEntities } from 'src/apis/types/responseBase';
import { getFetch } from 'src/utils/fetcher';
import {
  EMPLOYEE_AVAILABLE_COMPETENCES_MSW_STATUS_KEY,
  EMPLOYEE_COMPETENCES_MSW_STATUS_KEY,
  EMPLOYEE_COMPETENCES_URL_PATH,
  GET_EMPLOYEE_AVAILABLE_COMPETENCES_KEY,
  GET_EMPLOYEE_COMPETENCES_KEY,
} from '../constants';
import {
  IConvertedAvailableCompetences,
  IEmployeeAvailableCompetences,
  IEmployeeAvailableCompetencesProperties,
  IEmployeeCompetences,
} from '../types';

const employeeCompetencesFetch = (userId?: string): Promise<IEmployeeCompetences> =>
  getFetch({
    path: EMPLOYEE_COMPETENCES_URL_PATH,
    itemId: `?userId=${userId}&isActive=true`,
    key: EMPLOYEE_COMPETENCES_MSW_STATUS_KEY,
  });

export const useGetEmployeeCompetences = (userId?: string) => {
  const { data, ...restProps } = useQuery([GET_EMPLOYEE_COMPETENCES_KEY, userId], () =>
    employeeCompetencesFetch(userId),
  );

  return {
    competencesList: data?.properties?.competenceList || [],
    ...restProps,
  };
};

const employeeAvailableCompetencesFetch = (
  userId?: string,
): Promise<IEmployeeAvailableCompetences> =>
  getFetch({
    path: EMPLOYEE_COMPETENCES_URL_PATH,
    itemId: `/available?userId=${userId}`,
    key: EMPLOYEE_AVAILABLE_COMPETENCES_MSW_STATUS_KEY,
  });

export const useGetAvailableEmployeeCompetences = (userId?: string) => {
  const { data, ...restProps } = useQuery(
    [GET_EMPLOYEE_AVAILABLE_COMPETENCES_KEY, userId],
    () => employeeAvailableCompetencesFetch(userId),
    { cacheTime: 0 },
  );

  const massagedData = (rows: IEntities<IEmployeeAvailableCompetencesProperties>[] | undefined) => {
    if (!rows) {
      return [];
    }

    const massagedRows: IConvertedAvailableCompetences[] = [];

    // I want to massage the data so that it looks like the data from the competencesAPI,
    // but if groupId is already in the array, I want to add the items to the existing group
    rows.forEach(({ properties }) => {
      const { groupId, groupName, competenceId, competenceName } = properties;

      // please skip adding the group if it already exists
      if (massagedRows.some((group) => group.groupId === groupId)) {
        massagedRows.forEach((group) => {
          if (group.groupId === groupId) {
            group.items?.push({ competenceId, competenceName });
          }
        });
        return;
      }

      massagedRows.push({
        groupId,
        groupName,
        items: [],
      });

      massagedRows.forEach((group) => {
        if (group.groupId === groupId) {
          group.items?.push({ competenceId, competenceName });
        }
      });
    });

    return massagedRows;
  };

  const availableCategories = massagedData(data?.entities).map(({ items, ...rest }) => rest);

  return {
    data: data?.entities ?? [],
    nestedData: massagedData(data?.entities),
    availableCategories,
    ...restProps,
  };
};
