import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { getPathWithQueries } from 'src/utils/url/url';
import { GET_UNALLOCATED_TASKS_KEY } from './getUnallocatedTasksAPI.constants';
import { TUnallocatedTasksRes } from './getUnallocatedTasksAPI.types';

const GET_UNALLOCATED_TASKS_URL_PATH = '/api/v2/projects/{projectId}/tasks-unallocated';

interface IGetUnallocatedTasks {
  projectId: string;
  userId: string;
  isIncludeParentTask?: boolean;
  searchText?: string;
}

const getUnallocatedTasks = ({
  isIncludeParentTask,
  projectId,
  searchText,
  userId,
}: IGetUnallocatedTasks): Promise<TUnallocatedTasksRes> =>
  getFetch({
    path: getPathWithQueries(GET_UNALLOCATED_TASKS_URL_PATH.replace('{projectId}', projectId), {
      isIncludeParentTask,
      searchText: searchText?.trim(),
      userId,
    }),
    key: GET_UNALLOCATED_TASKS_KEY,
  });

export const useGetUnallocatedTasks = (params: IGetUnallocatedTasks, enableQuery?: boolean) => {
  const { isIncludeParentTask, projectId, searchText, userId } = params;
  const { data, ...rest } = useQuery(
    [GET_UNALLOCATED_TASKS_KEY, isIncludeParentTask, projectId, searchText, userId],
    () => getUnallocatedTasks(params),
    { enabled: enableQuery && !!projectId && !!userId },
  );

  return { data: data?.entities, ...rest };
};
