import { Typography } from 'src/components/mui-components';
import { useGetCurrentPageTitle } from 'src/stores/PageStore';

export default () => {
  const pageTitle = useGetCurrentPageTitle();

  return (
    <Typography data-automation-id="ScreenHeaderTitle" component="h1" variant="h2" fontWeight={500}>
      {pageTitle}
    </Typography>
  );
};
