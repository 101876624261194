import { SettingsContentWrapper } from 'src/components/layout/SettingsContentWrapper';
import { useTranslation } from 'react-i18next';
import { PageSettingsWrapper } from '../../components/PageSettingsWrapper';

export const NotFound = () => {
  const { t } = useTranslation('pageSettings');
  return (
    <PageSettingsWrapper>
      <SettingsContentWrapper
        header={t('NoSettingsHeading')}
        description={t('NoSettingsDescription')}
      />
    </PageSettingsWrapper>
  );
};
