export default {
  Entities: [
    {
      Properties: {
        DisplayText: '1 Alam  Tam Future (ATA)',
        Value: '50',
      },
    },
    {
      Properties: {
        DisplayText: '1 Daniel Yap (DAY)',
        Value: '70',
      },
    },
    {
      Properties: {
        DisplayText: '1 Jimmy Lee (JIL)',
        Value: '71',
      },
    },
    {
      Properties: {
        DisplayText: 'Amy Aw (AAW)',
        Value: '54',
      },
    },
    {
      Properties: {
        DisplayText: 'Anders Nielsen (ANI)',
        Value: '1',
      },
    },
    {
      Properties: {
        DisplayText: 'Belinda (A) Bong (BEBI)',
        Value: '72',
      },
    },
    {
      Properties: {
        DisplayText: 'Carl Chan (CCH)',
        Value: '6',
      },
    },
    {
      Properties: {
        DisplayText: 'Erling Sørensen (ESØ)',
        Value: '9',
      },
    },
    {
      Properties: {
        DisplayText: 'George Best (IMO)',
        Value: '12',
      },
    },
    {
      Properties: {
        DisplayText: 'Gerda Angelsen (GAN)',
        Value: '11',
      },
    },
    {
      Properties: {
        DisplayText: 'James  Bong (JAB)',
        Value: '14',
      },
    },
    {
      Properties: {
        DisplayText: 'Jenny Wong (JEW)',
        Value: '37',
      },
    },
    {
      Properties: {
        DisplayText: 'Linda Lim (LIL)',
        Value: '53',
      },
    },
    {
      Properties: {
        DisplayText: 'Test TCC Past (TCC)',
        Value: '13',
      },
    },
    {
      Properties: {
        DisplayText: 'test test (ttt)',
        Value: '75',
      },
    },
    {
      Properties: {
        DisplayText: 'Todd Boehly (tbl)',
        Value: '73',
      },
    },
    {
      Properties: {
        DisplayText: 'Aaron Ng (AAN)',
        Value: '51',
      },
    },
    {
      Properties: {
        DisplayText: 'Åsu Åsu (Åsu)',
        Value: '65',
      },
    },
  ],
  Links: [
    {
      Href: 'https://uat.timelog.com/rp-test1/api/v2/filters/employee/content?pageIdentifier=NewResourcePlanner',
      Rel: 'self',
    },
  ],
};
