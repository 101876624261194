import { useEffect, RefObject } from 'react';
import { eventKeyEscape, eventKeyTab } from 'src/constants/keyboardKey';
import { getKeyboardFocusableElements } from 'src/utils/accessibility';

export const useSearchKeyboardEvent = (
  mainSearchIsOpen: boolean | undefined,
  closeSearch: () => void | undefined,
  currentMainSearchRef: HTMLDivElement | null,
  MainSearchTriggerRef: RefObject<HTMLButtonElement>,
) =>
  useEffect(() => {
    if (mainSearchIsOpen && currentMainSearchRef) {
      currentMainSearchRef.addEventListener('keydown', (event) => {
        const [firstElement, ...restElements] = getKeyboardFocusableElements(currentMainSearchRef);

        const isShiftKeyAndTabKeyAndFirstFocusableElement =
          event.key === eventKeyTab && event.shiftKey && event.target === firstElement;

        const isTabOnLastFocusableElementWithoutShift =
          event.key === eventKeyTab &&
          !event.shiftKey &&
          event.target === restElements?.[restElements.length - 1];

        // Are we leaving the main search using tab or shift + tab
        if (isShiftKeyAndTabKeyAndFirstFocusableElement) {
          closeSearch();
        } else if (isTabOnLastFocusableElementWithoutShift) {
          closeSearch();
        }

        if (event.key === eventKeyEscape) {
          const currentFocusItem = document.activeElement?.getAttribute('data-automation-id');
          if (
            currentFocusItem === 'SuperSearchInput' ||
            currentFocusItem === 'SearchInputClearButton'
          ) {
            closeSearch();
            MainSearchTriggerRef?.current?.focus();
          }
        }
      });
    } else if (currentMainSearchRef) {
      currentMainSearchRef.removeEventListener('keydown', (event) => {
        if (event.key === eventKeyEscape) {
          closeSearch();
        }
      });
    }
  }, [mainSearchIsOpen, closeSearch, currentMainSearchRef, MainSearchTriggerRef]);
