import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFetch } from 'src/utils/fetcher';
import { IAddCompetenceItems } from '../types';
import {
  EMPLOYEE_COMPETENCES_URL_PATH,
  GET_EMPLOYEE_AVAILABLE_COMPETENCES_KEY,
  GET_EMPLOYEE_COMPETENCES_KEY,
} from '../constants';

export const POST_EMPLOYEE_COMPETENCES_MSW_STATUS_KEY = 'postEmployeeCompetence';

export const POST_EMPLOYEE_COMPETENCES_KEY = 'POST_EMPLOYEE_COMPETENCES';

const postEmployeeCompetence = ({
  userId,
  employeeCompetences,
}: {
  userId: number;
  employeeCompetences: IAddCompetenceItems[];
}) =>
  postFetch({
    path: `${EMPLOYEE_COMPETENCES_URL_PATH}/bulk`,
    key: POST_EMPLOYEE_COMPETENCES_MSW_STATUS_KEY,
    body: JSON.stringify({
      UserId: userId,
      EmployeeCompetences: employeeCompetences?.map((c) => ({
        CompetenceId: c.competenceId,
        Value: c.value,
      })),
    }),
  });

export const usePostEmployeeCompetence = () => {
  const qc = useQueryClient();

  return useMutation(postEmployeeCompetence, {
    onSuccess: () => {
      qc.invalidateQueries([GET_EMPLOYEE_COMPETENCES_KEY]);
      qc.invalidateQueries([GET_EMPLOYEE_AVAILABLE_COMPETENCES_KEY]);
    },
  });
};
