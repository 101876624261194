export default {
  Entities: [
    {
      Properties: {
        IntercompanyExchangeRateId: 15289,
        Amount: 100,
        LookupKey: '313_35_35',
        InverseLookupKey: '313_35_35',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15290,
        Amount: 100,
        LookupKey: '313_2_2',
        InverseLookupKey: '313_2_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15291,
        Amount: 26.2829,
        LookupKey: '313_2_35',
        InverseLookupKey: '313_35_2',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15292,
        Amount: 380.4755,
        LookupKey: '313_35_2',
        InverseLookupKey: '313_2_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15293,
        Amount: 53.4374,
        LookupKey: '313_9_2',
        InverseLookupKey: '313_2_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15294,
        Amount: 187.1342,
        LookupKey: '313_2_9',
        InverseLookupKey: '313_9_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15295,
        Amount: 100,
        LookupKey: '313_9_9',
        InverseLookupKey: '313_9_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15296,
        Amount: 14.0449,
        LookupKey: '313_9_35',
        InverseLookupKey: '313_35_9',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15297,
        Amount: 712,
        LookupKey: '313_35_9',
        InverseLookupKey: '313_9_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15298,
        Amount: 236.2555,
        LookupKey: '313_21_2',
        InverseLookupKey: '313_2_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15299,
        Amount: 42.3271,
        LookupKey: '313_2_21',
        InverseLookupKey: '313_21_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15300,
        Amount: 442.115,
        LookupKey: '313_21_9',
        InverseLookupKey: '313_9_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15301,
        Amount: 22.6185,
        LookupKey: '313_9_21',
        InverseLookupKey: '313_21_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15302,
        Amount: 100,
        LookupKey: '313_21_21',
        InverseLookupKey: '313_21_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15303,
        Amount: 62.0948,
        LookupKey: '313_21_35',
        InverseLookupKey: '313_35_21',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15304,
        Amount: 161.0441,
        LookupKey: '313_35_21',
        InverseLookupKey: '313_21_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15305,
        Amount: 543.5363,
        LookupKey: '313_28_2',
        InverseLookupKey: '313_2_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15306,
        Amount: 18.398,
        LookupKey: '313_2_28',
        InverseLookupKey: '313_28_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15307,
        Amount: 1017.1426,
        LookupKey: '313_28_9',
        InverseLookupKey: '313_9_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15308,
        Amount: 9.8314,
        LookupKey: '313_9_28',
        InverseLookupKey: '313_28_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15309,
        Amount: 230.0629,
        LookupKey: '313_28_21',
        InverseLookupKey: '313_21_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15310,
        Amount: 43.4664,
        LookupKey: '313_21_28',
        InverseLookupKey: '313_28_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15311,
        Amount: 100,
        LookupKey: '313_28_28',
        InverseLookupKey: '313_28_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15312,
        Amount: 142.8571,
        LookupKey: '313_28_35',
        InverseLookupKey: '313_35_28',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15313,
        Amount: 70,
        LookupKey: '313_35_28',
        InverseLookupKey: '313_28_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15314,
        Amount: 74.5196,
        LookupKey: '313_29_2',
        InverseLookupKey: '313_2_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15315,
        Amount: 134.1929,
        LookupKey: '313_2_29',
        InverseLookupKey: '313_29_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15316,
        Amount: 139.4516,
        LookupKey: '313_29_9',
        InverseLookupKey: '313_9_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15317,
        Amount: 71.7092,
        LookupKey: '313_9_29',
        InverseLookupKey: '313_29_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15318,
        Amount: 31.5419,
        LookupKey: '313_29_21',
        InverseLookupKey: '313_21_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15319,
        Amount: 317.0386,
        LookupKey: '313_21_29',
        InverseLookupKey: '313_29_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15320,
        Amount: 13.7101,
        LookupKey: '313_29_28',
        InverseLookupKey: '313_28_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15321,
        Amount: 729.3875,
        LookupKey: '313_28_29',
        InverseLookupKey: '313_29_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15322,
        Amount: 100,
        LookupKey: '313_29_29',
        InverseLookupKey: '313_29_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15323,
        Amount: 19.5859,
        LookupKey: '313_29_35',
        InverseLookupKey: '313_35_29',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15324,
        Amount: 510.5714,
        LookupKey: '313_35_29',
        InverseLookupKey: '313_29_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15325,
        Amount: 1893.9542,
        LookupKey: '313_31_2',
        InverseLookupKey: '313_2_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15326,
        Amount: 5.28,
        LookupKey: '313_2_31',
        InverseLookupKey: '313_31_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15327,
        Amount: 3544.237,
        LookupKey: '313_31_9',
        InverseLookupKey: '313_9_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15328,
        Amount: 2.8215,
        LookupKey: '313_9_31',
        InverseLookupKey: '313_31_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15329,
        Amount: 801.6551,
        LookupKey: '313_31_21',
        InverseLookupKey: '313_21_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15330,
        Amount: 12.4742,
        LookupKey: '313_21_31',
        InverseLookupKey: '313_31_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15331,
        Amount: 348.4503,
        LookupKey: '313_31_28',
        InverseLookupKey: '313_28_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15332,
        Amount: 28.6984,
        LookupKey: '313_28_31',
        InverseLookupKey: '313_31_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15333,
        Amount: 2541.5535,
        LookupKey: '313_31_29',
        InverseLookupKey: '313_29_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15334,
        Amount: 3.9346,
        LookupKey: '313_29_31',
        InverseLookupKey: '313_31_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15335,
        Amount: 100,
        LookupKey: '313_31_31',
        InverseLookupKey: '313_31_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15336,
        Amount: 497.7861,
        LookupKey: '313_31_35',
        InverseLookupKey: '313_35_31',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15337,
        Amount: 20.0889,
        LookupKey: '313_35_31',
        InverseLookupKey: '313_31_35',
        IsImmutable: false,
      },
    },
  ],
  Links: [
    {
      Href: 'http://mockapi/api/v1/exchange-rate/get-matrix-exchange-rates-by-period-id?periodId=313',
      Rel: 'self',
    },
  ],
};

export const matrixExchangeRateResultSetForPeriodId312 = {
  Entities: [
    {
      Properties: {
        IntercompanyExchangeRateId: 15240,
        Amount: 100.0,
        LookupKey: '312_35_35',
        InverseLookupKey: '312_35_35',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15241,
        Amount: 100.0,
        LookupKey: '312_2_2',
        InverseLookupKey: '312_2_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15242,
        Amount: 26.2829,
        LookupKey: '312_2_35',
        InverseLookupKey: '312_35_2',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15243,
        Amount: 380.4755,
        LookupKey: '312_35_2',
        InverseLookupKey: '312_2_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15244,
        Amount: 53.5127,
        LookupKey: '312_9_2',
        InverseLookupKey: '312_2_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15245,
        Amount: 186.8714,
        LookupKey: '312_2_9',
        InverseLookupKey: '312_9_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15246,
        Amount: 100.0,
        LookupKey: '312_9_9',
        InverseLookupKey: '312_9_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15247,
        Amount: 14.0647,
        LookupKey: '312_9_35',
        InverseLookupKey: '312_35_9',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15248,
        Amount: 711.0,
        LookupKey: '312_35_9',
        InverseLookupKey: '312_9_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15249,
        Amount: 236.2555,
        LookupKey: '312_21_2',
        InverseLookupKey: '312_2_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15250,
        Amount: 42.3271,
        LookupKey: '312_2_21',
        InverseLookupKey: '312_21_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15251,
        Amount: 441.494,
        LookupKey: '312_21_9',
        InverseLookupKey: '312_9_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15252,
        Amount: 22.6504,
        LookupKey: '312_9_21',
        InverseLookupKey: '312_21_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15253,
        Amount: 100.0,
        LookupKey: '312_21_21',
        InverseLookupKey: '312_21_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15254,
        Amount: 62.0948,
        LookupKey: '312_21_35',
        InverseLookupKey: '312_35_21',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15255,
        Amount: 161.0441,
        LookupKey: '312_35_21',
        InverseLookupKey: '312_21_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15256,
        Amount: 548.5684,
        LookupKey: '312_28_2',
        InverseLookupKey: '312_2_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15257,
        Amount: 18.2293,
        LookupKey: '312_2_28',
        InverseLookupKey: '312_28_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15258,
        Amount: 1025.1177,
        LookupKey: '312_28_9',
        InverseLookupKey: '312_9_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15259,
        Amount: 9.755,
        LookupKey: '312_9_28',
        InverseLookupKey: '312_28_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15260,
        Amount: 232.1929,
        LookupKey: '312_28_21',
        InverseLookupKey: '312_21_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15261,
        Amount: 43.0676,
        LookupKey: '312_21_28',
        InverseLookupKey: '312_28_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15262,
        Amount: 100.0,
        LookupKey: '312_28_28',
        InverseLookupKey: '312_28_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15263,
        Amount: 144.1797,
        LookupKey: '312_28_35',
        InverseLookupKey: '312_35_28',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15264,
        Amount: 69.3579,
        LookupKey: '312_35_28',
        InverseLookupKey: '312_28_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15265,
        Amount: 74.5196,
        LookupKey: '312_29_2',
        InverseLookupKey: '312_2_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15266,
        Amount: 134.1929,
        LookupKey: '312_2_29',
        InverseLookupKey: '312_29_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15267,
        Amount: 139.2557,
        LookupKey: '312_29_9',
        InverseLookupKey: '312_9_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15268,
        Amount: 71.8103,
        LookupKey: '312_9_29',
        InverseLookupKey: '312_29_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15269,
        Amount: 31.5419,
        LookupKey: '312_29_21',
        InverseLookupKey: '312_21_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15270,
        Amount: 317.0386,
        LookupKey: '312_21_29',
        InverseLookupKey: '312_29_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15271,
        Amount: 13.5844,
        LookupKey: '312_29_28',
        InverseLookupKey: '312_28_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15272,
        Amount: 736.1385,
        LookupKey: '312_28_29',
        InverseLookupKey: '312_29_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15273,
        Amount: 100.0,
        LookupKey: '312_29_29',
        InverseLookupKey: '312_29_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15274,
        Amount: 19.5859,
        LookupKey: '312_29_35',
        InverseLookupKey: '312_35_29',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15275,
        Amount: 510.5714,
        LookupKey: '312_35_29',
        InverseLookupKey: '312_29_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15276,
        Amount: 1891.9715,
        LookupKey: '312_31_2',
        InverseLookupKey: '312_2_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15277,
        Amount: 5.2855,
        LookupKey: '312_2_31',
        InverseLookupKey: '312_31_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15278,
        Amount: 3535.5542,
        LookupKey: '312_31_9',
        InverseLookupKey: '312_9_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15279,
        Amount: 2.8284,
        LookupKey: '312_9_31',
        InverseLookupKey: '312_31_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15280,
        Amount: 800.8159,
        LookupKey: '312_31_21',
        InverseLookupKey: '312_21_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15281,
        Amount: 12.4873,
        LookupKey: '312_21_31',
        InverseLookupKey: '312_31_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15282,
        Amount: 344.8926,
        LookupKey: '312_31_28',
        InverseLookupKey: '312_28_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15283,
        Amount: 28.9945,
        LookupKey: '312_28_31',
        InverseLookupKey: '312_31_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15284,
        Amount: 2538.8929,
        LookupKey: '312_31_29',
        InverseLookupKey: '312_29_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15285,
        Amount: 3.9387,
        LookupKey: '312_29_31',
        InverseLookupKey: '312_31_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15286,
        Amount: 100.0,
        LookupKey: '312_31_31',
        InverseLookupKey: '312_31_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15287,
        Amount: 497.265,
        LookupKey: '312_31_35',
        InverseLookupKey: '312_35_31',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15288,
        Amount: 20.11,
        LookupKey: '312_35_31',
        InverseLookupKey: '312_31_35',
        IsImmutable: false,
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v1/exchange-rate/matrix-exchange-rates-by-period-id?periodId=312',
      Rel: 'self',
    },
  ],
};

export const matrixExchangeRateResultSetForPeriodId311 = {
  Entities: [
    {
      Properties: {
        IntercompanyExchangeRateId: 15191,
        Amount: 100.0,
        LookupKey: '311_35_35',
        InverseLookupKey: '311_35_35',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15192,
        Amount: 100.0,
        LookupKey: '311_2_2',
        InverseLookupKey: '311_2_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15193,
        Amount: 28.0,
        LookupKey: '311_2_35',
        InverseLookupKey: '311_35_2',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15194,
        Amount: 357.1429,
        LookupKey: '311_35_2',
        InverseLookupKey: '311_2_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15195,
        Amount: 50.3018,
        LookupKey: '311_9_2',
        InverseLookupKey: '311_2_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15196,
        Amount: 198.8,
        LookupKey: '311_2_9',
        InverseLookupKey: '311_9_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15197,
        Amount: 100.0,
        LookupKey: '311_9_9',
        InverseLookupKey: '311_9_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15198,
        Amount: 14.0845,
        LookupKey: '311_9_35',
        InverseLookupKey: '311_35_9',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15199,
        Amount: 710.0,
        LookupKey: '311_35_9',
        InverseLookupKey: '311_9_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15200,
        Amount: 221.7672,
        LookupKey: '311_21_2',
        InverseLookupKey: '311_2_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15201,
        Amount: 45.0923,
        LookupKey: '311_2_21',
        InverseLookupKey: '311_21_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15202,
        Amount: 440.8731,
        LookupKey: '311_21_9',
        InverseLookupKey: '311_9_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15203,
        Amount: 22.6823,
        LookupKey: '311_9_21',
        InverseLookupKey: '311_21_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15204,
        Amount: 100.0,
        LookupKey: '311_21_21',
        InverseLookupKey: '311_21_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15205,
        Amount: 62.0948,
        LookupKey: '311_21_35',
        InverseLookupKey: '311_35_21',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15206,
        Amount: 161.0441,
        LookupKey: '311_35_21',
        InverseLookupKey: '311_21_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15207,
        Amount: 514.9276,
        LookupKey: '311_28_2',
        InverseLookupKey: '311_2_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15208,
        Amount: 19.4202,
        LookupKey: '311_2_28',
        InverseLookupKey: '311_28_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15209,
        Amount: 1023.6759,
        LookupKey: '311_28_9',
        InverseLookupKey: '311_9_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15210,
        Amount: 9.7687,
        LookupKey: '311_9_28',
        InverseLookupKey: '311_28_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15211,
        Amount: 232.1929,
        LookupKey: '311_28_21',
        InverseLookupKey: '311_21_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15212,
        Amount: 43.0676,
        LookupKey: '311_21_28',
        InverseLookupKey: '311_28_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15213,
        Amount: 100.0,
        LookupKey: '311_28_28',
        InverseLookupKey: '311_28_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15214,
        Amount: 144.1797,
        LookupKey: '311_28_35',
        InverseLookupKey: '311_35_28',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15215,
        Amount: 69.3579,
        LookupKey: '311_35_28',
        InverseLookupKey: '311_28_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15216,
        Amount: 69.9497,
        LookupKey: '311_29_2',
        InverseLookupKey: '311_2_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15217,
        Amount: 142.96,
        LookupKey: '311_2_29',
        InverseLookupKey: '311_29_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15218,
        Amount: 139.0599,
        LookupKey: '311_29_9',
        InverseLookupKey: '311_9_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15219,
        Amount: 71.9114,
        LookupKey: '311_9_29',
        InverseLookupKey: '311_29_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15220,
        Amount: 31.5419,
        LookupKey: '311_29_21',
        InverseLookupKey: '311_21_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15221,
        Amount: 317.0386,
        LookupKey: '311_21_29',
        InverseLookupKey: '311_29_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15222,
        Amount: 13.5844,
        LookupKey: '311_29_28',
        InverseLookupKey: '311_28_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15223,
        Amount: 736.1385,
        LookupKey: '311_28_29',
        InverseLookupKey: '311_29_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15224,
        Amount: 100.0,
        LookupKey: '311_29_29',
        InverseLookupKey: '311_29_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15225,
        Amount: 19.5859,
        LookupKey: '311_29_35',
        InverseLookupKey: '311_35_29',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15226,
        Amount: 510.5714,
        LookupKey: '311_35_29',
        InverseLookupKey: '311_29_35',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15227,
        Amount: 1776.8302,
        LookupKey: '311_31_2',
        InverseLookupKey: '311_2_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15228,
        Amount: 5.628,
        LookupKey: '311_2_31',
        InverseLookupKey: '311_31_2',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15229,
        Amount: 3532.338,
        LookupKey: '311_31_9',
        InverseLookupKey: '311_9_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15230,
        Amount: 2.831,
        LookupKey: '311_9_31',
        InverseLookupKey: '311_31_9',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15231,
        Amount: 801.2144,
        LookupKey: '311_31_21',
        InverseLookupKey: '311_21_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15232,
        Amount: 12.4811,
        LookupKey: '311_21_31',
        InverseLookupKey: '311_31_21',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15233,
        Amount: 345.0642,
        LookupKey: '311_31_28',
        InverseLookupKey: '311_28_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15234,
        Amount: 28.9801,
        LookupKey: '311_28_31',
        InverseLookupKey: '311_31_28',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15235,
        Amount: 2540.156,
        LookupKey: '311_31_29',
        InverseLookupKey: '311_29_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15236,
        Amount: 3.9368,
        LookupKey: '311_29_31',
        InverseLookupKey: '311_31_29',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15237,
        Amount: 100.0,
        LookupKey: '311_31_31',
        InverseLookupKey: '311_31_31',
        IsImmutable: true,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15238,
        Amount: 497.5124,
        LookupKey: '311_31_35',
        InverseLookupKey: '311_35_31',
        IsImmutable: false,
      },
    },
    {
      Properties: {
        IntercompanyExchangeRateId: 15239,
        Amount: 20.1,
        LookupKey: '311_35_31',
        InverseLookupKey: '311_31_35',
        IsImmutable: false,
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v1/exchange-rate/matrix-exchange-rates-by-period-id?periodId=311',
      Rel: 'self',
    },
  ],
};
