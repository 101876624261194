export default {
  Model: {
    properties: {
      count: 4,
    },
    class: 'viewselector',
    entities: [
      {
        class: 'viewselector',
        properties: {
          name: 'grouped-by',
          options: [
            {
              value: 'group-by-resource',
              selected: true,
            },
            {
              value: 'group-by-work-item',
              selected: false,
            },
          ],
        },
      },
      {
        class: 'viewselector',
        properties: {
          name: 'reporting-types',
          options: [
            {
              value: 'availability',
              selected: false,
            },
            {
              value: 'utilization',
              selected: true,
            },
          ],
        },
      },
      {
        class: 'viewselector',
        properties: {
          name: 'period-types',
          options: [
            {
              value: 'day',
              selected: false,
            },
            {
              value: 'week',
              selected: false,
            },
            {
              value: 'month',
              selected: true,
            },
            {
              value: 'quarter',
              selected: false,
            },
          ],
        },
      },
      {
        class: 'viewselector',
        properties: {
          name: 'unit-types',
          options: [
            {
              value: 'hours',
              selected: true,
            },
            {
              value: 'days',
              selected: false,
            },
            {
              value: 'percentages',
              selected: false,
            },
          ],
        },
      },
    ],
    links: [
      {
        href: 'http://resourceplanner.aks.timelog.com:80/viewoptions',
        rel: 'self',
      },
    ],
  },
  DeterministicHash: -709187100,
  StatusCode: 200,
};
