import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';
import { EXCHANGE_RATE_GET_PERIODS_KEY } from '../get/getPeriods';

const PUT_EXCHANGE_RATE_PERIOD_LOCK_URL_PATH = '/api/v1/exchange-rate/toggle-period-lock';
export const PUT_EXCHANGE_RATE_PERIOD_LOCK_MSW_STATUS_KEY = 'putExchangeRatePeriodLockAPI';

export interface UpdatePeriodLockVariables {
  periodId: number;
  isLockPeriod: boolean;
}

const updatePeriodLock = (updateModel: UpdatePeriodLockVariables) =>
  putFetch({
    path: PUT_EXCHANGE_RATE_PERIOD_LOCK_URL_PATH,
    key: PUT_EXCHANGE_RATE_PERIOD_LOCK_MSW_STATUS_KEY,
    body: JSON.stringify(updateModel),
  });

export const useUpdatePeriodLock = () => {
  const queryClient = useQueryClient();

  return useMutation(updatePeriodLock, {
    onSuccess: () => {
      queryClient.invalidateQueries([EXCHANGE_RATE_GET_PERIODS_KEY]);
    },
  });
};
