export const employeesFullResult = {
  Entities: [
    {
      Properties: {
        Id: 1,
        FirstName: 'John',
        LastName: 'Doe',
        FullName: 'John Doe',
        Initials: 'JD',
        Email: 'john@doe.com',
        DepartmentId: 1,
        DepartmentName: 'Department 1',
        JobTitle: 'Job Title 1',
        ManagerName: 'Manager 1',
        ManagerId: 1,
        ManagerInitials: 'M1',
        Phone: '12345678',
        HourlyRate: '1.200,00 Senior Developer',
        HourlyRateId: 1,
        InternalCost: '700,00 Senior Developer',
        InternalCostId: 1,
        NormalWorkingTime: 'Standard week 1',
        NormalWorkingTimeId: 1,
        SalaryGroup: 'Standard',
        SalaryGroupId: 1,
        EmployeeType: 'Full time',
        EmployeeTypeId: 1,
        PublicHolidayCalendar: 'Denmark',
        PublicHolidayCalendarId: 1,
        EmploymentStartDate: '2023-01-01',
        IsActive: true,
        Competences: [
          {
            Category: 'Category 1',
            Id: 1,
            Items: [
              {
                Id: 1,
                Name: 'Competence 1',
                Level: 4,
              },
              {
                Id: 2,
                Name: 'Competence 2',
                Level: 3,
              },
              {
                Id: 3,
                Name: 'Competence 3',
                Level: 2,
              },
              {
                Id: 4,
                Name: 'Competence 4',
                Level: 1,
              },
            ],
          },
          {
            Category: 'Category 2',
            Id: 2,
            Items: [
              {
                Id: 5,
                Name: 'Competence 5',
                Level: 4,
              },
              {
                Id: 6,
                Name: 'Competence 6',
                Level: 3,
              },
              {
                Id: 7,
                Name: 'Competence 7',
                Level: 2,
              },
              {
                Id: 8,
                Name: 'Competence 8',
                Level: 1,
              },
            ],
          },
        ],
      },
    },
    {
      Properties: {
        Id: 2,
        FirstName: 'Jane',
        LastName: 'Doe',
        FullName: 'Jane sdfsd fsdf sdf sdf sdf sdf sdf sdf Doe',
        Initials: 'JD',
        Email: 'jane@doe.com',
        DepartmentId: 2,
        DepartmentName: 'Department 2',
        JobTitle: 'Job Title 2',
        ManagerName: 'Manager 2',
        ManagerId: 2,
        ManagerInitials: 'M2',
        Phone: '87654321',
        HourlyRate: '1.300,00 Senior Consultant',
        HourlyRateId: 2,
        InternalCost: '800,00 Senior Consultant',
        InternalCostId: 2,
        NormalWorkingTime: 'Standard week 2',
        NormalWorkingTimeId: 2,
        SalaryGroup: 'Standard',
        SalaryGroupId: 1,
        EmployeeType: 'Part time',
        EmployeeTypeId: 2,
        PublicHolidayCalendar: 'Sweden',
        PublicHolidayCalendarId: 2,
        EmploymentStartDate: '2023-01-01',
        IsActive: false,
        Competences: [
          {
            Category: 'Category 1',
            Id: 1,
            Items: [
              {
                Id: 1,
                Name: 'Competence 1',
                Level: 4,
              },
              {
                Id: 2,
                Name: 'Competence 2',
                Level: 3,
              },
              {
                Id: 3,
                Name: 'Competence 3',
                Level: 2,
              },
              {
                Id: 4,
                Name: 'Competence 4',
                Level: 1,
              },
            ],
          },
          {
            Category: 'Category 2',
            Id: 2,
            Items: [
              {
                Id: 5,
                Name: 'Competence 5',
                Level: 4,
              },
              {
                Id: 6,
                Name: 'Competence 6',
                Level: 3,
              },
              {
                Id: 7,
                Name: 'Competence 7',
                Level: 2,
              },
              {
                Id: 8,
                Name: 'Competence 8',
                Level: 1,
              },
            ],
          },
        ],
      },
    },
  ],

  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/employees',
      Rel: 'self',
    },
  ],
};
