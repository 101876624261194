import { useIsFeatureEnable } from 'src/apis/pageSettingsAPI';
import { isDev } from 'src/utils/env';
import { IFeatureControl } from '../../types';

interface FeatureEnableProps
  extends Required<Omit<IFeatureControl, 'indexControlName' | 'actionName' | 'idForFalseScenario'>>,
    Pick<IFeatureControl, 'idForFalseScenario'> {}

const SpanComponent = ({ idForFalseScenario }: Pick<FeatureEnableProps, 'idForFalseScenario'>) => {
  if (idForFalseScenario && isDev)
    return <span data-automation-id={`FeatureDisable${idForFalseScenario}`} />;
  return null;
};

export default ({
  children,
  pageIdentifierKey,
  userFeatureKey,
  idForFalseScenario,
}: FeatureEnableProps) => {
  const { isFeatureEnable } = useIsFeatureEnable(pageIdentifierKey, userFeatureKey);

  return isFeatureEnable ? children : <SpanComponent idForFalseScenario={idForFalseScenario} />;
};
